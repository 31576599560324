import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Center } from "./styles";

const ModalAguarde = ({ popupLoadVisible, cancelarRequest, message }) => {
  return (
    <Modal show={popupLoadVisible}>
      <Modal.Body>
        <Center>
          <Spinner animation="border" className="justify-content-md-center" />
        </Center>
        <Center>
          {message}
          <hr></hr>
          {/* <button onClick={cancelarRequest}>Cancelar consulta</button> */}
        </Center>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAguarde;
