import React, { useEffect } from "react";
import authService from "../services/authService";
import { useHistory } from "react-router-dom";

export default function LoginCRM(props) {
  const params = new URLSearchParams(props.location.search);
  const token = params.get("t"); // bar
  const tokenAPI = params.get("tokenApi");
  const cnpj = params.get("cnpj");

  let history = useHistory();

  useEffect(() => {
    const loginToken = async () => {
      await authService.loginInWithToken(token);

      if (tokenAPI !== undefined && tokenAPI !== null) {
        localStorage.setItem("TOKENAPI", tokenAPI);
      }
      if (cnpj !== undefined && cnpj !== null) {
        history.push("/empresa/" + cnpj);
      } else {
        history.push("/pm");
      }
    };

    loginToken();
  }, [token, history]);

  return <></>;
}
