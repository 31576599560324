import cigamWebApi from "../services/webCigamApiService";

export async function importaEmpresasSelecionadasWebAPI(selected) {
  var usuarioCriacao = localStorage.getItem("CODIGO_USUARIO");

  const select = selected.map((p) => {    
    var obj = {
      NomeCompleto: p.razaoSocial,
      Fantasia: p.fantasia,
      Endereco: p.logradouro,
      Numero:  p.numeroLogradouro,
      Bairro: p.bairro,
      Municipio: p.municipio,
      Uf: p.uf,
      Telefone: p?.dddTelefone1 + " " + p.telefone1,
      CnpjCpf: p.cnpj,
      UsuarioCriacao: usuarioCriacao,
      Cep: p.cep,
      Cnae: p.cnaeFiscal,
      Faturamento: 0, //p.faturamento.replace('.', '').replace(',','.'),
      Complemento: p.complementoLogradouro,
    };

    return obj;
  });

  var res;
  
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('TOKENAPI')}` }
  };

  try {
    res = await cigamWebApi.post("genericos/ge/pessoa/CadastrarBuscaMercado", select, config);

    if (!!res) {
      if (!!res.data) {

        var arrayCadastrados = res.data.data.Cadastrados.map((p) => {
          return {descricao: " Empresa " + p.codigo + " criada para o CNPJ: " + p.cnpj + "\n", variant: "success"};
        });

        var arrayNaoCadastrados = res.data.data.NaoCadastrados.map((p) => {
          return {descricao: " Empresa com CNPJ " + p + " já existe!", variant: "warning"};
        });
        
        return arrayCadastrados.concat(arrayNaoCadastrados);
      }
    }
  } catch (error) {
    alert("Erro ao cadastrar Empresa");
  } 
}


