import { getRequest, postRequest } from "./request";

class ClienteEmpresaService {
  getResumo = () =>
    new Promise((resolve, reject) => {
      getRequest(`clienteEmpresa/resumo`, resolve, reject);
    });

  postCliente = (cnpj) =>
    new Promise((resolve, reject) => {
      postRequest("clienteempresa", { clientes: [{ cnpj }] }, resolve, reject);
    });

  postRemoveCliente = (cnpj) =>
    new Promise((resolve, reject) => {
      postRequest("clienteempresa/remove",  { cnpj } , resolve, reject);
    });

    
  postImportarEmpresa = (data) =>
  new Promise((resolve, reject) => {
    postRequest("empresaUsuario/importar",  data , resolve, reject);
  });
}

export default new ClienteEmpresaService();
