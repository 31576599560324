import React, { useState } from "react";
import { AvatarUsuario, ImagemLogoCigam } from "./assets/images";
import authService from "../../services/authService";
import { Top } from "./assets/styles/styles";
import { ModalAbout } from "../ModalAbout";
import Logout from "../../assets/logout.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const urlIntegration = localStorage.getItem("URLINTEGRATION");
  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      <ModalAbout showModal={showModal} setShowModal={setShowModal} />
      <Top>
        <p>Pesquisa de Mercado</p>
        <div className="logo">
          <ImagemLogoCigam />
          <span> ERP | CRM | BI | RH | PDV | MOBILE </span>
        </div>

        <div className="dropdown">
          <div className="user">
            <div className="avatar">
              <AvatarUsuario />
            </div>
            <p>{localStorage.getItem("NOME_USUARIO")}</p>
          </div>
          <div className="dropdown-content">
            <div className="avatar-dropdown">
              <AvatarUsuario />
            </div>
            <p className="nomeusuario">
              {localStorage.getItem("NOME_USUARIO").toUpperCase()}
            </p>
            <p className="emailusuario">
              Usuário: {localStorage.getItem("NOME_USUARIO")}
            </p>
            <hr className="separator" />
            {localStorage.getItem("RAZAOSOCIAL") === "undefined" ? (
              <></>
            ) : (
              <>
                <p className="razaosocial">
                  {localStorage.getItem("RAZAOSOCIAL")}
                </p>
                <hr className="separator" />
              </>
            )}
           
            {urlIntegration === undefined ? (
              <Link to="/pm/integracoes" className="minhasIntegracoes">
                Minhas Integrações
              </Link>
            ) : null}
            <Link to="#" onClick={openModal} className="sobre">
              Sobre
            </Link>
            <Link to="#" className="logout" onClick={() => authService.logout()}>
              <img src={Logout} alt="logout"></img>
              Sair
            </Link>
          </div>
        </div>
      </Top>
    </>
  );
};

export default Header;
