import { getRequest, postRequest } from "./request";

class IntegracaoEmpresaService {
  getIntegracaoEmpresa = (cnpj) =>
    new Promise((resolve, reject) => {
      getRequest(`integracaoEmpresa/cnpj/${cnpj}`, resolve, reject);
    });

  getIntegracaoEmpresaAll = () =>
    new Promise((resolve, reject) => {
      getRequest("/integracaoEmpresa", resolve, reject);
    });

  postIntegracaoEmpresa = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("/integracaoEmpresa", data, resolve, reject);
    });
  };

  putIntegracaoEmpresa = (data, cnpj) => {
    return new Promise((resolve, reject) => {
      postRequest(`/integracaoEmpresa/${cnpj}`, data, resolve, reject);
    });
  };

  deleteIntegracaoEmpresa = (cnpj) => {
    return new Promise((resolve, reject) => {
      postRequest(`/integracaoEmpresa/delete/cnpj/${cnpj}`, resolve, reject);
    });
  };
  deleteAllIntegracaoEmpresa = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("/integracaoEmpresa/delete/listcnpj",data, resolve, reject);
    });
  };
}

export default new IntegracaoEmpresaService();
