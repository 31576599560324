import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  width: 500px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  @media (min-width: 600) {
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  color: #000;
  background-color: #fff;
  padding: 15px;

  .title {
    font-size: 20px;
    opacity: 0.8;
    margin-left: 15px;
    color: #999999;
    display: flex;
  }
  .close {
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }
  button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: none;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.8;
    margin-left: 15px;
    color: #999999;
  }
`;

export const ModalBody = styled.div`
  background: #ffffff;
  color: #333333;
  font-size: 14px;
  padding-bottom: 1rem;
  

  .modalseparator {
    margin: 0;
    border: 0;
    border-top: 1px solid #ccc;
    padding-bottom: 2rem;
  }

  
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  margin-left: 2rem;
`;

export const Column = styled.div`
  padding: 0.25rem;
  float: left;
  min-height: 1px;
  box-sizing: border-box;
`;

export const ModalFooter = styled.div`
  background: #ffffff;
  color: #333333;
  margin-top: 20px ;
  margin-bottom: 10px;
  font-size: 11px;
  text-align: left;
`;
export const BtnConfirm = styled.button`
  font-size: 12px;
  letter-spacing: var(--unnamed-character-spacing-0);
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 5px;
  border-style: none;
  opacity: 1;
  background: #307da2 0% 0% no-repeat padding-box;
  width: 76px;
  height: 30px;
  text-align: center;
  &:hover {
    color: #ffffff;
    font-size: 12px;
    background: #13597b 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border-style: none;
    opacity: 1;
  }
`;
export const BtnCancel = styled.button`
  margin-left: 5px;
  border-radius: 5px;
  font-size: 12px;
  color: #3d4e68;
  border-style: none;
  background: #ffffff00;
  width: auto;
  width: 76px;
  height: 30px;
  text-align: center;
  &:hover {
    font-size: 12px;
    border-style: none;
    background: #d7d9da;
    color: #3d4e68;
  }
`;
