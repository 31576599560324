import styled from "styled-components";

import Cia from "./../images/cia.png";
import CiaStatus from "./../images/cia-status.png";

export const Top = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  background-color: #f5f5f5;
  position: fixed;
  z-index: 1000;
  padding-right: 80px;
  padding-left: 25px;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;

  p {
    padding-top: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #6c7a8f;
  }

  .logo {
    align-items: center;
    display: flex;
    align-items: center;
  }

  .logo span {
    margin-left: 10px;
    width: 204px;
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) 10px / var(--unnamed-line-spacing-24)
      var(--unnamed-font-family-roboto);
    text-align: left;
    font: normal normal normal 10px/24px Roboto;
    letter-spacing: 0.56px;
    color: #999999;
    opacity: 1;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: auto;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
    margin-left: -6em;
  }

  .nomeusuario {
    font: Roboto;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    margin: 0;
    padding-top: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
    letter-spacing: 0.17px;
    color: #6c7a8f;
    opacity: 1;
  }

  .razaosocial {
    font: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #6c7a8f;
    text-decoration: none;
    text-align: center;
    margin: 0;
    padding-top: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .emailusuario {
    font: Roboto;
    font-size: 10px;
    color: #6c7a8f;
    text-decoration: none;
    text-align: center;
    margin: 0;
    padding-top: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .dropdown-content a:link,
  a:visited {
    font: Roboto;
    font-size: 14px;
    color: #6c7a8f;
    padding: 12px 1.5em;
    text-decoration: none;
    display: block;
  }
  .logout img {
    margin-right: 10px;
  }

  .dropdown-content a:hover,
  a:active {
    background-color: #ddd;
  }
  .dropdown-content .separator {
    border: 0;
    border-top: 1px solid #ccc;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .user {
    display: flex;
    align-items: center;
  }

  .user p {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;

    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .avatar-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .avatar-dropdown img {
    margin: 10px;
    align-items: center;
    width: 45px;
    height: 45px;
  }

  .right-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;

    .cia {
      content: "";
      border-radius: 50px;
      width: 32px;
      height: 32px;
      background-image: url(${Cia});
      position: relative;

      .status {
        background-image: url(${CiaStatus});
        background-size: 10px;
        width: 12px;
        height: 12px;
        bottom: -2px;
        right: -2px;
        background-repeat: no-repeat;
        position: absolute;
      }
    }

    .relative {
      position: relative;
    }
  }

  /*MOBILE*/
  @media only screen and (max-width: 768px) {
    .logo {
      display: none;
    }
  }
`;

