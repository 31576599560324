import React from "react";
import { Main, DivLike, DivDislike, Likes, Dislikes } from "./styles";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";
import complementService from "../../services/complementService";

export const LikeDislike = ({
  idComplement,
  qtdLikes,
  qtdDislikes,
  like,
  dislike,
  setRefreshList,
}) => {
 
  const handleLikes = () => {
    var payload = {};

    if (like) {
      payload = { idInfComp: idComplement, likeDislike: "L", action: false };
    } else {
      payload = { idInfComp: idComplement, likeDislike: "L", action: true };
    }

    return complementService
      .postLike(payload)
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        setRefreshList(true);
      });
  };

  const handleDislikes = () => {
    var payload = {};
    if (dislike) {
      payload = { idInfComp: idComplement, likeDislike: "D", action: false };
    } else {
      payload = { idInfComp: idComplement, likeDislike: "D", action: true };
    }

    return complementService
      .postLike(payload)
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        setRefreshList(true);
      });
  };
 
  return (
    <>
      <Main>
        <DivLike>
          {like ? (
            <AiFillLike onClick={handleLikes} />
          ) : (
            <AiOutlineLike onClick={handleLikes} />
          )}
          <Likes>{!!!qtdLikes ? "0" : qtdLikes}</Likes>
        </DivLike>
        <DivDislike>
          {dislike ? (
            <AiFillDislike onClick={handleDislikes} />
          ) : (
            <AiOutlineDislike onClick={handleDislikes} />
          )}
          <Dislikes>{!!!qtdDislikes ? "0" : qtdDislikes}</Dislikes>
        </DivDislike>
      </Main>
    </>
  );
};
