import React from 'react';

import { Route, Redirect } from 'react-router';

const PrivateRoute =  (props) => {
    const token = localStorage.getItem('TOKEN');
    const validade = localStorage.getItem('VALIDADE');
    const dateAtual = new Date();

    let erro = (!token || token == null);
    
    if(!!validade) {
        const validadeDate = new Date(validade);

        if (!erro) {
            erro = ( dateAtual.getTime()  > validadeDate.getTime() );
        }
    }else {
        erro = true;
    }


    return (!erro)  ? <Route {...props}/> : <Redirect to="/auth" />
}
  

export default PrivateRoute;