import React from "react";

//icones
import Localizacao from "../../assets/Localizacao.svg";

import Operario from "../../assets/Operario.svg";
import Planilha from "../../assets/Planilha.svg";
import Alfinete from "../../assets/Alfinete.svg";
import Finance from "../../assets/Finance.svg";
import Socios from "../../assets/Socios.svg";
import Fone from "../../assets/Fone.svg";
import Styled from "./styledComponent";
import Card from "react-bootstrap/Card";

export default class CardHeaderExtrato extends React.Component {
  render() {
    let icon;

    switch (this.props.title) {
      case "Empresa":
        icon = <img src={Alfinete} size={50} alt="" className="iconCard" />;
        break;
      case "Contato":
        icon = <img src={Fone} size={50} alt="" className="iconCard" />;
        break;
      case "Atividade":
        icon = <img src={Operario} size={50} alt="" className="iconCard" />;
        break;
      case "Localização":
        icon = <img src={Localizacao} size={50} alt="" className="iconCard" />;
        break;
      case "Quadro Societário":
        icon = <img src={Socios} size={50} alt="" className="iconCard" />;
        break;
      case "Tributário":
        icon = <img src={Finance} size={50} alt="" className="iconCard" />;
        break;
      case "Situação Tributária":
        icon = <img src={Finance} size={50} alt="" className="iconCard" />;
        break;
      case "CNAE's":
        icon = (
          <i>
            <img src={Planilha} size={50} alt="" className="iconCard" />{" "}
          </i>
        );
        break;
      default:
        icon = "";
    }

    return (
      <Styled>
        <Card.Header>
          <header>
            <i> {icon} </i>
            <div className="logo">
              <h4 className="title">{this.props.title}</h4>
              <h6 className="subtitle ">{this.props.subtitle}</h6>
            </div>
          </header>
        </Card.Header>
      </Styled>
    );
  }
}
