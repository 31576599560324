export function converteDataMongo(data){

    function convertDate(inputFormat) 
    {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputFormat)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
        
    var dateString  = data;
    var year        = dateString.substring(0,4);
    var month       = dateString.substring(4,6);
    var day         = dateString.substring(6,8);
  
    var date        = convertDate(new Date(year, month-1, day));
  
    date.toLocaleString("pt-BR");
  
    return date;
  }