import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import CardTextExtrato from "../CardTextExtrato";

const CardTextSimplesNacional = ({ dadosSimples }) => {
  return dadosSimples.map((simples, index) => (
    <div key={index + "dadosSimples"}>
      {simples === null ? null : simples.opcao_simples === "S" ? (
        <>
          <Card.Subtitle>Opção Simples</Card.Subtitle>
          <p className="card-text"> Simples Nacional </p>
          <p className="card-text">
            Data: {moment(simples.data_opcao_simples).format("DD/MM/YYYY")}
          </p>
          {simples.data_exclusao_simples === null ? null : (
            <p className="card-text">
              Data Exclusão:{" "}
              {moment(simples.data_exclusao_simples).format("DD/MM/YYYY")}
            </p>
          )}
          <hr />

        </>
      ) : (
        <>
          <Card.Subtitle>Opção Simples</Card.Subtitle>
          <p className="card-text"> MEI </p>
          <p className="card-text">
            Data: {moment(simples.data_opcao_mei).format("DD/MM/YYYY")}
          </p>
          {simples.data_exclusao_mei === null ? null : (
            <p className="card-text">
              Data Exclusão:{" "}
              {moment(simples.data_exclusao_mei).format("DD/MM/YYYY")}
            </p>
          )}
          <hr />
        </>
      )}
    </div>
  ));
};

export default CardTextSimplesNacional;
