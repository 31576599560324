import cigamAPISQLServer from "../services/apiCadastroDeEmpresas";

export async function importaEmpresasSelecionadas(selected) {
  var cd_representante = "2000";
  var cd_pais = "1";
  var usuarioCriacao = localStorage.getItem("CODIGO_USUARIO");

  const select = selected.map((p) => {
    var obj = {
      nome_completo: p.razaoSocial,
      fone: p?.dddTelefone1 + " " + p.telefone1,
      endereco: p.logradouro,
      bairro: p.bairro,
      municipio: p.municipio,
      uf: p.uf,
      cep: p.cep,
      cnpj: p.cnpj,
      fantasia: p.fantasia,
      faturamento: 0,
      numero: p.numeroLogradouro,
      complemento: p.complementoLogradouro,
      cd_pais: cd_pais,
      cd_representante: cd_representante,
      usuario_criacao: usuarioCriacao,
      cnae:p.cnaeFiscal
    };
    return obj;
  });

  var res;

  try {
    res = await cigamAPISQLServer.post("/empresa/empresas", {
      empresas: select,
    });
  } catch (error) {}

     if (!!res) {       
       
       return res.data.map((p) => {
        return !!p.erro  ? {descricao: "Informação: " + p.erro + " - CNPJ: " + p.cnpj + "\n", variant: "info"} : {descricao: " Empresa " + p.empresa + " criada para o CNPJ: " + p.cnpj + "\n", variant: "success"};
      });
     }
}
