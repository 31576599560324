import React, { useState, useEffect, useCallback } from "react";
import Card from "react-bootstrap/Card";

import graphService from "../../services/graphService";
import historicoService from "../../services/historicoService";

import mapDataIE from "@highcharts/map-collection/countries/br/br-all.geo.json";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import Highcharts from "highcharts";

import {
  ContainerMapInfo,
  ContainerPercent,
  ContainerDescricao,
  ContainerDescricaoGrafico,
  ContainerEstado,
  ContainerGrafico,
  Mapa,
  Grafico,
  Layout,
  InfoMap,
  Container,
} from "./styles";
import { UFPorExtenso } from "../../utils/CodigoParaDescricao";

const GraphGeo = () => {
  const [selected, setSelected] = useState([]);
  const [options2, setOptions2] = useState({});
  const [options, setOptions] = useState({});
  const [data, setData] = useState([]);
  const [UFAtualizado, setUFAtualizado] = useState([]);
  const [historico, setHistorico] = useState({});

  highchartsMap(Highcharts);

  const staticOptionsGraph1 = {
    chart: {
      styledMode: true,
      height: 650,
      spacingLeft: 0,
    },

    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },

    tooltip: {
      headerFormat: "",
      pointFormat: `
        <b>{point.name}</b>: {point.value}%`,
    },
    colorAxis: {
      stops: [
        [0, "#8DCEB1"],
        [0.3, "#2b9348"],
        [0.6, "#ffd60a"],
        [1, "#FF5811"],
      ],
    },
    series: [
      {
        name: "Basemap",
        mapData: mapDataIE,
        borderColor: "#FFC3BA",
        borderWidth: 0.5,
        nullColor: "#FFEAE4",
        showInLegend: false,
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          // format: "{point.value:.1f}%",
          // format: "{point.name}",
          formatter: function () {
            return this.point.properties["hc-a2"];
          },
          style: {
            color: "#222",
            textOutline: false,
            fontFamily: "serif",
            fontSize: "9px",
          },
          states: {
            select: {
              borderColor: "#DEE2E6",
              color: "#307DA2",
            },
          },
        },
        point: {
          events: {
            click: function (oEvent) {
              callExternalFunction(oEvent.point); //call function  with arguments
            },
          },
        },
      },
    ],
  };

  const staticOptionsGraph2 = {
    chart: {
      styledMode: true,
      type: "bar",
      height: 650,
      spacingLeft: 0,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.x + ": </b> " + this.y + "%";
      },
      shared: true,
    },
    colorAxis: {
      stops: [
        [0, "#8DCEB1"],
        [0.3, "#0ead69"],
        [0.6, "#3da5d9"],
        [1, "#FF5811"],
      ],
    },
    xAxis: {},
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f} %",
        },
        //stacking: "normal",
      },
    },
  };

  function callExternalFunction(obj) {
    const uf = obj["hc-key"].replace("br-", "");
    setSelected([obj.municipio, obj.value, uf]);
    carregaUf([obj.municipio, obj.value, uf]);
    historicoService.postHistoricoGeoGraph({
      ...historico,
      indexGraphGeo: obj.index,
    });
  }

  const carregaUf = useCallback(
    (selected) => {
      const chamaGeoUf = async () => {
        const uf = selected[2];
        const responseUF = await graphService.getGeoUF(uf);

        const listaMunicipios = responseUF.map((data) => {
          return data.municipio;
        });

        const listaPercentual = responseUF.map((data) => {
          return data.percentual;
        });
        //Converte o estado de sigla para extenso
        const atualizaUF = selected[2];
        setUFAtualizado(UFPorExtenso(atualizaUF));

        const series = [
          {
            name: "Municipio",
            data: listaPercentual,
          },
        ];

        setOptions2({
          ...staticOptionsGraph2,
          xAxis: {
            categories: listaMunicipios,
          },
          series,
        });
      };

      chamaGeoUf();
    },
    [staticOptionsGraph2]
  );

  useEffect(() => {
    async function fetchData() {
      const get = await historicoService.getHistoricoAutoComplete();
      setHistorico(get);

      // const a = graphService.formataGraphGeo(await graphService.getGeo());

      var af = [
        ["br-sp", 30.12],
        ["br-ma", 1.29],
        ["br-pa", 1.99],
        ["br-sc", 4.81],
        ["br-ba", 5.04],
        ["br-ap", 0.24],
        ["br-ms", 1.24],
        ["br-mg", 10.35],
        ["br-go", 3.36],
        ["br-rs", 6.07],
        ["br-to", 0.62],
        ["br-pi", 0.74],
        ["br-al", 1.04],
        ["br-pb", 1.3],
        ["br-ce", 2.85],
        ["br-se", 0.68],
        ["br-rr", 0.19],
        ["br-pe", 3.02],
        ["br-pr", 6.7],
        ["br-es", 2.17],
        ["br-rj", 9.49],
        ["br-rn", 1.14],
        ["br-am", 1.03],
        ["br-mt", 1.83],
        ["br-df", 1.91],
        ["br-ac", 0.2],
        ["br-ro", 0.57],
      ];

      // console.log(
      //   a.map((a) => {
      //     return { "hc-key": a[0], value: a[1] };
      //   })
      // );

      setData(af);
    }

    fetchData();
  }, []);

  useEffect(() => {
    let indexMapa = !!historico ? historico.indexGraphGeo : 0;

    if (!!data) {
      if (data.length > 0) {
        if (indexMapa === 0 || indexMapa === undefined) {
          data.forEach((a, index) => {
            if (a[0] === "br-sp") {
              indexMapa = index;
            }
          });
        }

        const firstUf = data[indexMapa];

        const uf = !!data[indexMapa][0]
          ? data[indexMapa][0].replace("br-", "").toUpperCase()
          : "";
        setSelected([uf, firstUf[1], uf]);
        carregaUf([uf, firstUf[1], uf]);
      }
    }
    setOptions({
      ...staticOptionsGraph1,
      series: [
        {
          ...staticOptionsGraph1.series[0],
          data,
          states: {
            select: {
              borderColor: "#DEE2E6",
              color: "#307DA2",
            },
          },
        },
      ],
    });
  }, [data]);

  return (
    <Container>
      <Card className="text-left">
        <Card.Header>Perfilização</Card.Header>
        <Layout>
          <ContainerMapInfo>
            <InfoMap>
              <ContainerPercent>{selected[1]}%</ContainerPercent>
              <div>
                <ContainerDescricao>estão localizados em</ContainerDescricao>
                <ContainerEstado>
                  {UFAtualizado === "Brasil" ? selected[0] : UFAtualizado}
                </ContainerEstado>
              </div>
            </InfoMap>
            <Mapa>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"mapChart"}
                options={options}
              />
            </Mapa>
          </ContainerMapInfo>

          <ContainerGrafico>
            <ContainerDescricaoGrafico>
              Cidades de{" "}
              {UFAtualizado === "Brasil" ? selected[0] : UFAtualizado}
            </ContainerDescricaoGrafico>

            <Grafico>
              <HighchartsReact highcharts={Highcharts} options={options2} />
            </Grafico>
          </ContainerGrafico>
        </Layout>
      </Card>
    </Container>
  );
};

export default GraphGeo;
