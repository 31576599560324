import React, { useCallback, useRef, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { RiPlayListAddFill } from "react-icons/ri";
import {
  Background,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  Row,
  Column,
  LabelSplit,
  LabelIn,
  Values,
  Value,
} from "./styles";
import ComplementService from "../../services/complementService";
import Button from "react-bootstrap/Button";

export const ModalSimple = ({
  showModal,
  setShowModal,
  cnpjSelected,
  setRefreshList,
  attributes,
}) => {
  const modalRef = useRef();
  const [complements, setComplements] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };
  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  const handleChange = (event, value = 0) => {
    const newValue = complements;
    newValue[event.target.name] = event.target.value;
    setComplements({ ...newValue });
  };

  const handleSubmit = (publish) => {
    var obj = [];
    var telefone;
    var inscricao;
    var faturamento;
    var email;
    var colaboradores;
    var site;

    if (!!complements.fat1 && !!complements.fat2) {
      faturamento = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 1,
        values: [{ value: complements.fat1 }, { value: complements.fat2 }],
        namecard: "Empresa",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(faturamento);
    }

    if (!!complements.tel) {
      telefone = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 2,
        values: [{ value: complements.tel }],
        namecard: "Contato",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(telefone);
    }

    if (!!complements.email) {
      email = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 3,
        values: [{ value: complements.email }],
        namecard: "Contato",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(email);
    }

    if (!!complements.site) {
      var https =
        complements.site.includes("https://") ||
        complements.site.includes("http://");
      site = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 4,
        values: https
          ? [{ value: complements.site }]
          : [{ value: "https://" + complements.site }],
        namecard: "Empresa",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(site);
    }

    if (!!complements.colab1 && !!complements.colab2) {
      colaboradores = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 5,
        values: [{ value: complements.colab1 }, { value: complements.colab2 }],
        namecard: "Empresa",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(colaboradores);
    }

    if (!!complements.inscricao) {
      inscricao = {
        usuario: localStorage.getItem("CODIGO_USUARIO"),
        id_atribute: 6,
        values: [{ value: complements.inscricao }],
        namecard: "Empresa",
        cnpj: cnpjSelected,
        validated: true,
      };
      obj.push(inscricao);
    }

    if (obj.length === 0) {
      return enqueueSnackbar("Nenhum campo preenchido", {
        variant: "info",
      });
    } else {
      return ComplementService.postComplement(obj)
        .then((res) => {
          enqueueSnackbar("Informação complementar adicionada com sucesso!", {
            variant: "success",
          });
          setShowModal(false);
          setComplements([]);
        })
        .catch((error) => {
          enqueueSnackbar("Ocorreu um erro ao adicionar", {
            variant: "error",
          });
        })
        .finally(() => {
          setRefreshList();
        });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <ModalWrapper showModal={showModal}>
            <ModalHeader>
              <button
                onClick={() => setShowModal((prev) => !prev)}
                className="close"
                aria-label="Fechar"
              >
                <span>x</span>
              </button>

              <span className="title">
                <RiPlayListAddFill className="iconeHeader" />
                Informação complementar
              </span>
            </ModalHeader>
            <ModalBody>
              <hr className="modalseparator" />

              <Row>
                <Column>
                  <form>
                    {attributes.map((attribute) => (
                      <>
                        {/* <LabelSplit>{attribute.name}:</LabelSplit> */}

                        {attribute.id === 0 && null}
                        {attribute.id === 1 && (
                          <>
                            <LabelSplit>{attribute.name}</LabelSplit>
                            <Values>
                              <Value>
                                <InputMask
                                  id="value"
                                  type="number"
                                  className="form-value"
                                  placeholder="De"
                                  onChange={handleChange}
                                  name="fat1"
                                ></InputMask>
                              </Value>
                              <Value>
                                <InputMask
                                  id="value2"
                                  type="number"
                                  className="form-value"
                                  placeholder="Até"
                                  onChange={handleChange}
                                  name="fat2"
                                ></InputMask>
                              </Value>
                            </Values>
                          </>
                        )}
                        {attribute.id === 2 && (
                          <>
                            <InputMask
                              id="value"
                              mask="(99) 99999-9999"
                              maskPlaceholder={null}
                              type="tel"
                              className="form-full"
                              placeholder="Telefone"
                              onChange={handleChange}
                              name="tel"
                            ></InputMask>
                          </>
                        )}
                        {attribute.id === 3 && (
                          <>
                            <input
                              id="value"
                              type="email"
                              className="form-full"
                              placeholder="E-mail"
                              onChange={handleChange}
                              name="email"
                            ></input>
                          </>
                        )}
                        {attribute.id === 4 && (
                          <>
                            <input
                              id="value"
                              type="site"
                              className="form-full"
                              placeholder="Site"
                              onChange={handleChange}
                              name="site"
                            ></input>
                          </>
                        )}
                        {attribute.id === 5 && (
                          <>
                            <LabelSplit>
                              <LabelIn>{attribute.name}</LabelIn>

                              {/* <LabelIn>
                                <LabelIn>Até</LabelIn>
                              </LabelIn> */}
                            </LabelSplit>
                            <Values>
                              <Value>
                                <InputMask
                                  id="value"
                                  type="number"
                                  className="form-value"
                                  placeholder="De"
                                  onChange={handleChange}
                                  name="colab1"
                                ></InputMask>
                              </Value>
                              <Value>
                                <InputMask
                                  id="value2"
                                  type="number"
                                  className="form-value"
                                  placeholder="Até"
                                  onChange={handleChange}
                                  name="colab2"
                                ></InputMask>
                              </Value>
                            </Values>
                          </>
                        )}
                        {attribute.id === 6 && (
                          <>
                            <InputMask
                              id="value"
                              type="number"
                              className="form-full"
                              placeholder="Inscrição Estadual"
                              onChange={handleChange}
                              name="inscricao"
                            ></InputMask>
                          </>
                        )}
                      </>
                    ))}
                  </form>
                </Column>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="none"
                className="buttons"
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
