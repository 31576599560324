import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { Title, Subtitle, Main, Left, Right } from "./styles";
import { LikeDislike } from "../LikeDislike";

const CardTextComplement = ({
  id,
  idComplement,
  value,
  ultimoElementoDoCard,
  qtdDislikes,
  qtdLikes,
  like,
  dislike,
  setRefreshList,
  attribute,
}) => {
  const [attributeID, setAttributeID] = useState("");
  useEffect(() => {
    setAttributeID(!!attribute ? attribute.find((a) => a.id === id) : "");
  }, []);
  return (
    <>
      <Main>
        <Left>
          <Title>
            <AiOutlineInfoCircle
              style={{
                color: "#FF5811",
                fontSize: "16px",
                alignItems: "center",
              }}
              title="Informação complementar"
            />
            <Card.Subtitle className="text">{attributeID?.name}</Card.Subtitle>
          </Title>
          <Subtitle>
            <Card.Text id={id} className="subtitle">
              {attributeID?.typedata === "range" && attributeID?.id === 1 ? (
                value[1]?.value === undefined ? (
                  value[0]?.value
                ) : (
                  "De R$ " + value[0]?.value + " Até R$ " + value[1]?.value
                )
              ) : attributeID?.typedata === "range" && attributeID?.id === 5 ? (
                value[1]?.value === undefined ? (
                  +value[0]?.value
                ) : (
                  "De " + value[0]?.value + " Até " + value[1]?.value
                )
              ) : attributeID?.id === 4 ? (
                <a
                  href={value[0]?.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {value[0]?.value}
                </a>
              ) : (
                value[0]?.value
              )}
            </Card.Text>
            {ultimoElementoDoCard === true ? " " : " "}

            <hr></hr>
          </Subtitle>
        </Left>
        <Right>
          <LikeDislike
            idComplement={idComplement}
            qtdLikes={qtdLikes}
            qtdDislikes={qtdDislikes}
            like={like}
            dislike={dislike}
            setRefreshList={setRefreshList}
          />
        </Right>
      </Main>
    </>
  );
};
export default CardTextComplement;
