import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
`;

export const ModalWrapper = styled.div`
  width: 600px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  @media (min-width: 600) {
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  color: #000;
  background-color: #fff;
  padding: 20px;

  .title {
    font-size: 20px;
    opacity: 0.8;
    color: #307da2;
    display: flex;
  }
  .close {
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }
  button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: none;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.8;
    margin-left: 15px;
    color: #999999;
  }
  .title svg {
    margin: 2px;
    width: 27px;
    height: auto;
    margin-right: 20px;
  }
`;

export const ModalBody = styled.div`
  background: #ffffff;
  color: #222222;
  font-size: 14px;
  padding-bottom: 1rem;
  height: auto;

  .modalseparator {
    margin: 0;
    border: 0;
    border-top: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .form-select {
    width: 100%;
  padding: 12px 20px;
  margin-bottom: 18px ;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;

  }
  .form-full {
    width: 100%;
  padding: 12px 20px;
  margin-bottom: 18px ;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  
  }
  .form-value {
    width: 100%;
  padding: 12px 20px;
  margin-bottom: 18px ;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
 
  }
.form-value:focus, .form-full:focus, .form-select:focus {
  border: 2px solid #555;
}
`;
export const Value = styled.div`
  width: 50%;
  padding: 2px;
  
`;
export const Values = styled.div`
  display: flex;
`;
export const LabelIn = styled.div`
  width: 50%;
  font-weight: normal;
  /* padding: 2px; */
`;

export const LabelSplit = styled.div`
color: #666;
  width: auto;
  display: flex;
  padding-left: 2px;
`;

export const Row = styled.div`
  width: -webkit-fill-available;
  height: auto;
  float: left;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const Column = styled.div`
  padding: 0.25rem;
  float: left;
  min-height: 1px;
  box-sizing: border-box;
  width: 100%;
`;

export const ModalFooter = styled.div`
  background: #ffffff;
  color: #333333;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 11px;
  text-align: left;
  .buttons {
    font-size: 14px;
  }
`;
