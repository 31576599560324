import React from 'react'

const CheckboxFilter = ({label, name, checked, onChange, disabled}) => 
{
    return(<div className="row">
                <div className="form-group col-md-10">
                    <label>
                        <input type="checkbox" name ={name} disabled={disabled} checked={checked === undefined ? false :  checked } onChange={onChange} />  
                        {label}
                    </label>
                </div>
            </div>   
    )
}

export default CheckboxFilter
