import React, { useEffect, useState } from "react";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import { BiImport } from "react-icons/bi";
import { importaEmpresasSelecionadas } from "../../utils/APIImportaEmpresasSelecionadas";
import { importaEmpresasSelecionadasWebAPI } from "../../utils/APIImportaEmpresasSelecionadasWEBAPI";


import { useSnackbar } from "notistack";
import { ModalConfirm } from "../ModalConfirm";
import "./style.css";
import integracaoEmpresaService from "../../services/integracaoEmpresaService";

const TableResults = ({ expandRow, columns, tableResults }) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const urlIntegration = localStorage.getItem("URLINTEGRATION");
  const cnpjLogado = localStorage.getItem("CNPJ");
  const [confirm, setConfirm] = useState(false);
  const [selected, setSelected] = useState([]);
  const [empresasIntegradas, setEmpresasIntegradas] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const tokenAPI = localStorage.getItem("TOKENAPI");

  const openModal = () => {
    setShowModalConfirm((prev) => !prev);
  };

  const selectRow2 = {
    mode: "checkbox",
    hideSelectColumn: true,
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    color: "#def3ff",

    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        const newArray = selected.concat(row);
        setSelected(newArray);
        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });

        setEmpresasIntegradas(newArrayIntegration);
      } else {
        const newArray = selected.filter((p) => p.cnpj !== row.cnpj);
        setSelected(newArray);
        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });
        setEmpresasIntegradas(newArrayIntegration);
      }
    },

    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const newArray = selected.concat(rows);
        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });
        setSelected(newArray);
        setEmpresasIntegradas(newArrayIntegration);
      } else {
        setSelected([]);
        setEmpresasIntegradas([]);
      }
    },
  };

  const importar = () => {
    openModal();
  };

  useEffect(() => {
    const importAll = async () => {
      console.log(urlIntegration)
      if (urlIntegration === undefined) {
        //enviar para minhas intergrações
        if (confirm) {
           await integracaoEmpresaService
            .postIntegracaoEmpresa(empresasIntegradas)
            .then((res) => {
              enqueueSnackbar("Importado com sucesso!", {
                variant: "success",
              });
              setEmpresasIntegradas([]);
            })
            .catch((error) => {
              enqueueSnackbar("Ocorreu um erro ao importar", {
                variant: "error",
              });
            })
            .finally(() => {});
        }
      } else {
        if (confirm) {

          let result = null;
          if (((tokenAPI !== undefined) && (tokenAPI !== null)) && (cnpjLogado !== "93578813000144")) 
             result = await importaEmpresasSelecionadasWebAPI(selected);  
          else
             result = await importaEmpresasSelecionadas(selected);
                  
          if (!!result) {
            result.forEach((p) => {
              enqueueSnackbar(p.descricao, { variant: p.variant });
            });
          }
        }
      }
    };
    importAll();
    setConfirm(false);
  }, [confirm, empresasIntegradas, enqueueSnackbar, selected, urlIntegration]);

  return (
    <>
      <ModalConfirm
        showModalConfirm={showModalConfirm}
        setShowModalConfirm={setShowModalConfirm}
        setConfirm={setConfirm}
        message={
          selected.length === 1
            ? "Tem certeza que deseja importar a empresa selecionada?"
            : "Tem certeza que deseja importar as empresas selecionadas?"
        }
      />
      <div style={{ marginTop: 30 }}>
        <BootstrapTable
          striped
          hover
          keyField="cnpj"
          data={tableResults}
          selectRow={cnpjLogado === "93578813000144" ? selectRow : selectRow2}
          columns={columns}
          filter={filterFactory()}
        />
        <div className="">
          <img src="" alt="" />
        </div>
        {selected.length !== 0 && cnpjLogado === "93578813000144" ? (
          <>
            <div className="fab_import">
              <button
                className="import"
                id={"btn"}
                onClick={() => importar()}
                title="Importar"
              >
                <BiImport
                  alt=""
                  style={{ fontSize: 24 }}
                  className="iconCard"
                />
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TableResults;
