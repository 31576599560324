import React, { useCallback, useRef, useEffect } from "react";
import {
  Background,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  Row,
  Column,
  BtnCancel,
  BtnConfirm,
} from "./styles";

export const ModalConfirm = ({
  showModalConfirm,
  setShowModalConfirm,
  setConfirm,
  message,
  okButton,
  messages,
  headerMessage,
}) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModalConfirm(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalConfirm) {
        setShowModalConfirm(false);
      }
    },
    [setShowModalConfirm, showModalConfirm]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const confirma = () => {
    setConfirm(true);
    setShowModalConfirm(false);
  };

  const cancela = () => {
    setConfirm(false);
    setShowModalConfirm(false);
  };

  return (
    <>
      {showModalConfirm ? (
        <Background onClick={closeModal} ref={modalRef}>
          <ModalWrapper showModalConfirm={showModalConfirm}>
            <ModalHeader>
              <button
                onClick={() => setShowModalConfirm((prev) => !prev)}
                className="close"
                aria-label="Fechar"
              >
                <span>x</span>
              </button>
              <span className="title">
                {headerMessage !== undefined ? headerMessage : "Atenção"}
              </span>
            </ModalHeader>
            <ModalBody>
              <hr className="modalseparator" />

              {messages !== undefined ? (
                messages.map((e) => (
                  <Row>
                    <Column>{e}</Column>
                  </Row>
                ))
              ) : (
                <Row>
                  <Column>{message}</Column>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              {okButton === undefined || okButton === false ? (
                <Row>
                  <Column>
                    <BtnConfirm
                      type="button"
                      className="btnConfirma"
                      onClick={() => confirma()}
                    >
                      SIM
                    </BtnConfirm>
                  </Column>
                  <Column>
                    <BtnCancel
                      type="button"
                      className="btnCancela"
                      onClick={() => cancela()}
                    >
                      NÂO
                    </BtnCancel>
                  </Column>
                </Row>
              ) : (
                <Row>
                  <Column>
                    <BtnCancel
                      type="button"
                      className="btnCancela"
                      onClick={() => cancela()}
                    >
                      OK
                    </BtnCancel>
                  </Column>
                </Row>
              )}
            </ModalFooter>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
