import styled from "styled-components";

export const Balao = styled.div`
  background: red;
  position: fixed;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.5;
  p {
    text-align: center;
    color: white;
    font-size: 20px;
  }
  img {
    width: 50px;
    height: 50px;
    color: white;
  }
  :hover{
    opacity: 1;
  }
  `;
