export function descricaoSituacaoCadastral(situacao) {
  var Descricaosituacao = "";
  switch (situacao) {
    case "01":
      Descricaosituacao = "01 - NULA";
      break;
    case "02":
      Descricaosituacao = "02 - ATIVA";
      break;
    case "03":
      Descricaosituacao = "03 - SUSPENSA";
      break;
    case "04":
      Descricaosituacao = "04 - INAPTA";
      break;
    default:
      Descricaosituacao = "08 - BAIXADA";
  }
  return Descricaosituacao;
}

export function descricaoFilialMatrix(filial) {
  return filial === "1" ? "Matriz" : "Filial";
}

export function descricaoPorteEmpresa(porteEmpresa) {
  var descricaoPorteEmpresa = "";
  switch (porteEmpresa) {
    case "00":
      descricaoPorteEmpresa = "00 - NAO INFORMADO";
      break;
    case "01":
      descricaoPorteEmpresa = "01 - MICRO EMPRESA";
      break;
    case "03":
      descricaoPorteEmpresa = "03 - EMPRESA DE PEQUENO PORTE";
      break;
    default:
      descricaoPorteEmpresa = "05 - DEMAIS";
  }

  return descricaoPorteEmpresa;
}

export function UFPorExtenso(uf) {
  const ufFormatado = !!uf ? uf.toUpperCase() : "";
  var nomeEstado = "";

  switch (ufFormatado) {
    case "AC":
      nomeEstado = "Acre";
      break;
    case "AL":
      nomeEstado = "Alagoas";
      break;
    case "AP":
      nomeEstado = "Amapá";
      break;
    case "AM":
      nomeEstado = "Amazonas";
      break;
    case "BA":
      nomeEstado = "Bahia";
      break;
    case "CE":
      nomeEstado = "Ceará";
      break;
    case "DF":
      nomeEstado = "Distrito Federal";
      break;
    case "ES":
      nomeEstado = "Espírito Santo";
      break;
    case "GO":
      nomeEstado = "Goiás";
      break;
    case "MA":
      nomeEstado = "Maranhão";
      break;
    case "MT":
      nomeEstado = "Mato Grosso";
      break;
    case "MS":
      nomeEstado = "Mato Grosso do Sul";
      break;
    case "MG":
      nomeEstado = "Minas Gerais";
      break;
    case "PA":
      nomeEstado = "Pará";
      break;
    case "PB":
      nomeEstado = "Paraíba";
      break;
    case "PR":
      nomeEstado = "Paraná";
      break;
    case "PE":
      nomeEstado = "Pernambuco";
      break;
    case "PI":
      nomeEstado = "Piauí";
      break;
    case "RJ":
      nomeEstado = "Rio de Janeiro";
      break;
    case "RN":
      nomeEstado = "Rio Grande do Norte";
      break;
    case "RS":
      nomeEstado = "Rio Grande do Sul";
      break;
    case "RO":
      nomeEstado = "Rondônia";
      break;
    case "RR":
      nomeEstado = "Roraima";
      break;
    case "SC":
      nomeEstado = "Santa Catarina";
      break;
    case "SP":
      nomeEstado = "São Paulo";
      break;
    case "SE":
      nomeEstado = "Sergipe";
      break;
    case "TO":
      nomeEstado = "Tocantins";
      break;
    default:
      nomeEstado = "Brasil";
  }

  return nomeEstado;
}
