export function getArrayMunicipios(arrayEstados){
  
    var filtered = [
            {id: "Alta Floresta D'oeste", name: "RO - Alta Floresta D'oeste"},
            {id: "Ariquemes", name: "RO - Ariquemes"},
            {id: "Cabixi", name: "RO - Cabixi"},
            {id: "Cacoal", name: "RO - Cacoal"},
            {id: "Cerejeiras", name: "RO - Cerejeiras"},
            {id: "Colorado do Oeste", name: "RO - Colorado do Oeste"},
            {id: "Corumbiara", name: "RO - Corumbiara"},
            {id: "Costa Marques", name: "RO - Costa Marques"},
            {id: "Espigão D'oeste", name: "RO - Espigão D'oeste"},
            {id: "Guajará-mirim", name: "RO - Guajará-mirim"},
            {id: "Jaru", name: "RO - Jaru"},
            {id: "Ji-paraná", name: "RO - Ji-paraná"},
            {id: "Machadinho D'oeste", name: "RO - Machadinho D'oeste"},
            {id: "Nova Brasilândia D'oeste", name: "RO - Nova Brasilândia D'oeste"},
            {id: "Ouro Preto do Oeste", name: "RO - Ouro Preto do Oeste"},
            {id: "Pimenta Bueno", name: "RO - Pimenta Bueno"},
            {id: "Porto Velho", name: "RO - Porto Velho"}, 
            {id: "Presidente Médici", name: "RO - Presidente Médici"},
            {id: "Rio Crespo", name: "RO - Rio Crespo"},
            {id: "Rolim de Moura", name: "RO - Rolim de Moura"},
            {id: "Santa Luzia D'oeste", name: "RO - Santa Luzia D'oeste"},
            {id: "Vilhena", name: "RO - Vilhena"},
            {id: "São Miguel do Guaporé", name: "RO - São Miguel do Guaporé"},
            {id: "Nova Mamoré", name: "RO - Nova Mamoré"},
            {id: "Alvorada D'oeste", name: "RO - Alvorada D'oeste"},
            {id: "Alto Alegre Dos Parecis", name: "RO - Alto Alegre Dos Parecis"},
            {id: "Alto Paraíso", name: "RO - Alto Paraíso"},
            {id: "Buritis", name: "RO - Buritis"},
            {id: "Novo Horizonte do Oeste", name: "RO - Novo Horizonte do Oeste"},
            {id: "Cacaulândia", name: "RO - Cacaulândia"},
            {id: "Campo Novo de Rondônia", name: "RO - Campo Novo de Rondônia"},
            {id: "Candeias do Jamari", name: "RO - Candeias do Jamari"},
            {id: "Castanheiras", name: "RO - Castanheiras"},
            {id: "Chupinguaia", name: "RO - Chupinguaia"},
            {id: "Cujubim", name: "RO - Cujubim"}, 
            {id: "Governador Jorge Teixeira", name: "RO - Governador Jorge Teixeira"},
            {id: "Itapuã do Oeste", name: "RO - Itapuã do Oeste"},
            {id: "Ministro Andreazza", name: "RO - Ministro Andreazza"},
            {id: "Mirante da Serra", name: "RO - Mirante da Serra"},
            {id: "Monte Negro", name: "RO - Monte Negro"},
            {id: "Nova União", name: "RO - Nova União"},
            {id: "Parecis", name: "RO - Parecis"},
            {id: "Pimenteiras do Oeste", name: "RO - Pimenteiras do Oeste"},
            {id: "Primavera de Rondônia", name: "RO - Primavera de Rondônia"},
            {id: "São Felipe D'oeste", name: "RO - São Felipe D'oeste"},
            {id: "São Francisco do Guaporé", name: "RO - São Francisco do Guaporé"},
            {id: "Seringueiras", name: "RO - Seringueiras"},
            {id: "Teixeirópolis", name: "RO - Teixeirópolis"},
            {id: "Theobroma", name: "RO - Theobroma"},
            {id: "Urupá", name: "RO - Urupá"},
            {id: "Vale do Anari", name: "RO - Vale do Anari"},
            {id: "Vale do Paraíso", name: "RO - Vale do Paraíso"},
            {id: "Acrelândia", name: "AC - Acrelândia"},
            {id: "Assis Brasil", name: "AC - Assis Brasil"},
            {id: "Brasiléia", name: "AC - Brasiléia"},
            {id: "Bujari", name: "AC - Bujari"},
            {id: "Capixaba", name: "AC - Capixaba"},
            {id: "Cruzeiro do Sul", name: "AC - Cruzeiro do Sul"},
            {id: "Epitaciolândia", name: "AC - Epitaciolândia"},
            {id: "Feijó", name: "AC - Feijó"},
            {id: "Jordão", name: "AC - Jordão"},
            {id: "Mâncio Lima", name: "AC - Mâncio Lima"},
            {id: "Manoel Urbano", name: "AC - Manoel Urbano"},
            {id: "Marechal Thaumaturgo", name: "AC - Marechal Thaumaturgo"},
            {id: "Plácido de Castro", name: "AC - Plácido de Castro"},
            {id: "Porto Walter", name: "AC - Porto Walter"},
            {id: "Rio Branco", name: "AC - Rio Branco"},
            {id: "Rodrigues Alves", name: "AC - Rodrigues Alves"},
            {id: "Santa Rosa do Purus", name: "AC - Santa Rosa do Purus"},
            {id: "Senador Guiomard", name: "AC - Senador Guiomard"},
            {id: "Sena Madureira", name: "AC - Sena Madureira"},
            {id: "Tarauacá", name: "AC - Tarauacá"},
            {id: "Xapuri", name: "AC - Xapuri"},
            {id: "Porto Acre", name: "AC - Porto Acre"},
            {id: "Alvarães", name: "AM - Alvarães"},
            {id: "Amaturá", name: "AM - Amaturá"},
            {id: "Anamã", name: "AM - Anamã"},
            {id: "Anori", name: "AM - Anori"},
            {id: "Apuí", name: "AM - Apuí"},
            {id: "Atalaia do Norte", name: "AM - Atalaia do Norte"},
            {id: "Autazes", name: "AM - Autazes"},
            {id: "Barcelos", name: "AM - Barcelos"},
            {id: "Barreirinha", name: "AM - Barreirinha"},
            {id: "Benjamin Constant", name: "AM - Benjamin Constant"},
            {id: "Beruri", name: "AM - Beruri"},
            {id: "Boa Vista do Ramos", name: "AM - Boa Vista do Ramos"},
            {id: "Boca do Acre", name: "AM - Boca do Acre"},
            {id: "Borba", name: "AM - Borba"},
            {id: "Caapiranga", name: "AM - Caapiranga"},
            {id: "Canutama", name: "AM - Canutama"},
            {id: "Carauari", name: "AM - Carauari"},
            {id: "Careiro", name: "AM - Careiro"},
            {id: "Careiro da Várzea", name: "AM - Careiro da Várzea"},
            {id: "Coari", name: "AM - Coari"},
            {id: "Codajás", name: "AM - Codajás"},
            {id: "Eirunepé", name: "AM - Eirunepé"},
            {id: "Envira", name: "AM - Envira"},
            {id: "Fonte Boa", name: "AM - Fonte Boa"},
            {id: "Guajará", name: "AM - Guajará"},
            {id: "Humaitá", name: "AM - Humaitá"},
            {id: "Ipixuna", name: "AM - Ipixuna"},
            {id: "Iranduba", name: "AM - Iranduba"},
            {id: "Itacoatiara", name: "AM - Itacoatiara"},
            {id: "Itamarati", name: "AM - Itamarati"},
            {id: "Itapiranga", name: "AM - Itapiranga"},
            {id: "Japurá", name: "AM - Japurá"},
            {id: "Juruá", name: "AM - Juruá"},
            {id: "Jutaí", name: "AM - Jutaí"},
            {id: "Lábrea", name: "AM - Lábrea"},
            {id: "Manacapuru", name: "AM - Manacapuru"},
            {id: "Manaquiri", name: "AM - Manaquiri"},
            {id: "Manaus", name: "AM - Manaus"},
            {id: "Manicoré", name: "AM - Manicoré"},
            {id: "Maraã", name: "AM - Maraã"},
            {id: "Maués", name: "AM - Maués"},
            {id: "Nhamundá", name: "AM - Nhamundá"},
            {id: "Nova Olinda do Norte", name: "AM - Nova Olinda do Norte"},
            {id: "Novo Airão", name: "AM - Novo Airão"},
            {id: "Novo Aripuanã", name: "AM - Novo Aripuanã"},
            {id: "Parintins", name: "AM - Parintins"},
            {id: "Pauini", name: "AM - Pauini"},
            {id: "Presidente Figueiredo", name: "AM - Presidente Figueiredo"},
            {id: "Rio Preto da Eva", name: "AM - Rio Preto da Eva"},
            {id: "Santa Isabel do Rio Negro", name: "AM - Santa Isabel do Rio Negro"},
            {id: "Santo Antônio do Içá", name: "AM - Santo Antônio do Içá"},
            {id: "São Gabriel da Cachoeira", name: "AM - São Gabriel da Cachoeira"},
            {id: "São Paulo de Olivença", name: "AM - São Paulo de Olivença"},
            {id: "São Sebastião do Uatumã", name: "AM - São Sebastião do Uatumã"},
            {id: "Silves", name: "AM - Silves"},
            {id: "Tabatinga", name: "AM - Tabatinga"},
            {id: "Tapauá", name: "AM - Tapauá"},
            {id: "Tefé", name: "AM - Tefé"},
            {id: "Tonantins", name: "AM - Tonantins"},
            {id: "Uarini", name: "AM - Uarini"},
            {id: "Urucará", name: "AM - Urucará"},
            {id: "Urucurituba", name: "AM - Urucurituba"},
            {id: "Amajari", name: "RR - Amajari"},
            {id: "Alto Alegre", name: "RR - Alto Alegre"},
            {id: "Boa Vista", name: "RR - Boa Vista"},
            {id: "Bonfim", name: "RR - Bonfim"},
            {id: "Cantá", name: "RR - Cantá"},
            {id: "Caracaraí", name: "RR - Caracaraí"},
            {id: "Caroebe", name: "RR - Caroebe"},
            {id: "Iracema", name: "RR - Iracema"},
            {id: "Mucajaí", name: "RR - Mucajaí"},
            {id: "Normandia", name: "RR - Normandia"},
            {id: "Pacaraima", name: "RR - Pacaraima"},
            {id: "Rorainópolis", name: "RR - Rorainópolis"},
            {id: "São João da Baliza", name: "RR - São João da Baliza"},
            {id: "São Luiz", name: "RR - São Luiz"},
            {id: "Uiramutã", name: "RR - Uiramutã"},
            {id: "Abaetetuba", name: "PA - Abaetetuba"},
            {id: "Abel Figueiredo", name: "PA - Abel Figueiredo"},
            {id: "Acará", name: "PA - Acará"},
            {id: "Afuá", name: "PA - Afuá"},
            {id: "Água Azul do Norte", name: "PA - Água Azul do Norte"},
            {id: "Alenquer", name: "PA - Alenquer"},
            {id: "Almeirim", name: "PA - Almeirim"},
            {id: "Altamira", name: "PA - Altamira"},
            {id: "Anajás", name: "PA - Anajás"},
            {id: "Ananindeua", name: "PA - Ananindeua"},
            {id: "Anapu", name: "PA - Anapu"},
            {id: "Augusto Corrêa", name: "PA - Augusto Corrêa"},
            {id: "Aurora do Pará", name: "PA - Aurora do Pará"},
            {id: "Aveiro", name: "PA - Aveiro"},
            {id: "Bagre", name: "PA - Bagre"},
            {id: "Baião", name: "PA - Baião"},
            {id: "Bannach", name: "PA - Bannach"},
            {id: "Barcarena", name: "PA - Barcarena"},
            {id: "Belém", name: "PA - Belém"},
            {id: "Belterra", name: "PA - Belterra"},
            {id: "Benevides", name: "PA - Benevides"},
            {id: "Bom Jesus do Tocantins", name: "PA - Bom Jesus do Tocantins"},
            {id: "Bonito", name: "PA - Bonito"},
            {id: "Bragança", name: "PA - Bragança"},
            {id: "Brasil Novo", name: "PA - Brasil Novo"},
            {id: "Brejo Grande do Araguaia", name: "PA - Brejo Grande do Araguaia"},
            {id: "Breu Branco", name: "PA - Breu Branco"},
            {id: "Breves", name: "PA - Breves"},
            {id: "Bujaru", name: "PA - Bujaru"},
            {id: "Cachoeira do Piriá", name: "PA - Cachoeira do Piriá"},
            {id: "Cachoeira do Arari", name: "PA - Cachoeira do Arari"},
            {id: "Cametá", name: "PA - Cametá"},
            {id: "Canaã Dos Carajás", name: "PA - Canaã Dos Carajás"},
            {id: "Capanema", name: "PA - Capanema"},
            {id: "Capitão Poço", name: "PA - Capitão Poço"},
            {id: "Castanhal", name: "PA - Castanhal"},
            {id: "Chaves", name: "PA - Chaves"},
            {id: "Colares", name: "PA - Colares"},
            {id: "Conceição do Araguaia", name: "PA - Conceição do Araguaia"},
            {id: "Concórdia do Pará", name: "PA - Concórdia do Pará"},
            {id: "Cumaru do Norte", name: "PA - Cumaru do Norte"},
            {id: "Curionópolis", name: "PA - Curionópolis"},
            {id: "Curralinho", name: "PA - Curralinho"},
            {id: "Curuá", name: "PA - Curuá"},
            {id: "Curuçá", name: "PA - Curuçá"},
            {id: "Dom Eliseu", name: "PA - Dom Eliseu"},
            {id: "Eldorado Dos Carajás", name: "PA - Eldorado Dos Carajás"},
            {id: "Faro", name: "PA - Faro"},
            {id: "Floresta do Araguaia", name: "PA - Floresta do Araguaia"},
            {id: "Garrafão do Norte", name: "PA - Garrafão do Norte"},
            {id: "Goianésia do Pará", name: "PA - Goianésia do Pará"},
            {id: "Gurupá", name: "PA - Gurupá"},
            {id: "Igarapé-açu", name: "PA - Igarapé-açu"},
            {id: "Igarapé-miri", name: "PA - Igarapé-miri"},
            {id: "Inhangapi", name: "PA - Inhangapi"},
            {id: "Ipixuna do Pará", name: "PA - Ipixuna do Pará"},
            {id: "Irituia", name: "PA - Irituia"},
            {id: "Itaituba", name: "PA - Itaituba"},
            {id: "Itupiranga", name: "PA - Itupiranga"},
            {id: "Jacareacanga", name: "PA - Jacareacanga"},
            {id: "Jacundá", name: "PA - Jacundá"},
            {id: "Juruti", name: "PA - Juruti"},
            {id: "Limoeiro do Ajuru", name: "PA - Limoeiro do Ajuru"},
            {id: "Mãe do Rio", name: "PA - Mãe do Rio"},
            {id: "Magalhães Barata", name: "PA - Magalhães Barata"},
            {id: "Marabá", name: "PA - Marabá"},
            {id: "Maracanã", name: "PA - Maracanã"},
            {id: "Marapanim", name: "PA - Marapanim"},
            {id: "Marituba", name: "PA - Marituba"},
            {id: "Medicilândia", name: "PA - Medicilândia"},
            {id: "Melgaço", name: "PA - Melgaço"},
            {id: "Mocajuba", name: "PA - Mocajuba"},
            {id: "Moju", name: "PA - Moju"},
            {id: "Mojuí dos Campos", name: "PA - Mojuí dos Campos"},
            {id: "Monte Alegre", name: "PA - Monte Alegre"},
            {id: "Muaná", name: "PA - Muaná"},
            {id: "Nova Esperança do Piriá", name: "PA - Nova Esperança do Piriá"},
            {id: "Nova Ipixuna", name: "PA - Nova Ipixuna"},
            {id: "Nova Timboteua", name: "PA - Nova Timboteua"},
            {id: "Novo Progresso", name: "PA - Novo Progresso"},
            {id: "Novo Repartimento", name: "PA - Novo Repartimento"},
            {id: "Óbidos", name: "PA - Óbidos"},
            {id: "Oeiras do Pará", name: "PA - Oeiras do Pará"},
            {id: "Oriximiná", name: "PA - Oriximiná"},
            {id: "Ourém", name: "PA - Ourém"},
            {id: "Ourilândia do Norte", name: "PA - Ourilândia do Norte"},
            {id: "Pacajá", name: "PA - Pacajá"},
            {id: "Palestina do Pará", name: "PA - Palestina do Pará"},
            {id: "Paragominas", name: "PA - Paragominas"},
            {id: "Parauapebas", name: "PA - Parauapebas"},
            {id: "Pau D'arco", name: "PA - Pau D'arco"},
            {id: "Peixe-boi", name: "PA - Peixe-boi"},
            {id: "Piçarra", name: "PA - Piçarra"},
            {id: "Placas", name: "PA - Placas"},
            {id: "Ponta de Pedras", name: "PA - Ponta de Pedras"},
            {id: "Portel", name: "PA - Portel"},
            {id: "Porto de Moz", name: "PA - Porto de Moz"},
            {id: "Prainha", name: "PA - Prainha"},
            {id: "Primavera", name: "PA - Primavera"},
            {id: "Quatipuru", name: "PA - Quatipuru"},
            {id: "Redenção", name: "PA - Redenção"},
            {id: "Rio Maria", name: "PA - Rio Maria"},
            {id: "Rondon do Pará", name: "PA - Rondon do Pará"},
            {id: "Rurópolis", name: "PA - Rurópolis"},
            {id: "Salinópolis", name: "PA - Salinópolis"},
            {id: "Salvaterra", name: "PA - Salvaterra"},
            {id: "Santa Bárbara do Pará", name: "PA - Santa Bárbara do Pará"},
            {id: "Santa Cruz do Arari", name: "PA - Santa Cruz do Arari"},
            {id: "Santa Isabel do Pará", name: "PA - Santa Isabel do Pará"},
            {id: "Santa Luzia do Pará", name: "PA - Santa Luzia do Pará"},
            {id: "Santa Maria Das Barreiras", name: "PA - Santa Maria Das Barreiras"},
            {id: "Santa Maria do Pará", name: "PA - Santa Maria do Pará"},
            {id: "Santana do Araguaia", name: "PA - Santana do Araguaia"},
            {id: "Santarém", name: "PA - Santarém"},
            {id: "Santarém Novo", name: "PA - Santarém Novo"},
            {id: "Santo Antônio do Tauá", name: "PA - Santo Antônio do Tauá"},
            {id: "São Caetano de Odivelas", name: "PA - São Caetano de Odivelas"},
            {id: "São Domingos do Araguaia", name: "PA - São Domingos do Araguaia"},
            {id: "São Domingos do Capim", name: "PA - São Domingos do Capim"},
            {id: "São Félix do Xingu", name: "PA - São Félix do Xingu"},
            {id: "São Francisco do Pará", name: "PA - São Francisco do Pará"},
            {id: "São Geraldo do Araguaia", name: "PA - São Geraldo do Araguaia"},
            {id: "São João da Ponta", name: "PA - São João da Ponta"},
            {id: "São João de Pirabas", name: "PA - São João de Pirabas"},
            {id: "São João do Araguaia", name: "PA - São João do Araguaia"},
            {id: "São Miguel do Guamá", name: "PA - São Miguel do Guamá"},
            {id: "São Sebastião da Boa Vista", name: "PA - São Sebastião da Boa Vista"},
            {id: "Sapucaia", name: "PA - Sapucaia"},
            {id: "Senador José Porfírio", name: "PA - Senador José Porfírio"},
            {id: "Soure", name: "PA - Soure"},
            {id: "Tailândia", name: "PA - Tailândia"},
            {id: "Terra Alta", name: "PA - Terra Alta"},
            {id: "Terra Santa", name: "PA - Terra Santa"},
            {id: "Tomé-açu", name: "PA - Tomé-açu"},
            {id: "Tracuateua", name: "PA - Tracuateua"},
            {id: "Trairão", name: "PA - Trairão"},
            {id: "Tucumã", name: "PA - Tucumã"},
            {id: "Tucuruí", name: "PA - Tucuruí"},
            {id: "Ulianópolis", name: "PA - Ulianópolis"},
            {id: "Uruará", name: "PA - Uruará"},
            {id: "Vigia", name: "PA - Vigia"},
            {id: "Viseu", name: "PA - Viseu"},
            {id: "Vitória do Xingu", name: "PA - Vitória do Xingu"},
            {id: "Xinguara", name: "PA - Xinguara"},
            {id: "Serra do Navio", name: "AP - Serra do Navio"},
            {id: "Amapá", name: "AP - Amapá"},
            {id: "Pedra Branca do Amapari", name: "AP - Pedra Branca do Amapari"},
            {id: "Calçoene", name: "AP - Calçoene"},
            {id: "Cutias", name: "AP - Cutias"},
            {id: "Ferreira Gomes", name: "AP - Ferreira Gomes"},
            {id: "Itaubal", name: "AP - Itaubal"},
            {id: "Laranjal do Jari", name: "AP - Laranjal do Jari"},
            {id: "Macapá", name: "AP - Macapá"},
            {id: "Mazagão", name: "AP - Mazagão"},
            {id: "Oiapoque", name: "AP - Oiapoque"},
            {id: "Porto Grande", name: "AP - Porto Grande"},
            {id: "Pracuúba", name: "AP - Pracuúba"},
            {id: "Santana", name: "AP - Santana"},
            {id: "Tartarugalzinho", name: "AP - Tartarugalzinho"},
            {id: "Vitória do Jari", name: "AP - Vitória do Jari"},
            {id: "Abreulândia", name: "TO - Abreulândia"},
            {id: "Aguiarnópolis", name: "TO - Aguiarnópolis"},
            {id: "Aliança do Tocantins", name: "TO - Aliança do Tocantins"},
            {id: "Almas", name: "TO - Almas"},
            {id: "Alvorada", name: "TO - Alvorada"},
            {id: "Ananás", name: "TO - Ananás"},
            {id: "Angico", name: "TO - Angico"},
            {id: "Aparecida do Rio Negro", name: "TO - Aparecida do Rio Negro"},
            {id: "Aragominas", name: "TO - Aragominas"},
            {id: "Araguacema", name: "TO - Araguacema"},
            {id: "Araguaçu", name: "TO - Araguaçu"},
            {id: "Araguaína", name: "TO - Araguaína"},
            {id: "Araguanã", name: "TO - Araguanã"},
            {id: "Araguatins", name: "TO - Araguatins"},
            {id: "Arapoema", name: "TO - Arapoema"},
            {id: "Arraias", name: "TO - Arraias"},
            {id: "Augustinópolis", name: "TO - Augustinópolis"},
            {id: "Aurora do Tocantins", name: "TO - Aurora do Tocantins"},
            {id: "Axixá do Tocantins", name: "TO - Axixá do Tocantins"},
            {id: "Babaçulândia", name: "TO - Babaçulândia"},
            {id: "Bandeirantes do Tocantins", name: "TO - Bandeirantes do Tocantins"},
            {id: "Barra do Ouro", name: "TO - Barra do Ouro"},
            {id: "Barrolândia", name: "TO - Barrolândia"},
            {id: "Bernardo Sayão", name: "TO - Bernardo Sayão"},
            {id: "Bom Jesus do Tocantins", name: "TO - Bom Jesus do Tocantins"},
            {id: "Brasilândia do Tocantins", name: "TO - Brasilândia do Tocantins"},
            {id: "Brejinho de Nazaré", name: "TO - Brejinho de Nazaré"},
            {id: "Buriti do Tocantins", name: "TO - Buriti do Tocantins"},
            {id: "Cachoeirinha", name: "TO - Cachoeirinha"},
            {id: "Campos Lindos", name: "TO - Campos Lindos"},
            {id: "Cariri do Tocantins", name: "TO - Cariri do Tocantins"},
            {id: "Carmolândia", name: "TO - Carmolândia"},
            {id: "Carrasco Bonito", name: "TO - Carrasco Bonito"},
            {id: "Caseara", name: "TO - Caseara"},
            {id: "Centenário", name: "TO - Centenário"},
            {id: "Chapada de Areia", name: "TO - Chapada de Areia"},
            {id: "Chapada da Natividade", name: "TO - Chapada da Natividade"},
            {id: "Colinas do Tocantins", name: "TO - Colinas do Tocantins"},
            {id: "Combinado", name: "TO - Combinado"},
            {id: "Conceição do Tocantins", name: "TO - Conceição do Tocantins"},
            {id: "Couto de Magalhães", name: "TO - Couto de Magalhães"},
            {id: "Cristalândia", name: "TO - Cristalândia"},
            {id: "Crixás do Tocantins", name: "TO - Crixás do Tocantins"},
            {id: "Darcinópolis", name: "TO - Darcinópolis"},
            {id: "Dianópolis", name: "TO - Dianópolis"},
            {id: "Divinópolis do Tocantins", name: "TO - Divinópolis do Tocantins"},
            {id: "Dois Irmãos do Tocantins", name: "TO - Dois Irmãos do Tocantins"},
            {id: "Dueré", name: "TO - Dueré"},
            {id: "Esperantina", name: "TO - Esperantina"},
            {id: "Fátima", name: "TO - Fátima"},
            {id: "Figueirópolis", name: "TO - Figueirópolis"},
            {id: "Filadélfia", name: "TO - Filadélfia"},
            {id: "Formoso do Araguaia", name: "TO - Formoso do Araguaia"},
            {id: "Fortaleza do Tabocão", name: "TO - Fortaleza do Tabocão"},
            {id: "Goianorte", name: "TO - Goianorte"},
            {id: "Goiatins", name: "TO - Goiatins"},
            {id: "Guaraí", name: "TO - Guaraí"},
            {id: "Gurupi", name: "TO - Gurupi"},
            {id: "Ipueiras", name: "TO - Ipueiras"},
            {id: "Itacajá", name: "TO - Itacajá"},
            {id: "Itaguatins", name: "TO - Itaguatins"},
            {id: "Itapiratins", name: "TO - Itapiratins"},
            {id: "Itaporã do Tocantins", name: "TO - Itaporã do Tocantins"},
            {id: "Jaú do Tocantins", name: "TO - Jaú do Tocantins"},
            {id: "Juarina", name: "TO - Juarina"},
            {id: "Lagoa da Confusão", name: "TO - Lagoa da Confusão"},
            {id: "Lagoa do Tocantins", name: "TO - Lagoa do Tocantins"},
            {id: "Lajeado", name: "TO - Lajeado"},
            {id: "Lavandeira", name: "TO - Lavandeira"},
            {id: "Lizarda", name: "TO - Lizarda"},
            {id: "Luzinópolis", name: "TO - Luzinópolis"},
            {id: "Marianópolis do Tocantins", name: "TO - Marianópolis do Tocantins"},
            {id: "Mateiros", name: "TO - Mateiros"},
            {id: "Maurilândia do Tocantins", name: "TO - Maurilândia do Tocantins"},
            {id: "Miracema do Tocantins", name: "TO - Miracema do Tocantins"},
            {id: "Miranorte", name: "TO - Miranorte"},
            {id: "Monte do Carmo", name: "TO - Monte do Carmo"},
            {id: "Monte Santo do Tocantins", name: "TO - Monte Santo do Tocantins"},
            {id: "Palmeiras do Tocantins", name: "TO - Palmeiras do Tocantins"},
            {id: "Muricilândia", name: "TO - Muricilândia"},
            {id: "Natividade", name: "TO - Natividade"},
            {id: "Nazaré", name: "TO - Nazaré"},
            {id: "Nova Olinda", name: "TO - Nova Olinda"},
            {id: "Nova Rosalândia", name: "TO - Nova Rosalândia"},
            {id: "Novo Acordo", name: "TO - Novo Acordo"},
            {id: "Novo Alegre", name: "TO - Novo Alegre"},
            {id: "Novo Jardim", name: "TO - Novo Jardim"},
            {id: "Oliveira de Fátima", name: "TO - Oliveira de Fátima"},
            {id: "Palmeirante", name: "TO - Palmeirante"},
            {id: "Palmeirópolis", name: "TO - Palmeirópolis"},
            {id: "Paraíso do Tocantins", name: "TO - Paraíso do Tocantins"},
            {id: "Paranã", name: "TO - Paranã"},
            {id: "Pau D'arco", name: "TO - Pau D'arco"},
            {id: "Pedro Afonso", name: "TO - Pedro Afonso"},
            {id: "Peixe", name: "TO - Peixe"},
            {id: "Pequizeiro", name: "TO - Pequizeiro"},
            {id: "Colméia", name: "TO - Colméia"},
            {id: "Pindorama do Tocantins", name: "TO - Pindorama do Tocantins"},
            {id: "Piraquê", name: "TO - Piraquê"},
            {id: "Pium", name: "TO - Pium"},
            {id: "Ponte Alta do Bom Jesus", name: "TO - Ponte Alta do Bom Jesus"},
            {id: "Ponte Alta do Tocantins", name: "TO - Ponte Alta do Tocantins"},
            {id: "Porto Alegre do Tocantins", name: "TO - Porto Alegre do Tocantins"},
            {id: "Porto Nacional", name: "TO - Porto Nacional"},
            {id: "Praia Norte", name: "TO - Praia Norte"},
            {id: "Presidente Kennedy", name: "TO - Presidente Kennedy"},
            {id: "Pugmil", name: "TO - Pugmil"},
            {id: "Recursolândia", name: "TO - Recursolândia"},
            {id: "Riachinho", name: "TO - Riachinho"},
            {id: "Rio da Conceição", name: "TO - Rio da Conceição"},
            {id: "Rio Dos Bois", name: "TO - Rio Dos Bois"},
            {id: "Rio Sono", name: "TO - Rio Sono"},
            {id: "Sampaio", name: "TO - Sampaio"},
            {id: "Sandolândia", name: "TO - Sandolândia"},
            {id: "Santa fé do Araguaia", name: "TO - Santa fé do Araguaia"},
            {id: "Santa Maria do Tocantins", name: "TO - Santa Maria do Tocantins"},
            {id: "Santa Rita do Tocantins", name: "TO - Santa Rita do Tocantins"},
            {id: "Santa Rosa do Tocantins", name: "TO - Santa Rosa do Tocantins"},
            {id: "Santa Tereza do Tocantins", name: "TO - Santa Tereza do Tocantins"},
            {id: "Santa Terezinha do Tocantins", name: "TO - Santa Terezinha do Tocantins"},
            {id: "São Bento do Tocantins", name: "TO - São Bento do Tocantins"},
            {id: "São Félix do Tocantins", name: "TO - São Félix do Tocantins"},
            {id: "São Miguel do Tocantins", name: "TO - São Miguel do Tocantins"},
            {id: "São Salvador do Tocantins", name: "TO - São Salvador do Tocantins"},
            {id: "São Sebastião do Tocantins", name: "TO - São Sebastião do Tocantins"},
            {id: "São Valério da Natividade", name: "TO - São Valério da Natividade"},
            {id: "Silvanópolis", name: "TO - Silvanópolis"},
            {id: "Sítio Novo do Tocantins", name: "TO - Sítio Novo do Tocantins"},
            {id: "Sucupira", name: "TO - Sucupira"},
            {id: "Taguatinga", name: "TO - Taguatinga"},
            {id: "Taipas do Tocantins", name: "TO - Taipas do Tocantins"},
            {id: "Talismã", name: "TO - Talismã"},
            {id: "Palmas", name: "TO - Palmas"},
            {id: "Tocantínia", name: "TO - Tocantínia"},
            {id: "Tocantinópolis", name: "TO - Tocantinópolis"},
            {id: "Tupirama", name: "TO - Tupirama"},
            {id: "Tupiratins", name: "TO - Tupiratins"},
            {id: "Wanderlândia", name: "TO - Wanderlândia"},
            {id: "Xambioá", name: "TO - Xambioá"},
            {id: "Açailândia", name: "MA - Açailândia"},
            {id: "Afonso Cunha", name: "MA - Afonso Cunha"},
            {id: "Água Doce do Maranhão", name: "MA - Água Doce do Maranhão"},
            {id: "Alcântara", name: "MA - Alcântara"},
            {id: "Aldeias Altas", name: "MA - Aldeias Altas"},
            {id: "Altamira do Maranhão", name: "MA - Altamira do Maranhão"},
            {id: "Alto Alegre do Maranhão", name: "MA - Alto Alegre do Maranhão"},
            {id: "Alto Alegre do Pindaré", name: "MA - Alto Alegre do Pindaré"},
            {id: "Alto Parnaíba", name: "MA - Alto Parnaíba"},
            {id: "Amapá do Maranhão", name: "MA - Amapá do Maranhão"},
            {id: "Amarante do Maranhão", name: "MA - Amarante do Maranhão"},
            {id: "Anajatuba", name: "MA - Anajatuba"},
            {id: "Anapurus", name: "MA - Anapurus"},
            {id: "Apicum-açu", name: "MA - Apicum-açu"},
            {id: "Araguanã", name: "MA - Araguanã"},
            {id: "Araioses", name: "MA - Araioses"},
            {id: "Arame", name: "MA - Arame"},
            {id: "Arari", name: "MA - Arari"},
            {id: "Axixá", name: "MA - Axixá"},
            {id: "Bacabal", name: "MA - Bacabal"},
            {id: "Bacabeira", name: "MA - Bacabeira"},
            {id: "Bacuri", name: "MA - Bacuri"},
            {id: "Bacurituba", name: "MA - Bacurituba"},
            {id: "Balsas", name: "MA - Balsas"},
            {id: "Barão de Grajaú", name: "MA - Barão de Grajaú"},
            {id: "Barra do Corda", name: "MA - Barra do Corda"},
            {id: "Barreirinhas", name: "MA - Barreirinhas"},
            {id: "Belágua", name: "MA - Belágua"},
            {id: "Bela Vista do Maranhão", name: "MA - Bela Vista do Maranhão"},
            {id: "Benedito Leite", name: "MA - Benedito Leite"},
            {id: "Bequimão", name: "MA - Bequimão"},
            {id: "Bernardo do Mearim", name: "MA - Bernardo do Mearim"},
            {id: "Boa Vista do Gurupi", name: "MA - Boa Vista do Gurupi"},
            {id: "Bom Jardim", name: "MA - Bom Jardim"},
            {id: "Bom Jesus Das Selvas", name: "MA - Bom Jesus Das Selvas"},
            {id: "Bom Lugar", name: "MA - Bom Lugar"},
            {id: "Brejo", name: "MA - Brejo"},
            {id: "Brejo de Areia", name: "MA - Brejo de Areia"},
            {id: "Buriti", name: "MA - Buriti"},
            {id: "Buriti Bravo", name: "MA - Buriti Bravo"},
            {id: "Buriticupu", name: "MA - Buriticupu"},
            {id: "Buritirana", name: "MA - Buritirana"},
            {id: "Cachoeira Grande", name: "MA - Cachoeira Grande"},
            {id: "Cajapió", name: "MA - Cajapió"},
            {id: "Cajari", name: "MA - Cajari"},
            {id: "Campestre do Maranhão", name: "MA - Campestre do Maranhão"},
            {id: "Cândido Mendes", name: "MA - Cândido Mendes"},
            {id: "Cantanhede", name: "MA - Cantanhede"},
            {id: "Capinzal do Norte", name: "MA - Capinzal do Norte"},
            {id: "Carolina", name: "MA - Carolina"},
            {id: "Carutapera", name: "MA - Carutapera"},
            {id: "Caxias", name: "MA - Caxias"},
            {id: "Cedral", name: "MA - Cedral"},
            {id: "Central do Maranhão", name: "MA - Central do Maranhão"},
            {id: "Centro do Guilherme", name: "MA - Centro do Guilherme"},
            {id: "Centro Novo do Maranhão", name: "MA - Centro Novo do Maranhão"},
            {id: "Chapadinha", name: "MA - Chapadinha"},
            {id: "Cidelândia", name: "MA - Cidelândia"},
            {id: "Codó", name: "MA - Codó"},
            {id: "Coelho Neto", name: "MA - Coelho Neto"},
            {id: "Colinas", name: "MA - Colinas"},
            {id: "Conceição do Lago-açu", name: "MA - Conceição do Lago-açu"},
            {id: "Coroatá", name: "MA - Coroatá"},
            {id: "Cururupu", name: "MA - Cururupu"},
            {id: "Davinópolis", name: "MA - Davinópolis"},
            {id: "Dom Pedro", name: "MA - Dom Pedro"},
            {id: "Duque Bacelar", name: "MA - Duque Bacelar"},
            {id: "Esperantinópolis", name: "MA - Esperantinópolis"},
            {id: "Estreito", name: "MA - Estreito"},
            {id: "Feira Nova do Maranhão", name: "MA - Feira Nova do Maranhão"},
            {id: "Fernando Falcão", name: "MA - Fernando Falcão"},
            {id: "Formosa da Serra Negra", name: "MA - Formosa da Serra Negra"},
            {id: "Fortaleza Dos Nogueiras", name: "MA - Fortaleza Dos Nogueiras"},
            {id: "Fortuna", name: "MA - Fortuna"},
            {id: "Godofredo Viana", name: "MA - Godofredo Viana"},
            {id: "Gonçalves Dias", name: "MA - Gonçalves Dias"},
            {id: "Governador Archer", name: "MA - Governador Archer"},
            {id: "Governador Edison Lobão", name: "MA - Governador Edison Lobão"},
            {id: "Governador Eugênio Barros", name: "MA - Governador Eugênio Barros"},
            {id: "Governador Luiz Rocha", name: "MA - Governador Luiz Rocha"},
            {id: "Governador Newton Bello", name: "MA - Governador Newton Bello"},
            {id: "Governador Nunes Freire", name: "MA - Governador Nunes Freire"},
            {id: "Graça Aranha", name: "MA - Graça Aranha"},
            {id: "Grajaú", name: "MA - Grajaú"},
            {id: "Guimarães", name: "MA - Guimarães"},
            {id: "Humberto de Campos", name: "MA - Humberto de Campos"},
            {id: "Icatu", name: "MA - Icatu"},
            {id: "Igarapé do Meio", name: "MA - Igarapé do Meio"},
            {id: "Igarapé Grande", name: "MA - Igarapé Grande"},
            {id: "Imperatriz", name: "MA - Imperatriz"},
            {id: "Itaipava do Grajaú", name: "MA - Itaipava do Grajaú"},
            {id: "Itapecuru Mirim", name: "MA - Itapecuru Mirim"},
            {id: "Itinga do Maranhão", name: "MA - Itinga do Maranhão"},
            {id: "Jatobá", name: "MA - Jatobá"},
            {id: "Jenipapo Dos Vieiras", name: "MA - Jenipapo Dos Vieiras"},
            {id: "João Lisboa", name: "MA - João Lisboa"},
            {id: "Joselândia", name: "MA - Joselândia"},
            {id: "Junco do Maranhão", name: "MA - Junco do Maranhão"},
            {id: "Lago da Pedra", name: "MA - Lago da Pedra"},
            {id: "Lago do Junco", name: "MA - Lago do Junco"},
            {id: "Lago Verde", name: "MA - Lago Verde"},
            {id: "Lagoa do Mato", name: "MA - Lagoa do Mato"},
            {id: "Lago Dos Rodrigues", name: "MA - Lago Dos Rodrigues"},
            {id: "Lagoa Grande do Maranhão", name: "MA - Lagoa Grande do Maranhão"},
            {id: "Lajeado Novo", name: "MA - Lajeado Novo"},
            {id: "Lima Campos", name: "MA - Lima Campos"},
            {id: "Loreto", name: "MA - Loreto"},
            {id: "Luís Domingues", name: "MA - Luís Domingues"},
            {id: "Magalhães de Almeida", name: "MA - Magalhães de Almeida"},
            {id: "Maracaçumé", name: "MA - Maracaçumé"},
            {id: "Marajá do Sena", name: "MA - Marajá do Sena"},
            {id: "Maranhãozinho", name: "MA - Maranhãozinho"},
            {id: "Mata Roma", name: "MA - Mata Roma"},
            {id: "Matinha", name: "MA - Matinha"},
            {id: "Matões", name: "MA - Matões"},
            {id: "Matões do Norte", name: "MA - Matões do Norte"},
            {id: "Milagres do Maranhão", name: "MA - Milagres do Maranhão"},
            {id: "Mirador", name: "MA - Mirador"},
            {id: "Miranda do Norte", name: "MA - Miranda do Norte"},
            {id: "Mirinzal", name: "MA - Mirinzal"},
            {id: "Monção", name: "MA - Monção"},
            {id: "Montes Altos", name: "MA - Montes Altos"},
            {id: "Morros", name: "MA - Morros"},
            {id: "Nina Rodrigues", name: "MA - Nina Rodrigues"},
            {id: "Nova Colinas", name: "MA - Nova Colinas"},
            {id: "Nova Iorque", name: "MA - Nova Iorque"},
            {id: "Nova Olinda do Maranhão", name: "MA - Nova Olinda do Maranhão"},
            {id: "Olho D'água Das Cunhãs", name: "MA - Olho D'água Das Cunhãs"},
            {id: "Olinda Nova do Maranhão", name: "MA - Olinda Nova do Maranhão"},
            {id: "Paço do Lumiar", name: "MA - Paço do Lumiar"},
            {id: "Palmeirândia", name: "MA - Palmeirândia"},
            {id: "Paraibano", name: "MA - Paraibano"},
            {id: "Parnarama", name: "MA - Parnarama"},
            {id: "Passagem Franca", name: "MA - Passagem Franca"},
            {id: "Pastos Bons", name: "MA - Pastos Bons"},
            {id: "Paulino Neves", name: "MA - Paulino Neves"},
            {id: "Paulo Ramos", name: "MA - Paulo Ramos"},
            {id: "Pedreiras", name: "MA - Pedreiras"},
            {id: "Pedro do Rosário", name: "MA - Pedro do Rosário"},
            {id: "Penalva", name: "MA - Penalva"},
            {id: "Peri Mirim", name: "MA - Peri Mirim"},
            {id: "Peritoró", name: "MA - Peritoró"},
            {id: "Pindaré-mirim", name: "MA - Pindaré-mirim"},
            {id: "Pinheiro", name: "MA - Pinheiro"},
            {id: "Pio Xii", name: "MA - Pio Xii"},
            {id: "Pirapemas", name: "MA - Pirapemas"},
            {id: "Poção de Pedras", name: "MA - Poção de Pedras"},
            {id: "Porto Franco", name: "MA - Porto Franco"},
            {id: "Porto Rico do Maranhão", name: "MA - Porto Rico do Maranhão"},
            {id: "Presidente Dutra", name: "MA - Presidente Dutra"},
            {id: "Presidente Juscelino", name: "MA - Presidente Juscelino"},
            {id: "Presidente Médici", name: "MA - Presidente Médici"},
            {id: "Presidente Sarney", name: "MA - Presidente Sarney"},
            {id: "Presidente Vargas", name: "MA - Presidente Vargas"},
            {id: "Primeira Cruz", name: "MA - Primeira Cruz"},
            {id: "Raposa", name: "MA - Raposa"},
            {id: "Riachão", name: "MA - Riachão"},
            {id: "Ribamar Fiquene", name: "MA - Ribamar Fiquene"},
            {id: "Rosário", name: "MA - Rosário"},
            {id: "Sambaíba", name: "MA - Sambaíba"},
            {id: "Santa Filomena do Maranhão", name: "MA - Santa Filomena do Maranhão"},
            {id: "Santa Helena", name: "MA - Santa Helena"},
            {id: "Santa Inês", name: "MA - Santa Inês"},
            {id: "Santa Luzia", name: "MA - Santa Luzia"},
            {id: "Santa Luzia do Paruá", name: "MA - Santa Luzia do Paruá"},
            {id: "Santa Quitéria do Maranhão", name: "MA - Santa Quitéria do Maranhão"},
            {id: "Santa Rita", name: "MA - Santa Rita"},
            {id: "Santana do Maranhão", name: "MA - Santana do Maranhão"},
            {id: "Santo Amaro do Maranhão", name: "MA - Santo Amaro do Maranhão"},
            {id: "Santo Antônio Dos Lopes", name: "MA - Santo Antônio Dos Lopes"},
            {id: "São Benedito do Rio Preto", name: "MA - São Benedito do Rio Preto"},
            {id: "São Bento", name: "MA - São Bento"},
            {id: "São Bernardo", name: "MA - São Bernardo"},
            {id: "São Domingos do Azeitão", name: "MA - São Domingos do Azeitão"},
            {id: "São Domingos do Maranhão", name: "MA - São Domingos do Maranhão"},
            {id: "São Félix de Balsas", name: "MA - São Félix de Balsas"},
            {id: "São Francisco do Brejão", name: "MA - São Francisco do Brejão"},
            {id: "São Francisco do Maranhão", name: "MA - São Francisco do Maranhão"},
            {id: "São João Batista", name: "MA - São João Batista"},
            {id: "São João do Carú", name: "MA - São João do Carú"},
            {id: "São João do Paraíso", name: "MA - São João do Paraíso"},
            {id: "São João do Soter", name: "MA - São João do Soter"},
            {id: "São João Dos Patos", name: "MA - São João Dos Patos"},
            {id: "São José de Ribamar", name: "MA - São José de Ribamar"},
            {id: "São José Dos Basílios", name: "MA - São José Dos Basílios"},
            {id: "São Luís", name: "MA - São Luís"},
            {id: "São Luís Gonzaga do Maranhão", name: "MA - São Luís Gonzaga do Maranhão"},
            {id: "São Mateus do Maranhão", name: "MA - São Mateus do Maranhão"},
            {id: "São Pedro da Água Branca", name: "MA - São Pedro da Água Branca"},
            {id: "São Pedro Dos Crentes", name: "MA - São Pedro Dos Crentes"},
            {id: "São Raimundo Das Mangabeiras", name: "MA - São Raimundo Das Mangabeiras"},
            {id: "São Raimundo do Doca Bezerra", name: "MA - São Raimundo do Doca Bezerra"},
            {id: "São Roberto", name: "MA - São Roberto"},
            {id: "São Vicente Ferrer", name: "MA - São Vicente Ferrer"},
            {id: "Satubinha", name: "MA - Satubinha"},
            {id: "Senador Alexandre Costa", name: "MA - Senador Alexandre Costa"},
            {id: "Senador la Rocque", name: "MA - Senador la Rocque"},
            {id: "Serrano do Maranhão", name: "MA - Serrano do Maranhão"},
            {id: "Sítio Novo", name: "MA - Sítio Novo"},
            {id: "Sucupira do Norte", name: "MA - Sucupira do Norte"},
            {id: "Sucupira do Riachão", name: "MA - Sucupira do Riachão"},
            {id: "Tasso Fragoso", name: "MA - Tasso Fragoso"},
            {id: "Timbiras", name: "MA - Timbiras"},
            {id: "Timon", name: "MA - Timon"},
            {id: "Trizidela do Vale", name: "MA - Trizidela do Vale"},
            {id: "Tufilândia", name: "MA - Tufilândia"},
            {id: "Tuntum", name: "MA - Tuntum"},
            {id: "Turiaçu", name: "MA - Turiaçu"},
            {id: "Turilândia", name: "MA - Turilândia"},
            {id: "Tutóia", name: "MA - Tutóia"},
            {id: "Urbano Santos", name: "MA - Urbano Santos"},
            {id: "Vargem Grande", name: "MA - Vargem Grande"},
            {id: "Viana", name: "MA - Viana"},
            {id: "Vila Nova Dos Martírios", name: "MA - Vila Nova Dos Martírios"},
            {id: "Vitória do Mearim", name: "MA - Vitória do Mearim"},
            {id: "Vitorino Freire", name: "MA - Vitorino Freire"},
            {id: "zé Doca", name: "MA - zé Doca"},
            {id: "Acauã", name: "PI - Acauã"},
            {id: "Agricolândia", name: "PI - Agricolândia"},
            {id: "Água Branca", name: "PI - Água Branca"},
            {id: "Alagoinha do Piauí", name: "PI - Alagoinha do Piauí"},
            {id: "Alegrete do Piauí", name: "PI - Alegrete do Piauí"},
            {id: "Alto Longá", name: "PI - Alto Longá"},
            {id: "Altos", name: "PI - Altos"},
            {id: "Alvorada do Gurguéia", name: "PI - Alvorada do Gurguéia"},
            {id: "Amarante", name: "PI - Amarante"},
            {id: "Angical do Piauí", name: "PI - Angical do Piauí"},
            {id: "Anísio de Abreu", name: "PI - Anísio de Abreu"},
            {id: "Antônio Almeida", name: "PI - Antônio Almeida"},
            {id: "Aroazes", name: "PI - Aroazes"},
            {id: "Aroeiras do Itaim", name: "PI - Aroeiras do Itaim"},
            {id: "Arraial", name: "PI - Arraial"},
            {id: "Assunção do Piauí", name: "PI - Assunção do Piauí"},
            {id: "Avelino Lopes", name: "PI - Avelino Lopes"},
            {id: "Baixa Grande do Ribeiro", name: "PI - Baixa Grande do Ribeiro"},
            {id: "Barra D'alcântara", name: "PI - Barra D'alcântara"},
            {id: "Barras", name: "PI - Barras"},
            {id: "Barreiras do Piauí", name: "PI - Barreiras do Piauí"},
            {id: "Barro Duro", name: "PI - Barro Duro"},
            {id: "Batalha", name: "PI - Batalha"},
            {id: "Bela Vista do Piauí", name: "PI - Bela Vista do Piauí"},
            {id: "Belém do Piauí", name: "PI - Belém do Piauí"},
            {id: "Beneditinos", name: "PI - Beneditinos"},
            {id: "Bertolínia", name: "PI - Bertolínia"},
            {id: "Betânia do Piauí", name: "PI - Betânia do Piauí"},
            {id: "Boa Hora", name: "PI - Boa Hora"},
            {id: "Bocaina", name: "PI - Bocaina"},
            {id: "Bom Jesus", name: "PI - Bom Jesus"},
            {id: "Bom Princípio do Piauí", name: "PI - Bom Princípio do Piauí"},
            {id: "Bonfim do Piauí", name: "PI - Bonfim do Piauí"},
            {id: "Boqueirão do Piauí", name: "PI - Boqueirão do Piauí"},
            {id: "Brasileira", name: "PI - Brasileira"},
            {id: "Brejo do Piauí", name: "PI - Brejo do Piauí"},
            {id: "Buriti Dos Lopes", name: "PI - Buriti Dos Lopes"},
            {id: "Buriti Dos Montes", name: "PI - Buriti Dos Montes"},
            {id: "Cabeceiras do Piauí", name: "PI - Cabeceiras do Piauí"},
            {id: "Cajazeiras do Piauí", name: "PI - Cajazeiras do Piauí"},
            {id: "Cajueiro da Praia", name: "PI - Cajueiro da Praia"},
            {id: "Caldeirão Grande do Piauí", name: "PI - Caldeirão Grande do Piauí"},
            {id: "Campinas do Piauí", name: "PI - Campinas do Piauí"},
            {id: "Campo Alegre do Fidalgo", name: "PI - Campo Alegre do Fidalgo"},
            {id: "Campo Grande do Piauí", name: "PI - Campo Grande do Piauí"},
            {id: "Campo Largo do Piauí", name: "PI - Campo Largo do Piauí"},
            {id: "Campo Maior", name: "PI - Campo Maior"},
            {id: "Canavieira", name: "PI - Canavieira"},
            {id: "Canto do Buriti", name: "PI - Canto do Buriti"},
            {id: "Capitão de Campos", name: "PI - Capitão de Campos"},
            {id: "Capitão Gervásio Oliveira", name: "PI - Capitão Gervásio Oliveira"},
            {id: "Caracol", name: "PI - Caracol"},
            {id: "Caraúbas do Piauí", name: "PI - Caraúbas do Piauí"},
            {id: "Caridade do Piauí", name: "PI - Caridade do Piauí"},
            {id: "Castelo do Piauí", name: "PI - Castelo do Piauí"},
            {id: "Caxingó", name: "PI - Caxingó"},
            {id: "Cocal", name: "PI - Cocal"},
            {id: "Cocal de Telha", name: "PI - Cocal de Telha"},
            {id: "Cocal Dos Alves", name: "PI - Cocal Dos Alves"},
            {id: "Coivaras", name: "PI - Coivaras"},
            {id: "Colônia do Gurguéia", name: "PI - Colônia do Gurguéia"},
            {id: "Colônia do Piauí", name: "PI - Colônia do Piauí"},
            {id: "Conceição do Canindé", name: "PI - Conceição do Canindé"},
            {id: "Coronel José Dias", name: "PI - Coronel José Dias"},
            {id: "Corrente", name: "PI - Corrente"},
            {id: "Cristalândia do Piauí", name: "PI - Cristalândia do Piauí"},
            {id: "Cristino Castro", name: "PI - Cristino Castro"},
            {id: "Curimatá", name: "PI - Curimatá"},
            {id: "Currais", name: "PI - Currais"},
            {id: "Curralinhos", name: "PI - Curralinhos"},
            {id: "Curral Novo do Piauí", name: "PI - Curral Novo do Piauí"},
            {id: "Demerval Lobão", name: "PI - Demerval Lobão"},
            {id: "Dirceu Arcoverde", name: "PI - Dirceu Arcoverde"},
            {id: "Dom Expedito Lopes", name: "PI - Dom Expedito Lopes"},
            {id: "Domingos Mourão", name: "PI - Domingos Mourão"},
            {id: "Dom Inocêncio", name: "PI - Dom Inocêncio"},
            {id: "Elesbão Veloso", name: "PI - Elesbão Veloso"},
            {id: "Eliseu Martins", name: "PI - Eliseu Martins"},
            {id: "Esperantina", name: "PI - Esperantina"},
            {id: "Fartura do Piauí", name: "PI - Fartura do Piauí"},
            {id: "Flores do Piauí", name: "PI - Flores do Piauí"},
            {id: "Floresta do Piauí", name: "PI - Floresta do Piauí"},
            {id: "Floriano", name: "PI - Floriano"},
            {id: "Francinópolis", name: "PI - Francinópolis"},
            {id: "Francisco Ayres", name: "PI - Francisco Ayres"},
            {id: "Francisco Macedo", name: "PI - Francisco Macedo"},
            {id: "Francisco Santos", name: "PI - Francisco Santos"},
            {id: "Fronteiras", name: "PI - Fronteiras"},
            {id: "Geminiano", name: "PI - Geminiano"},
            {id: "Gilbués", name: "PI - Gilbués"},
            {id: "Guadalupe", name: "PI - Guadalupe"},
            {id: "Guaribas", name: "PI - Guaribas"},
            {id: "Hugo Napoleão", name: "PI - Hugo Napoleão"},
            {id: "Ilha Grande", name: "PI - Ilha Grande"},
            {id: "Inhuma", name: "PI - Inhuma"},
            {id: "Ipiranga do Piauí", name: "PI - Ipiranga do Piauí"},
            {id: "Isaías Coelho", name: "PI - Isaías Coelho"},
            {id: "Itainópolis", name: "PI - Itainópolis"},
            {id: "Itaueira", name: "PI - Itaueira"},
            {id: "Jacobina do Piauí", name: "PI - Jacobina do Piauí"},
            {id: "Jaicós", name: "PI - Jaicós"},
            {id: "Jardim do Mulato", name: "PI - Jardim do Mulato"},
            {id: "Jatobá do Piauí", name: "PI - Jatobá do Piauí"},
            {id: "Jerumenha", name: "PI - Jerumenha"},
            {id: "João Costa", name: "PI - João Costa"},
            {id: "Joaquim Pires", name: "PI - Joaquim Pires"},
            {id: "Joca Marques", name: "PI - Joca Marques"},
            {id: "José de Freitas", name: "PI - José de Freitas"},
            {id: "Juazeiro do Piauí", name: "PI - Juazeiro do Piauí"},
            {id: "Júlio Borges", name: "PI - Júlio Borges"},
            {id: "Jurema", name: "PI - Jurema"},
            {id: "Lagoinha do Piauí", name: "PI - Lagoinha do Piauí"},
            {id: "Lagoa Alegre", name: "PI - Lagoa Alegre"},
            {id: "Lagoa do Barro do Piauí", name: "PI - Lagoa do Barro do Piauí"},
            {id: "Lagoa de São Francisco", name: "PI - Lagoa de São Francisco"},
            {id: "Lagoa do Piauí", name: "PI - Lagoa do Piauí"},
            {id: "Lagoa do Sítio", name: "PI - Lagoa do Sítio"},
            {id: "Landri Sales", name: "PI - Landri Sales"},
            {id: "Luís Correia", name: "PI - Luís Correia"},
            {id: "Luzilândia", name: "PI - Luzilândia"},
            {id: "Madeiro", name: "PI - Madeiro"},
            {id: "Manoel Emídio", name: "PI - Manoel Emídio"},
            {id: "Marcolândia", name: "PI - Marcolândia"},
            {id: "Marcos Parente", name: "PI - Marcos Parente"},
            {id: "Massapê do Piauí", name: "PI - Massapê do Piauí"},
            {id: "Matias Olímpio", name: "PI - Matias Olímpio"},
            {id: "Miguel Alves", name: "PI - Miguel Alves"},
            {id: "Miguel Leão", name: "PI - Miguel Leão"},
            {id: "Milton Brandão", name: "PI - Milton Brandão"},
            {id: "Monsenhor Gil", name: "PI - Monsenhor Gil"},
            {id: "Monsenhor Hipólito", name: "PI - Monsenhor Hipólito"},
            {id: "Monte Alegre do Piauí", name: "PI - Monte Alegre do Piauí"},
            {id: "Morro Cabeça no Tempo", name: "PI - Morro Cabeça no Tempo"},
            {id: "Morro do Chapéu do Piauí", name: "PI - Morro do Chapéu do Piauí"},
            {id: "Murici Dos Portelas", name: "PI - Murici Dos Portelas"},
            {id: "Nazaré do Piauí", name: "PI - Nazaré do Piauí"},
            {id: "Nazária", name: "PI - Nazária"},
            {id: "Nossa Senhora de Nazaré", name: "PI - Nossa Senhora de Nazaré"},
            {id: "Nossa Senhora Dos Remédios", name: "PI - Nossa Senhora Dos Remédios"},
            {id: "Novo Oriente do Piauí", name: "PI - Novo Oriente do Piauí"},
            {id: "Novo Santo Antônio", name: "PI - Novo Santo Antônio"},
            {id: "Oeiras", name: "PI - Oeiras"},
            {id: "Olho D'água do Piauí", name: "PI - Olho D'água do Piauí"},
            {id: "Padre Marcos", name: "PI - Padre Marcos"},
            {id: "Paes Landim", name: "PI - Paes Landim"},
            {id: "Pajeú do Piauí", name: "PI - Pajeú do Piauí"},
            {id: "Palmeira do Piauí", name: "PI - Palmeira do Piauí"},
            {id: "Palmeirais", name: "PI - Palmeirais"},
            {id: "Paquetá", name: "PI - Paquetá"},
            {id: "Parnaguá", name: "PI - Parnaguá"},
            {id: "Parnaíba", name: "PI - Parnaíba"},
            {id: "Passagem Franca do Piauí", name: "PI - Passagem Franca do Piauí"},
            {id: "Patos do Piauí", name: "PI - Patos do Piauí"},
            {id: "Pau D'arco do Piauí", name: "PI - Pau D'arco do Piauí"},
            {id: "Paulistana", name: "PI - Paulistana"},
            {id: "Pavussu", name: "PI - Pavussu"},
            {id: "Pedro ii", name: "PI - Pedro ii"},
            {id: "Pedro Laurentino", name: "PI - Pedro Laurentino"},
            {id: "Nova Santa Rita", name: "PI - Nova Santa Rita"},
            {id: "Picos", name: "PI - Picos"},
            {id: "Pimenteiras", name: "PI - Pimenteiras"},
            {id: "Pio ix", name: "PI - Pio ix"},
            {id: "Piracuruca", name: "PI - Piracuruca"},
            {id: "Piripiri", name: "PI - Piripiri"},
            {id: "Porto", name: "PI - Porto"},
            {id: "Porto Alegre do Piauí", name: "PI - Porto Alegre do Piauí"},
            {id: "Prata do Piauí", name: "PI - Prata do Piauí"},
            {id: "Queimada Nova", name: "PI - Queimada Nova"},
            {id: "Redenção do Gurguéia", name: "PI - Redenção do Gurguéia"},
            {id: "Regeneração", name: "PI - Regeneração"},
            {id: "Riacho Frio", name: "PI - Riacho Frio"},
            {id: "Ribeira do Piauí", name: "PI - Ribeira do Piauí"},
            {id: "Ribeiro Gonçalves", name: "PI - Ribeiro Gonçalves"},
            {id: "Rio Grande do Piauí", name: "PI - Rio Grande do Piauí"},
            {id: "Santa Cruz do Piauí", name: "PI - Santa Cruz do Piauí"},
            {id: "Santa Cruz Dos Milagres", name: "PI - Santa Cruz Dos Milagres"},
            {id: "Santa Filomena", name: "PI - Santa Filomena"},
            {id: "Santa Luz", name: "PI - Santa Luz"},
            {id: "Santana do Piauí", name: "PI - Santana do Piauí"},
            {id: "Santa Rosa do Piauí", name: "PI - Santa Rosa do Piauí"},
            {id: "Santo Antônio de Lisboa", name: "PI - Santo Antônio de Lisboa"},
            {id: "Santo Antônio Dos Milagres", name: "PI - Santo Antônio Dos Milagres"},
            {id: "Santo Inácio do Piauí", name: "PI - Santo Inácio do Piauí"},
            {id: "São Braz do Piauí", name: "PI - São Braz do Piauí"},
            {id: "São Félix do Piauí", name: "PI - São Félix do Piauí"},
            {id: "São Francisco de Assis do Piauí", name: "PI - São Francisco de Assis do Piauí"},
            {id: "São Francisco do Piauí", name: "PI - São Francisco do Piauí"},
            {id: "São Gonçalo do Gurguéia", name: "PI - São Gonçalo do Gurguéia"},
            {id: "São Gonçalo do Piauí", name: "PI - São Gonçalo do Piauí"},
            {id: "São João da Canabrava", name: "PI - São João da Canabrava"},
            {id: "São João da Fronteira", name: "PI - São João da Fronteira"},
            {id: "São João da Serra", name: "PI - São João da Serra"},
            {id: "São João da Varjota", name: "PI - São João da Varjota"},
            {id: "São João do Arraial", name: "PI - São João do Arraial"},
            {id: "São João do Piauí", name: "PI - São João do Piauí"},
            {id: "São José do Divino", name: "PI - São José do Divino"},
            {id: "São José do Peixe", name: "PI - São José do Peixe"},
            {id: "São José do Piauí", name: "PI - São José do Piauí"},
            {id: "São Julião", name: "PI - São Julião"},
            {id: "São Lourenço do Piauí", name: "PI - São Lourenço do Piauí"},
            {id: "São Luis do Piauí", name: "PI - São Luis do Piauí"},
            {id: "São Miguel da Baixa Grande", name: "PI - São Miguel da Baixa Grande"},
            {id: "São Miguel do Fidalgo", name: "PI - São Miguel do Fidalgo"},
            {id: "São Miguel do Tapuio", name: "PI - São Miguel do Tapuio"},
            {id: "São Pedro do Piauí", name: "PI - São Pedro do Piauí"},
            {id: "São Raimundo Nonato", name: "PI - São Raimundo Nonato"},
            {id: "Sebastião Barros", name: "PI - Sebastião Barros"},
            {id: "Sebastião Leal", name: "PI - Sebastião Leal"},
            {id: "Sigefredo Pacheco", name: "PI - Sigefredo Pacheco"},
            {id: "Simões", name: "PI - Simões"},
            {id: "Simplício Mendes", name: "PI - Simplício Mendes"},
            {id: "Socorro do Piauí", name: "PI - Socorro do Piauí"},
            {id: "Sussuapara", name: "PI - Sussuapara"},
            {id: "Tamboril do Piauí", name: "PI - Tamboril do Piauí"},
            {id: "Tanque do Piauí", name: "PI - Tanque do Piauí"},
            {id: "Teresina", name: "PI - Teresina"},
            {id: "União", name: "PI - União"},
            {id: "Uruçuí", name: "PI - Uruçuí"},
            {id: "Valença do Piauí", name: "PI - Valença do Piauí"},
            {id: "Várzea Branca", name: "PI - Várzea Branca"},
            {id: "Várzea Grande", name: "PI - Várzea Grande"},
            {id: "Vera Mendes", name: "PI - Vera Mendes"},
            {id: "Vila Nova do Piauí", name: "PI - Vila Nova do Piauí"},
            {id: "Wall Ferraz", name: "PI - Wall Ferraz"},
            {id: "Abaiara", name: "CE - Abaiara"},
            {id: "Acarapé", name: "CE - Acarapé"},
            {id: "Acaraú", name: "CE - Acaraú"},
            {id: "Acopiara", name: "CE - Acopiara"},
            {id: "Aiuaba", name: "CE - Aiuaba"},
            {id: "Alcântaras", name: "CE - Alcântaras"},
            {id: "Altaneira", name: "CE - Altaneira"},
            {id: "Alto Santo", name: "CE - Alto Santo"},
            {id: "Amontada", name: "CE - Amontada"},
            {id: "Antonina do Norte", name: "CE - Antonina do Norte"},
            {id: "Apuiarés", name: "CE - Apuiarés"},
            {id: "Aquiraz", name: "CE - Aquiraz"},
            {id: "Aracati", name: "CE - Aracati"},
            {id: "Aracoiaba", name: "CE - Aracoiaba"},
            {id: "Ararendá", name: "CE - Ararendá"},
            {id: "Araripe", name: "CE - Araripe"},
            {id: "Aratuba", name: "CE - Aratuba"},
            {id: "Arneiroz", name: "CE - Arneiroz"},
            {id: "Assaré", name: "CE - Assaré"},
            {id: "Aurora", name: "CE - Aurora"},
            {id: "Baixio", name: "CE - Baixio"},
            {id: "Banabuiú", name: "CE - Banabuiú"},
            {id: "Barbalha", name: "CE - Barbalha"},
            {id: "Barreira", name: "CE - Barreira"},
            {id: "Barro", name: "CE - Barro"},
            {id: "Barroquinha", name: "CE - Barroquinha"},
            {id: "Baturité", name: "CE - Baturité"},
            {id: "Beberibe", name: "CE - Beberibe"},
            {id: "Bela Cruz", name: "CE - Bela Cruz"},
            {id: "Boa Viagem", name: "CE - Boa Viagem"},
            {id: "Brejo Santo", name: "CE - Brejo Santo"},
            {id: "Camocim", name: "CE - Camocim"},
            {id: "Campos Sales", name: "CE - Campos Sales"},
            {id: "Canindé", name: "CE - Canindé"},
            {id: "Capistrano", name: "CE - Capistrano"},
            {id: "Caridade", name: "CE - Caridade"},
            {id: "Cariré", name: "CE - Cariré"},
            {id: "Caririaçu", name: "CE - Caririaçu"},
            {id: "Cariús", name: "CE - Cariús"},
            {id: "Carnaubal", name: "CE - Carnaubal"},
            {id: "Cascavel", name: "CE - Cascavel"},
            {id: "Catarina", name: "CE - Catarina"},
            {id: "Catunda", name: "CE - Catunda"},
            {id: "Caucaia", name: "CE - Caucaia"},
            {id: "Cedro", name: "CE - Cedro"},
            {id: "Chaval", name: "CE - Chaval"},
            {id: "Choró", name: "CE - Choró"},
            {id: "Chorozinho", name: "CE - Chorozinho"},
            {id: "Coreaú", name: "CE - Coreaú"},
            {id: "Crateús", name: "CE - Crateús"},
            {id: "Crato", name: "CE - Crato"},
            {id: "Croatá", name: "CE - Croatá"},
            {id: "Cruz", name: "CE - Cruz"},
            {id: "Deputado Irapuan Pinheiro", name: "CE - Deputado Irapuan Pinheiro"},
            {id: "Ererê", name: "CE - Ererê"},
            {id: "Eusébio", name: "CE - Eusébio"},
            {id: "Farias Brito", name: "CE - Farias Brito"},
            {id: "Forquilha", name: "CE - Forquilha"},
            {id: "Fortaleza", name: "CE - Fortaleza"},
            {id: "Fortim", name: "CE - Fortim"},
            {id: "Frecheirinha", name: "CE - Frecheirinha"},
            {id: "General Sampaio", name: "CE - General Sampaio"},
            {id: "Graça", name: "CE - Graça"},
            {id: "Granja", name: "CE - Granja"},
            {id: "Granjeiro", name: "CE - Granjeiro"},
            {id: "Groaíras", name: "CE - Groaíras"},
            {id: "Guaiúba", name: "CE - Guaiúba"},
            {id: "Guaraciaba do Norte", name: "CE - Guaraciaba do Norte"},
            {id: "Guaramiranga", name: "CE - Guaramiranga"},
            {id: "Hidrolândia", name: "CE - Hidrolândia"},
            {id: "Horizonte", name: "CE - Horizonte"},
            {id: "Ibaretama", name: "CE - Ibaretama"},
            {id: "Ibiapina", name: "CE - Ibiapina"},
            {id: "Ibicuitinga", name: "CE - Ibicuitinga"},
            {id: "Icapuí", name: "CE - Icapuí"},
            {id: "Icó", name: "CE - Icó"},
            {id: "Iguatu", name: "CE - Iguatu"},
            {id: "Independência", name: "CE - Independência"},
            {id: "Ipaporanga", name: "CE - Ipaporanga"},
            {id: "Ipaumirim", name: "CE - Ipaumirim"},
            {id: "Ipu", name: "CE - Ipu"},
            {id: "Ipueiras", name: "CE - Ipueiras"},
            {id: "Iracema", name: "CE - Iracema"},
            {id: "Irauçuba", name: "CE - Irauçuba"},
            {id: "Itaiçaba", name: "CE - Itaiçaba"},
            {id: "Itaitinga", name: "CE - Itaitinga"},
            {id: "Itapagé", name: "CE - Itapagé"},
            {id: "Itapipoca", name: "CE - Itapipoca"},
            {id: "Itapiúna", name: "CE - Itapiúna"},
            {id: "Itarema", name: "CE - Itarema"},
            {id: "Itatira", name: "CE - Itatira"},
            {id: "Jaguaretama", name: "CE - Jaguaretama"},
            {id: "Jaguaribara", name: "CE - Jaguaribara"},
            {id: "Jaguaribe", name: "CE - Jaguaribe"},
            {id: "Jaguaruana", name: "CE - Jaguaruana"},
            {id: "Jardim", name: "CE - Jardim"},
            {id: "Jati", name: "CE - Jati"},
            {id: "Jijoca de Jericoacoara", name: "CE - Jijoca de Jericoacoara"},
            {id: "Juazeiro do Norte", name: "CE - Juazeiro do Norte"},
            {id: "Jucás", name: "CE - Jucás"},
            {id: "Lavras da Mangabeira", name: "CE - Lavras da Mangabeira"},
            {id: "Limoeiro do Norte", name: "CE - Limoeiro do Norte"},
            {id: "Madalena", name: "CE - Madalena"},
            {id: "Maracanaú", name: "CE - Maracanaú"},
            {id: "Maranguape", name: "CE - Maranguape"},
            {id: "Marco", name: "CE - Marco"},
            {id: "Martinópole", name: "CE - Martinópole"},
            {id: "Massapê", name: "CE - Massapê"},
            {id: "Mauriti", name: "CE - Mauriti"},
            {id: "Meruoca", name: "CE - Meruoca"},
            {id: "Milagres", name: "CE - Milagres"},
            {id: "Milhã", name: "CE - Milhã"},
            {id: "Miraíma", name: "CE - Miraíma"},
            {id: "Missão Velha", name: "CE - Missão Velha"},
            {id: "Mombaça", name: "CE - Mombaça"},
            {id: "Monsenhor Tabosa", name: "CE - Monsenhor Tabosa"},
            {id: "Morada Nova", name: "CE - Morada Nova"},
            {id: "Moraújo", name: "CE - Moraújo"},
            {id: "Morrinhos", name: "CE - Morrinhos"},
            {id: "Mucambo", name: "CE - Mucambo"},
            {id: "Mulungu", name: "CE - Mulungu"},
            {id: "Nova Olinda", name: "CE - Nova Olinda"},
            {id: "Nova Russas", name: "CE - Nova Russas"},
            {id: "Novo Oriente", name: "CE - Novo Oriente"},
            {id: "Ocara", name: "CE - Ocara"},
            {id: "Orós", name: "CE - Orós"},
            {id: "Pacajus", name: "CE - Pacajus"},
            {id: "Pacatuba", name: "CE - Pacatuba"},
            {id: "Pacoti", name: "CE - Pacoti"},
            {id: "Pacujá", name: "CE - Pacujá"},
            {id: "Palhano", name: "CE - Palhano"},
            {id: "Palmácia", name: "CE - Palmácia"},
            {id: "Paracuru", name: "CE - Paracuru"},
            {id: "Paraipaba", name: "CE - Paraipaba"},
            {id: "Parambu", name: "CE - Parambu"},
            {id: "Paramoti", name: "CE - Paramoti"},
            {id: "Pedra Branca", name: "CE - Pedra Branca"},
            {id: "Penaforte", name: "CE - Penaforte"},
            {id: "Pentecoste", name: "CE - Pentecoste"},
            {id: "Pereiro", name: "CE - Pereiro"},
            {id: "Pindoretama", name: "CE - Pindoretama"},
            {id: "Piquet Carneiro", name: "CE - Piquet Carneiro"},
            {id: "Pires Ferreira", name: "CE - Pires Ferreira"},
            {id: "Poranga", name: "CE - Poranga"},
            {id: "Porteiras", name: "CE - Porteiras"},
            {id: "Potengi", name: "CE - Potengi"},
            {id: "Potiretama", name: "CE - Potiretama"},
            {id: "Quiterianópolis", name: "CE - Quiterianópolis"},
            {id: "Quixadá", name: "CE - Quixadá"},
            {id: "Quixelô", name: "CE - Quixelô"},
            {id: "Quixeramobim", name: "CE - Quixeramobim"},
            {id: "Quixeré", name: "CE - Quixeré"},
            {id: "Redenção", name: "CE - Redenção"},
            {id: "Reriutaba", name: "CE - Reriutaba"},
            {id: "Russas", name: "CE - Russas"},
            {id: "Saboeiro", name: "CE - Saboeiro"},
            {id: "Salitre", name: "CE - Salitre"},
            {id: "Santana do Acaraú", name: "CE - Santana do Acaraú"},
            {id: "Santana do Cariri", name: "CE - Santana do Cariri"},
            {id: "Santa Quitéria", name: "CE - Santa Quitéria"},
            {id: "São Benedito", name: "CE - São Benedito"},
            {id: "São Gonçalo do Amarante", name: "CE - São Gonçalo do Amarante"},
            {id: "São João do Jaguaribe", name: "CE - São João do Jaguaribe"},
            {id: "São Luís do Curu", name: "CE - São Luís do Curu"},
            {id: "Senador Pompeu", name: "CE - Senador Pompeu"},
            {id: "Senador sá", name: "CE - Senador sá"},
            {id: "Sobral", name: "CE - Sobral"},
            {id: "Solonópole", name: "CE - Solonópole"},
            {id: "Tabuleiro do Norte", name: "CE - Tabuleiro do Norte"},
            {id: "Tamboril", name: "CE - Tamboril"},
            {id: "Tarrafas", name: "CE - Tarrafas"},
            {id: "Tauá", name: "CE - Tauá"},
            {id: "Tejuçuoca", name: "CE - Tejuçuoca"},
            {id: "Tianguá", name: "CE - Tianguá"},
            {id: "Trairi", name: "CE - Trairi"},
            {id: "Tururu", name: "CE - Tururu"},
            {id: "Ubajara", name: "CE - Ubajara"},
            {id: "Umari", name: "CE - Umari"},
            {id: "Umirim", name: "CE - Umirim"},
            {id: "Uruburetama", name: "CE - Uruburetama"},
            {id: "Uruoca", name: "CE - Uruoca"},
            {id: "Varjota", name: "CE - Varjota"},
            {id: "Várzea Alegre", name: "CE - Várzea Alegre"},
            {id: "Viçosa do Ceará", name: "CE - Viçosa do Ceará"},
            {id: "Acari", name: "RN - Acari"},
            {id: "Açu", name: "RN - Açu"},
            {id: "Afonso Bezerra", name: "RN - Afonso Bezerra"},
            {id: "Água Nova", name: "RN - Água Nova"},
            {id: "Alexandria", name: "RN - Alexandria"},
            {id: "Almino Afonso", name: "RN - Almino Afonso"},
            {id: "Alto do Rodrigues", name: "RN - Alto do Rodrigues"},
            {id: "Angicos", name: "RN - Angicos"},
            {id: "Antônio Martins", name: "RN - Antônio Martins"},
            {id: "Apodi", name: "RN - Apodi"},
            {id: "Areia Branca", name: "RN - Areia Branca"},
            {id: "Arês", name: "RN - Arês"},
            {id: "Augusto Severo", name: "RN - Augusto Severo"},
            {id: "Baía Formosa", name: "RN - Baía Formosa"},
            {id: "Baraúna", name: "RN - Baraúna"},
            {id: "Barcelona", name: "RN - Barcelona"},
            {id: "Bento Fernandes", name: "RN - Bento Fernandes"},
            {id: "Bodó", name: "RN - Bodó"},
            {id: "Bom Jesus", name: "RN - Bom Jesus"},
            {id: "Brejinho", name: "RN - Brejinho"},
            {id: "Caiçara do Norte", name: "RN - Caiçara do Norte"},
            {id: "Caiçara do Rio do Vento", name: "RN - Caiçara do Rio do Vento"},
            {id: "Caicó", name: "RN - Caicó"},
            {id: "Campo Redondo", name: "RN - Campo Redondo"},
            {id: "Canguaretama", name: "RN - Canguaretama"},
            {id: "Caraúbas", name: "RN - Caraúbas"},
            {id: "Carnaúba Dos Dantas", name: "RN - Carnaúba Dos Dantas"},
            {id: "Carnaubais", name: "RN - Carnaubais"},
            {id: "Ceará-mirim", name: "RN - Ceará-mirim"},
            {id: "Cerro Corá", name: "RN - Cerro Corá"},
            {id: "Coronel Ezequiel", name: "RN - Coronel Ezequiel"},
            {id: "Coronel João Pessoa", name: "RN - Coronel João Pessoa"},
            {id: "Cruzeta", name: "RN - Cruzeta"},
            {id: "Currais Novos", name: "RN - Currais Novos"},
            {id: "Doutor Severiano", name: "RN - Doutor Severiano"},
            {id: "Parnamirim", name: "RN - Parnamirim"},
            {id: "Encanto", name: "RN - Encanto"},
            {id: "Equador", name: "RN - Equador"},
            {id: "Espírito Santo", name: "RN - Espírito Santo"},
            {id: "Extremoz", name: "RN - Extremoz"},
            {id: "Felipe Guerra", name: "RN - Felipe Guerra"},
            {id: "Fernando Pedroza", name: "RN - Fernando Pedroza"},
            {id: "Florânia", name: "RN - Florânia"},
            {id: "Francisco Dantas", name: "RN - Francisco Dantas"},
            {id: "Frutuoso Gomes", name: "RN - Frutuoso Gomes"},
            {id: "Galinhos", name: "RN - Galinhos"},
            {id: "Goianinha", name: "RN - Goianinha"},
            {id: "Governador Dix-sept Rosado", name: "RN - Governador Dix-sept Rosado"},
            {id: "Grossos", name: "RN - Grossos"},
            {id: "Guamaré", name: "RN - Guamaré"},
            {id: "Ielmo Marinho", name: "RN - Ielmo Marinho"},
            {id: "Ipanguaçu", name: "RN - Ipanguaçu"},
            {id: "Ipueira", name: "RN - Ipueira"},
            {id: "Itajá", name: "RN - Itajá"},
            {id: "Itaú", name: "RN - Itaú"},
            {id: "Jaçanã", name: "RN - Jaçanã"},
            {id: "Jandaíra", name: "RN - Jandaíra"},
            {id: "Janduís", name: "RN - Janduís"},
            {id: "Januário Cicco", name: "RN - Januário Cicco"},
            {id: "Japi", name: "RN - Japi"},
            {id: "Jardim de Angicos", name: "RN - Jardim de Angicos"},
            {id: "Jardim de Piranhas", name: "RN - Jardim de Piranhas"},
            {id: "Jardim do Seridó", name: "RN - Jardim do Seridó"},
            {id: "João Câmara", name: "RN - João Câmara"},
            {id: "João Dias", name: "RN - João Dias"},
            {id: "José da Penha", name: "RN - José da Penha"},
            {id: "Jucurutu", name: "RN - Jucurutu"},
            {id: "Jundiá", name: "RN - Jundiá"},
            {id: "Lagoa D'anta", name: "RN - Lagoa D'anta"},
            {id: "Lagoa de Pedras", name: "RN - Lagoa de Pedras"},
            {id: "Lagoa de Velhos", name: "RN - Lagoa de Velhos"},
            {id: "Lagoa Nova", name: "RN - Lagoa Nova"},
            {id: "Lagoa Salgada", name: "RN - Lagoa Salgada"},
            {id: "Lajes", name: "RN - Lajes"},
            {id: "Lajes Pintadas", name: "RN - Lajes Pintadas"},
            {id: "Lucrécia", name: "RN - Lucrécia"},
            {id: "Luís Gomes", name: "RN - Luís Gomes"},
            {id: "Macaíba", name: "RN - Macaíba"},
            {id: "Macau", name: "RN - Macau"},
            {id: "Major Sales", name: "RN - Major Sales"},
            {id: "Marcelino Vieira", name: "RN - Marcelino Vieira"},
            {id: "Martins", name: "RN - Martins"},
            {id: "Maxaranguape", name: "RN - Maxaranguape"},
            {id: "Messias Targino", name: "RN - Messias Targino"},
            {id: "Montanhas", name: "RN - Montanhas"},
            {id: "Monte Alegre", name: "RN - Monte Alegre"},
            {id: "Monte Das Gameleiras", name: "RN - Monte Das Gameleiras"},
            {id: "Mossoró", name: "RN - Mossoró"},
            {id: "Natal", name: "RN - Natal"},
            {id: "Nísia Floresta", name: "RN - Nísia Floresta"},
            {id: "Nova Cruz", name: "RN - Nova Cruz"},
            {id: "Olho-d'água do Borges", name: "RN - Olho-d'água do Borges"},
            {id: "Ouro Branco", name: "RN - Ouro Branco"},
            {id: "Paraná", name: "RN - Paraná"},
            {id: "Paraú", name: "RN - Paraú"},
            {id: "Parazinho", name: "RN - Parazinho"},
            {id: "Parelhas", name: "RN - Parelhas"},
            {id: "Rio do Fogo", name: "RN - Rio do Fogo"},
            {id: "Passa e Fica", name: "RN - Passa e Fica"},
            {id: "Passagem", name: "RN - Passagem"},
            {id: "Patu", name: "RN - Patu"},
            {id: "Santa Maria", name: "RN - Santa Maria"},
            {id: "Pau Dos Ferros", name: "RN - Pau Dos Ferros"},
            {id: "Pedra Grande", name: "RN - Pedra Grande"},
            {id: "Pedra Preta", name: "RN - Pedra Preta"},
            {id: "Pedro Avelino", name: "RN - Pedro Avelino"},
            {id: "Pedro Velho", name: "RN - Pedro Velho"},
            {id: "Pendências", name: "RN - Pendências"},
            {id: "Pilões", name: "RN - Pilões"},
            {id: "Poço Branco", name: "RN - Poço Branco"},
            {id: "Portalegre", name: "RN - Portalegre"},
            {id: "Porto do Mangue", name: "RN - Porto do Mangue"},
            {id: "Presidente Juscelino", name: "RN - Presidente Juscelino"},
            {id: "Pureza", name: "RN - Pureza"},
            {id: "Rafael Fernandes", name: "RN - Rafael Fernandes"},
            {id: "Rafael Godeiro", name: "RN - Rafael Godeiro"},
            {id: "Riacho da Cruz", name: "RN - Riacho da Cruz"},
            {id: "Riacho de Santana", name: "RN - Riacho de Santana"},
            {id: "Riachuelo", name: "RN - Riachuelo"},
            {id: "Rodolfo Fernandes", name: "RN - Rodolfo Fernandes"},
            {id: "Tibau", name: "RN - Tibau"},
            {id: "Ruy Barbosa", name: "RN - Ruy Barbosa"},
            {id: "Santa Cruz", name: "RN - Santa Cruz"},
            {id: "Santana do Matos", name: "RN - Santana do Matos"},
            {id: "Santana do Seridó", name: "RN - Santana do Seridó"},
            {id: "Santo Antônio", name: "RN - Santo Antônio"},
            {id: "São Bento do Norte", name: "RN - São Bento do Norte"},
            {id: "São Bento do Trairí", name: "RN - São Bento do Trairí"},
            {id: "São Fernando", name: "RN - São Fernando"},
            {id: "São Francisco do Oeste", name: "RN - São Francisco do Oeste"},
            {id: "São Gonçalo do Amarante", name: "RN - São Gonçalo do Amarante"},
            {id: "São João do Sabugi", name: "RN - São João do Sabugi"},
            {id: "São José de Mipibu", name: "RN - São José de Mipibu"},
            {id: "São José do Campestre", name: "RN - São José do Campestre"},
            {id: "São José do Seridó", name: "RN - São José do Seridó"},
            {id: "São Miguel", name: "RN - São Miguel"},
            {id: "São Miguel do Gostoso", name: "RN - São Miguel do Gostoso"},
            {id: "São Paulo do Potengi", name: "RN - São Paulo do Potengi"},
            {id: "São Pedro", name: "RN - São Pedro"},
            {id: "São Rafael", name: "RN - São Rafael"},
            {id: "São Tomé", name: "RN - São Tomé"},
            {id: "São Vicente", name: "RN - São Vicente"},
            {id: "Senador Elói de Souza", name: "RN - Senador Elói de Souza"},
            {id: "Senador Georgino Avelino", name: "RN - Senador Georgino Avelino"},
            {id: "Serra de São Bento", name: "RN - Serra de São Bento"},
            {id: "Serra do Mel", name: "RN - Serra do Mel"},
            {id: "Serra Negra do Norte", name: "RN - Serra Negra do Norte"},
            {id: "Serrinha", name: "RN - Serrinha"},
            {id: "Serrinha Dos Pintos", name: "RN - Serrinha Dos Pintos"},
            {id: "Severiano Melo", name: "RN - Severiano Melo"},
            {id: "Sítio Novo", name: "RN - Sítio Novo"},
            {id: "Taboleiro Grande", name: "RN - Taboleiro Grande"},
            {id: "Taipu", name: "RN - Taipu"},
            {id: "Tangará", name: "RN - Tangará"},
            {id: "Tenente Ananias", name: "RN - Tenente Ananias"},
            {id: "Tenente Laurentino Cruz", name: "RN - Tenente Laurentino Cruz"},
            {id: "Tibau do Sul", name: "RN - Tibau do Sul"},
            {id: "Timbaúba Dos Batistas", name: "RN - Timbaúba Dos Batistas"},
            {id: "Touros", name: "RN - Touros"},
            {id: "Triunfo Potiguar", name: "RN - Triunfo Potiguar"},
            {id: "Umarizal", name: "RN - Umarizal"},
            {id: "Upanema", name: "RN - Upanema"},
            {id: "Várzea", name: "RN - Várzea"},
            {id: "Venha-ver", name: "RN - Venha-ver"},
            {id: "Vera Cruz", name: "RN - Vera Cruz"},
            {id: "Viçosa", name: "RN - Viçosa"},
            {id: "Vila Flor", name: "RN - Vila Flor"},
            {id: "Água Branca", name: "PB - Água Branca"},
            {id: "Aguiar", name: "PB - Aguiar"},
            {id: "Alagoa Grande", name: "PB - Alagoa Grande"},
            {id: "Alagoa Nova", name: "PB - Alagoa Nova"},
            {id: "Alagoinha", name: "PB - Alagoinha"},
            {id: "Alcantil", name: "PB - Alcantil"},
            {id: "Algodão de Jandaíra", name: "PB - Algodão de Jandaíra"},
            {id: "Alhandra", name: "PB - Alhandra"},
            {id: "São João do Rio do Peixe", name: "PB - São João do Rio do Peixe"},
            {id: "Amparo", name: "PB - Amparo"},
            {id: "Aparecida", name: "PB - Aparecida"},
            {id: "Araçagi", name: "PB - Araçagi"},
            {id: "Arara", name: "PB - Arara"},
            {id: "Araruna", name: "PB - Araruna"},
            {id: "Areia", name: "PB - Areia"},
            {id: "Areia de Baraúnas", name: "PB - Areia de Baraúnas"},
            {id: "Areial", name: "PB - Areial"},
            {id: "Aroeiras", name: "PB - Aroeiras"},
            {id: "Assunção", name: "PB - Assunção"},
            {id: "Baía da Traição", name: "PB - Baía da Traição"},
            {id: "Bananeiras", name: "PB - Bananeiras"},
            {id: "Baraúna", name: "PB - Baraúna"},
            {id: "Barra de Santana", name: "PB - Barra de Santana"},
            {id: "Barra de Santa Rosa", name: "PB - Barra de Santa Rosa"},
            {id: "Barra de São Miguel", name: "PB - Barra de São Miguel"},
            {id: "Bayeux", name: "PB - Bayeux"},
            {id: "Belém", name: "PB - Belém"},
            {id: "Belém do Brejo do Cruz", name: "PB - Belém do Brejo do Cruz"},
            {id: "Bernardino Batista", name: "PB - Bernardino Batista"},
            {id: "Boa Ventura", name: "PB - Boa Ventura"},
            {id: "Boa Vista", name: "PB - Boa Vista"},
            {id: "Bom Jesus", name: "PB - Bom Jesus"},
            {id: "Bom Sucesso", name: "PB - Bom Sucesso"},
            {id: "Bonito de Santa fé", name: "PB - Bonito de Santa fé"},
            {id: "Boqueirão", name: "PB - Boqueirão"},
            {id: "Igaracy", name: "PB - Igaracy"},
            {id: "Borborema", name: "PB - Borborema"},
            {id: "Brejo do Cruz", name: "PB - Brejo do Cruz"},
            {id: "Brejo Dos Santos", name: "PB - Brejo Dos Santos"},
            {id: "Caaporã", name: "PB - Caaporã"},
            {id: "Cabaceiras", name: "PB - Cabaceiras"},
            {id: "Cabedelo", name: "PB - Cabedelo"},
            {id: "Cachoeira Dos Índios", name: "PB - Cachoeira Dos Índios"},
            {id: "Cacimba de Areia", name: "PB - Cacimba de Areia"},
            {id: "Cacimba de Dentro", name: "PB - Cacimba de Dentro"},
            {id: "Cacimbas", name: "PB - Cacimbas"},
            {id: "Caiçara", name: "PB - Caiçara"},
            {id: "Cajazeiras", name: "PB - Cajazeiras"},
            {id: "Cajazeirinhas", name: "PB - Cajazeirinhas"},
            {id: "Caldas Brandão", name: "PB - Caldas Brandão"},
            {id: "Camalaú", name: "PB - Camalaú"},
            {id: "Campina Grande", name: "PB - Campina Grande"},
            {id: "Capim", name: "PB - Capim"},
            {id: "Caraúbas", name: "PB - Caraúbas"},
            {id: "Carrapateira", name: "PB - Carrapateira"},
            {id: "Casserengue", name: "PB - Casserengue"},
            {id: "Catingueira", name: "PB - Catingueira"},
            {id: "Catolé do Rocha", name: "PB - Catolé do Rocha"},
            {id: "Caturité", name: "PB - Caturité"},
            {id: "Conceição", name: "PB - Conceição"},
            {id: "Condado", name: "PB - Condado"},
            {id: "Conde", name: "PB - Conde"},
            {id: "Congo", name: "PB - Congo"},
            {id: "Coremas", name: "PB - Coremas"},
            {id: "Coxixola", name: "PB - Coxixola"},
            {id: "Cruz do Espírito Santo", name: "PB - Cruz do Espírito Santo"},
            {id: "Cubati", name: "PB - Cubati"},
            {id: "Cuité", name: "PB - Cuité"},
            {id: "Cuitegi", name: "PB - Cuitegi"},
            {id: "Cuité de Mamanguape", name: "PB - Cuité de Mamanguape"},
            {id: "Curral de Cima", name: "PB - Curral de Cima"},
            {id: "Curral Velho", name: "PB - Curral Velho"},
            {id: "Damião", name: "PB - Damião"},
            {id: "Desterro", name: "PB - Desterro"},
            {id: "Vista Serrana", name: "PB - Vista Serrana"},
            {id: "Diamante", name: "PB - Diamante"},
            {id: "Dona Inês", name: "PB - Dona Inês"},
            {id: "Duas Estradas", name: "PB - Duas Estradas"},
            {id: "Emas", name: "PB - Emas"},
            {id: "Esperança", name: "PB - Esperança"},
            {id: "Fagundes", name: "PB - Fagundes"},
            {id: "Frei Martinho", name: "PB - Frei Martinho"},
            {id: "Gado Bravo", name: "PB - Gado Bravo"},
            {id: "Guarabira", name: "PB - Guarabira"},
            {id: "Gurinhém", name: "PB - Gurinhém"},
            {id: "Gurjão", name: "PB - Gurjão"},
            {id: "Ibiara", name: "PB - Ibiara"},
            {id: "Imaculada", name: "PB - Imaculada"},
            {id: "Ingá", name: "PB - Ingá"},
            {id: "Itabaiana", name: "PB - Itabaiana"},
            {id: "Itaporanga", name: "PB - Itaporanga"},
            {id: "Itapororoca", name: "PB - Itapororoca"},
            {id: "Itatuba", name: "PB - Itatuba"},
            {id: "Jacaraú", name: "PB - Jacaraú"},
            {id: "Jericó", name: "PB - Jericó"},
            {id: "João Pessoa", name: "PB - João Pessoa"},
            {id: "Juarez Távora", name: "PB - Juarez Távora"},
            {id: "Juazeirinho", name: "PB - Juazeirinho"},
            {id: "Junco do Seridó", name: "PB - Junco do Seridó"},
            {id: "Juripiranga", name: "PB - Juripiranga"},
            {id: "Juru", name: "PB - Juru"},
            {id: "Lagoa", name: "PB - Lagoa"},
            {id: "Lagoa de Dentro", name: "PB - Lagoa de Dentro"},
            {id: "Lagoa Seca", name: "PB - Lagoa Seca"},
            {id: "Lastro", name: "PB - Lastro"},
            {id: "Livramento", name: "PB - Livramento"},
            {id: "Logradouro", name: "PB - Logradouro"},
            {id: "Lucena", name: "PB - Lucena"},
            {id: "Mãe D'água", name: "PB - Mãe D'água"},
            {id: "Malta", name: "PB - Malta"},
            {id: "Mamanguape", name: "PB - Mamanguape"},
            {id: "Manaíra", name: "PB - Manaíra"},
            {id: "Marcação", name: "PB - Marcação"},
            {id: "Mari", name: "PB - Mari"},
            {id: "Marizópolis", name: "PB - Marizópolis"},
            {id: "Massaranduba", name: "PB - Massaranduba"},
            {id: "Mataraca", name: "PB - Mataraca"},
            {id: "Matinhas", name: "PB - Matinhas"},
            {id: "Mato Grosso", name: "PB - Mato Grosso"},
            {id: "Maturéia", name: "PB - Maturéia"},
            {id: "Mogeiro", name: "PB - Mogeiro"},
            {id: "Montadas", name: "PB - Montadas"},
            {id: "Monte Horebe", name: "PB - Monte Horebe"},
            {id: "Monteiro", name: "PB - Monteiro"},
            {id: "Mulungu", name: "PB - Mulungu"},
            {id: "Natuba", name: "PB - Natuba"},
            {id: "Nazarezinho", name: "PB - Nazarezinho"},
            {id: "Nova Floresta", name: "PB - Nova Floresta"},
            {id: "Nova Olinda", name: "PB - Nova Olinda"},
            {id: "Nova Palmeira", name: "PB - Nova Palmeira"},
            {id: "Olho D'água", name: "PB - Olho D'água"},
            {id: "Olivedos", name: "PB - Olivedos"},
            {id: "Ouro Velho", name: "PB - Ouro Velho"},
            {id: "Parari", name: "PB - Parari"},
            {id: "Passagem", name: "PB - Passagem"},
            {id: "Patos", name: "PB - Patos"},
            {id: "Paulista", name: "PB - Paulista"},
            {id: "Pedra Branca", name: "PB - Pedra Branca"},
            {id: "Pedra Lavrada", name: "PB - Pedra Lavrada"},
            {id: "Pedras de Fogo", name: "PB - Pedras de Fogo"},
            {id: "Piancó", name: "PB - Piancó"},
            {id: "Picuí", name: "PB - Picuí"},
            {id: "Pilar", name: "PB - Pilar"},
            {id: "Pilões", name: "PB - Pilões"},
            {id: "Pilõezinhos", name: "PB - Pilõezinhos"},
            {id: "Pirpirituba", name: "PB - Pirpirituba"},
            {id: "Pitimbu", name: "PB - Pitimbu"},
            {id: "Pocinhos", name: "PB - Pocinhos"},
            {id: "Poço Dantas", name: "PB - Poço Dantas"},
            {id: "Poço de José de Moura", name: "PB - Poço de José de Moura"},
            {id: "Pombal", name: "PB - Pombal"},
            {id: "Prata", name: "PB - Prata"},
            {id: "Princesa Isabel", name: "PB - Princesa Isabel"},
            {id: "Puxinanã", name: "PB - Puxinanã"},
            {id: "Queimadas", name: "PB - Queimadas"},
            {id: "Quixabá", name: "PB - Quixabá"},
            {id: "Remígio", name: "PB - Remígio"},
            {id: "Pedro Régis", name: "PB - Pedro Régis"},
            {id: "Riachão", name: "PB - Riachão"},
            {id: "Riachão do Bacamarte", name: "PB - Riachão do Bacamarte"},
            {id: "Riachão do Poço", name: "PB - Riachão do Poço"},
            {id: "Riacho de Santo Antônio", name: "PB - Riacho de Santo Antônio"},
            {id: "Riacho Dos Cavalos", name: "PB - Riacho Dos Cavalos"},
            {id: "Rio Tinto", name: "PB - Rio Tinto"},
            {id: "Salgadinho", name: "PB - Salgadinho"},
            {id: "Salgado de São Félix", name: "PB - Salgado de São Félix"},
            {id: "Santa Cecília", name: "PB - Santa Cecília"},
            {id: "Santa Cruz", name: "PB - Santa Cruz"},
            {id: "Santa Helena", name: "PB - Santa Helena"},
            {id: "Santa Inês", name: "PB - Santa Inês"},
            {id: "Santa Luzia", name: "PB - Santa Luzia"},
            {id: "Santana de Mangueira", name: "PB - Santana de Mangueira"},
            {id: "Santana Dos Garrotes", name: "PB - Santana Dos Garrotes"},
            {id: "Santarém", name: "PB - Santarém"},
            {id: "Santa Rita", name: "PB - Santa Rita"},
            {id: "Santa Teresinha", name: "PB - Santa Teresinha"},
            {id: "Santo André", name: "PB - Santo André"},
            {id: "São Bento", name: "PB - São Bento"},
            {id: "São Bentinho", name: "PB - São Bentinho"},
            {id: "São Domingos do Cariri", name: "PB - São Domingos do Cariri"},
            {id: "São Domingos de Pombal", name: "PB - São Domingos de Pombal"},
            {id: "São Francisco", name: "PB - São Francisco"},
            {id: "São João do Cariri", name: "PB - São João do Cariri"},
            {id: "São João do Tigre", name: "PB - São João do Tigre"},
            {id: "São José da Lagoa Tapada", name: "PB - São José da Lagoa Tapada"},
            {id: "São José de Caiana", name: "PB - São José de Caiana"},
            {id: "São José de Espinharas", name: "PB - São José de Espinharas"},
            {id: "São José Dos Ramos", name: "PB - São José Dos Ramos"},
            {id: "São José de Piranhas", name: "PB - São José de Piranhas"},
            {id: "São José de Princesa", name: "PB - São José de Princesa"},
            {id: "São José do Bonfim", name: "PB - São José do Bonfim"},
            {id: "São José do Brejo do Cruz", name: "PB - São José do Brejo do Cruz"},
            {id: "São José do Sabugi", name: "PB - São José do Sabugi"},
            {id: "São José Dos Cordeiros", name: "PB - São José Dos Cordeiros"},
            {id: "São Mamede", name: "PB - São Mamede"},
            {id: "São Miguel de Taipu", name: "PB - São Miguel de Taipu"},
            {id: "São Sebastião de Lagoa de Roça", name: "PB - São Sebastião de Lagoa de Roça"},
            {id: "São Sebastião do Umbuzeiro", name: "PB - São Sebastião do Umbuzeiro"},
            {id: "Sapé", name: "PB - Sapé"},
            {id: "Seridó", name: "PB - Seridó"},
            {id: "Serra Branca", name: "PB - Serra Branca"},
            {id: "Serra da Raiz", name: "PB - Serra da Raiz"},
            {id: "Serra Grande", name: "PB - Serra Grande"},
            {id: "Serra Redonda", name: "PB - Serra Redonda"},
            {id: "Serraria", name: "PB - Serraria"},
            {id: "Sertãozinho", name: "PB - Sertãozinho"},
            {id: "Sobrado", name: "PB - Sobrado"},
            {id: "Solânea", name: "PB - Solânea"},
            {id: "Soledade", name: "PB - Soledade"},
            {id: "Sossêgo", name: "PB - Sossêgo"},
            {id: "Sousa", name: "PB - Sousa"},
            {id: "Sumé", name: "PB - Sumé"},
            {id: "Campo de Santana", name: "PB - Campo de Santana"},
            {id: "Taperoá", name: "PB - Taperoá"},
            {id: "Tavares", name: "PB - Tavares"},
            {id: "Teixeira", name: "PB - Teixeira"},
            {id: "Tenório", name: "PB - Tenório"},
            {id: "Triunfo", name: "PB - Triunfo"},
            {id: "Uiraúna", name: "PB - Uiraúna"},
            {id: "Umbuzeiro", name: "PB - Umbuzeiro"},
            {id: "Várzea", name: "PB - Várzea"},
            {id: "Vieirópolis", name: "PB - Vieirópolis"},
            {id: "Zabelê", name: "PB - Zabelê"},
            {id: "Abreu e Lima", name: "PE - Abreu e Lima"},
            {id: "Afogados da Ingazeira", name: "PE - Afogados da Ingazeira"},
            {id: "Afrânio", name: "PE - Afrânio"},
            {id: "Agrestina", name: "PE - Agrestina"},
            {id: "Água Preta", name: "PE - Água Preta"},
            {id: "Águas Belas", name: "PE - Águas Belas"},
            {id: "Alagoinha", name: "PE - Alagoinha"},
            {id: "Aliança", name: "PE - Aliança"},
            {id: "Altinho", name: "PE - Altinho"},
            {id: "Amaraji", name: "PE - Amaraji"},
            {id: "Angelim", name: "PE - Angelim"},
            {id: "Araçoiaba", name: "PE - Araçoiaba"},
            {id: "Araripina", name: "PE - Araripina"},
            {id: "Arcoverde", name: "PE - Arcoverde"},
            {id: "Barra de Guabiraba", name: "PE - Barra de Guabiraba"},
            {id: "Barreiros", name: "PE - Barreiros"},
            {id: "Belém de Maria", name: "PE - Belém de Maria"},
            {id: "Belém de São Francisco", name: "PE - Belém de São Francisco"},
            {id: "Belo Jardim", name: "PE - Belo Jardim"},
            {id: "Betânia", name: "PE - Betânia"},
            {id: "Bezerros", name: "PE - Bezerros"},
            {id: "Bodocó", name: "PE - Bodocó"},
            {id: "Bom Conselho", name: "PE - Bom Conselho"},
            {id: "Bom Jardim", name: "PE - Bom Jardim"},
            {id: "Bonito", name: "PE - Bonito"},
            {id: "Brejão", name: "PE - Brejão"},
            {id: "Brejinho", name: "PE - Brejinho"},
            {id: "Brejo da Madre de Deus", name: "PE - Brejo da Madre de Deus"},
            {id: "Buenos Aires", name: "PE - Buenos Aires"},
            {id: "Buíque", name: "PE - Buíque"},
            {id: "Cabo de Santo Agostinho", name: "PE - Cabo de Santo Agostinho"},
            {id: "Cabrobó", name: "PE - Cabrobó"},
            {id: "Cachoeirinha", name: "PE - Cachoeirinha"},
            {id: "Caetés", name: "PE - Caetés"},
            {id: "Calçado", name: "PE - Calçado"},
            {id: "Calumbi", name: "PE - Calumbi"},
            {id: "Camaragibe", name: "PE - Camaragibe"},
            {id: "Camocim de São Félix", name: "PE - Camocim de São Félix"},
            {id: "Camutanga", name: "PE - Camutanga"},
            {id: "Canhotinho", name: "PE - Canhotinho"},
            {id: "Capoeiras", name: "PE - Capoeiras"},
            {id: "Carnaíba", name: "PE - Carnaíba"},
            {id: "Carnaubeira da Penha", name: "PE - Carnaubeira da Penha"},
            {id: "Carpina", name: "PE - Carpina"},
            {id: "Caruaru", name: "PE - Caruaru"},
            {id: "Casinhas", name: "PE - Casinhas"},
            {id: "Catende", name: "PE - Catende"},
            {id: "Cedro", name: "PE - Cedro"},
            {id: "Chã de Alegria", name: "PE - Chã de Alegria"},
            {id: "Chã Grande", name: "PE - Chã Grande"},
            {id: "Condado", name: "PE - Condado"},
            {id: "Correntes", name: "PE - Correntes"},
            {id: "Cortês", name: "PE - Cortês"},
            {id: "Cumaru", name: "PE - Cumaru"},
            {id: "Cupira", name: "PE - Cupira"},
            {id: "Custódia", name: "PE - Custódia"},
            {id: "Dormentes", name: "PE - Dormentes"},
            {id: "Escada", name: "PE - Escada"},
            {id: "Exu", name: "PE - Exu"},
            {id: "Feira Nova", name: "PE - Feira Nova"},
            {id: "Fernando de Noronha", name: "PE - Fernando de Noronha"},
            {id: "Ferreiros", name: "PE - Ferreiros"},
            {id: "Flores", name: "PE - Flores"},
            {id: "Floresta", name: "PE - Floresta"},
            {id: "Frei Miguelinho", name: "PE - Frei Miguelinho"},
            {id: "Gameleira", name: "PE - Gameleira"},
            {id: "Garanhuns", name: "PE - Garanhuns"},
            {id: "Glória do Goitá", name: "PE - Glória do Goitá"},
            {id: "Goiana", name: "PE - Goiana"},
            {id: "Granito", name: "PE - Granito"},
            {id: "Gravatá", name: "PE - Gravatá"},
            {id: "Iati", name: "PE - Iati"},
            {id: "Ibimirim", name: "PE - Ibimirim"},
            {id: "Ibirajuba", name: "PE - Ibirajuba"},
            {id: "Igarassu", name: "PE - Igarassu"},
            {id: "Iguaraci", name: "PE - Iguaraci"},
            {id: "Inajá", name: "PE - Inajá"},
            {id: "Ingazeira", name: "PE - Ingazeira"},
            {id: "Ipojuca", name: "PE - Ipojuca"},
            {id: "Ipubi", name: "PE - Ipubi"},
            {id: "Itacuruba", name: "PE - Itacuruba"},
            {id: "Itaíba", name: "PE - Itaíba"},
            {id: "Ilha de Itamaracá", name: "PE - Ilha de Itamaracá"},
            {id: "Itambé", name: "PE - Itambé"},
            {id: "Itapetim", name: "PE - Itapetim"},
            {id: "Itapissuma", name: "PE - Itapissuma"},
            {id: "Itaquitinga", name: "PE - Itaquitinga"},
            {id: "Jaboatão Dos Guararapes", name: "PE - Jaboatão Dos Guararapes"},
            {id: "Jaqueira", name: "PE - Jaqueira"},
            {id: "Jataúba", name: "PE - Jataúba"},
            {id: "Jatobá", name: "PE - Jatobá"},
            {id: "João Alfredo", name: "PE - João Alfredo"},
            {id: "Joaquim Nabuco", name: "PE - Joaquim Nabuco"},
            {id: "Jucati", name: "PE - Jucati"},
            {id: "Jupi", name: "PE - Jupi"},
            {id: "Jurema", name: "PE - Jurema"},
            {id: "Lagoa do Carro", name: "PE - Lagoa do Carro"},
            {id: "Lagoa do Itaenga", name: "PE - Lagoa do Itaenga"},
            {id: "Lagoa do Ouro", name: "PE - Lagoa do Ouro"},
            {id: "Lagoa Dos Gatos", name: "PE - Lagoa Dos Gatos"},
            {id: "Lagoa Grande", name: "PE - Lagoa Grande"},
            {id: "Lajedo", name: "PE - Lajedo"},
            {id: "Limoeiro", name: "PE - Limoeiro"},
            {id: "Macaparana", name: "PE - Macaparana"},
            {id: "Machados", name: "PE - Machados"},
            {id: "Manari", name: "PE - Manari"},
            {id: "Maraial", name: "PE - Maraial"},
            {id: "Mirandiba", name: "PE - Mirandiba"},
            {id: "Moreno", name: "PE - Moreno"},
            {id: "Nazaré da Mata", name: "PE - Nazaré da Mata"},
            {id: "Olinda", name: "PE - Olinda"},
            {id: "Orobó", name: "PE - Orobó"},
            {id: "Orocó", name: "PE - Orocó"},
            {id: "Ouricuri", name: "PE - Ouricuri"},
            {id: "Palmares", name: "PE - Palmares"},
            {id: "Palmeirina", name: "PE - Palmeirina"},
            {id: "Panelas", name: "PE - Panelas"},
            {id: "Paranatama", name: "PE - Paranatama"},
            {id: "Parnamirim", name: "PE - Parnamirim"},
            {id: "Passira", name: "PE - Passira"},
            {id: "Paudalho", name: "PE - Paudalho"},
            {id: "Paulista", name: "PE - Paulista"},
            {id: "Pedra", name: "PE - Pedra"},
            {id: "Pesqueira", name: "PE - Pesqueira"},
            {id: "Petrolândia", name: "PE - Petrolândia"},
            {id: "Petrolina", name: "PE - Petrolina"},
            {id: "Poção", name: "PE - Poção"},
            {id: "Pombos", name: "PE - Pombos"},
            {id: "Primavera", name: "PE - Primavera"},
            {id: "Quipapá", name: "PE - Quipapá"},
            {id: "Quixaba", name: "PE - Quixaba"},
            {id: "Recife", name: "PE - Recife"},
            {id: "Riacho Das Almas", name: "PE - Riacho Das Almas"},
            {id: "Ribeirão", name: "PE - Ribeirão"},
            {id: "Rio Formoso", name: "PE - Rio Formoso"},
            {id: "Sairé", name: "PE - Sairé"},
            {id: "Salgadinho", name: "PE - Salgadinho"},
            {id: "Salgueiro", name: "PE - Salgueiro"},
            {id: "Saloá", name: "PE - Saloá"},
            {id: "Sanharó", name: "PE - Sanharó"},
            {id: "Santa Cruz", name: "PE - Santa Cruz"},
            {id: "Santa Cruz da Baixa Verde", name: "PE - Santa Cruz da Baixa Verde"},
            {id: "Santa Cruz do Capibaribe", name: "PE - Santa Cruz do Capibaribe"},
            {id: "Santa Filomena", name: "PE - Santa Filomena"},
            {id: "Santa Maria da Boa Vista", name: "PE - Santa Maria da Boa Vista"},
            {id: "Santa Maria do Cambucá", name: "PE - Santa Maria do Cambucá"},
            {id: "Santa Terezinha", name: "PE - Santa Terezinha"},
            {id: "São Benedito do Sul", name: "PE - São Benedito do Sul"},
            {id: "São Bento do Una", name: "PE - São Bento do Una"},
            {id: "São Caitano", name: "PE - São Caitano"},
            {id: "São João", name: "PE - São João"},
            {id: "São Joaquim do Monte", name: "PE - São Joaquim do Monte"},
            {id: "São José da Coroa Grande", name: "PE - São José da Coroa Grande"},
            {id: "São José do Belmonte", name: "PE - São José do Belmonte"},
            {id: "São José do Egito", name: "PE - São José do Egito"},
            {id: "São Lourenço da Mata", name: "PE - São Lourenço da Mata"},
            {id: "São Vicente Ferrer", name: "PE - São Vicente Ferrer"},
            {id: "Serra Talhada", name: "PE - Serra Talhada"},
            {id: "Serrita", name: "PE - Serrita"},
            {id: "Sertânia", name: "PE - Sertânia"},
            {id: "Sirinhaém", name: "PE - Sirinhaém"},
            {id: "Moreilândia", name: "PE - Moreilândia"},
            {id: "Solidão", name: "PE - Solidão"},
            {id: "Surubim", name: "PE - Surubim"},
            {id: "Tabira", name: "PE - Tabira"},
            {id: "Tacaimbó", name: "PE - Tacaimbó"},
            {id: "Tacaratu", name: "PE - Tacaratu"},
            {id: "Tamandaré", name: "PE - Tamandaré"},
            {id: "Taquaritinga do Norte", name: "PE - Taquaritinga do Norte"},
            {id: "Terezinha", name: "PE - Terezinha"},
            {id: "Terra Nova", name: "PE - Terra Nova"},
            {id: "Timbaúba", name: "PE - Timbaúba"},
            {id: "Toritama", name: "PE - Toritama"},
            {id: "Tracunhaém", name: "PE - Tracunhaém"},
            {id: "Trindade", name: "PE - Trindade"},
            {id: "Triunfo", name: "PE - Triunfo"},
            {id: "Tupanatinga", name: "PE - Tupanatinga"},
            {id: "Tuparetama", name: "PE - Tuparetama"},
            {id: "Venturosa", name: "PE - Venturosa"},
            {id: "Verdejante", name: "PE - Verdejante"},
            {id: "Vertente do Lério", name: "PE - Vertente do Lério"},
            {id: "Vertentes", name: "PE - Vertentes"},
            {id: "Vicência", name: "PE - Vicência"},
            {id: "Vitória de Santo Antão", name: "PE - Vitória de Santo Antão"},
            {id: "Xexéu", name: "PE - Xexéu"},
            {id: "Água Branca", name: "AL - Água Branca"},
            {id: "Anadia", name: "AL - Anadia"},
            {id: "Arapiraca", name: "AL - Arapiraca"},
            {id: "Atalaia", name: "AL - Atalaia"},
            {id: "Barra de Santo Antônio", name: "AL - Barra de Santo Antônio"},
            {id: "Barra de São Miguel", name: "AL - Barra de São Miguel"},
            {id: "Batalha", name: "AL - Batalha"},
            {id: "Belém", name: "AL - Belém"},
            {id: "Belo Monte", name: "AL - Belo Monte"},
            {id: "Boca da Mata", name: "AL - Boca da Mata"},
            {id: "Branquinha", name: "AL - Branquinha"},
            {id: "Cacimbinhas", name: "AL - Cacimbinhas"},
            {id: "Cajueiro", name: "AL - Cajueiro"},
            {id: "Campestre", name: "AL - Campestre"},
            {id: "Campo Alegre", name: "AL - Campo Alegre"},
            {id: "Campo Grande", name: "AL - Campo Grande"},
            {id: "Canapi", name: "AL - Canapi"},
            {id: "Capela", name: "AL - Capela"},
            {id: "Carneiros", name: "AL - Carneiros"},
            {id: "Chã Preta", name: "AL - Chã Preta"},
            {id: "Coité do Nóia", name: "AL - Coité do Nóia"},
            {id: "Colônia Leopoldina", name: "AL - Colônia Leopoldina"},
            {id: "Coqueiro Seco", name: "AL - Coqueiro Seco"},
            {id: "Coruripe", name: "AL - Coruripe"},
            {id: "Craíbas", name: "AL - Craíbas"},
            {id: "Delmiro Gouveia", name: "AL - Delmiro Gouveia"},
            {id: "Dois Riachos", name: "AL - Dois Riachos"},
            {id: "Estrela de Alagoas", name: "AL - Estrela de Alagoas"},
            {id: "Feira Grande", name: "AL - Feira Grande"},
            {id: "Feliz Deserto", name: "AL - Feliz Deserto"},
            {id: "Flexeiras", name: "AL - Flexeiras"},
            {id: "Girau do Ponciano", name: "AL - Girau do Ponciano"},
            {id: "Ibateguara", name: "AL - Ibateguara"},
            {id: "Igaci", name: "AL - Igaci"},
            {id: "Igreja Nova", name: "AL - Igreja Nova"},
            {id: "Inhapi", name: "AL - Inhapi"},
            {id: "Jacaré Dos Homens", name: "AL - Jacaré Dos Homens"},
            {id: "Jacuípe", name: "AL - Jacuípe"},
            {id: "Japaratinga", name: "AL - Japaratinga"},
            {id: "Jaramataia", name: "AL - Jaramataia"},
            {id: "Jequiá da Praia", name: "AL - Jequiá da Praia"},
            {id: "Joaquim Gomes", name: "AL - Joaquim Gomes"},
            {id: "Jundiá", name: "AL - Jundiá"},
            {id: "Junqueiro", name: "AL - Junqueiro"},
            {id: "Lagoa da Canoa", name: "AL - Lagoa da Canoa"},
            {id: "Limoeiro de Anadia", name: "AL - Limoeiro de Anadia"},
            {id: "Maceió", name: "AL - Maceió"},
            {id: "Major Isidoro", name: "AL - Major Isidoro"},
            {id: "Maragogi", name: "AL - Maragogi"},
            {id: "Maravilha", name: "AL - Maravilha"},
            {id: "Marechal Deodoro", name: "AL - Marechal Deodoro"},
            {id: "Maribondo", name: "AL - Maribondo"},
            {id: "Mar Vermelho", name: "AL - Mar Vermelho"},
            {id: "Mata Grande", name: "AL - Mata Grande"},
            {id: "Matriz de Camaragibe", name: "AL - Matriz de Camaragibe"},
            {id: "Messias", name: "AL - Messias"},
            {id: "Minador do Negrão", name: "AL - Minador do Negrão"},
            {id: "Monteirópolis", name: "AL - Monteirópolis"},
            {id: "Murici", name: "AL - Murici"},
            {id: "Novo Lino", name: "AL - Novo Lino"},
            {id: "Olho D'água Das Flores", name: "AL - Olho D'água Das Flores"},
            {id: "Olho D'água do Casado", name: "AL - Olho D'água do Casado"},
            {id: "Olho D'água Grande", name: "AL - Olho D'água Grande"},
            {id: "Olivença", name: "AL - Olivença"},
            {id: "Ouro Branco", name: "AL - Ouro Branco"},
            {id: "Palestina", name: "AL - Palestina"},
            {id: "Palmeira Dos Índios", name: "AL - Palmeira Dos Índios"},
            {id: "Pão de Açúcar", name: "AL - Pão de Açúcar"},
            {id: "Pariconha", name: "AL - Pariconha"},
            {id: "Paripueira", name: "AL - Paripueira"},
            {id: "Passo de Camaragibe", name: "AL - Passo de Camaragibe"},
            {id: "Paulo Jacinto", name: "AL - Paulo Jacinto"},
            {id: "Penedo", name: "AL - Penedo"},
            {id: "Piaçabuçu", name: "AL - Piaçabuçu"},
            {id: "Pilar", name: "AL - Pilar"},
            {id: "Pindoba", name: "AL - Pindoba"},
            {id: "Piranhas", name: "AL - Piranhas"},
            {id: "Poço Das Trincheiras", name: "AL - Poço Das Trincheiras"},
            {id: "Porto Calvo", name: "AL - Porto Calvo"},
            {id: "Porto de Pedras", name: "AL - Porto de Pedras"},
            {id: "Porto Real do Colégio", name: "AL - Porto Real do Colégio"},
            {id: "Quebrangulo", name: "AL - Quebrangulo"},
            {id: "Rio Largo", name: "AL - Rio Largo"},
            {id: "Roteiro", name: "AL - Roteiro"},
            {id: "Santa Luzia do Norte", name: "AL - Santa Luzia do Norte"},
            {id: "Santana do Ipanema", name: "AL - Santana do Ipanema"},
            {id: "Santana do Mundaú", name: "AL - Santana do Mundaú"},
            {id: "São Brás", name: "AL - São Brás"},
            {id: "São José da Laje", name: "AL - São José da Laje"},
            {id: "São José da Tapera", name: "AL - São José da Tapera"},
            {id: "São Luís do Quitunde", name: "AL - São Luís do Quitunde"},
            {id: "São Miguel Dos Campos", name: "AL - São Miguel Dos Campos"},
            {id: "São Miguel Dos Milagres", name: "AL - São Miguel Dos Milagres"},
            {id: "São Sebastião", name: "AL - São Sebastião"},
            {id: "Satuba", name: "AL - Satuba"},
            {id: "Senador Rui Palmeira", name: "AL - Senador Rui Palmeira"},
            {id: "Tanque D'arca", name: "AL - Tanque D'arca"},
            {id: "Taquarana", name: "AL - Taquarana"},
            {id: "Teotônio Vilela", name: "AL - Teotônio Vilela"},
            {id: "Traipu", name: "AL - Traipu"},
            {id: "União Dos Palmares", name: "AL - União Dos Palmares"},
            {id: "Viçosa", name: "AL - Viçosa"},
            {id: "Amparo de São Francisco", name: "SE - Amparo de São Francisco"},
            {id: "Aquidabã", name: "SE - Aquidabã"},
            {id: "Aracaju", name: "SE - Aracaju"},
            {id: "Arauá", name: "SE - Arauá"},
            {id: "Areia Branca", name: "SE - Areia Branca"},
            {id: "Barra Dos Coqueiros", name: "SE - Barra Dos Coqueiros"},
            {id: "Boquim", name: "SE - Boquim"},
            {id: "Brejo Grande", name: "SE - Brejo Grande"},
            {id: "Campo do Brito", name: "SE - Campo do Brito"},
            {id: "Canhoba", name: "SE - Canhoba"},
            {id: "Canindé de São Francisco", name: "SE - Canindé de São Francisco"},
            {id: "Capela", name: "SE - Capela"},
            {id: "Carira", name: "SE - Carira"},
            {id: "Carmópolis", name: "SE - Carmópolis"},
            {id: "Cedro de São João", name: "SE - Cedro de São João"},
            {id: "Cristinápolis", name: "SE - Cristinápolis"},
            {id: "Cumbe", name: "SE - Cumbe"},
            {id: "Divina Pastora", name: "SE - Divina Pastora"},
            {id: "Estância", name: "SE - Estância"},
            {id: "Feira Nova", name: "SE - Feira Nova"},
            {id: "Frei Paulo", name: "SE - Frei Paulo"},
            {id: "Gararu", name: "SE - Gararu"},
            {id: "General Maynard", name: "SE - General Maynard"},
            {id: "Gracho Cardoso", name: "SE - Gracho Cardoso"},
            {id: "Ilha Das Flores", name: "SE - Ilha Das Flores"},
            {id: "Indiaroba", name: "SE - Indiaroba"},
            {id: "Itabaiana", name: "SE - Itabaiana"},
            {id: "Itabaianinha", name: "SE - Itabaianinha"},
            {id: "Itabi", name: "SE - Itabi"},
            {id: "Itaporanga D'ajuda", name: "SE - Itaporanga D'ajuda"},
            {id: "Japaratuba", name: "SE - Japaratuba"},
            {id: "Japoatã", name: "SE - Japoatã"},
            {id: "Lagarto", name: "SE - Lagarto"},
            {id: "Laranjeiras", name: "SE - Laranjeiras"},
            {id: "Macambira", name: "SE - Macambira"},
            {id: "Malhada Dos Bois", name: "SE - Malhada Dos Bois"},
            {id: "Malhador", name: "SE - Malhador"},
            {id: "Maruim", name: "SE - Maruim"},
            {id: "Moita Bonita", name: "SE - Moita Bonita"},
            {id: "Monte Alegre de Sergipe", name: "SE - Monte Alegre de Sergipe"},
            {id: "Muribeca", name: "SE - Muribeca"},
            {id: "Neópolis", name: "SE - Neópolis"},
            {id: "Nossa Senhora Aparecida", name: "SE - Nossa Senhora Aparecida"},
            {id: "Nossa Senhora da Glória", name: "SE - Nossa Senhora da Glória"},
            {id: "Nossa Senhora Das Dores", name: "SE - Nossa Senhora Das Dores"},
            {id: "Nossa Senhora de Lourdes", name: "SE - Nossa Senhora de Lourdes"},
            {id: "Nossa Senhora do Socorro", name: "SE - Nossa Senhora do Socorro"},
            {id: "Pacatuba", name: "SE - Pacatuba"},
            {id: "Pedra Mole", name: "SE - Pedra Mole"},
            {id: "Pedrinhas", name: "SE - Pedrinhas"},
            {id: "Pinhão", name: "SE - Pinhão"},
            {id: "Pirambu", name: "SE - Pirambu"},
            {id: "Poço Redondo", name: "SE - Poço Redondo"},
            {id: "Poço Verde", name: "SE - Poço Verde"},
            {id: "Porto da Folha", name: "SE - Porto da Folha"},
            {id: "Propriá", name: "SE - Propriá"},
            {id: "Riachão do Dantas", name: "SE - Riachão do Dantas"},
            {id: "Riachuelo", name: "SE - Riachuelo"},
            {id: "Ribeirópolis", name: "SE - Ribeirópolis"},
            {id: "Rosário do Catete", name: "SE - Rosário do Catete"},
            {id: "Salgado", name: "SE - Salgado"},
            {id: "Santa Luzia do Itanhy", name: "SE - Santa Luzia do Itanhy"},
            {id: "Santana do São Francisco", name: "SE - Santana do São Francisco"},
            {id: "Santa Rosa de Lima", name: "SE - Santa Rosa de Lima"},
            {id: "Santo Amaro Das Brotas", name: "SE - Santo Amaro Das Brotas"},
            {id: "São Cristóvão", name: "SE - São Cristóvão"},
            {id: "São Domingos", name: "SE - São Domingos"},
            {id: "São Francisco", name: "SE - São Francisco"},
            {id: "São Miguel do Aleixo", name: "SE - São Miguel do Aleixo"},
            {id: "Simão Dias", name: "SE - Simão Dias"},
            {id: "Siriri", name: "SE - Siriri"},
            {id: "Telha", name: "SE - Telha"},
            {id: "Tobias Barreto", name: "SE - Tobias Barreto"},
            {id: "Tomar do Geru", name: "SE - Tomar do Geru"},
            {id: "Umbaúba", name: "SE - Umbaúba"},
            {id: "Abaíra", name: "BA - Abaíra"},
            {id: "Abaré", name: "BA - Abaré"},
            {id: "Acajutiba", name: "BA - Acajutiba"},
            {id: "Adustina", name: "BA - Adustina"},
            {id: "Água Fria", name: "BA - Água Fria"},
            {id: "Érico Cardoso", name: "BA - Érico Cardoso"},
            {id: "Aiquara", name: "BA - Aiquara"},
            {id: "Alagoinhas", name: "BA - Alagoinhas"},
            {id: "Alcobaça", name: "BA - Alcobaça"},
            {id: "Almadina", name: "BA - Almadina"},
            {id: "Amargosa", name: "BA - Amargosa"},
            {id: "Amélia Rodrigues", name: "BA - Amélia Rodrigues"},
            {id: "América Dourada", name: "BA - América Dourada"},
            {id: "Anagé", name: "BA - Anagé"},
            {id: "Andaraí", name: "BA - Andaraí"},
            {id: "Andorinha", name: "BA - Andorinha"},
            {id: "Angical", name: "BA - Angical"},
            {id: "Anguera", name: "BA - Anguera"},
            {id: "Antas", name: "BA - Antas"},
            {id: "Antônio Cardoso", name: "BA - Antônio Cardoso"},
            {id: "Antônio Gonçalves", name: "BA - Antônio Gonçalves"},
            {id: "Aporá", name: "BA - Aporá"},
            {id: "Apuarema", name: "BA - Apuarema"},
            {id: "Aracatu", name: "BA - Aracatu"},
            {id: "Araças", name: "BA - Araças"},
            {id: "Araci", name: "BA - Araci"},
            {id: "Aramari", name: "BA - Aramari"},
            {id: "Arataca", name: "BA - Arataca"},
            {id: "Aratuípe", name: "BA - Aratuípe"},
            {id: "Aurelino Leal", name: "BA - Aurelino Leal"},
            {id: "Baianópolis", name: "BA - Baianópolis"},
            {id: "Baixa Grande", name: "BA - Baixa Grande"},
            {id: "Banzaê", name: "BA - Banzaê"},
            {id: "Barra", name: "BA - Barra"},
            {id: "Barra da Estiva", name: "BA - Barra da Estiva"},
            {id: "Barra do Choça", name: "BA - Barra do Choça"},
            {id: "Barra do Mendes", name: "BA - Barra do Mendes"},
            {id: "Barra do Rocha", name: "BA - Barra do Rocha"},
            {id: "Barreiras", name: "BA - Barreiras"},
            {id: "Barro Alto", name: "BA - Barro Alto"},
            {id: "Barrocas", name: "BA - Barrocas"},
            {id: "Governador Lomanto Júnior", name: "BA - Governador Lomanto Júnior"},
            {id: "Belmonte", name: "BA - Belmonte"},
            {id: "Belo Campo", name: "BA - Belo Campo"},
            {id: "Biritinga", name: "BA - Biritinga"},
            {id: "Boa Nova", name: "BA - Boa Nova"},
            {id: "Boa Vista do Tupim", name: "BA - Boa Vista do Tupim"},
            {id: "Bom Jesus da Lapa", name: "BA - Bom Jesus da Lapa"},
            {id: "Bom Jesus da Serra", name: "BA - Bom Jesus da Serra"},
            {id: "Boninal", name: "BA - Boninal"},
            {id: "Bonito", name: "BA - Bonito"},
            {id: "Boquira", name: "BA - Boquira"},
            {id: "Botuporã", name: "BA - Botuporã"},
            {id: "Brejões", name: "BA - Brejões"},
            {id: "Brejolândia", name: "BA - Brejolândia"},
            {id: "Brotas de Macaúbas", name: "BA - Brotas de Macaúbas"},
            {id: "Brumado", name: "BA - Brumado"},
            {id: "Buerarema", name: "BA - Buerarema"},
            {id: "Buritirama", name: "BA - Buritirama"},
            {id: "Caatiba", name: "BA - Caatiba"},
            {id: "Cabaceiras do Paraguaçu", name: "BA - Cabaceiras do Paraguaçu"},
            {id: "Cachoeira", name: "BA - Cachoeira"},
            {id: "Caculé", name: "BA - Caculé"},
            {id: "Caém", name: "BA - Caém"},
            {id: "Caetanos", name: "BA - Caetanos"},
            {id: "Caetité", name: "BA - Caetité"},
            {id: "Cafarnaum", name: "BA - Cafarnaum"},
            {id: "Cairu", name: "BA - Cairu"},
            {id: "Caldeirão Grande", name: "BA - Caldeirão Grande"},
            {id: "Camacan", name: "BA - Camacan"},
            {id: "Camaçari", name: "BA - Camaçari"},
            {id: "Camamu", name: "BA - Camamu"},
            {id: "Campo Alegre de Lourdes", name: "BA - Campo Alegre de Lourdes"},
            {id: "Campo Formoso", name: "BA - Campo Formoso"},
            {id: "Canápolis", name: "BA - Canápolis"},
            {id: "Canarana", name: "BA - Canarana"},
            {id: "Canavieiras", name: "BA - Canavieiras"},
            {id: "Candeal", name: "BA - Candeal"},
            {id: "Candeias", name: "BA - Candeias"},
            {id: "Candiba", name: "BA - Candiba"},
            {id: "Cândido Sales", name: "BA - Cândido Sales"},
            {id: "Cansanção", name: "BA - Cansanção"},
            {id: "Canudos", name: "BA - Canudos"},
            {id: "Capela do Alto Alegre", name: "BA - Capela do Alto Alegre"},
            {id: "Capim Grosso", name: "BA - Capim Grosso"},
            {id: "Caraíbas", name: "BA - Caraíbas"},
            {id: "Caravelas", name: "BA - Caravelas"},
            {id: "Cardeal da Silva", name: "BA - Cardeal da Silva"},
            {id: "Carinhanha", name: "BA - Carinhanha"},
            {id: "Casa Nova", name: "BA - Casa Nova"},
            {id: "Castro Alves", name: "BA - Castro Alves"},
            {id: "Catolândia", name: "BA - Catolândia"},
            {id: "Catu", name: "BA - Catu"},
            {id: "Caturama", name: "BA - Caturama"},
            {id: "Central", name: "BA - Central"},
            {id: "Chorrochó", name: "BA - Chorrochó"},
            {id: "Cícero Dantas", name: "BA - Cícero Dantas"},
            {id: "Cipó", name: "BA - Cipó"},
            {id: "Coaraci", name: "BA - Coaraci"},
            {id: "Cocos", name: "BA - Cocos"},
            {id: "Conceição da Feira", name: "BA - Conceição da Feira"},
            {id: "Conceição do Almeida", name: "BA - Conceição do Almeida"},
            {id: "Conceição do Coité", name: "BA - Conceição do Coité"},
            {id: "Conceição do Jacuípe", name: "BA - Conceição do Jacuípe"},
            {id: "Conde", name: "BA - Conde"},
            {id: "Condeúba", name: "BA - Condeúba"},
            {id: "Contendas do Sincorá", name: "BA - Contendas do Sincorá"},
            {id: "Coração de Maria", name: "BA - Coração de Maria"},
            {id: "Cordeiros", name: "BA - Cordeiros"},
            {id: "Coribe", name: "BA - Coribe"},
            {id: "Coronel João sá", name: "BA - Coronel João sá"},
            {id: "Correntina", name: "BA - Correntina"},
            {id: "Cotegipe", name: "BA - Cotegipe"},
            {id: "Cravolândia", name: "BA - Cravolândia"},
            {id: "Crisópolis", name: "BA - Crisópolis"},
            {id: "Cristópolis", name: "BA - Cristópolis"},
            {id: "Cruz Das Almas", name: "BA - Cruz Das Almas"},
            {id: "Curaçá", name: "BA - Curaçá"},
            {id: "Dário Meira", name: "BA - Dário Meira"},
            {id: "Dias D'ávila", name: "BA - Dias D'ávila"},
            {id: "Dom Basílio", name: "BA - Dom Basílio"},
            {id: "Dom Macedo Costa", name: "BA - Dom Macedo Costa"},
            {id: "Elísio Medrado", name: "BA - Elísio Medrado"},
            {id: "Encruzilhada", name: "BA - Encruzilhada"},
            {id: "Entre Rios", name: "BA - Entre Rios"},
            {id: "Esplanada", name: "BA - Esplanada"},
            {id: "Euclides da Cunha", name: "BA - Euclides da Cunha"},
            {id: "Eunápolis", name: "BA - Eunápolis"},
            {id: "Fátima", name: "BA - Fátima"},
            {id: "Feira da Mata", name: "BA - Feira da Mata"},
            {id: "Feira de Santana", name: "BA - Feira de Santana"},
            {id: "Filadélfia", name: "BA - Filadélfia"},
            {id: "Firmino Alves", name: "BA - Firmino Alves"},
            {id: "Floresta Azul", name: "BA - Floresta Azul"},
            {id: "Formosa do Rio Preto", name: "BA - Formosa do Rio Preto"},
            {id: "Gandu", name: "BA - Gandu"},
            {id: "Gavião", name: "BA - Gavião"},
            {id: "Gentio do Ouro", name: "BA - Gentio do Ouro"},
            {id: "Glória", name: "BA - Glória"},
            {id: "Gongogi", name: "BA - Gongogi"},
            {id: "Governador Mangabeira", name: "BA - Governador Mangabeira"},
            {id: "Guajeru", name: "BA - Guajeru"},
            {id: "Guanambi", name: "BA - Guanambi"},
            {id: "Guaratinga", name: "BA - Guaratinga"},
            {id: "Heliópolis", name: "BA - Heliópolis"},
            {id: "Iaçu", name: "BA - Iaçu"},
            {id: "Ibiassucê", name: "BA - Ibiassucê"},
            {id: "Ibicaraí", name: "BA - Ibicaraí"},
            {id: "Ibicoara", name: "BA - Ibicoara"},
            {id: "Ibicuí", name: "BA - Ibicuí"},
            {id: "Ibipeba", name: "BA - Ibipeba"},
            {id: "Ibipitanga", name: "BA - Ibipitanga"},
            {id: "Ibiquera", name: "BA - Ibiquera"},
            {id: "Ibirapitanga", name: "BA - Ibirapitanga"},
            {id: "Ibirapuã", name: "BA - Ibirapuã"},
            {id: "Ibirataia", name: "BA - Ibirataia"},
            {id: "Ibitiara", name: "BA - Ibitiara"},
            {id: "Ibititá", name: "BA - Ibititá"},
            {id: "Ibotirama", name: "BA - Ibotirama"},
            {id: "Ichu", name: "BA - Ichu"},
            {id: "Igaporã", name: "BA - Igaporã"},
            {id: "Igrapiúna", name: "BA - Igrapiúna"},
            {id: "Iguaí", name: "BA - Iguaí"},
            {id: "Ilhéus", name: "BA - Ilhéus"},
            {id: "Inhambupe", name: "BA - Inhambupe"},
            {id: "Ipecaetá", name: "BA - Ipecaetá"},
            {id: "Ipiaú", name: "BA - Ipiaú"},
            {id: "Ipirá", name: "BA - Ipirá"},
            {id: "Ipupiara", name: "BA - Ipupiara"},
            {id: "Irajuba", name: "BA - Irajuba"},
            {id: "Iramaia", name: "BA - Iramaia"},
            {id: "Iraquara", name: "BA - Iraquara"},
            {id: "Irará", name: "BA - Irará"},
            {id: "Irecê", name: "BA - Irecê"},
            {id: "Itabela", name: "BA - Itabela"},
            {id: "Itaberaba", name: "BA - Itaberaba"},
            {id: "Itabuna", name: "BA - Itabuna"},
            {id: "Itacaré", name: "BA - Itacaré"},
            {id: "Itaeté", name: "BA - Itaeté"},
            {id: "Itagi", name: "BA - Itagi"},
            {id: "Itagibá", name: "BA - Itagibá"},
            {id: "Itagimirim", name: "BA - Itagimirim"},
            {id: "Itaguaçu da Bahia", name: "BA - Itaguaçu da Bahia"},
            {id: "Itaju do Colônia", name: "BA - Itaju do Colônia"},
            {id: "Itajuípe", name: "BA - Itajuípe"},
            {id: "Itamaraju", name: "BA - Itamaraju"},
            {id: "Itamari", name: "BA - Itamari"},
            {id: "Itambé", name: "BA - Itambé"},
            {id: "Itanagra", name: "BA - Itanagra"},
            {id: "Itanhém", name: "BA - Itanhém"},
            {id: "Itaparica", name: "BA - Itaparica"},
            {id: "Itapé", name: "BA - Itapé"},
            {id: "Itapebi", name: "BA - Itapebi"},
            {id: "Itapetinga", name: "BA - Itapetinga"},
            {id: "Itapicuru", name: "BA - Itapicuru"},
            {id: "Itapitanga", name: "BA - Itapitanga"},
            {id: "Itaquara", name: "BA - Itaquara"},
            {id: "Itarantim", name: "BA - Itarantim"},
            {id: "Itatim", name: "BA - Itatim"},
            {id: "Itiruçu", name: "BA - Itiruçu"},
            {id: "Itiúba", name: "BA - Itiúba"},
            {id: "Itororó", name: "BA - Itororó"},
            {id: "Ituaçu", name: "BA - Ituaçu"},
            {id: "Ituberá", name: "BA - Ituberá"},
            {id: "Iuiú", name: "BA - Iuiú"},
            {id: "Jaborandi", name: "BA - Jaborandi"},
            {id: "Jacaraci", name: "BA - Jacaraci"},
            {id: "Jacobina", name: "BA - Jacobina"},
            {id: "Jaguaquara", name: "BA - Jaguaquara"},
            {id: "Jaguarari", name: "BA - Jaguarari"},
            {id: "Jaguaripe", name: "BA - Jaguaripe"},
            {id: "Jandaíra", name: "BA - Jandaíra"},
            {id: "Jequié", name: "BA - Jequié"},
            {id: "Jeremoabo", name: "BA - Jeremoabo"},
            {id: "Jiquiriçá", name: "BA - Jiquiriçá"},
            {id: "Jitaúna", name: "BA - Jitaúna"},
            {id: "João Dourado", name: "BA - João Dourado"},
            {id: "Juazeiro", name: "BA - Juazeiro"},
            {id: "Jucuruçu", name: "BA - Jucuruçu"},
            {id: "Jussara", name: "BA - Jussara"},
            {id: "Jussari", name: "BA - Jussari"},
            {id: "Jussiape", name: "BA - Jussiape"},
            {id: "Lafaiete Coutinho", name: "BA - Lafaiete Coutinho"},
            {id: "Lagoa Real", name: "BA - Lagoa Real"},
            {id: "Laje", name: "BA - Laje"},
            {id: "Lajedão", name: "BA - Lajedão"},
            {id: "Lajedinho", name: "BA - Lajedinho"},
            {id: "Lajedo do Tabocal", name: "BA - Lajedo do Tabocal"},
            {id: "Lamarão", name: "BA - Lamarão"},
            {id: "Lapão", name: "BA - Lapão"},
            {id: "Lauro de Freitas", name: "BA - Lauro de Freitas"},
            {id: "Lençóis", name: "BA - Lençóis"},
            {id: "Licínio de Almeida", name: "BA - Licínio de Almeida"},
            {id: "Livramento de Nossa Senhora", name: "BA - Livramento de Nossa Senhora"},
            {id: "Luís Eduardo Magalhães", name: "BA - Luís Eduardo Magalhães"},
            {id: "Macajuba", name: "BA - Macajuba"},
            {id: "Macarani", name: "BA - Macarani"},
            {id: "Macaúbas", name: "BA - Macaúbas"},
            {id: "Macururé", name: "BA - Macururé"},
            {id: "Madre de Deus", name: "BA - Madre de Deus"},
            {id: "Maetinga", name: "BA - Maetinga"},
            {id: "Maiquinique", name: "BA - Maiquinique"},
            {id: "Mairi", name: "BA - Mairi"},
            {id: "Malhada", name: "BA - Malhada"},
            {id: "Malhada de Pedras", name: "BA - Malhada de Pedras"},
            {id: "Manoel Vitorino", name: "BA - Manoel Vitorino"},
            {id: "Mansidão", name: "BA - Mansidão"},
            {id: "Maracás", name: "BA - Maracás"},
            {id: "Maragogipe", name: "BA - Maragogipe"},
            {id: "Maraú", name: "BA - Maraú"},
            {id: "Marcionílio Souza", name: "BA - Marcionílio Souza"},
            {id: "Mascote", name: "BA - Mascote"},
            {id: "Mata de São João", name: "BA - Mata de São João"},
            {id: "Matina", name: "BA - Matina"},
            {id: "Medeiros Neto", name: "BA - Medeiros Neto"},
            {id: "Miguel Calmon", name: "BA - Miguel Calmon"},
            {id: "Milagres", name: "BA - Milagres"},
            {id: "Mirangaba", name: "BA - Mirangaba"},
            {id: "Mirante", name: "BA - Mirante"},
            {id: "Monte Santo", name: "BA - Monte Santo"},
            {id: "Morpará", name: "BA - Morpará"},
            {id: "Morro do Chapéu", name: "BA - Morro do Chapéu"},
            {id: "Mortugaba", name: "BA - Mortugaba"},
            {id: "Mucugê", name: "BA - Mucugê"},
            {id: "Mucuri", name: "BA - Mucuri"},
            {id: "Mulungu do Morro", name: "BA - Mulungu do Morro"},
            {id: "Mundo Novo", name: "BA - Mundo Novo"},
            {id: "Muniz Ferreira", name: "BA - Muniz Ferreira"},
            {id: "Muquém de São Francisco", name: "BA - Muquém de São Francisco"},
            {id: "Muritiba", name: "BA - Muritiba"},
            {id: "Mutuípe", name: "BA - Mutuípe"},
            {id: "Nazaré", name: "BA - Nazaré"},
            {id: "Nilo Peçanha", name: "BA - Nilo Peçanha"},
            {id: "Nordestina", name: "BA - Nordestina"},
            {id: "Nova Canaã", name: "BA - Nova Canaã"},
            {id: "Nova Fátima", name: "BA - Nova Fátima"},
            {id: "Nova Ibiá", name: "BA - Nova Ibiá"},
            {id: "Nova Itarana", name: "BA - Nova Itarana"},
            {id: "Nova Redenção", name: "BA - Nova Redenção"},
            {id: "Nova Soure", name: "BA - Nova Soure"},
            {id: "Nova Viçosa", name: "BA - Nova Viçosa"},
            {id: "Novo Horizonte", name: "BA - Novo Horizonte"},
            {id: "Novo Triunfo", name: "BA - Novo Triunfo"},
            {id: "Olindina", name: "BA - Olindina"},
            {id: "Oliveira Dos Brejinhos", name: "BA - Oliveira Dos Brejinhos"},
            {id: "Ouriçangas", name: "BA - Ouriçangas"},
            {id: "Ourolândia", name: "BA - Ourolândia"},
            {id: "Palmas de Monte Alto", name: "BA - Palmas de Monte Alto"},
            {id: "Palmeiras", name: "BA - Palmeiras"},
            {id: "Paramirim", name: "BA - Paramirim"},
            {id: "Paratinga", name: "BA - Paratinga"},
            {id: "Paripiranga", name: "BA - Paripiranga"},
            {id: "Pau Brasil", name: "BA - Pau Brasil"},
            {id: "Paulo Afonso", name: "BA - Paulo Afonso"},
            {id: "pé de Serra", name: "BA - pé de Serra"},
            {id: "Pedrão", name: "BA - Pedrão"},
            {id: "Pedro Alexandre", name: "BA - Pedro Alexandre"},
            {id: "Piatã", name: "BA - Piatã"},
            {id: "Pilão Arcado", name: "BA - Pilão Arcado"},
            {id: "Pindaí", name: "BA - Pindaí"},
            {id: "Pindobaçu", name: "BA - Pindobaçu"},
            {id: "Pintadas", name: "BA - Pintadas"},
            {id: "Piraí do Norte", name: "BA - Piraí do Norte"},
            {id: "Piripá", name: "BA - Piripá"},
            {id: "Piritiba", name: "BA - Piritiba"},
            {id: "Planaltino", name: "BA - Planaltino"},
            {id: "Planalto", name: "BA - Planalto"},
            {id: "Poções", name: "BA - Poções"},
            {id: "Pojuca", name: "BA - Pojuca"},
            {id: "Ponto Novo", name: "BA - Ponto Novo"},
            {id: "Porto Seguro", name: "BA - Porto Seguro"},
            {id: "Potiraguá", name: "BA - Potiraguá"},
            {id: "Prado", name: "BA - Prado"},
            {id: "Presidente Dutra", name: "BA - Presidente Dutra"},
            {id: "Presidente Jânio Quadros", name: "BA - Presidente Jânio Quadros"},
            {id: "Presidente Tancredo Neves", name: "BA - Presidente Tancredo Neves"},
            {id: "Queimadas", name: "BA - Queimadas"},
            {id: "Quijingue", name: "BA - Quijingue"},
            {id: "Quixabeira", name: "BA - Quixabeira"},
            {id: "Rafael Jambeiro", name: "BA - Rafael Jambeiro"},
            {id: "Remanso", name: "BA - Remanso"},
            {id: "Retirolândia", name: "BA - Retirolândia"},
            {id: "Riachão Das Neves", name: "BA - Riachão Das Neves"},
            {id: "Riachão do Jacuípe", name: "BA - Riachão do Jacuípe"},
            {id: "Riacho de Santana", name: "BA - Riacho de Santana"},
            {id: "Ribeira do Amparo", name: "BA - Ribeira do Amparo"},
            {id: "Ribeira do Pombal", name: "BA - Ribeira do Pombal"},
            {id: "Ribeirão do Largo", name: "BA - Ribeirão do Largo"},
            {id: "Rio de Contas", name: "BA - Rio de Contas"},
            {id: "Rio do Antônio", name: "BA - Rio do Antônio"},
            {id: "Rio do Pires", name: "BA - Rio do Pires"},
            {id: "Rio Real", name: "BA - Rio Real"},
            {id: "Rodelas", name: "BA - Rodelas"},
            {id: "Ruy Barbosa", name: "BA - Ruy Barbosa"},
            {id: "Salinas da Margarida", name: "BA - Salinas da Margarida"},
            {id: "Salvador", name: "BA - Salvador"},
            {id: "Santa Bárbara", name: "BA - Santa Bárbara"},
            {id: "Santa Brígida", name: "BA - Santa Brígida"},
            {id: "Santa Cruz Cabrália", name: "BA - Santa Cruz Cabrália"},
            {id: "Santa Cruz da Vitória", name: "BA - Santa Cruz da Vitória"},
            {id: "Santa Inês", name: "BA - Santa Inês"},
            {id: "Santaluz", name: "BA - Santaluz"},
            {id: "Santa Luzia", name: "BA - Santa Luzia"},
            {id: "Santa Maria da Vitória", name: "BA - Santa Maria da Vitória"},
            {id: "Santana", name: "BA - Santana"},
            {id: "Santanópolis", name: "BA - Santanópolis"},
            {id: "Santa Rita de Cássia", name: "BA - Santa Rita de Cássia"},
            {id: "Santa Teresinha", name: "BA - Santa Teresinha"},
            {id: "Santo Amaro", name: "BA - Santo Amaro"},
            {id: "Santo Antônio de Jesus", name: "BA - Santo Antônio de Jesus"},
            {id: "Santo Estêvão", name: "BA - Santo Estêvão"},
            {id: "São Desidério", name: "BA - São Desidério"},
            {id: "São Domingos", name: "BA - São Domingos"},
            {id: "São Félix", name: "BA - São Félix"},
            {id: "São Félix do Coribe", name: "BA - São Félix do Coribe"},
            {id: "São Felipe", name: "BA - São Felipe"},
            {id: "São Francisco do Conde", name: "BA - São Francisco do Conde"},
            {id: "São Gabriel", name: "BA - São Gabriel"},
            {id: "São Gonçalo Dos Campos", name: "BA - São Gonçalo Dos Campos"},
            {id: "São José da Vitória", name: "BA - São José da Vitória"},
            {id: "São José do Jacuípe", name: "BA - São José do Jacuípe"},
            {id: "São Miguel Das Matas", name: "BA - São Miguel Das Matas"},
            {id: "São Sebastião do Passé", name: "BA - São Sebastião do Passé"},
            {id: "Sapeaçu", name: "BA - Sapeaçu"},
            {id: "Sátiro Dias", name: "BA - Sátiro Dias"},
            {id: "Saubara", name: "BA - Saubara"},
            {id: "Saúde", name: "BA - Saúde"},
            {id: "Seabra", name: "BA - Seabra"},
            {id: "Sebastião Laranjeiras", name: "BA - Sebastião Laranjeiras"},
            {id: "Senhor do Bonfim", name: "BA - Senhor do Bonfim"},
            {id: "Serra do Ramalho", name: "BA - Serra do Ramalho"},
            {id: "Sento sé", name: "BA - Sento sé"},
            {id: "Serra Dourada", name: "BA - Serra Dourada"},
            {id: "Serra Preta", name: "BA - Serra Preta"},
            {id: "Serrinha", name: "BA - Serrinha"},
            {id: "Serrolândia", name: "BA - Serrolândia"},
            {id: "Simões Filho", name: "BA - Simões Filho"},
            {id: "Sítio do Mato", name: "BA - Sítio do Mato"},
            {id: "Sítio do Quinto", name: "BA - Sítio do Quinto"},
            {id: "Sobradinho", name: "BA - Sobradinho"},
            {id: "Souto Soares", name: "BA - Souto Soares"},
            {id: "Tabocas do Brejo Velho", name: "BA - Tabocas do Brejo Velho"},
            {id: "Tanhaçu", name: "BA - Tanhaçu"},
            {id: "Tanque Novo", name: "BA - Tanque Novo"},
            {id: "Tanquinho", name: "BA - Tanquinho"},
            {id: "Taperoá", name: "BA - Taperoá"},
            {id: "Tapiramutá", name: "BA - Tapiramutá"},
            {id: "Teixeira de Freitas", name: "BA - Teixeira de Freitas"},
            {id: "Teodoro Sampaio", name: "BA - Teodoro Sampaio"},
            {id: "Teofilândia", name: "BA - Teofilândia"},
            {id: "Teolândia", name: "BA - Teolândia"},
            {id: "Terra Nova", name: "BA - Terra Nova"},
            {id: "Tremedal", name: "BA - Tremedal"},
            {id: "Tucano", name: "BA - Tucano"},
            {id: "Uauá", name: "BA - Uauá"},
            {id: "Ubaíra", name: "BA - Ubaíra"},
            {id: "Ubaitaba", name: "BA - Ubaitaba"},
            {id: "Ubatã", name: "BA - Ubatã"},
            {id: "Uibaí", name: "BA - Uibaí"},
            {id: "Umburanas", name: "BA - Umburanas"},
            {id: "Una", name: "BA - Una"},
            {id: "Urandi", name: "BA - Urandi"},
            {id: "Uruçuca", name: "BA - Uruçuca"},
            {id: "Utinga", name: "BA - Utinga"},
            {id: "Valença", name: "BA - Valença"},
            {id: "Valente", name: "BA - Valente"},
            {id: "Várzea da Roça", name: "BA - Várzea da Roça"},
            {id: "Várzea do Poço", name: "BA - Várzea do Poço"},
            {id: "Várzea Nova", name: "BA - Várzea Nova"},
            {id: "Varzedo", name: "BA - Varzedo"},
            {id: "Vera Cruz", name: "BA - Vera Cruz"},
            {id: "Vereda", name: "BA - Vereda"},
            {id: "Vitória da Conquista", name: "BA - Vitória da Conquista"},
            {id: "Wagner", name: "BA - Wagner"},
            {id: "Wanderley", name: "BA - Wanderley"},
            {id: "Wenceslau Guimarães", name: "BA - Wenceslau Guimarães"},
            {id: "Xique-xique", name: "BA - Xique-xique"},
            {id: "Abadia Dos Dourados", name: "MG - Abadia Dos Dourados"},
            {id: "Abaeté", name: "MG - Abaeté"},
            {id: "Abre Campo", name: "MG - Abre Campo"},
            {id: "Acaiaca", name: "MG - Acaiaca"},
            {id: "Açucena", name: "MG - Açucena"},
            {id: "Água Boa", name: "MG - Água Boa"},
            {id: "Água Comprida", name: "MG - Água Comprida"},
            {id: "Aguanil", name: "MG - Aguanil"},
            {id: "Águas Formosas", name: "MG - Águas Formosas"},
            {id: "Águas Vermelhas", name: "MG - Águas Vermelhas"},
            {id: "Aimorés", name: "MG - Aimorés"},
            {id: "Aiuruoca", name: "MG - Aiuruoca"},
            {id: "Alagoa", name: "MG - Alagoa"},
            {id: "Albertina", name: "MG - Albertina"},
            {id: "Além Paraíba", name: "MG - Além Paraíba"},
            {id: "Alfenas", name: "MG - Alfenas"},
            {id: "Alfredo Vasconcelos", name: "MG - Alfredo Vasconcelos"},
            {id: "Almenara", name: "MG - Almenara"},
            {id: "Alpercata", name: "MG - Alpercata"},
            {id: "Alpinópolis", name: "MG - Alpinópolis"},
            {id: "Alterosa", name: "MG - Alterosa"},
            {id: "Alto Caparaó", name: "MG - Alto Caparaó"},
            {id: "Alto Rio Doce", name: "MG - Alto Rio Doce"},
            {id: "Alvarenga", name: "MG - Alvarenga"},
            {id: "Alvinópolis", name: "MG - Alvinópolis"},
            {id: "Alvorada de Minas", name: "MG - Alvorada de Minas"},
            {id: "Amparo do Serra", name: "MG - Amparo do Serra"},
            {id: "Andradas", name: "MG - Andradas"},
            {id: "Cachoeira de Pajeú", name: "MG - Cachoeira de Pajeú"},
            {id: "Andrelândia", name: "MG - Andrelândia"},
            {id: "Angelândia", name: "MG - Angelândia"},
            {id: "Antônio Carlos", name: "MG - Antônio Carlos"},
            {id: "Antônio Dias", name: "MG - Antônio Dias"},
            {id: "Antônio Prado de Minas", name: "MG - Antônio Prado de Minas"},
            {id: "Araçaí", name: "MG - Araçaí"},
            {id: "Aracitaba", name: "MG - Aracitaba"},
            {id: "Araçuaí", name: "MG - Araçuaí"},
            {id: "Araguari", name: "MG - Araguari"},
            {id: "Arantina", name: "MG - Arantina"},
            {id: "Araponga", name: "MG - Araponga"},
            {id: "Araporã", name: "MG - Araporã"},
            {id: "Arapuá", name: "MG - Arapuá"},
            {id: "Araújos", name: "MG - Araújos"},
            {id: "Araxá", name: "MG - Araxá"},
            {id: "Arceburgo", name: "MG - Arceburgo"},
            {id: "Arcos", name: "MG - Arcos"},
            {id: "Areado", name: "MG - Areado"},
            {id: "Argirita", name: "MG - Argirita"},
            {id: "Aricanduva", name: "MG - Aricanduva"},
            {id: "Arinos", name: "MG - Arinos"},
            {id: "Astolfo Dutra", name: "MG - Astolfo Dutra"},
            {id: "Ataléia", name: "MG - Ataléia"},
            {id: "Augusto de Lima", name: "MG - Augusto de Lima"},
            {id: "Baependi", name: "MG - Baependi"},
            {id: "Baldim", name: "MG - Baldim"},
            {id: "Bambuí", name: "MG - Bambuí"},
            {id: "Bandeira", name: "MG - Bandeira"},
            {id: "Bandeira do Sul", name: "MG - Bandeira do Sul"},
            {id: "Barão de Cocais", name: "MG - Barão de Cocais"},
            {id: "Barão de Monte Alto", name: "MG - Barão de Monte Alto"},
            {id: "Barbacena", name: "MG - Barbacena"},
            {id: "Barra Longa", name: "MG - Barra Longa"},
            {id: "Barroso", name: "MG - Barroso"},
            {id: "Bela Vista de Minas", name: "MG - Bela Vista de Minas"},
            {id: "Belmiro Braga", name: "MG - Belmiro Braga"},
            {id: "Belo Horizonte", name: "MG - Belo Horizonte"},
            {id: "Belo Oriente", name: "MG - Belo Oriente"},
            {id: "Belo Vale", name: "MG - Belo Vale"},
            {id: "Berilo", name: "MG - Berilo"},
            {id: "Bertópolis", name: "MG - Bertópolis"},
            {id: "Berizal", name: "MG - Berizal"},
            {id: "Betim", name: "MG - Betim"},
            {id: "Bias Fortes", name: "MG - Bias Fortes"},
            {id: "Bicas", name: "MG - Bicas"},
            {id: "Biquinhas", name: "MG - Biquinhas"},
            {id: "Boa Esperança", name: "MG - Boa Esperança"},
            {id: "Bocaina de Minas", name: "MG - Bocaina de Minas"},
            {id: "Bocaiúva", name: "MG - Bocaiúva"},
            {id: "Bom Despacho", name: "MG - Bom Despacho"},
            {id: "Bom Jardim de Minas", name: "MG - Bom Jardim de Minas"},
            {id: "Bom Jesus da Penha", name: "MG - Bom Jesus da Penha"},
            {id: "Bom Jesus do Amparo", name: "MG - Bom Jesus do Amparo"},
            {id: "Bom Jesus do Galho", name: "MG - Bom Jesus do Galho"},
            {id: "Bom Repouso", name: "MG - Bom Repouso"},
            {id: "Bom Sucesso", name: "MG - Bom Sucesso"},
            {id: "Bonfim", name: "MG - Bonfim"},
            {id: "Bonfinópolis de Minas", name: "MG - Bonfinópolis de Minas"},
            {id: "Bonito de Minas", name: "MG - Bonito de Minas"},
            {id: "Borda da Mata", name: "MG - Borda da Mata"},
            {id: "Botelhos", name: "MG - Botelhos"},
            {id: "Botumirim", name: "MG - Botumirim"},
            {id: "Brasilândia de Minas", name: "MG - Brasilândia de Minas"},
            {id: "Brasília de Minas", name: "MG - Brasília de Minas"},
            {id: "Brás Pires", name: "MG - Brás Pires"},
            {id: "Braúnas", name: "MG - Braúnas"},
            {id: "Brasópolis", name: "MG - Brasópolis"},
            {id: "Brumadinho", name: "MG - Brumadinho"},
            {id: "Bueno Brandão", name: "MG - Bueno Brandão"},
            {id: "Buenópolis", name: "MG - Buenópolis"},
            {id: "Bugre", name: "MG - Bugre"},
            {id: "Buritis", name: "MG - Buritis"},
            {id: "Buritizeiro", name: "MG - Buritizeiro"},
            {id: "Cabeceira Grande", name: "MG - Cabeceira Grande"},
            {id: "Cabo Verde", name: "MG - Cabo Verde"},
            {id: "Cachoeira da Prata", name: "MG - Cachoeira da Prata"},
            {id: "Cachoeira de Minas", name: "MG - Cachoeira de Minas"},
            {id: "Cachoeira Dourada", name: "MG - Cachoeira Dourada"},
            {id: "Caetanópolis", name: "MG - Caetanópolis"},
            {id: "Caeté", name: "MG - Caeté"},
            {id: "Caiana", name: "MG - Caiana"},
            {id: "Cajuri", name: "MG - Cajuri"},
            {id: "Caldas", name: "MG - Caldas"},
            {id: "Camacho", name: "MG - Camacho"},
            {id: "Camanducaia", name: "MG - Camanducaia"},
            {id: "Cambuí", name: "MG - Cambuí"},
            {id: "Cambuquira", name: "MG - Cambuquira"},
            {id: "Campanário", name: "MG - Campanário"},
            {id: "Campanha", name: "MG - Campanha"},
            {id: "Campestre", name: "MG - Campestre"},
            {id: "Campina Verde", name: "MG - Campina Verde"},
            {id: "Campo Azul", name: "MG - Campo Azul"},
            {id: "Campo Belo", name: "MG - Campo Belo"},
            {id: "Campo do Meio", name: "MG - Campo do Meio"},
            {id: "Campo Florido", name: "MG - Campo Florido"},
            {id: "Campos Altos", name: "MG - Campos Altos"},
            {id: "Campos Gerais", name: "MG - Campos Gerais"},
            {id: "Canaã", name: "MG - Canaã"},
            {id: "Canápolis", name: "MG - Canápolis"},
            {id: "Cana Verde", name: "MG - Cana Verde"},
            {id: "Candeias", name: "MG - Candeias"},
            {id: "Cantagalo", name: "MG - Cantagalo"},
            {id: "Caparaó", name: "MG - Caparaó"},
            {id: "Capela Nova", name: "MG - Capela Nova"},
            {id: "Capelinha", name: "MG - Capelinha"},
            {id: "Capetinga", name: "MG - Capetinga"},
            {id: "Capim Branco", name: "MG - Capim Branco"},
            {id: "Capinópolis", name: "MG - Capinópolis"},
            {id: "Capitão Andrade", name: "MG - Capitão Andrade"},
            {id: "Capitão Enéas", name: "MG - Capitão Enéas"},
            {id: "Capitólio", name: "MG - Capitólio"},
            {id: "Caputira", name: "MG - Caputira"},
            {id: "Caraí", name: "MG - Caraí"},
            {id: "Caranaíba", name: "MG - Caranaíba"},
            {id: "Carandaí", name: "MG - Carandaí"},
            {id: "Carangola", name: "MG - Carangola"},
            {id: "Caratinga", name: "MG - Caratinga"},
            {id: "Carbonita", name: "MG - Carbonita"},
            {id: "Careaçu", name: "MG - Careaçu"},
            {id: "Carlos Chagas", name: "MG - Carlos Chagas"},
            {id: "Carmésia", name: "MG - Carmésia"},
            {id: "Carmo da Cachoeira", name: "MG - Carmo da Cachoeira"},
            {id: "Carmo da Mata", name: "MG - Carmo da Mata"},
            {id: "Carmo de Minas", name: "MG - Carmo de Minas"},
            {id: "Carmo do Cajuru", name: "MG - Carmo do Cajuru"},
            {id: "Carmo do Paranaíba", name: "MG - Carmo do Paranaíba"},
            {id: "Carmo do Rio Claro", name: "MG - Carmo do Rio Claro"},
            {id: "Carmópolis de Minas", name: "MG - Carmópolis de Minas"},
            {id: "Carneirinho", name: "MG - Carneirinho"},
            {id: "Carrancas", name: "MG - Carrancas"},
            {id: "Carvalhópolis", name: "MG - Carvalhópolis"},
            {id: "Carvalhos", name: "MG - Carvalhos"},
            {id: "Casa Grande", name: "MG - Casa Grande"},
            {id: "Cascalho Rico", name: "MG - Cascalho Rico"},
            {id: "Cássia", name: "MG - Cássia"},
            {id: "Conceição da Barra de Minas", name: "MG - Conceição da Barra de Minas"},
            {id: "Cataguases", name: "MG - Cataguases"},
            {id: "Catas Altas", name: "MG - Catas Altas"},
            {id: "Catas Altas da Noruega", name: "MG - Catas Altas da Noruega"},
            {id: "Catuji", name: "MG - Catuji"},
            {id: "Catuti", name: "MG - Catuti"},
            {id: "Caxambu", name: "MG - Caxambu"},
            {id: "Cedro do Abaeté", name: "MG - Cedro do Abaeté"},
            {id: "Central de Minas", name: "MG - Central de Minas"},
            {id: "Centralina", name: "MG - Centralina"},
            {id: "Chácara", name: "MG - Chácara"},
            {id: "Chalé", name: "MG - Chalé"},
            {id: "Chapada do Norte", name: "MG - Chapada do Norte"},
            {id: "Chapada Gaúcha", name: "MG - Chapada Gaúcha"},
            {id: "Chiador", name: "MG - Chiador"},
            {id: "Cipotânea", name: "MG - Cipotânea"},
            {id: "Claraval", name: "MG - Claraval"},
            {id: "Claro Dos Poções", name: "MG - Claro Dos Poções"},
            {id: "Cláudio", name: "MG - Cláudio"},
            {id: "Coimbra", name: "MG - Coimbra"},
            {id: "Coluna", name: "MG - Coluna"},
            {id: "Comendador Gomes", name: "MG - Comendador Gomes"},
            {id: "Comercinho", name: "MG - Comercinho"},
            {id: "Conceição da Aparecida", name: "MG - Conceição da Aparecida"},
            {id: "Conceição Das Pedras", name: "MG - Conceição Das Pedras"},
            {id: "Conceição Das Alagoas", name: "MG - Conceição Das Alagoas"},
            {id: "Conceição de Ipanema", name: "MG - Conceição de Ipanema"},
            {id: "Conceição do Mato Dentro", name: "MG - Conceição do Mato Dentro"},
            {id: "Conceição do Pará", name: "MG - Conceição do Pará"},
            {id: "Conceição do Rio Verde", name: "MG - Conceição do Rio Verde"},
            {id: "Conceição Dos Ouros", name: "MG - Conceição Dos Ouros"},
            {id: "Cônego Marinho", name: "MG - Cônego Marinho"},
            {id: "Confins", name: "MG - Confins"},
            {id: "Congonhal", name: "MG - Congonhal"},
            {id: "Congonhas", name: "MG - Congonhas"},
            {id: "Congonhas do Norte", name: "MG - Congonhas do Norte"},
            {id: "Conquista", name: "MG - Conquista"},
            {id: "Conselheiro Lafaiete", name: "MG - Conselheiro Lafaiete"},
            {id: "Conselheiro Pena", name: "MG - Conselheiro Pena"},
            {id: "Consolação", name: "MG - Consolação"},
            {id: "Contagem", name: "MG - Contagem"},
            {id: "Coqueiral", name: "MG - Coqueiral"},
            {id: "Coração de Jesus", name: "MG - Coração de Jesus"},
            {id: "Cordisburgo", name: "MG - Cordisburgo"},
            {id: "Cordislândia", name: "MG - Cordislândia"},
            {id: "Corinto", name: "MG - Corinto"},
            {id: "Coroaci", name: "MG - Coroaci"},
            {id: "Coromandel", name: "MG - Coromandel"},
            {id: "Coronel Fabriciano", name: "MG - Coronel Fabriciano"},
            {id: "Coronel Murta", name: "MG - Coronel Murta"},
            {id: "Coronel Pacheco", name: "MG - Coronel Pacheco"},
            {id: "Coronel Xavier Chaves", name: "MG - Coronel Xavier Chaves"},
            {id: "Córrego Danta", name: "MG - Córrego Danta"},
            {id: "Córrego do Bom Jesus", name: "MG - Córrego do Bom Jesus"},
            {id: "Córrego Fundo", name: "MG - Córrego Fundo"},
            {id: "Córrego Novo", name: "MG - Córrego Novo"},
            {id: "Couto de Magalhães de Minas", name: "MG - Couto de Magalhães de Minas"},
            {id: "Crisólita", name: "MG - Crisólita"},
            {id: "Cristais", name: "MG - Cristais"},
            {id: "Cristália", name: "MG - Cristália"},
            {id: "Cristiano Otoni", name: "MG - Cristiano Otoni"},
            {id: "Cristina", name: "MG - Cristina"},
            {id: "Crucilândia", name: "MG - Crucilândia"},
            {id: "Cruzeiro da Fortaleza", name: "MG - Cruzeiro da Fortaleza"},
            {id: "Cruzília", name: "MG - Cruzília"},
            {id: "Cuparaque", name: "MG - Cuparaque"},
            {id: "Curral de Dentro", name: "MG - Curral de Dentro"},
            {id: "Curvelo", name: "MG - Curvelo"},
            {id: "Datas", name: "MG - Datas"},
            {id: "Delfim Moreira", name: "MG - Delfim Moreira"},
            {id: "Delfinópolis", name: "MG - Delfinópolis"},
            {id: "Delta", name: "MG - Delta"},
            {id: "Descoberto", name: "MG - Descoberto"},
            {id: "Desterro de Entre Rios", name: "MG - Desterro de Entre Rios"},
            {id: "Desterro do Melo", name: "MG - Desterro do Melo"},
            {id: "Diamantina", name: "MG - Diamantina"},
            {id: "Diogo de Vasconcelos", name: "MG - Diogo de Vasconcelos"},
            {id: "Dionísio", name: "MG - Dionísio"},
            {id: "Divinésia", name: "MG - Divinésia"},
            {id: "Divino", name: "MG - Divino"},
            {id: "Divino Das Laranjeiras", name: "MG - Divino Das Laranjeiras"},
            {id: "Divinolândia de Minas", name: "MG - Divinolândia de Minas"},
            {id: "Divinópolis", name: "MG - Divinópolis"},
            {id: "Divisa Alegre", name: "MG - Divisa Alegre"},
            {id: "Divisa Nova", name: "MG - Divisa Nova"},
            {id: "Divisópolis", name: "MG - Divisópolis"},
            {id: "Dom Bosco", name: "MG - Dom Bosco"},
            {id: "Dom Cavati", name: "MG - Dom Cavati"},
            {id: "Dom Joaquim", name: "MG - Dom Joaquim"},
            {id: "Dom Silvério", name: "MG - Dom Silvério"},
            {id: "Dom Viçoso", name: "MG - Dom Viçoso"},
            {id: "Dona Eusébia", name: "MG - Dona Eusébia"},
            {id: "Dores de Campos", name: "MG - Dores de Campos"},
            {id: "Dores de Guanhães", name: "MG - Dores de Guanhães"},
            {id: "Dores do Indaiá", name: "MG - Dores do Indaiá"},
            {id: "Dores do Turvo", name: "MG - Dores do Turvo"},
            {id: "Doresópolis", name: "MG - Doresópolis"},
            {id: "Douradoquara", name: "MG - Douradoquara"},
            {id: "Durandé", name: "MG - Durandé"},
            {id: "Elói Mendes", name: "MG - Elói Mendes"},
            {id: "Engenheiro Caldas", name: "MG - Engenheiro Caldas"},
            {id: "Engenheiro Navarro", name: "MG - Engenheiro Navarro"},
            {id: "Entre Folhas", name: "MG - Entre Folhas"},
            {id: "Entre Rios de Minas", name: "MG - Entre Rios de Minas"},
            {id: "Ervália", name: "MG - Ervália"},
            {id: "Esmeraldas", name: "MG - Esmeraldas"},
            {id: "Espera Feliz", name: "MG - Espera Feliz"},
            {id: "Espinosa", name: "MG - Espinosa"},
            {id: "Espírito Santo do Dourado", name: "MG - Espírito Santo do Dourado"},
            {id: "Estiva", name: "MG - Estiva"},
            {id: "Estrela Dalva", name: "MG - Estrela Dalva"},
            {id: "Estrela do Indaiá", name: "MG - Estrela do Indaiá"},
            {id: "Estrela do Sul", name: "MG - Estrela do Sul"},
            {id: "Eugenópolis", name: "MG - Eugenópolis"},
            {id: "Ewbank da Câmara", name: "MG - Ewbank da Câmara"},
            {id: "Extrema", name: "MG - Extrema"},
            {id: "Fama", name: "MG - Fama"},
            {id: "Faria Lemos", name: "MG - Faria Lemos"},
            {id: "Felício Dos Santos", name: "MG - Felício Dos Santos"},
            {id: "São Gonçalo do Rio Preto", name: "MG - São Gonçalo do Rio Preto"},
            {id: "Felisburgo", name: "MG - Felisburgo"},
            {id: "Felixlândia", name: "MG - Felixlândia"},
            {id: "Fernandes Tourinho", name: "MG - Fernandes Tourinho"},
            {id: "Ferros", name: "MG - Ferros"},
            {id: "Fervedouro", name: "MG - Fervedouro"},
            {id: "Florestal", name: "MG - Florestal"},
            {id: "Formiga", name: "MG - Formiga"},
            {id: "Formoso", name: "MG - Formoso"},
            {id: "Fortaleza de Minas", name: "MG - Fortaleza de Minas"},
            {id: "Fortuna de Minas", name: "MG - Fortuna de Minas"},
            {id: "Francisco Badaró", name: "MG - Francisco Badaró"},
            {id: "Francisco Dumont", name: "MG - Francisco Dumont"},
            {id: "Francisco sá", name: "MG - Francisco sá"},
            {id: "Franciscópolis", name: "MG - Franciscópolis"},
            {id: "Frei Gaspar", name: "MG - Frei Gaspar"},
            {id: "Frei Inocêncio", name: "MG - Frei Inocêncio"},
            {id: "Frei Lagonegro", name: "MG - Frei Lagonegro"},
            {id: "Fronteira", name: "MG - Fronteira"},
            {id: "Fronteira Dos Vales", name: "MG - Fronteira Dos Vales"},
            {id: "Fruta de Leite", name: "MG - Fruta de Leite"},
            {id: "Frutal", name: "MG - Frutal"},
            {id: "Funilândia", name: "MG - Funilândia"},
            {id: "Galiléia", name: "MG - Galiléia"},
            {id: "Gameleiras", name: "MG - Gameleiras"},
            {id: "Glaucilândia", name: "MG - Glaucilândia"},
            {id: "Goiabeira", name: "MG - Goiabeira"},
            {id: "Goianá", name: "MG - Goianá"},
            {id: "Gonçalves", name: "MG - Gonçalves"},
            {id: "Gonzaga", name: "MG - Gonzaga"},
            {id: "Gouveia", name: "MG - Gouveia"},
            {id: "Governador Valadares", name: "MG - Governador Valadares"},
            {id: "Grão Mogol", name: "MG - Grão Mogol"},
            {id: "Grupiara", name: "MG - Grupiara"},
            {id: "Guanhães", name: "MG - Guanhães"},
            {id: "Guapé", name: "MG - Guapé"},
            {id: "Guaraciaba", name: "MG - Guaraciaba"},
            {id: "Guaraciama", name: "MG - Guaraciama"},
            {id: "Guaranésia", name: "MG - Guaranésia"},
            {id: "Guarani", name: "MG - Guarani"},
            {id: "Guarará", name: "MG - Guarará"},
            {id: "Guarda-mor", name: "MG - Guarda-mor"},
            {id: "Guaxupé", name: "MG - Guaxupé"},
            {id: "Guidoval", name: "MG - Guidoval"},
            {id: "Guimarânia", name: "MG - Guimarânia"},
            {id: "Guiricema", name: "MG - Guiricema"},
            {id: "Gurinhatã", name: "MG - Gurinhatã"},
            {id: "Heliodora", name: "MG - Heliodora"},
            {id: "Iapu", name: "MG - Iapu"},
            {id: "Ibertioga", name: "MG - Ibertioga"},
            {id: "Ibiá", name: "MG - Ibiá"},
            {id: "Ibiaí", name: "MG - Ibiaí"},
            {id: "Ibiracatu", name: "MG - Ibiracatu"},
            {id: "Ibiraci", name: "MG - Ibiraci"},
            {id: "Ibirité", name: "MG - Ibirité"},
            {id: "Ibitiúra de Minas", name: "MG - Ibitiúra de Minas"},
            {id: "Ibituruna", name: "MG - Ibituruna"},
            {id: "Icaraí de Minas", name: "MG - Icaraí de Minas"},
            {id: "Igarapé", name: "MG - Igarapé"},
            {id: "Igaratinga", name: "MG - Igaratinga"},
            {id: "Iguatama", name: "MG - Iguatama"},
            {id: "Ijaci", name: "MG - Ijaci"},
            {id: "Ilicínea", name: "MG - Ilicínea"},
            {id: "Imbé de Minas", name: "MG - Imbé de Minas"},
            {id: "Inconfidentes", name: "MG - Inconfidentes"},
            {id: "Indaiabira", name: "MG - Indaiabira"},
            {id: "Indianópolis", name: "MG - Indianópolis"},
            {id: "Ingaí", name: "MG - Ingaí"},
            {id: "Inhapim", name: "MG - Inhapim"},
            {id: "Inhaúma", name: "MG - Inhaúma"},
            {id: "Inimutaba", name: "MG - Inimutaba"},
            {id: "Ipaba", name: "MG - Ipaba"},
            {id: "Ipanema", name: "MG - Ipanema"},
            {id: "Ipatinga", name: "MG - Ipatinga"},
            {id: "Ipiaçu", name: "MG - Ipiaçu"},
            {id: "Ipuiúna", name: "MG - Ipuiúna"},
            {id: "Iraí de Minas", name: "MG - Iraí de Minas"},
            {id: "Itabira", name: "MG - Itabira"},
            {id: "Itabirinha", name: "MG - Itabirinha"},
            {id: "Itabirito", name: "MG - Itabirito"},
            {id: "Itacambira", name: "MG - Itacambira"},
            {id: "Itacarambi", name: "MG - Itacarambi"},
            {id: "Itaguara", name: "MG - Itaguara"},
            {id: "Itaipé", name: "MG - Itaipé"},
            {id: "Itajubá", name: "MG - Itajubá"},
            {id: "Itamarandiba", name: "MG - Itamarandiba"},
            {id: "Itamarati de Minas", name: "MG - Itamarati de Minas"},
            {id: "Itambacuri", name: "MG - Itambacuri"},
            {id: "Itambé do Mato Dentro", name: "MG - Itambé do Mato Dentro"},
            {id: "Itamogi", name: "MG - Itamogi"},
            {id: "Itamonte", name: "MG - Itamonte"},
            {id: "Itanhandu", name: "MG - Itanhandu"},
            {id: "Itanhomi", name: "MG - Itanhomi"},
            {id: "Itaobim", name: "MG - Itaobim"},
            {id: "Itapagipe", name: "MG - Itapagipe"},
            {id: "Itapecerica", name: "MG - Itapecerica"},
            {id: "Itapeva", name: "MG - Itapeva"},
            {id: "Itatiaiuçu", name: "MG - Itatiaiuçu"},
            {id: "Itaú de Minas", name: "MG - Itaú de Minas"},
            {id: "Itaúna", name: "MG - Itaúna"},
            {id: "Itaverava", name: "MG - Itaverava"},
            {id: "Itinga", name: "MG - Itinga"},
            {id: "Itueta", name: "MG - Itueta"},
            {id: "Ituiutaba", name: "MG - Ituiutaba"},
            {id: "Itumirim", name: "MG - Itumirim"},
            {id: "Iturama", name: "MG - Iturama"},
            {id: "Itutinga", name: "MG - Itutinga"},
            {id: "Jaboticatubas", name: "MG - Jaboticatubas"},
            {id: "Jacinto", name: "MG - Jacinto"},
            {id: "Jacuí", name: "MG - Jacuí"},
            {id: "Jacutinga", name: "MG - Jacutinga"},
            {id: "Jaguaraçu", name: "MG - Jaguaraçu"},
            {id: "Jaíba", name: "MG - Jaíba"},
            {id: "Jampruca", name: "MG - Jampruca"},
            {id: "Janaúba", name: "MG - Janaúba"},
            {id: "Januária", name: "MG - Januária"},
            {id: "Japaraíba", name: "MG - Japaraíba"},
            {id: "Japonvar", name: "MG - Japonvar"},
            {id: "Jeceaba", name: "MG - Jeceaba"},
            {id: "Jenipapo de Minas", name: "MG - Jenipapo de Minas"},
            {id: "Jequeri", name: "MG - Jequeri"},
            {id: "Jequitaí", name: "MG - Jequitaí"},
            {id: "Jequitibá", name: "MG - Jequitibá"},
            {id: "Jequitinhonha", name: "MG - Jequitinhonha"},
            {id: "Jesuânia", name: "MG - Jesuânia"},
            {id: "Joaíma", name: "MG - Joaíma"},
            {id: "Joanésia", name: "MG - Joanésia"},
            {id: "João Monlevade", name: "MG - João Monlevade"},
            {id: "João Pinheiro", name: "MG - João Pinheiro"},
            {id: "Joaquim Felício", name: "MG - Joaquim Felício"},
            {id: "Jordânia", name: "MG - Jordânia"},
            {id: "José Gonçalves de Minas", name: "MG - José Gonçalves de Minas"},
            {id: "José Raydan", name: "MG - José Raydan"},
            {id: "Josenópolis", name: "MG - Josenópolis"},
            {id: "Nova União", name: "MG - Nova União"},
            {id: "Juatuba", name: "MG - Juatuba"},
            {id: "Juiz de Fora", name: "MG - Juiz de Fora"},
            {id: "Juramento", name: "MG - Juramento"},
            {id: "Juruaia", name: "MG - Juruaia"},
            {id: "Juvenília", name: "MG - Juvenília"},
            {id: "Ladainha", name: "MG - Ladainha"},
            {id: "Lagamar", name: "MG - Lagamar"},
            {id: "Lagoa da Prata", name: "MG - Lagoa da Prata"},
            {id: "Lagoa Dos Patos", name: "MG - Lagoa Dos Patos"},
            {id: "Lagoa Dourada", name: "MG - Lagoa Dourada"},
            {id: "Lagoa Formosa", name: "MG - Lagoa Formosa"},
            {id: "Lagoa Grande", name: "MG - Lagoa Grande"},
            {id: "Lagoa Santa", name: "MG - Lagoa Santa"},
            {id: "Lajinha", name: "MG - Lajinha"},
            {id: "Lambari", name: "MG - Lambari"},
            {id: "Lamim", name: "MG - Lamim"},
            {id: "Laranjal", name: "MG - Laranjal"},
            {id: "Lassance", name: "MG - Lassance"},
            {id: "Lavras", name: "MG - Lavras"},
            {id: "Leandro Ferreira", name: "MG - Leandro Ferreira"},
            {id: "Leme do Prado", name: "MG - Leme do Prado"},
            {id: "Leopoldina", name: "MG - Leopoldina"},
            {id: "Liberdade", name: "MG - Liberdade"},
            {id: "Lima Duarte", name: "MG - Lima Duarte"},
            {id: "Limeira do Oeste", name: "MG - Limeira do Oeste"},
            {id: "Lontra", name: "MG - Lontra"},
            {id: "Luisburgo", name: "MG - Luisburgo"},
            {id: "Luislândia", name: "MG - Luislândia"},
            {id: "Luminárias", name: "MG - Luminárias"},
            {id: "Luz", name: "MG - Luz"},
            {id: "Machacalis", name: "MG - Machacalis"},
            {id: "Machado", name: "MG - Machado"},
            {id: "Madre de Deus de Minas", name: "MG - Madre de Deus de Minas"},
            {id: "Malacacheta", name: "MG - Malacacheta"},
            {id: "Mamonas", name: "MG - Mamonas"},
            {id: "Manga", name: "MG - Manga"},
            {id: "Manhuaçu", name: "MG - Manhuaçu"},
            {id: "Manhumirim", name: "MG - Manhumirim"},
            {id: "Mantena", name: "MG - Mantena"},
            {id: "Maravilhas", name: "MG - Maravilhas"},
            {id: "Mar de Espanha", name: "MG - Mar de Espanha"},
            {id: "Maria da fé", name: "MG - Maria da fé"},
            {id: "Mariana", name: "MG - Mariana"},
            {id: "Marilac", name: "MG - Marilac"},
            {id: "Mário Campos", name: "MG - Mário Campos"},
            {id: "Maripá de Minas", name: "MG - Maripá de Minas"},
            {id: "Marliéria", name: "MG - Marliéria"},
            {id: "Marmelópolis", name: "MG - Marmelópolis"},
            {id: "Martinho Campos", name: "MG - Martinho Campos"},
            {id: "Martins Soares", name: "MG - Martins Soares"},
            {id: "Mata Verde", name: "MG - Mata Verde"},
            {id: "Materlândia", name: "MG - Materlândia"},
            {id: "Mateus Leme", name: "MG - Mateus Leme"},
            {id: "Matias Barbosa", name: "MG - Matias Barbosa"},
            {id: "Matias Cardoso", name: "MG - Matias Cardoso"},
            {id: "Matipó", name: "MG - Matipó"},
            {id: "Mato Verde", name: "MG - Mato Verde"},
            {id: "Matozinhos", name: "MG - Matozinhos"},
            {id: "Matutina", name: "MG - Matutina"},
            {id: "Medeiros", name: "MG - Medeiros"},
            {id: "Medina", name: "MG - Medina"},
            {id: "Mendes Pimentel", name: "MG - Mendes Pimentel"},
            {id: "Mercês", name: "MG - Mercês"},
            {id: "Mesquita", name: "MG - Mesquita"},
            {id: "Minas Novas", name: "MG - Minas Novas"},
            {id: "Minduri", name: "MG - Minduri"},
            {id: "Mirabela", name: "MG - Mirabela"},
            {id: "Miradouro", name: "MG - Miradouro"},
            {id: "Miraí", name: "MG - Miraí"},
            {id: "Miravânia", name: "MG - Miravânia"},
            {id: "Moeda", name: "MG - Moeda"},
            {id: "Moema", name: "MG - Moema"},
            {id: "Monjolos", name: "MG - Monjolos"},
            {id: "Monsenhor Paulo", name: "MG - Monsenhor Paulo"},
            {id: "Montalvânia", name: "MG - Montalvânia"},
            {id: "Monte Alegre de Minas", name: "MG - Monte Alegre de Minas"},
            {id: "Monte Azul", name: "MG - Monte Azul"},
            {id: "Monte Belo", name: "MG - Monte Belo"},
            {id: "Monte Carmelo", name: "MG - Monte Carmelo"},
            {id: "Monte Formoso", name: "MG - Monte Formoso"},
            {id: "Monte Santo de Minas", name: "MG - Monte Santo de Minas"},
            {id: "Montes Claros", name: "MG - Montes Claros"},
            {id: "Monte Sião", name: "MG - Monte Sião"},
            {id: "Montezuma", name: "MG - Montezuma"},
            {id: "Morada Nova de Minas", name: "MG - Morada Nova de Minas"},
            {id: "Morro da Garça", name: "MG - Morro da Garça"},
            {id: "Morro do Pilar", name: "MG - Morro do Pilar"},
            {id: "Munhoz", name: "MG - Munhoz"},
            {id: "Muriaé", name: "MG - Muriaé"},
            {id: "Mutum", name: "MG - Mutum"},
            {id: "Muzambinho", name: "MG - Muzambinho"},
            {id: "Nacip Raydan", name: "MG - Nacip Raydan"},
            {id: "Nanuque", name: "MG - Nanuque"},
            {id: "Naque", name: "MG - Naque"},
            {id: "Natalândia", name: "MG - Natalândia"},
            {id: "Natércia", name: "MG - Natércia"},
            {id: "Nazareno", name: "MG - Nazareno"},
            {id: "Nepomuceno", name: "MG - Nepomuceno"},
            {id: "Ninheira", name: "MG - Ninheira"},
            {id: "Nova Belém", name: "MG - Nova Belém"},
            {id: "Nova Era", name: "MG - Nova Era"},
            {id: "Nova Lima", name: "MG - Nova Lima"},
            {id: "Nova Módica", name: "MG - Nova Módica"},
            {id: "Nova Ponte", name: "MG - Nova Ponte"},
            {id: "Nova Porteirinha", name: "MG - Nova Porteirinha"},
            {id: "Nova Resende", name: "MG - Nova Resende"},
            {id: "Nova Serrana", name: "MG - Nova Serrana"},
            {id: "Novo Cruzeiro", name: "MG - Novo Cruzeiro"},
            {id: "Novo Oriente de Minas", name: "MG - Novo Oriente de Minas"},
            {id: "Novorizonte", name: "MG - Novorizonte"},
            {id: "Olaria", name: "MG - Olaria"},
            {id: "Olhos-d'água", name: "MG - Olhos-d'água"},
            {id: "Olímpio Noronha", name: "MG - Olímpio Noronha"},
            {id: "Oliveira", name: "MG - Oliveira"},
            {id: "Oliveira Fortes", name: "MG - Oliveira Fortes"},
            {id: "Onça de Pitangui", name: "MG - Onça de Pitangui"},
            {id: "Oratórios", name: "MG - Oratórios"},
            {id: "Orizânia", name: "MG - Orizânia"},
            {id: "Ouro Branco", name: "MG - Ouro Branco"},
            {id: "Ouro Fino", name: "MG - Ouro Fino"},
            {id: "Ouro Preto", name: "MG - Ouro Preto"},
            {id: "Ouro Verde de Minas", name: "MG - Ouro Verde de Minas"},
            {id: "Padre Carvalho", name: "MG - Padre Carvalho"},
            {id: "Padre Paraíso", name: "MG - Padre Paraíso"},
            {id: "Paineiras", name: "MG - Paineiras"},
            {id: "Pains", name: "MG - Pains"},
            {id: "Pai Pedro", name: "MG - Pai Pedro"},
            {id: "Paiva", name: "MG - Paiva"},
            {id: "Palma", name: "MG - Palma"},
            {id: "Palmópolis", name: "MG - Palmópolis"},
            {id: "Papagaios", name: "MG - Papagaios"},
            {id: "Paracatu", name: "MG - Paracatu"},
            {id: "Pará de Minas", name: "MG - Pará de Minas"},
            {id: "Paraguaçu", name: "MG - Paraguaçu"},
            {id: "Paraisópolis", name: "MG - Paraisópolis"},
            {id: "Paraopeba", name: "MG - Paraopeba"},
            {id: "Passabém", name: "MG - Passabém"},
            {id: "Passa Quatro", name: "MG - Passa Quatro"},
            {id: "Passa Tempo", name: "MG - Passa Tempo"},
            {id: "Passa-vinte", name: "MG - Passa-vinte"},
            {id: "Passos", name: "MG - Passos"},
            {id: "Patis", name: "MG - Patis"},
            {id: "Patos de Minas", name: "MG - Patos de Minas"},
            {id: "Patrocínio", name: "MG - Patrocínio"},
            {id: "Patrocínio do Muriaé", name: "MG - Patrocínio do Muriaé"},
            {id: "Paula Cândido", name: "MG - Paula Cândido"},
            {id: "Paulistas", name: "MG - Paulistas"},
            {id: "Pavão", name: "MG - Pavão"},
            {id: "Peçanha", name: "MG - Peçanha"},
            {id: "Pedra Azul", name: "MG - Pedra Azul"},
            {id: "Pedra Bonita", name: "MG - Pedra Bonita"},
            {id: "Pedra do Anta", name: "MG - Pedra do Anta"},
            {id: "Pedra do Indaiá", name: "MG - Pedra do Indaiá"},
            {id: "Pedra Dourada", name: "MG - Pedra Dourada"},
            {id: "Pedralva", name: "MG - Pedralva"},
            {id: "Pedras de Maria da Cruz", name: "MG - Pedras de Maria da Cruz"},
            {id: "Pedrinópolis", name: "MG - Pedrinópolis"},
            {id: "Pedro Leopoldo", name: "MG - Pedro Leopoldo"},
            {id: "Pedro Teixeira", name: "MG - Pedro Teixeira"},
            {id: "Pequeri", name: "MG - Pequeri"},
            {id: "Pequi", name: "MG - Pequi"},
            {id: "Perdigão", name: "MG - Perdigão"},
            {id: "Perdizes", name: "MG - Perdizes"},
            {id: "Perdões", name: "MG - Perdões"},
            {id: "Periquito", name: "MG - Periquito"},
            {id: "Pescador", name: "MG - Pescador"},
            {id: "Piau", name: "MG - Piau"},
            {id: "Piedade de Caratinga", name: "MG - Piedade de Caratinga"},
            {id: "Piedade de Ponte Nova", name: "MG - Piedade de Ponte Nova"},
            {id: "Piedade do Rio Grande", name: "MG - Piedade do Rio Grande"},
            {id: "Piedade Dos Gerais", name: "MG - Piedade Dos Gerais"},
            {id: "Pimenta", name: "MG - Pimenta"},
            {id: "Pingo-d'água", name: "MG - Pingo-d'água"},
            {id: "Pintópolis", name: "MG - Pintópolis"},
            {id: "Piracema", name: "MG - Piracema"},
            {id: "Pirajuba", name: "MG - Pirajuba"},
            {id: "Piranga", name: "MG - Piranga"},
            {id: "Piranguçu", name: "MG - Piranguçu"},
            {id: "Piranguinho", name: "MG - Piranguinho"},
            {id: "Pirapetinga", name: "MG - Pirapetinga"},
            {id: "Pirapora", name: "MG - Pirapora"},
            {id: "Piraúba", name: "MG - Piraúba"},
            {id: "Pitangui", name: "MG - Pitangui"},
            {id: "Piumhi", name: "MG - Piumhi"},
            {id: "Planura", name: "MG - Planura"},
            {id: "Poço Fundo", name: "MG - Poço Fundo"},
            {id: "Poços de Caldas", name: "MG - Poços de Caldas"},
            {id: "Pocrane", name: "MG - Pocrane"},
            {id: "Pompéu", name: "MG - Pompéu"},
            {id: "Ponte Nova", name: "MG - Ponte Nova"},
            {id: "Ponto Chique", name: "MG - Ponto Chique"},
            {id: "Ponto Dos Volantes", name: "MG - Ponto Dos Volantes"},
            {id: "Porteirinha", name: "MG - Porteirinha"},
            {id: "Porto Firme", name: "MG - Porto Firme"},
            {id: "Poté", name: "MG - Poté"},
            {id: "Pouso Alegre", name: "MG - Pouso Alegre"},
            {id: "Pouso Alto", name: "MG - Pouso Alto"},
            {id: "Prados", name: "MG - Prados"},
            {id: "Prata", name: "MG - Prata"},
            {id: "Pratápolis", name: "MG - Pratápolis"},
            {id: "Pratinha", name: "MG - Pratinha"},
            {id: "Presidente Bernardes", name: "MG - Presidente Bernardes"},
            {id: "Presidente Juscelino", name: "MG - Presidente Juscelino"},
            {id: "Presidente Kubitschek", name: "MG - Presidente Kubitschek"},
            {id: "Presidente Olegário", name: "MG - Presidente Olegário"},
            {id: "Alto Jequitibá", name: "MG - Alto Jequitibá"},
            {id: "Prudente de Morais", name: "MG - Prudente de Morais"},
            {id: "Quartel Geral", name: "MG - Quartel Geral"},
            {id: "Queluzito", name: "MG - Queluzito"},
            {id: "Raposos", name: "MG - Raposos"},
            {id: "Raul Soares", name: "MG - Raul Soares"},
            {id: "Recreio", name: "MG - Recreio"},
            {id: "Reduto", name: "MG - Reduto"},
            {id: "Resende Costa", name: "MG - Resende Costa"},
            {id: "Resplendor", name: "MG - Resplendor"},
            {id: "Ressaquinha", name: "MG - Ressaquinha"},
            {id: "Riachinho", name: "MG - Riachinho"},
            {id: "Riacho Dos Machados", name: "MG - Riacho Dos Machados"},
            {id: "Ribeirão Das Neves", name: "MG - Ribeirão Das Neves"},
            {id: "Ribeirão Vermelho", name: "MG - Ribeirão Vermelho"},
            {id: "Rio Acima", name: "MG - Rio Acima"},
            {id: "Rio Casca", name: "MG - Rio Casca"},
            {id: "Rio Doce", name: "MG - Rio Doce"},
            {id: "Rio do Prado", name: "MG - Rio do Prado"},
            {id: "Rio Espera", name: "MG - Rio Espera"},
            {id: "Rio Manso", name: "MG - Rio Manso"},
            {id: "Rio Novo", name: "MG - Rio Novo"},
            {id: "Rio Paranaíba", name: "MG - Rio Paranaíba"},
            {id: "Rio Pardo de Minas", name: "MG - Rio Pardo de Minas"},
            {id: "Rio Piracicaba", name: "MG - Rio Piracicaba"},
            {id: "Rio Pomba", name: "MG - Rio Pomba"},
            {id: "Rio Preto", name: "MG - Rio Preto"},
            {id: "Rio Vermelho", name: "MG - Rio Vermelho"},
            {id: "Ritápolis", name: "MG - Ritápolis"},
            {id: "Rochedo de Minas", name: "MG - Rochedo de Minas"},
            {id: "Rodeiro", name: "MG - Rodeiro"},
            {id: "Romaria", name: "MG - Romaria"},
            {id: "Rosário da Limeira", name: "MG - Rosário da Limeira"},
            {id: "Rubelita", name: "MG - Rubelita"},
            {id: "Rubim", name: "MG - Rubim"},
            {id: "Sabará", name: "MG - Sabará"},
            {id: "Sabinópolis", name: "MG - Sabinópolis"},
            {id: "Sacramento", name: "MG - Sacramento"},
            {id: "Salinas", name: "MG - Salinas"},
            {id: "Salto da Divisa", name: "MG - Salto da Divisa"},
            {id: "Santa Bárbara", name: "MG - Santa Bárbara"},
            {id: "Santa Bárbara do Leste", name: "MG - Santa Bárbara do Leste"},
            {id: "Santa Bárbara do Monte Verde", name: "MG - Santa Bárbara do Monte Verde"},
            {id: "Santa Bárbara do Tugúrio", name: "MG - Santa Bárbara do Tugúrio"},
            {id: "Santa Cruz de Minas", name: "MG - Santa Cruz de Minas"},
            {id: "Santa Cruz de Salinas", name: "MG - Santa Cruz de Salinas"},
            {id: "Santa Cruz do Escalvado", name: "MG - Santa Cruz do Escalvado"},
            {id: "Santa Efigênia de Minas", name: "MG - Santa Efigênia de Minas"},
            {id: "Santa fé de Minas", name: "MG - Santa fé de Minas"},
            {id: "Santa Helena de Minas", name: "MG - Santa Helena de Minas"},
            {id: "Santa Juliana", name: "MG - Santa Juliana"},
            {id: "Santa Luzia", name: "MG - Santa Luzia"},
            {id: "Santa Margarida", name: "MG - Santa Margarida"},
            {id: "Santa Maria de Itabira", name: "MG - Santa Maria de Itabira"},
            {id: "Santa Maria do Salto", name: "MG - Santa Maria do Salto"},
            {id: "Santa Maria do Suaçuí", name: "MG - Santa Maria do Suaçuí"},
            {id: "Santana da Vargem", name: "MG - Santana da Vargem"},
            {id: "Santana de Cataguases", name: "MG - Santana de Cataguases"},
            {id: "Santana de Pirapama", name: "MG - Santana de Pirapama"},
            {id: "Santana do Deserto", name: "MG - Santana do Deserto"},
            {id: "Santana do Garambéu", name: "MG - Santana do Garambéu"},
            {id: "Santana do Jacaré", name: "MG - Santana do Jacaré"},
            {id: "Santana do Manhuaçu", name: "MG - Santana do Manhuaçu"},
            {id: "Santana do Paraíso", name: "MG - Santana do Paraíso"},
            {id: "Santana do Riacho", name: "MG - Santana do Riacho"},
            {id: "Santana Dos Montes", name: "MG - Santana Dos Montes"},
            {id: "Santa Rita de Caldas", name: "MG - Santa Rita de Caldas"},
            {id: "Santa Rita de Jacutinga", name: "MG - Santa Rita de Jacutinga"},
            {id: "Santa Rita de Minas", name: "MG - Santa Rita de Minas"},
            {id: "Santa Rita de Ibitipoca", name: "MG - Santa Rita de Ibitipoca"},
            {id: "Santa Rita do Itueto", name: "MG - Santa Rita do Itueto"},
            {id: "Santa Rita do Sapucaí", name: "MG - Santa Rita do Sapucaí"},
            {id: "Santa Rosa da Serra", name: "MG - Santa Rosa da Serra"},
            {id: "Santa Vitória", name: "MG - Santa Vitória"},
            {id: "Santo Antônio do Amparo", name: "MG - Santo Antônio do Amparo"},
            {id: "Santo Antônio do Aventureiro", name: "MG - Santo Antônio do Aventureiro"},
            {id: "Santo Antônio do Grama", name: "MG - Santo Antônio do Grama"},
            {id: "Santo Antônio do Itambé", name: "MG - Santo Antônio do Itambé"},
            {id: "Santo Antônio do Jacinto", name: "MG - Santo Antônio do Jacinto"},
            {id: "Santo Antônio do Monte", name: "MG - Santo Antônio do Monte"},
            {id: "Santo Antônio do Retiro", name: "MG - Santo Antônio do Retiro"},
            {id: "Santo Antônio do Rio Abaixo", name: "MG - Santo Antônio do Rio Abaixo"},
            {id: "Santo Hipólito", name: "MG - Santo Hipólito"},
            {id: "Santos Dumont", name: "MG - Santos Dumont"},
            {id: "São Bento Abade", name: "MG - São Bento Abade"},
            {id: "São Brás do Suaçuí", name: "MG - São Brás do Suaçuí"},
            {id: "São Domingos Das Dores", name: "MG - São Domingos Das Dores"},
            {id: "São Domingos do Prata", name: "MG - São Domingos do Prata"},
            {id: "São Félix de Minas", name: "MG - São Félix de Minas"},
            {id: "São Francisco", name: "MG - São Francisco"},
            {id: "São Francisco de Paula", name: "MG - São Francisco de Paula"},
            {id: "São Francisco de Sales", name: "MG - São Francisco de Sales"},
            {id: "São Francisco do Glória", name: "MG - São Francisco do Glória"},
            {id: "São Geraldo", name: "MG - São Geraldo"},
            {id: "São Geraldo da Piedade", name: "MG - São Geraldo da Piedade"},
            {id: "São Geraldo do Baixio", name: "MG - São Geraldo do Baixio"},
            {id: "São Gonçalo do Abaeté", name: "MG - São Gonçalo do Abaeté"},
            {id: "São Gonçalo do Pará", name: "MG - São Gonçalo do Pará"},
            {id: "São Gonçalo do Rio Abaixo", name: "MG - São Gonçalo do Rio Abaixo"},
            {id: "São Gonçalo do Sapucaí", name: "MG - São Gonçalo do Sapucaí"},
            {id: "São Gotardo", name: "MG - São Gotardo"},
            {id: "São João Batista do Glória", name: "MG - São João Batista do Glória"},
            {id: "São João da Lagoa", name: "MG - São João da Lagoa"},
            {id: "São João da Mata", name: "MG - São João da Mata"},
            {id: "São João da Ponte", name: "MG - São João da Ponte"},
            {id: "São João Das Missões", name: "MG - São João Das Missões"},
            {id: "São João Del Rei", name: "MG - São João Del Rei"},
            {id: "São João do Manhuaçu", name: "MG - São João do Manhuaçu"},
            {id: "São João do Manteninha", name: "MG - São João do Manteninha"},
            {id: "São João do Oriente", name: "MG - São João do Oriente"},
            {id: "São João do Pacuí", name: "MG - São João do Pacuí"},
            {id: "São João do Paraíso", name: "MG - São João do Paraíso"},
            {id: "São João Evangelista", name: "MG - São João Evangelista"},
            {id: "São João Nepomuceno", name: "MG - São João Nepomuceno"},
            {id: "São Joaquim de Bicas", name: "MG - São Joaquim de Bicas"},
            {id: "São José da Barra", name: "MG - São José da Barra"},
            {id: "São José da Lapa", name: "MG - São José da Lapa"},
            {id: "São José da Safira", name: "MG - São José da Safira"},
            {id: "São José da Varginha", name: "MG - São José da Varginha"},
            {id: "São José do Alegre", name: "MG - São José do Alegre"},
            {id: "São José do Divino", name: "MG - São José do Divino"},
            {id: "São José do Goiabal", name: "MG - São José do Goiabal"},
            {id: "São José do Jacuri", name: "MG - São José do Jacuri"},
            {id: "São José do Mantimento", name: "MG - São José do Mantimento"},
            {id: "São Lourenço", name: "MG - São Lourenço"},
            {id: "São Miguel do Anta", name: "MG - São Miguel do Anta"},
            {id: "São Pedro da União", name: "MG - São Pedro da União"},
            {id: "São Pedro Dos Ferros", name: "MG - São Pedro Dos Ferros"},
            {id: "São Pedro do Suaçuí", name: "MG - São Pedro do Suaçuí"},
            {id: "São Romão", name: "MG - São Romão"},
            {id: "São Roque de Minas", name: "MG - São Roque de Minas"},
            {id: "São Sebastião da Bela Vista", name: "MG - São Sebastião da Bela Vista"},
            {id: "São Sebastião da Vargem Alegre", name: "MG - São Sebastião da Vargem Alegre"},
            {id: "São Sebastião do Anta", name: "MG - São Sebastião do Anta"},
            {id: "São Sebastião do Maranhão", name: "MG - São Sebastião do Maranhão"},
            {id: "São Sebastião do Oeste", name: "MG - São Sebastião do Oeste"},
            {id: "São Sebastião do Paraíso", name: "MG - São Sebastião do Paraíso"},
            {id: "São Sebastião do Rio Preto", name: "MG - São Sebastião do Rio Preto"},
            {id: "São Sebastião do Rio Verde", name: "MG - São Sebastião do Rio Verde"},
            {id: "São Tiago", name: "MG - São Tiago"},
            {id: "São Tomás de Aquino", name: "MG - São Tomás de Aquino"},
            {id: "São Thomé Das Letras", name: "MG - São Thomé Das Letras"},
            {id: "São Vicente de Minas", name: "MG - São Vicente de Minas"},
            {id: "Sapucaí-mirim", name: "MG - Sapucaí-mirim"},
            {id: "Sardoá", name: "MG - Sardoá"},
            {id: "Sarzedo", name: "MG - Sarzedo"},
            {id: "Setubinha", name: "MG - Setubinha"},
            {id: "Sem-peixe", name: "MG - Sem-peixe"},
            {id: "Senador Amaral", name: "MG - Senador Amaral"},
            {id: "Senador Cortes", name: "MG - Senador Cortes"},
            {id: "Senador Firmino", name: "MG - Senador Firmino"},
            {id: "Senador José Bento", name: "MG - Senador José Bento"},
            {id: "Senador Modestino Gonçalves", name: "MG - Senador Modestino Gonçalves"},
            {id: "Senhora de Oliveira", name: "MG - Senhora de Oliveira"},
            {id: "Senhora do Porto", name: "MG - Senhora do Porto"},
            {id: "Senhora Dos Remédios", name: "MG - Senhora Dos Remédios"},
            {id: "Sericita", name: "MG - Sericita"},
            {id: "Seritinga", name: "MG - Seritinga"},
            {id: "Serra Azul de Minas", name: "MG - Serra Azul de Minas"},
            {id: "Serra da Saudade", name: "MG - Serra da Saudade"},
            {id: "Serra Dos Aimorés", name: "MG - Serra Dos Aimorés"},
            {id: "Serra do Salitre", name: "MG - Serra do Salitre"},
            {id: "Serrania", name: "MG - Serrania"},
            {id: "Serranópolis de Minas", name: "MG - Serranópolis de Minas"},
            {id: "Serranos", name: "MG - Serranos"},
            {id: "Serro", name: "MG - Serro"},
            {id: "Sete Lagoas", name: "MG - Sete Lagoas"},
            {id: "Silveirânia", name: "MG - Silveirânia"},
            {id: "Silvianópolis", name: "MG - Silvianópolis"},
            {id: "Simão Pereira", name: "MG - Simão Pereira"},
            {id: "Simonésia", name: "MG - Simonésia"},
            {id: "Sobrália", name: "MG - Sobrália"},
            {id: "Soledade de Minas", name: "MG - Soledade de Minas"},
            {id: "Tabuleiro", name: "MG - Tabuleiro"},
            {id: "Taiobeiras", name: "MG - Taiobeiras"},
            {id: "Taparuba", name: "MG - Taparuba"},
            {id: "Tapira", name: "MG - Tapira"},
            {id: "Tapiraí", name: "MG - Tapiraí"},
            {id: "Taquaraçu de Minas", name: "MG - Taquaraçu de Minas"},
            {id: "Tarumirim", name: "MG - Tarumirim"},
            {id: "Teixeiras", name: "MG - Teixeiras"},
            {id: "Teófilo Otoni", name: "MG - Teófilo Otoni"},
            {id: "Timóteo", name: "MG - Timóteo"},
            {id: "Tiradentes", name: "MG - Tiradentes"},
            {id: "Tiros", name: "MG - Tiros"},
            {id: "Tocantins", name: "MG - Tocantins"},
            {id: "Tocos do Moji", name: "MG - Tocos do Moji"},
            {id: "Toledo", name: "MG - Toledo"},
            {id: "Tombos", name: "MG - Tombos"},
            {id: "Três Corações", name: "MG - Três Corações"},
            {id: "Três Marias", name: "MG - Três Marias"},
            {id: "Três Pontas", name: "MG - Três Pontas"},
            {id: "Tumiritinga", name: "MG - Tumiritinga"},
            {id: "Tupaciguara", name: "MG - Tupaciguara"},
            {id: "Turmalina", name: "MG - Turmalina"},
            {id: "Turvolândia", name: "MG - Turvolândia"},
            {id: "Ubá", name: "MG - Ubá"},
            {id: "Ubaí", name: "MG - Ubaí"},
            {id: "Ubaporanga", name: "MG - Ubaporanga"},
            {id: "Uberaba", name: "MG - Uberaba"},
            {id: "Uberlândia", name: "MG - Uberlândia"},
            {id: "Umburatiba", name: "MG - Umburatiba"},
            {id: "Unaí", name: "MG - Unaí"},
            {id: "União de Minas", name: "MG - União de Minas"},
            {id: "Uruana de Minas", name: "MG - Uruana de Minas"},
            {id: "Urucânia", name: "MG - Urucânia"},
            {id: "Urucuia", name: "MG - Urucuia"},
            {id: "Vargem Alegre", name: "MG - Vargem Alegre"},
            {id: "Vargem Bonita", name: "MG - Vargem Bonita"},
            {id: "Vargem Grande do Rio Pardo", name: "MG - Vargem Grande do Rio Pardo"},
            {id: "Varginha", name: "MG - Varginha"},
            {id: "Varjão de Minas", name: "MG - Varjão de Minas"},
            {id: "Várzea da Palma", name: "MG - Várzea da Palma"},
            {id: "Varzelândia", name: "MG - Varzelândia"},
            {id: "Vazante", name: "MG - Vazante"},
            {id: "Verdelândia", name: "MG - Verdelândia"},
            {id: "Veredinha", name: "MG - Veredinha"},
            {id: "Veríssimo", name: "MG - Veríssimo"},
            {id: "Vermelho Novo", name: "MG - Vermelho Novo"},
            {id: "Vespasiano", name: "MG - Vespasiano"},
            {id: "Viçosa", name: "MG - Viçosa"},
            {id: "Vieiras", name: "MG - Vieiras"},
            {id: "Mathias Lobato", name: "MG - Mathias Lobato"},
            {id: "Virgem da Lapa", name: "MG - Virgem da Lapa"},
            {id: "Virgínia", name: "MG - Virgínia"},
            {id: "Virginópolis", name: "MG - Virginópolis"},
            {id: "Virgolândia", name: "MG - Virgolândia"},
            {id: "Visconde do Rio Branco", name: "MG - Visconde do Rio Branco"},
            {id: "Volta Grande", name: "MG - Volta Grande"},
            {id: "Wenceslau Braz", name: "MG - Wenceslau Braz"},
            {id: "Afonso Cláudio", name: "ES - Afonso Cláudio"},
            {id: "Águia Branca", name: "ES - Águia Branca"},
            {id: "Água Doce do Norte", name: "ES - Água Doce do Norte"},
            {id: "Alegre", name: "ES - Alegre"},
            {id: "Alfredo Chaves", name: "ES - Alfredo Chaves"},
            {id: "Alto Rio Novo", name: "ES - Alto Rio Novo"},
            {id: "Anchieta", name: "ES - Anchieta"},
            {id: "Apiacá", name: "ES - Apiacá"},
            {id: "Aracruz", name: "ES - Aracruz"},
            {id: "Atilio Vivacqua", name: "ES - Atilio Vivacqua"},
            {id: "Baixo Guandu", name: "ES - Baixo Guandu"},
            {id: "Barra de São Francisco", name: "ES - Barra de São Francisco"},
            {id: "Boa Esperança", name: "ES - Boa Esperança"},
            {id: "Bom Jesus do Norte", name: "ES - Bom Jesus do Norte"},
            {id: "Brejetuba", name: "ES - Brejetuba"},
            {id: "Cachoeiro de Itapemirim", name: "ES - Cachoeiro de Itapemirim"},
            {id: "Cariacica", name: "ES - Cariacica"},
            {id: "Castelo", name: "ES - Castelo"},
            {id: "Colatina", name: "ES - Colatina"},
            {id: "Conceição da Barra", name: "ES - Conceição da Barra"},
            {id: "Conceição do Castelo", name: "ES - Conceição do Castelo"},
            {id: "Divino de São Lourenço", name: "ES - Divino de São Lourenço"},
            {id: "Domingos Martins", name: "ES - Domingos Martins"},
            {id: "Dores do Rio Preto", name: "ES - Dores do Rio Preto"},
            {id: "Ecoporanga", name: "ES - Ecoporanga"},
            {id: "Fundão", name: "ES - Fundão"},
            {id: "Governador Lindenberg", name: "ES - Governador Lindenberg"},
            {id: "Guaçuí", name: "ES - Guaçuí"},
            {id: "Guarapari", name: "ES - Guarapari"},
            {id: "Ibatiba", name: "ES - Ibatiba"},
            {id: "Ibiraçu", name: "ES - Ibiraçu"},
            {id: "Ibitirama", name: "ES - Ibitirama"},
            {id: "Iconha", name: "ES - Iconha"},
            {id: "Irupi", name: "ES - Irupi"},
            {id: "Itaguaçu", name: "ES - Itaguaçu"},
            {id: "Itapemirim", name: "ES - Itapemirim"},
            {id: "Itarana", name: "ES - Itarana"},
            {id: "Iúna", name: "ES - Iúna"},
            {id: "Jaguaré", name: "ES - Jaguaré"},
            {id: "Jerônimo Monteiro", name: "ES - Jerônimo Monteiro"},
            {id: "João Neiva", name: "ES - João Neiva"},
            {id: "Laranja da Terra", name: "ES - Laranja da Terra"},
            {id: "Linhares", name: "ES - Linhares"},
            {id: "Mantenópolis", name: "ES - Mantenópolis"},
            {id: "Marataízes", name: "ES - Marataízes"},
            {id: "Marechal Floriano", name: "ES - Marechal Floriano"},
            {id: "Marilândia", name: "ES - Marilândia"},
            {id: "Mimoso do Sul", name: "ES - Mimoso do Sul"},
            {id: "Montanha", name: "ES - Montanha"},
            {id: "Mucurici", name: "ES - Mucurici"},
            {id: "Muniz Freire", name: "ES - Muniz Freire"},
            {id: "Muqui", name: "ES - Muqui"},
            {id: "Nova Venécia", name: "ES - Nova Venécia"},
            {id: "Pancas", name: "ES - Pancas"},
            {id: "Pedro Canário", name: "ES - Pedro Canário"},
            {id: "Pinheiros", name: "ES - Pinheiros"},
            {id: "Piúma", name: "ES - Piúma"},
            {id: "Ponto Belo", name: "ES - Ponto Belo"},
            {id: "Presidente Kennedy", name: "ES - Presidente Kennedy"},
            {id: "Rio Bananal", name: "ES - Rio Bananal"},
            {id: "Rio Novo do Sul", name: "ES - Rio Novo do Sul"},
            {id: "Santa Leopoldina", name: "ES - Santa Leopoldina"},
            {id: "Santa Maria de Jetibá", name: "ES - Santa Maria de Jetibá"},
            {id: "Santa Teresa", name: "ES - Santa Teresa"},
            {id: "São Domingos do Norte", name: "ES - São Domingos do Norte"},
            {id: "São Gabriel da Palha", name: "ES - São Gabriel da Palha"},
            {id: "São José do Calçado", name: "ES - São José do Calçado"},
            {id: "São Mateus", name: "ES - São Mateus"},
            {id: "São Roque do Canaã", name: "ES - São Roque do Canaã"},
            {id: "Serra", name: "ES - Serra"},
            {id: "Sooretama", name: "ES - Sooretama"},
            {id: "Vargem Alta", name: "ES - Vargem Alta"},
            {id: "Venda Nova do Imigrante", name: "ES - Venda Nova do Imigrante"},
            {id: "Viana", name: "ES - Viana"},
            {id: "Vila Pavão", name: "ES - Vila Pavão"},
            {id: "Vila Valério", name: "ES - Vila Valério"},
            {id: "Vila Velha", name: "ES - Vila Velha"},
            {id: "Vitória", name: "ES - Vitória"},
            {id: "Angra Dos Reis", name: "RJ - Angra Dos Reis"},
            {id: "Aperibé", name: "RJ - Aperibé"},
            {id: "Araruama", name: "RJ - Araruama"},
            {id: "Areal", name: "RJ - Areal"},
            {id: "Armação Dos Búzios", name: "RJ - Armação Dos Búzios"},
            {id: "Arraial do Cabo", name: "RJ - Arraial do Cabo"},
            {id: "Barra do Piraí", name: "RJ - Barra do Piraí"},
            {id: "Barra Mansa", name: "RJ - Barra Mansa"},
            {id: "Belford Roxo", name: "RJ - Belford Roxo"},
            {id: "Bom Jardim", name: "RJ - Bom Jardim"},
            {id: "Bom Jesus do Itabapoana", name: "RJ - Bom Jesus do Itabapoana"},
            {id: "Cabo Frio", name: "RJ - Cabo Frio"},
            {id: "Cachoeiras de Macacu", name: "RJ - Cachoeiras de Macacu"},
            {id: "Cambuci", name: "RJ - Cambuci"},
            {id: "Carapebus", name: "RJ - Carapebus"},
            {id: "Comendador Levy Gasparian", name: "RJ - Comendador Levy Gasparian"},
            {id: "Campos Dos Goytacazes", name: "RJ - Campos Dos Goytacazes"},
            {id: "Cantagalo", name: "RJ - Cantagalo"},
            {id: "Cardoso Moreira", name: "RJ - Cardoso Moreira"},
            {id: "Carmo", name: "RJ - Carmo"},
            {id: "Casimiro de Abreu", name: "RJ - Casimiro de Abreu"},
            {id: "Conceição de Macabu", name: "RJ - Conceição de Macabu"},
            {id: "Cordeiro", name: "RJ - Cordeiro"},
            {id: "Duas Barras", name: "RJ - Duas Barras"},
            {id: "Duque de Caxias", name: "RJ - Duque de Caxias"},
            {id: "Engenheiro Paulo de Frontin", name: "RJ - Engenheiro Paulo de Frontin"},
            {id: "Guapimirim", name: "RJ - Guapimirim"},
            {id: "Iguaba Grande", name: "RJ - Iguaba Grande"},
            {id: "Itaboraí", name: "RJ - Itaboraí"},
            {id: "Itaguaí", name: "RJ - Itaguaí"},
            {id: "Italva", name: "RJ - Italva"},
            {id: "Itaocara", name: "RJ - Itaocara"},
            {id: "Itaperuna", name: "RJ - Itaperuna"},
            {id: "Itatiaia", name: "RJ - Itatiaia"},
            {id: "Japeri", name: "RJ - Japeri"},
            {id: "Laje do Muriaé", name: "RJ - Laje do Muriaé"},
            {id: "Macaé", name: "RJ - Macaé"},
            {id: "Macuco", name: "RJ - Macuco"},
            {id: "Magé", name: "RJ - Magé"},
            {id: "Mangaratiba", name: "RJ - Mangaratiba"},
            {id: "Maricá", name: "RJ - Maricá"},
            {id: "Mendes", name: "RJ - Mendes"},
            {id: "Mesquita", name: "RJ - Mesquita"},
            {id: "Miguel Pereira", name: "RJ - Miguel Pereira"},
            {id: "Miracema", name: "RJ - Miracema"},
            {id: "Natividade", name: "RJ - Natividade"},
            {id: "Nilópolis", name: "RJ - Nilópolis"},
            {id: "Niterói", name: "RJ - Niterói"},
            {id: "Nova Friburgo", name: "RJ - Nova Friburgo"},
            {id: "Nova Iguaçu", name: "RJ - Nova Iguaçu"},
            {id: "Paracambi", name: "RJ - Paracambi"},
            {id: "Paraíba do Sul", name: "RJ - Paraíba do Sul"},
            {id: "Parati", name: "RJ - Parati"},
            {id: "Paty do Alferes", name: "RJ - Paty do Alferes"},
            {id: "Petrópolis", name: "RJ - Petrópolis"},
            {id: "Pinheiral", name: "RJ - Pinheiral"},
            {id: "Piraí", name: "RJ - Piraí"},
            {id: "Porciúncula", name: "RJ - Porciúncula"},
            {id: "Porto Real", name: "RJ - Porto Real"},
            {id: "Quatis", name: "RJ - Quatis"},
            {id: "Queimados", name: "RJ - Queimados"},
            {id: "Quissamã", name: "RJ - Quissamã"},
            {id: "Resende", name: "RJ - Resende"},
            {id: "Rio Bonito", name: "RJ - Rio Bonito"},
            {id: "Rio Claro", name: "RJ - Rio Claro"},
            {id: "Rio Das Flores", name: "RJ - Rio Das Flores"},
            {id: "Rio Das Ostras", name: "RJ - Rio Das Ostras"},
            {id: "Rio de Janeiro", name: "RJ - Rio de Janeiro"},
            {id: "Santa Maria Madalena", name: "RJ - Santa Maria Madalena"},
            {id: "Santo Antônio de Pádua", name: "RJ - Santo Antônio de Pádua"},
            {id: "São Francisco de Itabapoana", name: "RJ - São Francisco de Itabapoana"},
            {id: "São Fidélis", name: "RJ - São Fidélis"},
            {id: "São Gonçalo", name: "RJ - São Gonçalo"},
            {id: "São João da Barra", name: "RJ - São João da Barra"},
            {id: "São João de Meriti", name: "RJ - São João de Meriti"},
            {id: "São José de Ubá", name: "RJ - São José de Ubá"},
            {id: "São José do Vale do Rio Preto", name: "RJ - São José do Vale do Rio Preto"},
            {id: "São Pedro da Aldeia", name: "RJ - São Pedro da Aldeia"},
            {id: "São Sebastião do Alto", name: "RJ - São Sebastião do Alto"},
            {id: "Sapucaia", name: "RJ - Sapucaia"},
            {id: "Saquarema", name: "RJ - Saquarema"},
            {id: "Seropédica", name: "RJ - Seropédica"},
            {id: "Silva Jardim", name: "RJ - Silva Jardim"},
            {id: "Sumidouro", name: "RJ - Sumidouro"},
            {id: "Tanguá", name: "RJ - Tanguá"},
            {id: "Teresópolis", name: "RJ - Teresópolis"},
            {id: "Trajano de Morais", name: "RJ - Trajano de Morais"},
            {id: "Três Rios", name: "RJ - Três Rios"},
            {id: "Valença", name: "RJ - Valença"},
            {id: "Varre-sai", name: "RJ - Varre-sai"},
            {id: "Vassouras", name: "RJ - Vassouras"},
            {id: "Volta Redonda", name: "RJ - Volta Redonda"},
            {id: "Adamantina", name: "SP - Adamantina"},
            {id: "Adolfo", name: "SP - Adolfo"},
            {id: "Aguaí", name: "SP - Aguaí"},
            {id: "Águas da Prata", name: "SP - Águas da Prata"},
            {id: "Águas de Lindóia", name: "SP - Águas de Lindóia"},
            {id: "Águas de Santa Bárbara", name: "SP - Águas de Santa Bárbara"},
            {id: "Águas de São Pedro", name: "SP - Águas de São Pedro"},
            {id: "Agudos", name: "SP - Agudos"},
            {id: "Alambari", name: "SP - Alambari"},
            {id: "Alfredo Marcondes", name: "SP - Alfredo Marcondes"},
            {id: "Altair", name: "SP - Altair"},
            {id: "Altinópolis", name: "SP - Altinópolis"},
            {id: "Alto Alegre", name: "SP - Alto Alegre"},
            {id: "Alumínio", name: "SP - Alumínio"},
            {id: "Álvares Florence", name: "SP - Álvares Florence"},
            {id: "Álvares Machado", name: "SP - Álvares Machado"},
            {id: "Álvaro de Carvalho", name: "SP - Álvaro de Carvalho"},
            {id: "Alvinlândia", name: "SP - Alvinlândia"},
            {id: "Americana", name: "SP - Americana"},
            {id: "Américo Brasiliense", name: "SP - Américo Brasiliense"},
            {id: "Américo de Campos", name: "SP - Américo de Campos"},
            {id: "Amparo", name: "SP - Amparo"},
            {id: "Analândia", name: "SP - Analândia"},
            {id: "Andradina", name: "SP - Andradina"},
            {id: "Angatuba", name: "SP - Angatuba"},
            {id: "Anhembi", name: "SP - Anhembi"},
            {id: "Anhumas", name: "SP - Anhumas"},
            {id: "Aparecida", name: "SP - Aparecida"},
            {id: "Aparecida D'oeste", name: "SP - Aparecida D'oeste"},
            {id: "Apiaí", name: "SP - Apiaí"},
            {id: "Araçariguama", name: "SP - Araçariguama"},
            {id: "Araçatuba", name: "SP - Araçatuba"},
            {id: "Araçoiaba da Serra", name: "SP - Araçoiaba da Serra"},
            {id: "Aramina", name: "SP - Aramina"},
            {id: "Arandu", name: "SP - Arandu"},
            {id: "Arapeí", name: "SP - Arapeí"},
            {id: "Araraquara", name: "SP - Araraquara"},
            {id: "Araras", name: "SP - Araras"},
            {id: "Arco-íris", name: "SP - Arco-íris"},
            {id: "Arealva", name: "SP - Arealva"},
            {id: "Areias", name: "SP - Areias"},
            {id: "Areiópolis", name: "SP - Areiópolis"},
            {id: "Ariranha", name: "SP - Ariranha"},
            {id: "Artur Nogueira", name: "SP - Artur Nogueira"},
            {id: "Arujá", name: "SP - Arujá"},
            {id: "Aspásia", name: "SP - Aspásia"},
            {id: "Assis", name: "SP - Assis"},
            {id: "Atibaia", name: "SP - Atibaia"},
            {id: "Auriflama", name: "SP - Auriflama"},
            {id: "Avaí", name: "SP - Avaí"},
            {id: "Avanhandava", name: "SP - Avanhandava"},
            {id: "Avaré", name: "SP - Avaré"},
            {id: "Bady Bassitt", name: "SP - Bady Bassitt"},
            {id: "Balbinos", name: "SP - Balbinos"},
            {id: "Bálsamo", name: "SP - Bálsamo"},
            {id: "Bananal", name: "SP - Bananal"},
            {id: "Barão de Antonina", name: "SP - Barão de Antonina"},
            {id: "Barbosa", name: "SP - Barbosa"},
            {id: "Bariri", name: "SP - Bariri"},
            {id: "Barra Bonita", name: "SP - Barra Bonita"},
            {id: "Barra do Chapéu", name: "SP - Barra do Chapéu"},
            {id: "Barra do Turvo", name: "SP - Barra do Turvo"},
            {id: "Barretos", name: "SP - Barretos"},
            {id: "Barrinha", name: "SP - Barrinha"},
            {id: "Barueri", name: "SP - Barueri"},
            {id: "Bastos", name: "SP - Bastos"},
            {id: "Batatais", name: "SP - Batatais"},
            {id: "Bauru", name: "SP - Bauru"},
            {id: "Bebedouro", name: "SP - Bebedouro"},
            {id: "Bento de Abreu", name: "SP - Bento de Abreu"},
            {id: "Bernardino de Campos", name: "SP - Bernardino de Campos"},
            {id: "Bertioga", name: "SP - Bertioga"},
            {id: "Bilac", name: "SP - Bilac"},
            {id: "Birigui", name: "SP - Birigui"},
            {id: "Biritiba-mirim", name: "SP - Biritiba-mirim"},
            {id: "Boa Esperança do Sul", name: "SP - Boa Esperança do Sul"},
            {id: "Bocaina", name: "SP - Bocaina"},
            {id: "Bofete", name: "SP - Bofete"},
            {id: "Boituva", name: "SP - Boituva"},
            {id: "Bom Jesus Dos Perdões", name: "SP - Bom Jesus Dos Perdões"},
            {id: "Bom Sucesso de Itararé", name: "SP - Bom Sucesso de Itararé"},
            {id: "Borá", name: "SP - Borá"},
            {id: "Boracéia", name: "SP - Boracéia"},
            {id: "Borborema", name: "SP - Borborema"},
            {id: "Borebi", name: "SP - Borebi"},
            {id: "Botucatu", name: "SP - Botucatu"},
            {id: "Bragança Paulista", name: "SP - Bragança Paulista"},
            {id: "Braúna", name: "SP - Braúna"},
            {id: "Brejo Alegre", name: "SP - Brejo Alegre"},
            {id: "Brodowski", name: "SP - Brodowski"},
            {id: "Brotas", name: "SP - Brotas"},
            {id: "Buri", name: "SP - Buri"},
            {id: "Buritama", name: "SP - Buritama"},
            {id: "Buritizal", name: "SP - Buritizal"},
            {id: "Cabrália Paulista", name: "SP - Cabrália Paulista"},
            {id: "Cabreúva", name: "SP - Cabreúva"},
            {id: "Caçapava", name: "SP - Caçapava"},
            {id: "Cachoeira Paulista", name: "SP - Cachoeira Paulista"},
            {id: "Caconde", name: "SP - Caconde"},
            {id: "Cafelândia", name: "SP - Cafelândia"},
            {id: "Caiabu", name: "SP - Caiabu"},
            {id: "Caieiras", name: "SP - Caieiras"},
            {id: "Caiuá", name: "SP - Caiuá"},
            {id: "Cajamar", name: "SP - Cajamar"},
            {id: "Cajati", name: "SP - Cajati"},
            {id: "Cajobi", name: "SP - Cajobi"},
            {id: "Cajuru", name: "SP - Cajuru"},
            {id: "Campina do Monte Alegre", name: "SP - Campina do Monte Alegre"},
            {id: "Campinas", name: "SP - Campinas"},
            {id: "Campo Limpo Paulista", name: "SP - Campo Limpo Paulista"},
            {id: "Campos do Jordão", name: "SP - Campos do Jordão"},
            {id: "Campos Novos Paulista", name: "SP - Campos Novos Paulista"},
            {id: "Cananéia", name: "SP - Cananéia"},
            {id: "Canas", name: "SP - Canas"},
            {id: "Cândido Mota", name: "SP - Cândido Mota"},
            {id: "Cândido Rodrigues", name: "SP - Cândido Rodrigues"},
            {id: "Canitar", name: "SP - Canitar"},
            {id: "Capão Bonito", name: "SP - Capão Bonito"},
            {id: "Capela do Alto", name: "SP - Capela do Alto"},
            {id: "Capivari", name: "SP - Capivari"},
            {id: "Caraguatatuba", name: "SP - Caraguatatuba"},
            {id: "Carapicuíba", name: "SP - Carapicuíba"},
            {id: "Cardoso", name: "SP - Cardoso"},
            {id: "Casa Branca", name: "SP - Casa Branca"},
            {id: "Cássia Dos Coqueiros", name: "SP - Cássia Dos Coqueiros"},
            {id: "Castilho", name: "SP - Castilho"},
            {id: "Catanduva", name: "SP - Catanduva"},
            {id: "Catiguá", name: "SP - Catiguá"},
            {id: "Cedral", name: "SP - Cedral"},
            {id: "Cerqueira César", name: "SP - Cerqueira César"},
            {id: "Cerquilho", name: "SP - Cerquilho"},
            {id: "Cesário Lange", name: "SP - Cesário Lange"},
            {id: "Charqueada", name: "SP - Charqueada"},
            {id: "Clementina", name: "SP - Clementina"},
            {id: "Colina", name: "SP - Colina"},
            {id: "Colômbia", name: "SP - Colômbia"},
            {id: "Conchal", name: "SP - Conchal"},
            {id: "Conchas", name: "SP - Conchas"},
            {id: "Cordeirópolis", name: "SP - Cordeirópolis"},
            {id: "Coroados", name: "SP - Coroados"},
            {id: "Coronel Macedo", name: "SP - Coronel Macedo"},
            {id: "Corumbataí", name: "SP - Corumbataí"},
            {id: "Cosmópolis", name: "SP - Cosmópolis"},
            {id: "Cosmorama", name: "SP - Cosmorama"},
            {id: "Cotia", name: "SP - Cotia"},
            {id: "Cravinhos", name: "SP - Cravinhos"},
            {id: "Cristais Paulista", name: "SP - Cristais Paulista"},
            {id: "Cruzália", name: "SP - Cruzália"},
            {id: "Cruzeiro", name: "SP - Cruzeiro"},
            {id: "Cubatão", name: "SP - Cubatão"},
            {id: "Cunha", name: "SP - Cunha"},
            {id: "Descalvado", name: "SP - Descalvado"},
            {id: "Diadema", name: "SP - Diadema"},
            {id: "Dirce Reis", name: "SP - Dirce Reis"},
            {id: "Divinolândia", name: "SP - Divinolândia"},
            {id: "Dobrada", name: "SP - Dobrada"},
            {id: "Dois Córregos", name: "SP - Dois Córregos"},
            {id: "Dolcinópolis", name: "SP - Dolcinópolis"},
            {id: "Dourado", name: "SP - Dourado"},
            {id: "Dracena", name: "SP - Dracena"},
            {id: "Duartina", name: "SP - Duartina"},
            {id: "Dumont", name: "SP - Dumont"},
            {id: "Echaporã", name: "SP - Echaporã"},
            {id: "Eldorado", name: "SP - Eldorado"},
            {id: "Elias Fausto", name: "SP - Elias Fausto"},
            {id: "Elisiário", name: "SP - Elisiário"},
            {id: "Embaúba", name: "SP - Embaúba"},
            {id: "Embu", name: "SP - Embu"},
            {id: "Embu-guaçu", name: "SP - Embu-guaçu"},
            {id: "Emilianópolis", name: "SP - Emilianópolis"},
            {id: "Engenheiro Coelho", name: "SP - Engenheiro Coelho"},
            {id: "Espírito Santo do Pinhal", name: "SP - Espírito Santo do Pinhal"},
            {id: "Espírito Santo do Turvo", name: "SP - Espírito Santo do Turvo"},
            {id: "Estrela D'oeste", name: "SP - Estrela D'oeste"},
            {id: "Estrela do Norte", name: "SP - Estrela do Norte"},
            {id: "Euclides da Cunha Paulista", name: "SP - Euclides da Cunha Paulista"},
            {id: "Fartura", name: "SP - Fartura"},
            {id: "Fernandópolis", name: "SP - Fernandópolis"},
            {id: "Fernando Prestes", name: "SP - Fernando Prestes"},
            {id: "Fernão", name: "SP - Fernão"},
            {id: "Ferraz de Vasconcelos", name: "SP - Ferraz de Vasconcelos"},
            {id: "Flora Rica", name: "SP - Flora Rica"},
            {id: "Floreal", name: "SP - Floreal"},
            {id: "Flórida Paulista", name: "SP - Flórida Paulista"},
            {id: "Florínia", name: "SP - Florínia"},
            {id: "Franca", name: "SP - Franca"},
            {id: "Francisco Morato", name: "SP - Francisco Morato"},
            {id: "Franco da Rocha", name: "SP - Franco da Rocha"},
            {id: "Gabriel Monteiro", name: "SP - Gabriel Monteiro"},
            {id: "Gália", name: "SP - Gália"},
            {id: "Garça", name: "SP - Garça"},
            {id: "Gastão Vidigal", name: "SP - Gastão Vidigal"},
            {id: "Gavião Peixoto", name: "SP - Gavião Peixoto"},
            {id: "General Salgado", name: "SP - General Salgado"},
            {id: "Getulina", name: "SP - Getulina"},
            {id: "Glicério", name: "SP - Glicério"},
            {id: "Guaiçara", name: "SP - Guaiçara"},
            {id: "Guaimbê", name: "SP - Guaimbê"},
            {id: "Guaíra", name: "SP - Guaíra"},
            {id: "Guapiaçu", name: "SP - Guapiaçu"},
            {id: "Guapiara", name: "SP - Guapiara"},
            {id: "Guará", name: "SP - Guará"},
            {id: "Guaraçaí", name: "SP - Guaraçaí"},
            {id: "Guaraci", name: "SP - Guaraci"},
            {id: "Guarani D'oeste", name: "SP - Guarani D'oeste"},
            {id: "Guarantã", name: "SP - Guarantã"},
            {id: "Guararapes", name: "SP - Guararapes"},
            {id: "Guararema", name: "SP - Guararema"},
            {id: "Guaratinguetá", name: "SP - Guaratinguetá"},
            {id: "Guareí", name: "SP - Guareí"},
            {id: "Guariba", name: "SP - Guariba"},
            {id: "Guarujá", name: "SP - Guarujá"},
            {id: "Guarulhos", name: "SP - Guarulhos"},
            {id: "Guatapará", name: "SP - Guatapará"},
            {id: "Guzolândia", name: "SP - Guzolândia"},
            {id: "Herculândia", name: "SP - Herculândia"},
            {id: "Holambra", name: "SP - Holambra"},
            {id: "Hortolândia", name: "SP - Hortolândia"},
            {id: "Iacanga", name: "SP - Iacanga"},
            {id: "Iacri", name: "SP - Iacri"},
            {id: "Iaras", name: "SP - Iaras"},
            {id: "Ibaté", name: "SP - Ibaté"},
            {id: "Ibirá", name: "SP - Ibirá"},
            {id: "Ibirarema", name: "SP - Ibirarema"},
            {id: "Ibitinga", name: "SP - Ibitinga"},
            {id: "Ibiúna", name: "SP - Ibiúna"},
            {id: "Icém", name: "SP - Icém"},
            {id: "Iepê", name: "SP - Iepê"},
            {id: "Igaraçu do Tietê", name: "SP - Igaraçu do Tietê"},
            {id: "Igarapava", name: "SP - Igarapava"},
            {id: "Igaratá", name: "SP - Igaratá"},
            {id: "Iguape", name: "SP - Iguape"},
            {id: "Ilhabela", name: "SP - Ilhabela"},
            {id: "Ilha Comprida", name: "SP - Ilha Comprida"},
            {id: "Ilha Solteira", name: "SP - Ilha Solteira"},
            {id: "Indaiatuba", name: "SP - Indaiatuba"},
            {id: "Indiana", name: "SP - Indiana"},
            {id: "Indiaporã", name: "SP - Indiaporã"},
            {id: "Inúbia Paulista", name: "SP - Inúbia Paulista"},
            {id: "Ipaussu", name: "SP - Ipaussu"},
            {id: "Iperó", name: "SP - Iperó"},
            {id: "Ipeúna", name: "SP - Ipeúna"},
            {id: "Ipiguá", name: "SP - Ipiguá"},
            {id: "Iporanga", name: "SP - Iporanga"},
            {id: "Ipuã", name: "SP - Ipuã"},
            {id: "Iracemápolis", name: "SP - Iracemápolis"},
            {id: "Irapuã", name: "SP - Irapuã"},
            {id: "Irapuru", name: "SP - Irapuru"},
            {id: "Itaberá", name: "SP - Itaberá"},
            {id: "Itaí", name: "SP - Itaí"},
            {id: "Itajobi", name: "SP - Itajobi"},
            {id: "Itaju", name: "SP - Itaju"},
            {id: "Itanhaém", name: "SP - Itanhaém"},
            {id: "Itaóca", name: "SP - Itaóca"},
            {id: "Itapecerica da Serra", name: "SP - Itapecerica da Serra"},
            {id: "Itapetininga", name: "SP - Itapetininga"},
            {id: "Itapeva", name: "SP - Itapeva"},
            {id: "Itapevi", name: "SP - Itapevi"},
            {id: "Itapira", name: "SP - Itapira"},
            {id: "Itapirapuã Paulista", name: "SP - Itapirapuã Paulista"},
            {id: "Itápolis", name: "SP - Itápolis"},
            {id: "Itaporanga", name: "SP - Itaporanga"},
            {id: "Itapuí", name: "SP - Itapuí"},
            {id: "Itapura", name: "SP - Itapura"},
            {id: "Itaquaquecetuba", name: "SP - Itaquaquecetuba"},
            {id: "Itararé", name: "SP - Itararé"},
            {id: "Itariri", name: "SP - Itariri"},
            {id: "Itatiba", name: "SP - Itatiba"},
            {id: "Itatinga", name: "SP - Itatinga"},
            {id: "Itirapina", name: "SP - Itirapina"},
            {id: "Itirapuã", name: "SP - Itirapuã"},
            {id: "Itobi", name: "SP - Itobi"},
            {id: "Itu", name: "SP - Itu"},
            {id: "Itupeva", name: "SP - Itupeva"},
            {id: "Ituverava", name: "SP - Ituverava"},
            {id: "Jaborandi", name: "SP - Jaborandi"},
            {id: "Jaboticabal", name: "SP - Jaboticabal"},
            {id: "Jacareí", name: "SP - Jacareí"},
            {id: "Jaci", name: "SP - Jaci"},
            {id: "Jacupiranga", name: "SP - Jacupiranga"},
            {id: "Jaguariúna", name: "SP - Jaguariúna"},
            {id: "Jales", name: "SP - Jales"},
            {id: "Jambeiro", name: "SP - Jambeiro"},
            {id: "Jandira", name: "SP - Jandira"},
            {id: "Jardinópolis", name: "SP - Jardinópolis"},
            {id: "Jarinu", name: "SP - Jarinu"},
            {id: "Jaú", name: "SP - Jaú"},
            {id: "Jeriquara", name: "SP - Jeriquara"},
            {id: "Joanópolis", name: "SP - Joanópolis"},
            {id: "João Ramalho", name: "SP - João Ramalho"},
            {id: "José Bonifácio", name: "SP - José Bonifácio"},
            {id: "Júlio Mesquita", name: "SP - Júlio Mesquita"},
            {id: "Jumirim", name: "SP - Jumirim"},
            {id: "Jundiaí", name: "SP - Jundiaí"},
            {id: "Junqueirópolis", name: "SP - Junqueirópolis"},
            {id: "Juquiá", name: "SP - Juquiá"},
            {id: "Juquitiba", name: "SP - Juquitiba"},
            {id: "Lagoinha", name: "SP - Lagoinha"},
            {id: "Laranjal Paulista", name: "SP - Laranjal Paulista"},
            {id: "Lavínia", name: "SP - Lavínia"},
            {id: "Lavrinhas", name: "SP - Lavrinhas"},
            {id: "Leme", name: "SP - Leme"},
            {id: "Lençóis Paulista", name: "SP - Lençóis Paulista"},
            {id: "Limeira", name: "SP - Limeira"},
            {id: "Lindóia", name: "SP - Lindóia"},
            {id: "Lins", name: "SP - Lins"},
            {id: "Lorena", name: "SP - Lorena"},
            {id: "Lourdes", name: "SP - Lourdes"},
            {id: "Louveira", name: "SP - Louveira"},
            {id: "Lucélia", name: "SP - Lucélia"},
            {id: "Lucianópolis", name: "SP - Lucianópolis"},
            {id: "Luís Antônio", name: "SP - Luís Antônio"},
            {id: "Luiziânia", name: "SP - Luiziânia"},
            {id: "Lupércio", name: "SP - Lupércio"},
            {id: "Lutécia", name: "SP - Lutécia"},
            {id: "Macatuba", name: "SP - Macatuba"},
            {id: "Macaubal", name: "SP - Macaubal"},
            {id: "Macedônia", name: "SP - Macedônia"},
            {id: "Magda", name: "SP - Magda"},
            {id: "Mairinque", name: "SP - Mairinque"},
            {id: "Mairiporã", name: "SP - Mairiporã"},
            {id: "Manduri", name: "SP - Manduri"},
            {id: "Marabá Paulista", name: "SP - Marabá Paulista"},
            {id: "Maracaí", name: "SP - Maracaí"},
            {id: "Marapoama", name: "SP - Marapoama"},
            {id: "Mariápolis", name: "SP - Mariápolis"},
            {id: "Marília", name: "SP - Marília"},
            {id: "Marinópolis", name: "SP - Marinópolis"},
            {id: "Martinópolis", name: "SP - Martinópolis"},
            {id: "Matão", name: "SP - Matão"},
            {id: "Mauá", name: "SP - Mauá"},
            {id: "Mendonça", name: "SP - Mendonça"},
            {id: "Meridiano", name: "SP - Meridiano"},
            {id: "Mesópolis", name: "SP - Mesópolis"},
            {id: "Miguelópolis", name: "SP - Miguelópolis"},
            {id: "Mineiros do Tietê", name: "SP - Mineiros do Tietê"},
            {id: "Miracatu", name: "SP - Miracatu"},
            {id: "Mira Estrela", name: "SP - Mira Estrela"},
            {id: "Mirandópolis", name: "SP - Mirandópolis"},
            {id: "Mirante do Paranapanema", name: "SP - Mirante do Paranapanema"},
            {id: "Mirassol", name: "SP - Mirassol"},
            {id: "Mirassolândia", name: "SP - Mirassolândia"},
            {id: "Mococa", name: "SP - Mococa"},
            {id: "Moji Das Cruzes", name: "SP - Moji Das Cruzes"},
            {id: "Mogi Guaçu", name: "SP - Mogi Guaçu"},
            {id: "Moji-mirim", name: "SP - Moji-mirim"},
            {id: "Mombuca", name: "SP - Mombuca"},
            {id: "Monções", name: "SP - Monções"},
            {id: "Mongaguá", name: "SP - Mongaguá"},
            {id: "Monte Alegre do Sul", name: "SP - Monte Alegre do Sul"},
            {id: "Monte Alto", name: "SP - Monte Alto"},
            {id: "Monte Aprazível", name: "SP - Monte Aprazível"},
            {id: "Monte Azul Paulista", name: "SP - Monte Azul Paulista"},
            {id: "Monte Castelo", name: "SP - Monte Castelo"},
            {id: "Monteiro Lobato", name: "SP - Monteiro Lobato"},
            {id: "Monte Mor", name: "SP - Monte Mor"},
            {id: "Morro Agudo", name: "SP - Morro Agudo"},
            {id: "Morungaba", name: "SP - Morungaba"},
            {id: "Motuca", name: "SP - Motuca"},
            {id: "Murutinga do Sul", name: "SP - Murutinga do Sul"},
            {id: "Nantes", name: "SP - Nantes"},
            {id: "Narandiba", name: "SP - Narandiba"},
            {id: "Natividade da Serra", name: "SP - Natividade da Serra"},
            {id: "Nazaré Paulista", name: "SP - Nazaré Paulista"},
            {id: "Neves Paulista", name: "SP - Neves Paulista"},
            {id: "Nhandeara", name: "SP - Nhandeara"},
            {id: "Nipoã", name: "SP - Nipoã"},
            {id: "Nova Aliança", name: "SP - Nova Aliança"},
            {id: "Nova Campina", name: "SP - Nova Campina"},
            {id: "Nova Canaã Paulista", name: "SP - Nova Canaã Paulista"},
            {id: "Nova Castilho", name: "SP - Nova Castilho"},
            {id: "Nova Europa", name: "SP - Nova Europa"},
            {id: "Nova Granada", name: "SP - Nova Granada"},
            {id: "Nova Guataporanga", name: "SP - Nova Guataporanga"},
            {id: "Nova Independência", name: "SP - Nova Independência"},
            {id: "Novais", name: "SP - Novais"},
            {id: "Nova Luzitânia", name: "SP - Nova Luzitânia"},
            {id: "Nova Odessa", name: "SP - Nova Odessa"},
            {id: "Novo Horizonte", name: "SP - Novo Horizonte"},
            {id: "Nuporanga", name: "SP - Nuporanga"},
            {id: "Ocauçu", name: "SP - Ocauçu"},
            {id: "Óleo", name: "SP - Óleo"},
            {id: "Olímpia", name: "SP - Olímpia"},
            {id: "Onda Verde", name: "SP - Onda Verde"},
            {id: "Oriente", name: "SP - Oriente"},
            {id: "Orindiúva", name: "SP - Orindiúva"},
            {id: "Orlândia", name: "SP - Orlândia"},
            {id: "Osasco", name: "SP - Osasco"},
            {id: "Oscar Bressane", name: "SP - Oscar Bressane"},
            {id: "Osvaldo Cruz", name: "SP - Osvaldo Cruz"},
            {id: "Ourinhos", name: "SP - Ourinhos"},
            {id: "Ouroeste", name: "SP - Ouroeste"},
            {id: "Ouro Verde", name: "SP - Ouro Verde"},
            {id: "Pacaembu", name: "SP - Pacaembu"},
            {id: "Palestina", name: "SP - Palestina"},
            {id: "Palmares Paulista", name: "SP - Palmares Paulista"},
            {id: "Palmeira D'oeste", name: "SP - Palmeira D'oeste"},
            {id: "Palmital", name: "SP - Palmital"},
            {id: "Panorama", name: "SP - Panorama"},
            {id: "Paraguaçu Paulista", name: "SP - Paraguaçu Paulista"},
            {id: "Paraibuna", name: "SP - Paraibuna"},
            {id: "Paraíso", name: "SP - Paraíso"},
            {id: "Paranapanema", name: "SP - Paranapanema"},
            {id: "Paranapuã", name: "SP - Paranapuã"},
            {id: "Parapuã", name: "SP - Parapuã"},
            {id: "Pardinho", name: "SP - Pardinho"},
            {id: "Pariquera-açu", name: "SP - Pariquera-açu"},
            {id: "Parisi", name: "SP - Parisi"},
            {id: "Patrocínio Paulista", name: "SP - Patrocínio Paulista"},
            {id: "Paulicéia", name: "SP - Paulicéia"},
            {id: "Paulínia", name: "SP - Paulínia"},
            {id: "Paulistânia", name: "SP - Paulistânia"},
            {id: "Paulo de Faria", name: "SP - Paulo de Faria"},
            {id: "Pederneiras", name: "SP - Pederneiras"},
            {id: "Pedra Bela", name: "SP - Pedra Bela"},
            {id: "Pedranópolis", name: "SP - Pedranópolis"},
            {id: "Pedregulho", name: "SP - Pedregulho"},
            {id: "Pedreira", name: "SP - Pedreira"},
            {id: "Pedrinhas Paulista", name: "SP - Pedrinhas Paulista"},
            {id: "Pedro de Toledo", name: "SP - Pedro de Toledo"},
            {id: "Penápolis", name: "SP - Penápolis"},
            {id: "Pereira Barreto", name: "SP - Pereira Barreto"},
            {id: "Pereiras", name: "SP - Pereiras"},
            {id: "Peruíbe", name: "SP - Peruíbe"},
            {id: "Piacatu", name: "SP - Piacatu"},
            {id: "Piedade", name: "SP - Piedade"},
            {id: "Pilar do Sul", name: "SP - Pilar do Sul"},
            {id: "Pindamonhangaba", name: "SP - Pindamonhangaba"},
            {id: "Pindorama", name: "SP - Pindorama"},
            {id: "Pinhalzinho", name: "SP - Pinhalzinho"},
            {id: "Piquerobi", name: "SP - Piquerobi"},
            {id: "Piquete", name: "SP - Piquete"},
            {id: "Piracaia", name: "SP - Piracaia"},
            {id: "Piracicaba", name: "SP - Piracicaba"},
            {id: "Piraju", name: "SP - Piraju"},
            {id: "Pirajuí", name: "SP - Pirajuí"},
            {id: "Pirangi", name: "SP - Pirangi"},
            {id: "Pirapora do Bom Jesus", name: "SP - Pirapora do Bom Jesus"},
            {id: "Pirapozinho", name: "SP - Pirapozinho"},
            {id: "Pirassununga", name: "SP - Pirassununga"},
            {id: "Piratininga", name: "SP - Piratininga"},
            {id: "Pitangueiras", name: "SP - Pitangueiras"},
            {id: "Planalto", name: "SP - Planalto"},
            {id: "Platina", name: "SP - Platina"},
            {id: "Poá", name: "SP - Poá"},
            {id: "Poloni", name: "SP - Poloni"},
            {id: "Pompéia", name: "SP - Pompéia"},
            {id: "Pongaí", name: "SP - Pongaí"},
            {id: "Pontal", name: "SP - Pontal"},
            {id: "Pontalinda", name: "SP - Pontalinda"},
            {id: "Pontes Gestal", name: "SP - Pontes Gestal"},
            {id: "Populina", name: "SP - Populina"},
            {id: "Porangaba", name: "SP - Porangaba"},
            {id: "Porto Feliz", name: "SP - Porto Feliz"},
            {id: "Porto Ferreira", name: "SP - Porto Ferreira"},
            {id: "Potim", name: "SP - Potim"},
            {id: "Potirendaba", name: "SP - Potirendaba"},
            {id: "Pracinha", name: "SP - Pracinha"},
            {id: "Pradópolis", name: "SP - Pradópolis"},
            {id: "Praia Grande", name: "SP - Praia Grande"},
            {id: "Pratânia", name: "SP - Pratânia"},
            {id: "Presidente Alves", name: "SP - Presidente Alves"},
            {id: "Presidente Bernardes", name: "SP - Presidente Bernardes"},
            {id: "Presidente Epitácio", name: "SP - Presidente Epitácio"},
            {id: "Presidente Prudente", name: "SP - Presidente Prudente"},
            {id: "Presidente Venceslau", name: "SP - Presidente Venceslau"},
            {id: "Promissão", name: "SP - Promissão"},
            {id: "Quadra", name: "SP - Quadra"},
            {id: "Quatá", name: "SP - Quatá"},
            {id: "Queiroz", name: "SP - Queiroz"},
            {id: "Queluz", name: "SP - Queluz"},
            {id: "Quintana", name: "SP - Quintana"},
            {id: "Rafard", name: "SP - Rafard"},
            {id: "Rancharia", name: "SP - Rancharia"},
            {id: "Redenção da Serra", name: "SP - Redenção da Serra"},
            {id: "Regente Feijó", name: "SP - Regente Feijó"},
            {id: "Reginópolis", name: "SP - Reginópolis"},
            {id: "Registro", name: "SP - Registro"},
            {id: "Restinga", name: "SP - Restinga"},
            {id: "Ribeira", name: "SP - Ribeira"},
            {id: "Ribeirão Bonito", name: "SP - Ribeirão Bonito"},
            {id: "Ribeirão Branco", name: "SP - Ribeirão Branco"},
            {id: "Ribeirão Corrente", name: "SP - Ribeirão Corrente"},
            {id: "Ribeirão do Sul", name: "SP - Ribeirão do Sul"},
            {id: "Ribeirão Dos Índios", name: "SP - Ribeirão Dos Índios"},
            {id: "Ribeirão Grande", name: "SP - Ribeirão Grande"},
            {id: "Ribeirão Pires", name: "SP - Ribeirão Pires"},
            {id: "Ribeirão Preto", name: "SP - Ribeirão Preto"},
            {id: "Riversul", name: "SP - Riversul"},
            {id: "Rifaina", name: "SP - Rifaina"},
            {id: "Rincão", name: "SP - Rincão"},
            {id: "Rinópolis", name: "SP - Rinópolis"},
            {id: "Rio Claro", name: "SP - Rio Claro"},
            {id: "Rio Das Pedras", name: "SP - Rio Das Pedras"},
            {id: "Rio Grande da Serra", name: "SP - Rio Grande da Serra"},
            {id: "Riolândia", name: "SP - Riolândia"},
            {id: "Rosana", name: "SP - Rosana"},
            {id: "Roseira", name: "SP - Roseira"},
            {id: "Rubiácea", name: "SP - Rubiácea"},
            {id: "Rubinéia", name: "SP - Rubinéia"},
            {id: "Sabino", name: "SP - Sabino"},
            {id: "Sagres", name: "SP - Sagres"},
            {id: "Sales", name: "SP - Sales"},
            {id: "Sales Oliveira", name: "SP - Sales Oliveira"},
            {id: "Salesópolis", name: "SP - Salesópolis"},
            {id: "Salmourão", name: "SP - Salmourão"},
            {id: "Saltinho", name: "SP - Saltinho"},
            {id: "Salto", name: "SP - Salto"},
            {id: "Salto de Pirapora", name: "SP - Salto de Pirapora"},
            {id: "Salto Grande", name: "SP - Salto Grande"},
            {id: "Sandovalina", name: "SP - Sandovalina"},
            {id: "Santa Adélia", name: "SP - Santa Adélia"},
            {id: "Santa Albertina", name: "SP - Santa Albertina"},
            {id: "Santa Bárbara D'oeste", name: "SP - Santa Bárbara D'oeste"},
            {id: "Santa Branca", name: "SP - Santa Branca"},
            {id: "Santa Clara D'oeste", name: "SP - Santa Clara D'oeste"},
            {id: "Santa Cruz da Conceição", name: "SP - Santa Cruz da Conceição"},
            {id: "Santa Cruz da Esperança", name: "SP - Santa Cruz da Esperança"},
            {id: "Santa Cruz Das Palmeiras", name: "SP - Santa Cruz Das Palmeiras"},
            {id: "Santa Cruz do Rio Pardo", name: "SP - Santa Cruz do Rio Pardo"},
            {id: "Santa Ernestina", name: "SP - Santa Ernestina"},
            {id: "Santa fé do Sul", name: "SP - Santa fé do Sul"},
            {id: "Santa Gertrudes", name: "SP - Santa Gertrudes"},
            {id: "Santa Isabel", name: "SP - Santa Isabel"},
            {id: "Santa Lúcia", name: "SP - Santa Lúcia"},
            {id: "Santa Maria da Serra", name: "SP - Santa Maria da Serra"},
            {id: "Santa Mercedes", name: "SP - Santa Mercedes"},
            {id: "Santana da Ponte Pensa", name: "SP - Santana da Ponte Pensa"},
            {id: "Santana de Parnaíba", name: "SP - Santana de Parnaíba"},
            {id: "Santa Rita D'oeste", name: "SP - Santa Rita D'oeste"},
            {id: "Santa Rita do Passa Quatro", name: "SP - Santa Rita do Passa Quatro"},
            {id: "Santa Rosa de Viterbo", name: "SP - Santa Rosa de Viterbo"},
            {id: "Santa Salete", name: "SP - Santa Salete"},
            {id: "Santo Anastácio", name: "SP - Santo Anastácio"},
            {id: "Santo André", name: "SP - Santo André"},
            {id: "Santo Antônio da Alegria", name: "SP - Santo Antônio da Alegria"},
            {id: "Santo Antônio de Posse", name: "SP - Santo Antônio de Posse"},
            {id: "Santo Antônio do Aracanguá", name: "SP - Santo Antônio do Aracanguá"},
            {id: "Santo Antônio do Jardim", name: "SP - Santo Antônio do Jardim"},
            {id: "Santo Antônio do Pinhal", name: "SP - Santo Antônio do Pinhal"},
            {id: "Santo Expedito", name: "SP - Santo Expedito"},
            {id: "Santópolis do Aguapeí", name: "SP - Santópolis do Aguapeí"},
            {id: "Santos", name: "SP - Santos"},
            {id: "São Bento do Sapucaí", name: "SP - São Bento do Sapucaí"},
            {id: "São Bernardo do Campo", name: "SP - São Bernardo do Campo"},
            {id: "São Caetano do Sul", name: "SP - São Caetano do Sul"},
            {id: "São Carlos", name: "SP - São Carlos"},
            {id: "São Francisco", name: "SP - São Francisco"},
            {id: "São João da Boa Vista", name: "SP - São João da Boa Vista"},
            {id: "São João Das Duas Pontes", name: "SP - São João Das Duas Pontes"},
            {id: "São João de Iracema", name: "SP - São João de Iracema"},
            {id: "São João do Pau D'alho", name: "SP - São João do Pau D'alho"},
            {id: "São Joaquim da Barra", name: "SP - São Joaquim da Barra"},
            {id: "São José da Bela Vista", name: "SP - São José da Bela Vista"},
            {id: "São José do Barreiro", name: "SP - São José do Barreiro"},
            {id: "São José do Rio Pardo", name: "SP - São José do Rio Pardo"},
            {id: "São José do Rio Preto", name: "SP - São José do Rio Preto"},
            {id: "São José Dos Campos", name: "SP - São José Dos Campos"},
            {id: "São Lourenço da Serra", name: "SP - São Lourenço da Serra"},
            {id: "São Luís do Paraitinga", name: "SP - São Luís do Paraitinga"},
            {id: "São Manuel", name: "SP - São Manuel"},
            {id: "São Miguel Arcanjo", name: "SP - São Miguel Arcanjo"},
            {id: "São Paulo", name: "SP - São Paulo"},
            {id: "São Pedro", name: "SP - São Pedro"},
            {id: "São Pedro do Turvo", name: "SP - São Pedro do Turvo"},
            {id: "São Roque", name: "SP - São Roque"},
            {id: "São Sebastião", name: "SP - São Sebastião"},
            {id: "São Sebastião da Grama", name: "SP - São Sebastião da Grama"},
            {id: "São Simão", name: "SP - São Simão"},
            {id: "São Vicente", name: "SP - São Vicente"},
            {id: "Sarapuí", name: "SP - Sarapuí"},
            {id: "Sarutaiá", name: "SP - Sarutaiá"},
            {id: "Sebastianópolis do Sul", name: "SP - Sebastianópolis do Sul"},
            {id: "Serra Azul", name: "SP - Serra Azul"},
            {id: "Serrana", name: "SP - Serrana"},
            {id: "Serra Negra", name: "SP - Serra Negra"},
            {id: "Sertãozinho", name: "SP - Sertãozinho"},
            {id: "Sete Barras", name: "SP - Sete Barras"},
            {id: "Severínia", name: "SP - Severínia"},
            {id: "Silveiras", name: "SP - Silveiras"},
            {id: "Socorro", name: "SP - Socorro"},
            {id: "Sorocaba", name: "SP - Sorocaba"},
            {id: "Sud Mennucci", name: "SP - Sud Mennucci"},
            {id: "Sumaré", name: "SP - Sumaré"},
            {id: "Suzano", name: "SP - Suzano"},
            {id: "Suzanápolis", name: "SP - Suzanápolis"},
            {id: "Tabapuã", name: "SP - Tabapuã"},
            {id: "Tabatinga", name: "SP - Tabatinga"},
            {id: "Taboão da Serra", name: "SP - Taboão da Serra"},
            {id: "Taciba", name: "SP - Taciba"},
            {id: "Taguaí", name: "SP - Taguaí"},
            {id: "Taiaçu", name: "SP - Taiaçu"},
            {id: "Taiúva", name: "SP - Taiúva"},
            {id: "Tambaú", name: "SP - Tambaú"},
            {id: "Tanabi", name: "SP - Tanabi"},
            {id: "Tapiraí", name: "SP - Tapiraí"},
            {id: "Tapiratiba", name: "SP - Tapiratiba"},
            {id: "Taquaral", name: "SP - Taquaral"},
            {id: "Taquaritinga", name: "SP - Taquaritinga"},
            {id: "Taquarituba", name: "SP - Taquarituba"},
            {id: "Taquarivaí", name: "SP - Taquarivaí"},
            {id: "Tarabai", name: "SP - Tarabai"},
            {id: "Tarumã", name: "SP - Tarumã"},
            {id: "Tatuí", name: "SP - Tatuí"},
            {id: "Taubaté", name: "SP - Taubaté"},
            {id: "Tejupá", name: "SP - Tejupá"},
            {id: "Teodoro Sampaio", name: "SP - Teodoro Sampaio"},
            {id: "Terra Roxa", name: "SP - Terra Roxa"},
            {id: "Tietê", name: "SP - Tietê"},
            {id: "Timburi", name: "SP - Timburi"},
            {id: "Torre de Pedra", name: "SP - Torre de Pedra"},
            {id: "Torrinha", name: "SP - Torrinha"},
            {id: "Trabiju", name: "SP - Trabiju"},
            {id: "Tremembé", name: "SP - Tremembé"},
            {id: "Três Fronteiras", name: "SP - Três Fronteiras"},
            {id: "Tuiuti", name: "SP - Tuiuti"},
            {id: "Tupã", name: "SP - Tupã"},
            {id: "Tupi Paulista", name: "SP - Tupi Paulista"},
            {id: "Turiúba", name: "SP - Turiúba"},
            {id: "Turmalina", name: "SP - Turmalina"},
            {id: "Ubarana", name: "SP - Ubarana"},
            {id: "Ubatuba", name: "SP - Ubatuba"},
            {id: "Ubirajara", name: "SP - Ubirajara"},
            {id: "Uchoa", name: "SP - Uchoa"},
            {id: "União Paulista", name: "SP - União Paulista"},
            {id: "Urânia", name: "SP - Urânia"},
            {id: "Uru", name: "SP - Uru"},
            {id: "Urupês", name: "SP - Urupês"},
            {id: "Valentim Gentil", name: "SP - Valentim Gentil"},
            {id: "Valinhos", name: "SP - Valinhos"},
            {id: "Valparaíso", name: "SP - Valparaíso"},
            {id: "Vargem", name: "SP - Vargem"},
            {id: "Vargem Grande do Sul", name: "SP - Vargem Grande do Sul"},
            {id: "Vargem Grande Paulista", name: "SP - Vargem Grande Paulista"},
            {id: "Várzea Paulista", name: "SP - Várzea Paulista"},
            {id: "Vera Cruz", name: "SP - Vera Cruz"},
            {id: "Vinhedo", name: "SP - Vinhedo"},
            {id: "Viradouro", name: "SP - Viradouro"},
            {id: "Vista Alegre do Alto", name: "SP - Vista Alegre do Alto"},
            {id: "Vitória Brasil", name: "SP - Vitória Brasil"},
            {id: "Votorantim", name: "SP - Votorantim"},
            {id: "Votuporanga", name: "SP - Votuporanga"},
            {id: "Zacarias", name: "SP - Zacarias"},
            {id: "Chavantes", name: "SP - Chavantes"},
            {id: "Estiva Gerbi", name: "SP - Estiva Gerbi"},
            {id: "Abatiá", name: "PR - Abatiá"},
            {id: "Adrianópolis", name: "PR - Adrianópolis"},
            {id: "Agudos do Sul", name: "PR - Agudos do Sul"},
            {id: "Almirante Tamandaré", name: "PR - Almirante Tamandaré"},
            {id: "Altamira do Paraná", name: "PR - Altamira do Paraná"},
            {id: "Altônia", name: "PR - Altônia"},
            {id: "Alto Paraná", name: "PR - Alto Paraná"},
            {id: "Alto Piquiri", name: "PR - Alto Piquiri"},
            {id: "Alvorada do Sul", name: "PR - Alvorada do Sul"},
            {id: "Amaporã", name: "PR - Amaporã"},
            {id: "Ampére", name: "PR - Ampére"},
            {id: "Anahy", name: "PR - Anahy"},
            {id: "Andirá", name: "PR - Andirá"},
            {id: "Ângulo", name: "PR - Ângulo"},
            {id: "Antonina", name: "PR - Antonina"},
            {id: "Antônio Olinto", name: "PR - Antônio Olinto"},
            {id: "Apucarana", name: "PR - Apucarana"},
            {id: "Arapongas", name: "PR - Arapongas"},
            {id: "Arapoti", name: "PR - Arapoti"},
            {id: "Arapuã", name: "PR - Arapuã"},
            {id: "Araruna", name: "PR - Araruna"},
            {id: "Araucária", name: "PR - Araucária"},
            {id: "Ariranha do Ivaí", name: "PR - Ariranha do Ivaí"},
            {id: "Assaí", name: "PR - Assaí"},
            {id: "Assis Chateaubriand", name: "PR - Assis Chateaubriand"},
            {id: "Astorga", name: "PR - Astorga"},
            {id: "Atalaia", name: "PR - Atalaia"},
            {id: "Balsa Nova", name: "PR - Balsa Nova"},
            {id: "Bandeirantes", name: "PR - Bandeirantes"},
            {id: "Barbosa Ferraz", name: "PR - Barbosa Ferraz"},
            {id: "Barracão", name: "PR - Barracão"},
            {id: "Barra do Jacaré", name: "PR - Barra do Jacaré"},
            {id: "Bela Vista da Caroba", name: "PR - Bela Vista da Caroba"},
            {id: "Bela Vista do Paraíso", name: "PR - Bela Vista do Paraíso"},
            {id: "Bituruna", name: "PR - Bituruna"},
            {id: "Boa Esperança", name: "PR - Boa Esperança"},
            {id: "Boa Esperança do Iguaçu", name: "PR - Boa Esperança do Iguaçu"},
            {id: "Boa Ventura de São Roque", name: "PR - Boa Ventura de São Roque"},
            {id: "Boa Vista da Aparecida", name: "PR - Boa Vista da Aparecida"},
            {id: "Bocaiúva do Sul", name: "PR - Bocaiúva do Sul"},
            {id: "Bom Jesus do Sul", name: "PR - Bom Jesus do Sul"},
            {id: "Bom Sucesso", name: "PR - Bom Sucesso"},
            {id: "Bom Sucesso do Sul", name: "PR - Bom Sucesso do Sul"},
            {id: "Borrazópolis", name: "PR - Borrazópolis"},
            {id: "Braganey", name: "PR - Braganey"},
            {id: "Brasilândia do Sul", name: "PR - Brasilândia do Sul"},
            {id: "Cafeara", name: "PR - Cafeara"},
            {id: "Cafelândia", name: "PR - Cafelândia"},
            {id: "Cafezal do Sul", name: "PR - Cafezal do Sul"},
            {id: "Califórnia", name: "PR - Califórnia"},
            {id: "Cambará", name: "PR - Cambará"},
            {id: "Cambé", name: "PR - Cambé"},
            {id: "Cambira", name: "PR - Cambira"},
            {id: "Campina da Lagoa", name: "PR - Campina da Lagoa"},
            {id: "Campina do Simão", name: "PR - Campina do Simão"},
            {id: "Campina Grande do Sul", name: "PR - Campina Grande do Sul"},
            {id: "Campo Bonito", name: "PR - Campo Bonito"},
            {id: "Campo do Tenente", name: "PR - Campo do Tenente"},
            {id: "Campo Largo", name: "PR - Campo Largo"},
            {id: "Campo Magro", name: "PR - Campo Magro"},
            {id: "Campo Mourão", name: "PR - Campo Mourão"},
            {id: "Cândido de Abreu", name: "PR - Cândido de Abreu"},
            {id: "Candói", name: "PR - Candói"},
            {id: "Cantagalo", name: "PR - Cantagalo"},
            {id: "Capanema", name: "PR - Capanema"},
            {id: "Capitão Leônidas Marques", name: "PR - Capitão Leônidas Marques"},
            {id: "Carambeí", name: "PR - Carambeí"},
            {id: "Carlópolis", name: "PR - Carlópolis"},
            {id: "Cascavel", name: "PR - Cascavel"},
            {id: "Castro", name: "PR - Castro"},
            {id: "Catanduvas", name: "PR - Catanduvas"},
            {id: "Centenário do Sul", name: "PR - Centenário do Sul"},
            {id: "Cerro Azul", name: "PR - Cerro Azul"},
            {id: "Céu Azul", name: "PR - Céu Azul"},
            {id: "Chopinzinho", name: "PR - Chopinzinho"},
            {id: "Cianorte", name: "PR - Cianorte"},
            {id: "Cidade Gaúcha", name: "PR - Cidade Gaúcha"},
            {id: "Clevelândia", name: "PR - Clevelândia"},
            {id: "Colombo", name: "PR - Colombo"},
            {id: "Colorado", name: "PR - Colorado"},
            {id: "Congonhinhas", name: "PR - Congonhinhas"},
            {id: "Conselheiro Mairinck", name: "PR - Conselheiro Mairinck"},
            {id: "Contenda", name: "PR - Contenda"},
            {id: "Corbélia", name: "PR - Corbélia"},
            {id: "Cornélio Procópio", name: "PR - Cornélio Procópio"},
            {id: "Coronel Domingos Soares", name: "PR - Coronel Domingos Soares"},
            {id: "Coronel Vivida", name: "PR - Coronel Vivida"},
            {id: "Corumbataí do Sul", name: "PR - Corumbataí do Sul"},
            {id: "Cruzeiro do Iguaçu", name: "PR - Cruzeiro do Iguaçu"},
            {id: "Cruzeiro do Oeste", name: "PR - Cruzeiro do Oeste"},
            {id: "Cruzeiro do Sul", name: "PR - Cruzeiro do Sul"},
            {id: "Cruz Machado", name: "PR - Cruz Machado"},
            {id: "Cruzmaltina", name: "PR - Cruzmaltina"},
            {id: "Curitiba", name: "PR - Curitiba"},
            {id: "Curiúva", name: "PR - Curiúva"},
            {id: "Diamante do Norte", name: "PR - Diamante do Norte"},
            {id: "Diamante do Sul", name: "PR - Diamante do Sul"},
            {id: "Diamante D'oeste", name: "PR - Diamante D'oeste"},
            {id: "Dois Vizinhos", name: "PR - Dois Vizinhos"},
            {id: "Douradina", name: "PR - Douradina"},
            {id: "Doutor Camargo", name: "PR - Doutor Camargo"},
            {id: "Enéas Marques", name: "PR - Enéas Marques"},
            {id: "Engenheiro Beltrão", name: "PR - Engenheiro Beltrão"},
            {id: "Esperança Nova", name: "PR - Esperança Nova"},
            {id: "Entre Rios do Oeste", name: "PR - Entre Rios do Oeste"},
            {id: "Espigão Alto do Iguaçu", name: "PR - Espigão Alto do Iguaçu"},
            {id: "Farol", name: "PR - Farol"},
            {id: "Faxinal", name: "PR - Faxinal"},
            {id: "Fazenda Rio Grande", name: "PR - Fazenda Rio Grande"},
            {id: "Fênix", name: "PR - Fênix"},
            {id: "Fernandes Pinheiro", name: "PR - Fernandes Pinheiro"},
            {id: "Figueira", name: "PR - Figueira"},
            {id: "Floraí", name: "PR - Floraí"},
            {id: "Flor da Serra do Sul", name: "PR - Flor da Serra do Sul"},
            {id: "Floresta", name: "PR - Floresta"},
            {id: "Florestópolis", name: "PR - Florestópolis"},
            {id: "Flórida", name: "PR - Flórida"},
            {id: "Formosa do Oeste", name: "PR - Formosa do Oeste"},
            {id: "Foz do Iguaçu", name: "PR - Foz do Iguaçu"},
            {id: "Francisco Alves", name: "PR - Francisco Alves"},
            {id: "Francisco Beltrão", name: "PR - Francisco Beltrão"},
            {id: "Foz do Jordão", name: "PR - Foz do Jordão"},
            {id: "General Carneiro", name: "PR - General Carneiro"},
            {id: "Godoy Moreira", name: "PR - Godoy Moreira"},
            {id: "Goioerê", name: "PR - Goioerê"},
            {id: "Goioxim", name: "PR - Goioxim"},
            {id: "Grandes Rios", name: "PR - Grandes Rios"},
            {id: "Guaíra", name: "PR - Guaíra"},
            {id: "Guairaçá", name: "PR - Guairaçá"},
            {id: "Guamiranga", name: "PR - Guamiranga"},
            {id: "Guapirama", name: "PR - Guapirama"},
            {id: "Guaporema", name: "PR - Guaporema"},
            {id: "Guaraci", name: "PR - Guaraci"},
            {id: "Guaraniaçu", name: "PR - Guaraniaçu"},
            {id: "Guarapuava", name: "PR - Guarapuava"},
            {id: "Guaraqueçaba", name: "PR - Guaraqueçaba"},
            {id: "Guaratuba", name: "PR - Guaratuba"},
            {id: "Honório Serpa", name: "PR - Honório Serpa"},
            {id: "Ibaiti", name: "PR - Ibaiti"},
            {id: "Ibema", name: "PR - Ibema"},
            {id: "Ibiporã", name: "PR - Ibiporã"},
            {id: "Icaraíma", name: "PR - Icaraíma"},
            {id: "Iguaraçu", name: "PR - Iguaraçu"},
            {id: "Iguatu", name: "PR - Iguatu"},
            {id: "Imbaú", name: "PR - Imbaú"},
            {id: "Imbituva", name: "PR - Imbituva"},
            {id: "Inácio Martins", name: "PR - Inácio Martins"},
            {id: "Inajá", name: "PR - Inajá"},
            {id: "Indianópolis", name: "PR - Indianópolis"},
            {id: "Ipiranga", name: "PR - Ipiranga"},
            {id: "Iporã", name: "PR - Iporã"},
            {id: "Iracema do Oeste", name: "PR - Iracema do Oeste"},
            {id: "Irati", name: "PR - Irati"},
            {id: "Iretama", name: "PR - Iretama"},
            {id: "Itaguajé", name: "PR - Itaguajé"},
            {id: "Itaipulândia", name: "PR - Itaipulândia"},
            {id: "Itambaracá", name: "PR - Itambaracá"},
            {id: "Itambé", name: "PR - Itambé"},
            {id: "Itapejara D'oeste", name: "PR - Itapejara D'oeste"},
            {id: "Itaperuçu", name: "PR - Itaperuçu"},
            {id: "Itaúna do Sul", name: "PR - Itaúna do Sul"},
            {id: "Ivaí", name: "PR - Ivaí"},
            {id: "Ivaiporã", name: "PR - Ivaiporã"},
            {id: "Ivaté", name: "PR - Ivaté"},
            {id: "Ivatuba", name: "PR - Ivatuba"},
            {id: "Jaboti", name: "PR - Jaboti"},
            {id: "Jacarezinho", name: "PR - Jacarezinho"},
            {id: "Jaguapitã", name: "PR - Jaguapitã"},
            {id: "Jaguariaíva", name: "PR - Jaguariaíva"},
            {id: "Jandaia do Sul", name: "PR - Jandaia do Sul"},
            {id: "Janiópolis", name: "PR - Janiópolis"},
            {id: "Japira", name: "PR - Japira"},
            {id: "Japurá", name: "PR - Japurá"},
            {id: "Jardim Alegre", name: "PR - Jardim Alegre"},
            {id: "Jardim Olinda", name: "PR - Jardim Olinda"},
            {id: "Jataizinho", name: "PR - Jataizinho"},
            {id: "Jesuítas", name: "PR - Jesuítas"},
            {id: "Joaquim Távora", name: "PR - Joaquim Távora"},
            {id: "Jundiaí do Sul", name: "PR - Jundiaí do Sul"},
            {id: "Juranda", name: "PR - Juranda"},
            {id: "Jussara", name: "PR - Jussara"},
            {id: "Kaloré", name: "PR - Kaloré"},
            {id: "Lapa", name: "PR - Lapa"},
            {id: "Laranjal", name: "PR - Laranjal"},
            {id: "Laranjeiras do Sul", name: "PR - Laranjeiras do Sul"},
            {id: "Leópolis", name: "PR - Leópolis"},
            {id: "Lidianópolis", name: "PR - Lidianópolis"},
            {id: "Lindoeste", name: "PR - Lindoeste"},
            {id: "Loanda", name: "PR - Loanda"},
            {id: "Lobato", name: "PR - Lobato"},
            {id: "Londrina", name: "PR - Londrina"},
            {id: "Luiziana", name: "PR - Luiziana"},
            {id: "Lunardelli", name: "PR - Lunardelli"},
            {id: "Lupionópolis", name: "PR - Lupionópolis"},
            {id: "Mallet", name: "PR - Mallet"},
            {id: "Mamborê", name: "PR - Mamborê"},
            {id: "Mandaguaçu", name: "PR - Mandaguaçu"},
            {id: "Mandaguari", name: "PR - Mandaguari"},
            {id: "Mandirituba", name: "PR - Mandirituba"},
            {id: "Manfrinópolis", name: "PR - Manfrinópolis"},
            {id: "Mangueirinha", name: "PR - Mangueirinha"},
            {id: "Manoel Ribas", name: "PR - Manoel Ribas"},
            {id: "Marechal Cândido Rondon", name: "PR - Marechal Cândido Rondon"},
            {id: "Maria Helena", name: "PR - Maria Helena"},
            {id: "Marialva", name: "PR - Marialva"},
            {id: "Marilândia do Sul", name: "PR - Marilândia do Sul"},
            {id: "Marilena", name: "PR - Marilena"},
            {id: "Mariluz", name: "PR - Mariluz"},
            {id: "Maringá", name: "PR - Maringá"},
            {id: "Mariópolis", name: "PR - Mariópolis"},
            {id: "Maripá", name: "PR - Maripá"},
            {id: "Marmeleiro", name: "PR - Marmeleiro"},
            {id: "Marquinho", name: "PR - Marquinho"},
            {id: "Marumbi", name: "PR - Marumbi"},
            {id: "Matelândia", name: "PR - Matelândia"},
            {id: "Matinhos", name: "PR - Matinhos"},
            {id: "Mato Rico", name: "PR - Mato Rico"},
            {id: "Mauá da Serra", name: "PR - Mauá da Serra"},
            {id: "Medianeira", name: "PR - Medianeira"},
            {id: "Mercedes", name: "PR - Mercedes"},
            {id: "Mirador", name: "PR - Mirador"},
            {id: "Miraselva", name: "PR - Miraselva"},
            {id: "Missal", name: "PR - Missal"},
            {id: "Moreira Sales", name: "PR - Moreira Sales"},
            {id: "Morretes", name: "PR - Morretes"},
            {id: "Munhoz de Melo", name: "PR - Munhoz de Melo"},
            {id: "Nossa Senhora Das Graças", name: "PR - Nossa Senhora Das Graças"},
            {id: "Nova Aliança do Ivaí", name: "PR - Nova Aliança do Ivaí"},
            {id: "Nova América da Colina", name: "PR - Nova América da Colina"},
            {id: "Nova Aurora", name: "PR - Nova Aurora"},
            {id: "Nova Cantu", name: "PR - Nova Cantu"},
            {id: "Nova Esperança", name: "PR - Nova Esperança"},
            {id: "Nova Esperança do Sudoeste", name: "PR - Nova Esperança do Sudoeste"},
            {id: "Nova Fátima", name: "PR - Nova Fátima"},
            {id: "Nova Laranjeiras", name: "PR - Nova Laranjeiras"},
            {id: "Nova Londrina", name: "PR - Nova Londrina"},
            {id: "Nova Olímpia", name: "PR - Nova Olímpia"},
            {id: "Nova Santa Bárbara", name: "PR - Nova Santa Bárbara"},
            {id: "Nova Santa Rosa", name: "PR - Nova Santa Rosa"},
            {id: "Nova Prata do Iguaçu", name: "PR - Nova Prata do Iguaçu"},
            {id: "Nova Tebas", name: "PR - Nova Tebas"},
            {id: "Novo Itacolomi", name: "PR - Novo Itacolomi"},
            {id: "Ortigueira", name: "PR - Ortigueira"},
            {id: "Ourizona", name: "PR - Ourizona"},
            {id: "Ouro Verde do Oeste", name: "PR - Ouro Verde do Oeste"},
            {id: "Paiçandu", name: "PR - Paiçandu"},
            {id: "Palmas", name: "PR - Palmas"},
            {id: "Palmeira", name: "PR - Palmeira"},
            {id: "Palmital", name: "PR - Palmital"},
            {id: "Palotina", name: "PR - Palotina"},
            {id: "Paraíso do Norte", name: "PR - Paraíso do Norte"},
            {id: "Paranacity", name: "PR - Paranacity"},
            {id: "Paranaguá", name: "PR - Paranaguá"},
            {id: "Paranapoema", name: "PR - Paranapoema"},
            {id: "Paranavaí", name: "PR - Paranavaí"},
            {id: "Pato Bragado", name: "PR - Pato Bragado"},
            {id: "Pato Branco", name: "PR - Pato Branco"},
            {id: "Paula Freitas", name: "PR - Paula Freitas"},
            {id: "Paulo Frontin", name: "PR - Paulo Frontin"},
            {id: "Peabiru", name: "PR - Peabiru"},
            {id: "Perobal", name: "PR - Perobal"},
            {id: "Pérola", name: "PR - Pérola"},
            {id: "Pérola D'oeste", name: "PR - Pérola D'oeste"},
            {id: "Piên", name: "PR - Piên"},
            {id: "Pinhais", name: "PR - Pinhais"},
            {id: "Pinhalão", name: "PR - Pinhalão"},
            {id: "Pinhal de São Bento", name: "PR - Pinhal de São Bento"},
            {id: "Pinhão", name: "PR - Pinhão"},
            {id: "Piraí do Sul", name: "PR - Piraí do Sul"},
            {id: "Piraquara", name: "PR - Piraquara"},
            {id: "Pitanga", name: "PR - Pitanga"},
            {id: "Pitangueiras", name: "PR - Pitangueiras"},
            {id: "Planaltina do Paraná", name: "PR - Planaltina do Paraná"},
            {id: "Planalto", name: "PR - Planalto"},
            {id: "Ponta Grossa", name: "PR - Ponta Grossa"},
            {id: "Pontal do Paraná", name: "PR - Pontal do Paraná"},
            {id: "Porecatu", name: "PR - Porecatu"},
            {id: "Porto Amazonas", name: "PR - Porto Amazonas"},
            {id: "Porto Barreiro", name: "PR - Porto Barreiro"},
            {id: "Porto Rico", name: "PR - Porto Rico"},
            {id: "Porto Vitória", name: "PR - Porto Vitória"},
            {id: "Prado Ferreira", name: "PR - Prado Ferreira"},
            {id: "Pranchita", name: "PR - Pranchita"},
            {id: "Presidente Castelo Branco", name: "PR - Presidente Castelo Branco"},
            {id: "Primeiro de Maio", name: "PR - Primeiro de Maio"},
            {id: "Prudentópolis", name: "PR - Prudentópolis"},
            {id: "Quarto Centenário", name: "PR - Quarto Centenário"},
            {id: "Quatiguá", name: "PR - Quatiguá"},
            {id: "Quatro Barras", name: "PR - Quatro Barras"},
            {id: "Quatro Pontes", name: "PR - Quatro Pontes"},
            {id: "Quedas do Iguaçu", name: "PR - Quedas do Iguaçu"},
            {id: "Querência do Norte", name: "PR - Querência do Norte"},
            {id: "Quinta do Sol", name: "PR - Quinta do Sol"},
            {id: "Quitandinha", name: "PR - Quitandinha"},
            {id: "Ramilândia", name: "PR - Ramilândia"},
            {id: "Rancho Alegre", name: "PR - Rancho Alegre"},
            {id: "Rancho Alegre D'oeste", name: "PR - Rancho Alegre D'oeste"},
            {id: "Realeza", name: "PR - Realeza"},
            {id: "Rebouças", name: "PR - Rebouças"},
            {id: "Renascença", name: "PR - Renascença"},
            {id: "Reserva", name: "PR - Reserva"},
            {id: "Reserva do Iguaçu", name: "PR - Reserva do Iguaçu"},
            {id: "Ribeirão Claro", name: "PR - Ribeirão Claro"},
            {id: "Ribeirão do Pinhal", name: "PR - Ribeirão do Pinhal"},
            {id: "Rio Azul", name: "PR - Rio Azul"},
            {id: "Rio Bom", name: "PR - Rio Bom"},
            {id: "Rio Bonito do Iguaçu", name: "PR - Rio Bonito do Iguaçu"},
            {id: "Rio Branco do Ivaí", name: "PR - Rio Branco do Ivaí"},
            {id: "Rio Branco do Sul", name: "PR - Rio Branco do Sul"},
            {id: "Rio Negro", name: "PR - Rio Negro"},
            {id: "Rolândia", name: "PR - Rolândia"},
            {id: "Roncador", name: "PR - Roncador"},
            {id: "Rondon", name: "PR - Rondon"},
            {id: "Rosário do Ivaí", name: "PR - Rosário do Ivaí"},
            {id: "Sabáudia", name: "PR - Sabáudia"},
            {id: "Salgado Filho", name: "PR - Salgado Filho"},
            {id: "Salto do Itararé", name: "PR - Salto do Itararé"},
            {id: "Salto do Lontra", name: "PR - Salto do Lontra"},
            {id: "Santa Amélia", name: "PR - Santa Amélia"},
            {id: "Santa Cecília do Pavão", name: "PR - Santa Cecília do Pavão"},
            {id: "Santa Cruz de Monte Castelo", name: "PR - Santa Cruz de Monte Castelo"},
            {id: "Santa fé", name: "PR - Santa fé"},
            {id: "Santa Helena", name: "PR - Santa Helena"},
            {id: "Santa Inês", name: "PR - Santa Inês"},
            {id: "Santa Isabel do Ivaí", name: "PR - Santa Isabel do Ivaí"},
            {id: "Santa Izabel do Oeste", name: "PR - Santa Izabel do Oeste"},
            {id: "Santa Lúcia", name: "PR - Santa Lúcia"},
            {id: "Santa Maria do Oeste", name: "PR - Santa Maria do Oeste"},
            {id: "Santa Mariana", name: "PR - Santa Mariana"},
            {id: "Santa Mônica", name: "PR - Santa Mônica"},
            {id: "Santana do Itararé", name: "PR - Santana do Itararé"},
            {id: "Santa Tereza do Oeste", name: "PR - Santa Tereza do Oeste"},
            {id: "Santa Terezinha de Itaipu", name: "PR - Santa Terezinha de Itaipu"},
            {id: "Santo Antônio da Platina", name: "PR - Santo Antônio da Platina"},
            {id: "Santo Antônio do Caiuá", name: "PR - Santo Antônio do Caiuá"},
            {id: "Santo Antônio do Paraíso", name: "PR - Santo Antônio do Paraíso"},
            {id: "Santo Antônio do Sudoeste", name: "PR - Santo Antônio do Sudoeste"},
            {id: "Santo Inácio", name: "PR - Santo Inácio"},
            {id: "São Carlos do Ivaí", name: "PR - São Carlos do Ivaí"},
            {id: "São Jerônimo da Serra", name: "PR - São Jerônimo da Serra"},
            {id: "São João", name: "PR - São João"},
            {id: "São João do Caiuá", name: "PR - São João do Caiuá"},
            {id: "São João do Ivaí", name: "PR - São João do Ivaí"},
            {id: "São João do Triunfo", name: "PR - São João do Triunfo"},
            {id: "São Jorge D'oeste", name: "PR - São Jorge D'oeste"},
            {id: "São Jorge do Ivaí", name: "PR - São Jorge do Ivaí"},
            {id: "São Jorge do Patrocínio", name: "PR - São Jorge do Patrocínio"},
            {id: "São José da Boa Vista", name: "PR - São José da Boa Vista"},
            {id: "São José Das Palmeiras", name: "PR - São José Das Palmeiras"},
            {id: "São José Dos Pinhais", name: "PR - São José Dos Pinhais"},
            {id: "São Manoel do Paraná", name: "PR - São Manoel do Paraná"},
            {id: "São Mateus do Sul", name: "PR - São Mateus do Sul"},
            {id: "São Miguel do Iguaçu", name: "PR - São Miguel do Iguaçu"},
            {id: "São Pedro do Iguaçu", name: "PR - São Pedro do Iguaçu"},
            {id: "São Pedro do Ivaí", name: "PR - São Pedro do Ivaí"},
            {id: "São Pedro do Paraná", name: "PR - São Pedro do Paraná"},
            {id: "São Sebastião da Amoreira", name: "PR - São Sebastião da Amoreira"},
            {id: "São Tomé", name: "PR - São Tomé"},
            {id: "Sapopema", name: "PR - Sapopema"},
            {id: "Sarandi", name: "PR - Sarandi"},
            {id: "Saudade do Iguaçu", name: "PR - Saudade do Iguaçu"},
            {id: "Sengés", name: "PR - Sengés"},
            {id: "Serranópolis do Iguaçu", name: "PR - Serranópolis do Iguaçu"},
            {id: "Sertaneja", name: "PR - Sertaneja"},
            {id: "Sertanópolis", name: "PR - Sertanópolis"},
            {id: "Siqueira Campos", name: "PR - Siqueira Campos"},
            {id: "Sulina", name: "PR - Sulina"},
            {id: "Tamarana", name: "PR - Tamarana"},
            {id: "Tamboara", name: "PR - Tamboara"},
            {id: "Tapejara", name: "PR - Tapejara"},
            {id: "Tapira", name: "PR - Tapira"},
            {id: "Teixeira Soares", name: "PR - Teixeira Soares"},
            {id: "Telêmaco Borba", name: "PR - Telêmaco Borba"},
            {id: "Terra Boa", name: "PR - Terra Boa"},
            {id: "Terra Rica", name: "PR - Terra Rica"},
            {id: "Terra Roxa", name: "PR - Terra Roxa"},
            {id: "Tibagi", name: "PR - Tibagi"},
            {id: "Tijucas do Sul", name: "PR - Tijucas do Sul"},
            {id: "Toledo", name: "PR - Toledo"},
            {id: "Tomazina", name: "PR - Tomazina"},
            {id: "Três Barras do Paraná", name: "PR - Três Barras do Paraná"},
            {id: "Tunas do Paraná", name: "PR - Tunas do Paraná"},
            {id: "Tuneiras do Oeste", name: "PR - Tuneiras do Oeste"},
            {id: "Tupãssi", name: "PR - Tupãssi"},
            {id: "Turvo", name: "PR - Turvo"},
            {id: "Ubiratã", name: "PR - Ubiratã"},
            {id: "Umuarama", name: "PR - Umuarama"},
            {id: "União da Vitória", name: "PR - União da Vitória"},
            {id: "Uniflor", name: "PR - Uniflor"},
            {id: "Uraí", name: "PR - Uraí"},
            {id: "Wenceslau Braz", name: "PR - Wenceslau Braz"},
            {id: "Ventania", name: "PR - Ventania"},
            {id: "Vera Cruz do Oeste", name: "PR - Vera Cruz do Oeste"},
            {id: "Verê", name: "PR - Verê"},
            {id: "Vila Alta", name: "PR - Vila Alta"},
            {id: "Doutor Ulysses", name: "PR - Doutor Ulysses"},
            {id: "Virmond", name: "PR - Virmond"},
            {id: "Vitorino", name: "PR - Vitorino"},
            {id: "Xambrê", name: "PR - Xambrê"},
            {id: "Abdon Batista", name: "SC - Abdon Batista"},
            {id: "Abelardo Luz", name: "SC - Abelardo Luz"},
            {id: "Agrolândia", name: "SC - Agrolândia"},
            {id: "Agronômica", name: "SC - Agronômica"},
            {id: "Água Doce", name: "SC - Água Doce"},
            {id: "Águas de Chapecó", name: "SC - Águas de Chapecó"},
            {id: "Águas Frias", name: "SC - Águas Frias"},
            {id: "Águas Mornas", name: "SC - Águas Mornas"},
            {id: "Alfredo Wagner", name: "SC - Alfredo Wagner"},
            {id: "Alto Bela Vista", name: "SC - Alto Bela Vista"},
            {id: "Anchieta", name: "SC - Anchieta"},
            {id: "Angelina", name: "SC - Angelina"},
            {id: "Anita Garibaldi", name: "SC - Anita Garibaldi"},
            {id: "Anitápolis", name: "SC - Anitápolis"},
            {id: "Antônio Carlos", name: "SC - Antônio Carlos"},
            {id: "Apiúna", name: "SC - Apiúna"},
            {id: "Arabutã", name: "SC - Arabutã"},
            {id: "Araquari", name: "SC - Araquari"},
            {id: "Araranguá", name: "SC - Araranguá"},
            {id: "Armazém", name: "SC - Armazém"},
            {id: "Arroio Trinta", name: "SC - Arroio Trinta"},
            {id: "Arvoredo", name: "SC - Arvoredo"},
            {id: "Ascurra", name: "SC - Ascurra"},
            {id: "Atalanta", name: "SC - Atalanta"},
            {id: "Aurora", name: "SC - Aurora"},
            {id: "Balneário Arroio do Silva", name: "SC - Balneário Arroio do Silva"},
            {id: "Balneário Camboriú", name: "SC - Balneário Camboriú"},
            {id: "Balneário Barra do Sul", name: "SC - Balneário Barra do Sul"},
            {id: "Balneário Gaivota", name: "SC - Balneário Gaivota"},
            {id: "Bandeirante", name: "SC - Bandeirante"},
            {id: "Barra Bonita", name: "SC - Barra Bonita"},
            {id: "Barra Velha", name: "SC - Barra Velha"},
            {id: "Bela Vista do Toldo", name: "SC - Bela Vista do Toldo"},
            {id: "Belmonte", name: "SC - Belmonte"},
            {id: "Benedito Novo", name: "SC - Benedito Novo"},
            {id: "Biguaçu", name: "SC - Biguaçu"},
            {id: "Blumenau", name: "SC - Blumenau"},
            {id: "Bocaina do Sul", name: "SC - Bocaina do Sul"},
            {id: "Bombinhas", name: "SC - Bombinhas"},
            {id: "Bom Jardim da Serra", name: "SC - Bom Jardim da Serra"},
            {id: "Bom Jesus", name: "SC - Bom Jesus"},
            {id: "Bom Jesus do Oeste", name: "SC - Bom Jesus do Oeste"},
            {id: "Bom Retiro", name: "SC - Bom Retiro"},
            {id: "Botuverá", name: "SC - Botuverá"},
            {id: "Braço do Norte", name: "SC - Braço do Norte"},
            {id: "Braço do Trombudo", name: "SC - Braço do Trombudo"},
            {id: "Brunópolis", name: "SC - Brunópolis"},
            {id: "Brusque", name: "SC - Brusque"},
            {id: "Caçador", name: "SC - Caçador"},
            {id: "Caibi", name: "SC - Caibi"},
            {id: "Calmon", name: "SC - Calmon"},
            {id: "Camboriú", name: "SC - Camboriú"},
            {id: "Capão Alto", name: "SC - Capão Alto"},
            {id: "Campo Alegre", name: "SC - Campo Alegre"},
            {id: "Campo Belo do Sul", name: "SC - Campo Belo do Sul"},
            {id: "Campo Erê", name: "SC - Campo Erê"},
            {id: "Campos Novos", name: "SC - Campos Novos"},
            {id: "Canelinha", name: "SC - Canelinha"},
            {id: "Canoinhas", name: "SC - Canoinhas"},
            {id: "Capinzal", name: "SC - Capinzal"},
            {id: "Capivari de Baixo", name: "SC - Capivari de Baixo"},
            {id: "Catanduvas", name: "SC - Catanduvas"},
            {id: "Caxambu do Sul", name: "SC - Caxambu do Sul"},
            {id: "Celso Ramos", name: "SC - Celso Ramos"},
            {id: "Cerro Negro", name: "SC - Cerro Negro"},
            {id: "Chapadão do Lageado", name: "SC - Chapadão do Lageado"},
            {id: "Chapecó", name: "SC - Chapecó"},
            {id: "Cocal do Sul", name: "SC - Cocal do Sul"},
            {id: "Concórdia", name: "SC - Concórdia"},
            {id: "Cordilheira Alta", name: "SC - Cordilheira Alta"},
            {id: "Coronel Freitas", name: "SC - Coronel Freitas"},
            {id: "Coronel Martins", name: "SC - Coronel Martins"},
            {id: "Corupá", name: "SC - Corupá"},
            {id: "Correia Pinto", name: "SC - Correia Pinto"},
            {id: "Criciúma", name: "SC - Criciúma"},
            {id: "Cunha Porã", name: "SC - Cunha Porã"},
            {id: "Cunhataí", name: "SC - Cunhataí"},
            {id: "Curitibanos", name: "SC - Curitibanos"},
            {id: "Descanso", name: "SC - Descanso"},
            {id: "Dionísio Cerqueira", name: "SC - Dionísio Cerqueira"},
            {id: "Dona Emma", name: "SC - Dona Emma"},
            {id: "Doutor Pedrinho", name: "SC - Doutor Pedrinho"},
            {id: "Entre Rios", name: "SC - Entre Rios"},
            {id: "Ermo", name: "SC - Ermo"},
            {id: "Erval Velho", name: "SC - Erval Velho"},
            {id: "Faxinal Dos Guedes", name: "SC - Faxinal Dos Guedes"},
            {id: "Flor do Sertão", name: "SC - Flor do Sertão"},
            {id: "Florianópolis", name: "SC - Florianópolis"},
            {id: "Formosa do Sul", name: "SC - Formosa do Sul"},
            {id: "Forquilhinha", name: "SC - Forquilhinha"},
            {id: "Fraiburgo", name: "SC - Fraiburgo"},
            {id: "Frei Rogério", name: "SC - Frei Rogério"},
            {id: "Galvão", name: "SC - Galvão"},
            {id: "Garopaba", name: "SC - Garopaba"},
            {id: "Garuva", name: "SC - Garuva"},
            {id: "Gaspar", name: "SC - Gaspar"},
            {id: "Governador Celso Ramos", name: "SC - Governador Celso Ramos"},
            {id: "Grão Pará", name: "SC - Grão Pará"},
            {id: "Gravatal", name: "SC - Gravatal"},
            {id: "Guabiruba", name: "SC - Guabiruba"},
            {id: "Guaraciaba", name: "SC - Guaraciaba"},
            {id: "Guaramirim", name: "SC - Guaramirim"},
            {id: "Guarujá do Sul", name: "SC - Guarujá do Sul"},
            {id: "Guatambú", name: "SC - Guatambú"},
            {id: "Herval D'oeste", name: "SC - Herval D'oeste"},
            {id: "Ibiam", name: "SC - Ibiam"},
            {id: "Ibicaré", name: "SC - Ibicaré"},
            {id: "Ibirama", name: "SC - Ibirama"},
            {id: "Içara", name: "SC - Içara"},
            {id: "Ilhota", name: "SC - Ilhota"},
            {id: "Imaruí", name: "SC - Imaruí"},
            {id: "Imbituba", name: "SC - Imbituba"},
            {id: "Imbuia", name: "SC - Imbuia"},
            {id: "Indaial", name: "SC - Indaial"},
            {id: "Iomerê", name: "SC - Iomerê"},
            {id: "Ipira", name: "SC - Ipira"},
            {id: "Iporã do Oeste", name: "SC - Iporã do Oeste"},
            {id: "Ipuaçu", name: "SC - Ipuaçu"},
            {id: "Ipumirim", name: "SC - Ipumirim"},
            {id: "Iraceminha", name: "SC - Iraceminha"},
            {id: "Irani", name: "SC - Irani"},
            {id: "Irati", name: "SC - Irati"},
            {id: "Irineópolis", name: "SC - Irineópolis"},
            {id: "Itá", name: "SC - Itá"},
            {id: "Itaiópolis", name: "SC - Itaiópolis"},
            {id: "Itajaí", name: "SC - Itajaí"},
            {id: "Itapema", name: "SC - Itapema"},
            {id: "Itapiranga", name: "SC - Itapiranga"},
            {id: "Itapoá", name: "SC - Itapoá"},
            {id: "Ituporanga", name: "SC - Ituporanga"},
            {id: "Jaborá", name: "SC - Jaborá"},
            {id: "Jacinto Machado", name: "SC - Jacinto Machado"},
            {id: "Jaguaruna", name: "SC - Jaguaruna"},
            {id: "Jaraguá do Sul", name: "SC - Jaraguá do Sul"},
            {id: "Jardinópolis", name: "SC - Jardinópolis"},
            {id: "Joaçaba", name: "SC - Joaçaba"},
            {id: "Joinville", name: "SC - Joinville"},
            {id: "José Boiteux", name: "SC - José Boiteux"},
            {id: "Jupiá", name: "SC - Jupiá"},
            {id: "Lacerdópolis", name: "SC - Lacerdópolis"},
            {id: "Lages", name: "SC - Lages"},
            {id: "Laguna", name: "SC - Laguna"},
            {id: "Lajeado Grande", name: "SC - Lajeado Grande"},
            {id: "Laurentino", name: "SC - Laurentino"},
            {id: "Lauro Muller", name: "SC - Lauro Muller"},
            {id: "Lebon Régis", name: "SC - Lebon Régis"},
            {id: "Leoberto Leal", name: "SC - Leoberto Leal"},
            {id: "Lindóia do Sul", name: "SC - Lindóia do Sul"},
            {id: "Lontras", name: "SC - Lontras"},
            {id: "Luiz Alves", name: "SC - Luiz Alves"},
            {id: "Luzerna", name: "SC - Luzerna"},
            {id: "Macieira", name: "SC - Macieira"},
            {id: "Mafra", name: "SC - Mafra"},
            {id: "Major Gercino", name: "SC - Major Gercino"},
            {id: "Major Vieira", name: "SC - Major Vieira"},
            {id: "Maracajá", name: "SC - Maracajá"},
            {id: "Maravilha", name: "SC - Maravilha"},
            {id: "Marema", name: "SC - Marema"},
            {id: "Massaranduba", name: "SC - Massaranduba"},
            {id: "Matos Costa", name: "SC - Matos Costa"},
            {id: "Meleiro", name: "SC - Meleiro"},
            {id: "Mirim Doce", name: "SC - Mirim Doce"},
            {id: "Modelo", name: "SC - Modelo"},
            {id: "Mondaí", name: "SC - Mondaí"},
            {id: "Monte Carlo", name: "SC - Monte Carlo"},
            {id: "Monte Castelo", name: "SC - Monte Castelo"},
            {id: "Morro da Fumaça", name: "SC - Morro da Fumaça"},
            {id: "Morro Grande", name: "SC - Morro Grande"},
            {id: "Navegantes", name: "SC - Navegantes"},
            {id: "Nova Erechim", name: "SC - Nova Erechim"},
            {id: "Nova Itaberaba", name: "SC - Nova Itaberaba"},
            {id: "Nova Trento", name: "SC - Nova Trento"},
            {id: "Nova Veneza", name: "SC - Nova Veneza"},
            {id: "Novo Horizonte", name: "SC - Novo Horizonte"},
            {id: "Orleans", name: "SC - Orleans"},
            {id: "Otacílio Costa", name: "SC - Otacílio Costa"},
            {id: "Ouro", name: "SC - Ouro"},
            {id: "Ouro Verde", name: "SC - Ouro Verde"},
            {id: "Paial", name: "SC - Paial"},
            {id: "Painel", name: "SC - Painel"},
            {id: "Palhoça", name: "SC - Palhoça"},
            {id: "Palma Sola", name: "SC - Palma Sola"},
            {id: "Palmeira", name: "SC - Palmeira"},
            {id: "Palmitos", name: "SC - Palmitos"},
            {id: "Papanduva", name: "SC - Papanduva"},
            {id: "Paraíso", name: "SC - Paraíso"},
            {id: "Passo de Torres", name: "SC - Passo de Torres"},
            {id: "Passos Maia", name: "SC - Passos Maia"},
            {id: "Paulo Lopes", name: "SC - Paulo Lopes"},
            {id: "Pedras Grandes", name: "SC - Pedras Grandes"},
            {id: "Penha", name: "SC - Penha"},
            {id: "Peritiba", name: "SC - Peritiba"},
            {id: "Pescaria Brava", name: "SC - Pescaria Brava"},
            {id: "Petrolândia", name: "SC - Petrolândia"},
            {id: "Piçarras", name: "SC - Piçarras"},
            {id: "Pinhalzinho", name: "SC - Pinhalzinho"},
            {id: "Pinheiro Preto", name: "SC - Pinheiro Preto"},
            {id: "Piratuba", name: "SC - Piratuba"},
            {id: "Planalto Alegre", name: "SC - Planalto Alegre"},
            {id: "Pomerode", name: "SC - Pomerode"},
            {id: "Ponte Alta", name: "SC - Ponte Alta"},
            {id: "Ponte Alta do Norte", name: "SC - Ponte Alta do Norte"},
            {id: "Ponte Serrada", name: "SC - Ponte Serrada"},
            {id: "Porto Belo", name: "SC - Porto Belo"},
            {id: "Porto União", name: "SC - Porto União"},
            {id: "Pouso Redondo", name: "SC - Pouso Redondo"},
            {id: "Praia Grande", name: "SC - Praia Grande"},
            {id: "Presidente Castelo Branco", name: "SC - Presidente Castelo Branco"},
            {id: "Presidente Getúlio", name: "SC - Presidente Getúlio"},
            {id: "Presidente Nereu", name: "SC - Presidente Nereu"},
            {id: "Princesa", name: "SC - Princesa"},
            {id: "Quilombo", name: "SC - Quilombo"},
            {id: "Rancho Queimado", name: "SC - Rancho Queimado"},
            {id: "Rio Das Antas", name: "SC - Rio Das Antas"},
            {id: "Rio do Campo", name: "SC - Rio do Campo"},
            {id: "Rio do Oeste", name: "SC - Rio do Oeste"},
            {id: "Rio Dos Cedros", name: "SC - Rio Dos Cedros"},
            {id: "Rio do Sul", name: "SC - Rio do Sul"},
            {id: "Rio Fortuna", name: "SC - Rio Fortuna"},
            {id: "Rio Negrinho", name: "SC - Rio Negrinho"},
            {id: "Rio Rufino", name: "SC - Rio Rufino"},
            {id: "Riqueza", name: "SC - Riqueza"},
            {id: "Rodeio", name: "SC - Rodeio"},
            {id: "Romelândia", name: "SC - Romelândia"},
            {id: "Salete", name: "SC - Salete"},
            {id: "Saltinho", name: "SC - Saltinho"},
            {id: "Salto Veloso", name: "SC - Salto Veloso"},
            {id: "Sangão", name: "SC - Sangão"},
            {id: "Santa Cecília", name: "SC - Santa Cecília"},
            {id: "Santa Helena", name: "SC - Santa Helena"},
            {id: "Santa Rosa de Lima", name: "SC - Santa Rosa de Lima"},
            {id: "Santa Rosa do Sul", name: "SC - Santa Rosa do Sul"},
            {id: "Santa Terezinha", name: "SC - Santa Terezinha"},
            {id: "Santa Terezinha do Progresso", name: "SC - Santa Terezinha do Progresso"},
            {id: "Santiago do Sul", name: "SC - Santiago do Sul"},
            {id: "Santo Amaro da Imperatriz", name: "SC - Santo Amaro da Imperatriz"},
            {id: "São Bernardino", name: "SC - São Bernardino"},
            {id: "São Bento do Sul", name: "SC - São Bento do Sul"},
            {id: "São Bonifácio", name: "SC - São Bonifácio"},
            {id: "São Carlos", name: "SC - São Carlos"},
            {id: "São Cristovão do Sul", name: "SC - São Cristovão do Sul"},
            {id: "São Domingos", name: "SC - São Domingos"},
            {id: "São Francisco do Sul", name: "SC - São Francisco do Sul"},
            {id: "São João do Oeste", name: "SC - São João do Oeste"},
            {id: "São João Batista", name: "SC - São João Batista"},
            {id: "São João do Itaperiú", name: "SC - São João do Itaperiú"},
            {id: "São João do Sul", name: "SC - São João do Sul"},
            {id: "São Joaquim", name: "SC - São Joaquim"},
            {id: "São José", name: "SC - São José"},
            {id: "São José do Cedro", name: "SC - São José do Cedro"},
            {id: "São José do Cerrito", name: "SC - São José do Cerrito"},
            {id: "São Lourenço do Oeste", name: "SC - São Lourenço do Oeste"},
            {id: "São Ludgero", name: "SC - São Ludgero"},
            {id: "São Martinho", name: "SC - São Martinho"},
            {id: "São Miguel da Boa Vista", name: "SC - São Miguel da Boa Vista"},
            {id: "São Miguel do Oeste", name: "SC - São Miguel do Oeste"},
            {id: "São Pedro de Alcântara", name: "SC - São Pedro de Alcântara"},
            {id: "Saudades", name: "SC - Saudades"},
            {id: "Schroeder", name: "SC - Schroeder"},
            {id: "Seara", name: "SC - Seara"},
            {id: "Serra Alta", name: "SC - Serra Alta"},
            {id: "Siderópolis", name: "SC - Siderópolis"},
            {id: "Sombrio", name: "SC - Sombrio"},
            {id: "Sul Brasil", name: "SC - Sul Brasil"},
            {id: "Taió", name: "SC - Taió"},
            {id: "Tangará", name: "SC - Tangará"},
            {id: "Tigrinhos", name: "SC - Tigrinhos"},
            {id: "Tijucas", name: "SC - Tijucas"},
            {id: "Timbé do Sul", name: "SC - Timbé do Sul"},
            {id: "Timbó", name: "SC - Timbó"},
            {id: "Timbó Grande", name: "SC - Timbó Grande"},
            {id: "Três Barras", name: "SC - Três Barras"},
            {id: "Treviso", name: "SC - Treviso"},
            {id: "Treze de Maio", name: "SC - Treze de Maio"},
            {id: "Treze Tílias", name: "SC - Treze Tílias"},
            {id: "Trombudo Central", name: "SC - Trombudo Central"},
            {id: "Tubarão", name: "SC - Tubarão"},
            {id: "Tunápolis", name: "SC - Tunápolis"},
            {id: "Turvo", name: "SC - Turvo"},
            {id: "União do Oeste", name: "SC - União do Oeste"},
            {id: "Urubici", name: "SC - Urubici"},
            {id: "Urupema", name: "SC - Urupema"},
            {id: "Urussanga", name: "SC - Urussanga"},
            {id: "Vargeão", name: "SC - Vargeão"},
            {id: "Vargem", name: "SC - Vargem"},
            {id: "Vargem Bonita", name: "SC - Vargem Bonita"},
            {id: "Vidal Ramos", name: "SC - Vidal Ramos"},
            {id: "Videira", name: "SC - Videira"},
            {id: "Vitor Meireles", name: "SC - Vitor Meireles"},
            {id: "Witmarsum", name: "SC - Witmarsum"},
            {id: "Xanxerê", name: "SC - Xanxerê"},
            {id: "Xavantina", name: "SC - Xavantina"},
            {id: "Xaxim", name: "SC - Xaxim"},
            {id: "Zortéa", name: "SC - Zortéa"},
            {id: "Balneário Rincão", name: "SC - Balneário Rincão"},
            {id: "Aceguá", name: "RS - Aceguá"},
            {id: "Água Santa", name: "RS - Água Santa"},
            {id: "Agudo", name: "RS - Agudo"},
            {id: "Ajuricaba", name: "RS - Ajuricaba"},
            {id: "Alecrim", name: "RS - Alecrim"},
            {id: "Alegrete", name: "RS - Alegrete"},
            {id: "Alegria", name: "RS - Alegria"},
            {id: "Almirante Tamandaré do Sul", name: "RS - Almirante Tamandaré do Sul"},
            {id: "Alpestre", name: "RS - Alpestre"},
            {id: "Alto Alegre", name: "RS - Alto Alegre"},
            {id: "Alto Feliz", name: "RS - Alto Feliz"},
            {id: "Alvorada", name: "RS - Alvorada"},
            {id: "Amaral Ferrador", name: "RS - Amaral Ferrador"},
            {id: "Ametista do Sul", name: "RS - Ametista do Sul"},
            {id: "André da Rocha", name: "RS - André da Rocha"},
            {id: "Anta Gorda", name: "RS - Anta Gorda"},
            {id: "Antônio Prado", name: "RS - Antônio Prado"},
            {id: "Arambaré", name: "RS - Arambaré"},
            {id: "Araricá", name: "RS - Araricá"},
            {id: "Aratiba", name: "RS - Aratiba"},
            {id: "Arroio do Meio", name: "RS - Arroio do Meio"},
            {id: "Arroio do Sal", name: "RS - Arroio do Sal"},
            {id: "Arroio do Padre", name: "RS - Arroio do Padre"},
            {id: "Arroio Dos Ratos", name: "RS - Arroio Dos Ratos"},
            {id: "Arroio do Tigre", name: "RS - Arroio do Tigre"},
            {id: "Arroio Grande", name: "RS - Arroio Grande"},
            {id: "Arvorezinha", name: "RS - Arvorezinha"},
            {id: "Augusto Pestana", name: "RS - Augusto Pestana"},
            {id: "Áurea", name: "RS - Áurea"},
            {id: "Bagé", name: "RS - Bagé"},
            {id: "Balneário Pinhal", name: "RS - Balneário Pinhal"},
            {id: "Barão", name: "RS - Barão"},
            {id: "Barão de Cotegipe", name: "RS - Barão de Cotegipe"},
            {id: "Barão do Triunfo", name: "RS - Barão do Triunfo"},
            {id: "Barracão", name: "RS - Barracão"},
            {id: "Barra do Guarita", name: "RS - Barra do Guarita"},
            {id: "Barra do Quaraí", name: "RS - Barra do Quaraí"},
            {id: "Barra do Ribeiro", name: "RS - Barra do Ribeiro"},
            {id: "Barra do Rio Azul", name: "RS - Barra do Rio Azul"},
            {id: "Barra Funda", name: "RS - Barra Funda"},
            {id: "Barros Cassal", name: "RS - Barros Cassal"},
            {id: "Benjamin Constant do Sul", name: "RS - Benjamin Constant do Sul"},
            {id: "Bento Gonçalves", name: "RS - Bento Gonçalves"},
            {id: "Boa Vista Das Missões", name: "RS - Boa Vista Das Missões"},
            {id: "Boa Vista do Buricá", name: "RS - Boa Vista do Buricá"},
            {id: "Boa Vista do Cadeado", name: "RS - Boa Vista do Cadeado"},
            {id: "Boa Vista do Incra", name: "RS - Boa Vista do Incra"},
            {id: "Boa Vista do Sul", name: "RS - Boa Vista do Sul"},
            {id: "Bom Jesus", name: "RS - Bom Jesus"},
            {id: "Bom Princípio", name: "RS - Bom Princípio"},
            {id: "Bom Progresso", name: "RS - Bom Progresso"},
            {id: "Bom Retiro do Sul", name: "RS - Bom Retiro do Sul"},
            {id: "Boqueirão do Leão", name: "RS - Boqueirão do Leão"},
            {id: "Bossoroca", name: "RS - Bossoroca"},
            {id: "Bozano", name: "RS - Bozano"},
            {id: "Braga", name: "RS - Braga"},
            {id: "Brochier", name: "RS - Brochier"},
            {id: "Butiá", name: "RS - Butiá"},
            {id: "Caçapava do Sul", name: "RS - Caçapava do Sul"},
            {id: "Cacequi", name: "RS - Cacequi"},
            {id: "Cachoeira do Sul", name: "RS - Cachoeira do Sul"},
            {id: "Cachoeirinha", name: "RS - Cachoeirinha"},
            {id: "Cacique Doble", name: "RS - Cacique Doble"},
            {id: "Caibaté", name: "RS - Caibaté"},
            {id: "Caiçara", name: "RS - Caiçara"},
            {id: "Camaquã", name: "RS - Camaquã"},
            {id: "Camargo", name: "RS - Camargo"},
            {id: "Cambará do Sul", name: "RS - Cambará do Sul"},
            {id: "Campestre da Serra", name: "RS - Campestre da Serra"},
            {id: "Campina Das Missões", name: "RS - Campina Das Missões"},
            {id: "Campinas do Sul", name: "RS - Campinas do Sul"},
            {id: "Campo Bom", name: "RS - Campo Bom"},
            {id: "Campo Novo", name: "RS - Campo Novo"},
            {id: "Campos Borges", name: "RS - Campos Borges"},
            {id: "Candelária", name: "RS - Candelária"},
            {id: "Cândido Godói", name: "RS - Cândido Godói"},
            {id: "Candiota", name: "RS - Candiota"},
            {id: "Canela", name: "RS - Canela"},
            {id: "Canguçu", name: "RS - Canguçu"},
            {id: "Canoas", name: "RS - Canoas"},
            {id: "Canudos do Vale", name: "RS - Canudos do Vale"},
            {id: "Capão Bonito do Sul", name: "RS - Capão Bonito do Sul"},
            {id: "Capão da Canoa", name: "RS - Capão da Canoa"},
            {id: "Capão do Cipó", name: "RS - Capão do Cipó"},
            {id: "Capão do Leão", name: "RS - Capão do Leão"},
            {id: "Capivari do Sul", name: "RS - Capivari do Sul"},
            {id: "Capela de Santana", name: "RS - Capela de Santana"},
            {id: "Capitão", name: "RS - Capitão"},
            {id: "Carazinho", name: "RS - Carazinho"},
            {id: "Caraá", name: "RS - Caraá"},
            {id: "Carlos Barbosa", name: "RS - Carlos Barbosa"},
            {id: "Carlos Gomes", name: "RS - Carlos Gomes"},
            {id: "Casca", name: "RS - Casca"},
            {id: "Caseiros", name: "RS - Caseiros"},
            {id: "Catuípe", name: "RS - Catuípe"},
            {id: "Caxias do Sul", name: "RS - Caxias do Sul"},
            {id: "Centenário", name: "RS - Centenário"},
            {id: "Cerrito", name: "RS - Cerrito"},
            {id: "Cerro Branco", name: "RS - Cerro Branco"},
            {id: "Cerro Grande", name: "RS - Cerro Grande"},
            {id: "Cerro Grande do Sul", name: "RS - Cerro Grande do Sul"},
            {id: "Cerro Largo", name: "RS - Cerro Largo"},
            {id: "Chapada", name: "RS - Chapada"},
            {id: "Charqueadas", name: "RS - Charqueadas"},
            {id: "Charrua", name: "RS - Charrua"},
            {id: "Chiapetta", name: "RS - Chiapetta"},
            {id: "Chuí", name: "RS - Chuí"},
            {id: "Chuvisca", name: "RS - Chuvisca"},
            {id: "Cidreira", name: "RS - Cidreira"},
            {id: "Ciríaco", name: "RS - Ciríaco"},
            {id: "Colinas", name: "RS - Colinas"},
            {id: "Colorado", name: "RS - Colorado"},
            {id: "Condor", name: "RS - Condor"},
            {id: "Constantina", name: "RS - Constantina"},
            {id: "Coqueiro Baixo", name: "RS - Coqueiro Baixo"},
            {id: "Coqueiros do Sul", name: "RS - Coqueiros do Sul"},
            {id: "Coronel Barros", name: "RS - Coronel Barros"},
            {id: "Coronel Bicaco", name: "RS - Coronel Bicaco"},
            {id: "Coronel Pilar", name: "RS - Coronel Pilar"},
            {id: "Cotiporã", name: "RS - Cotiporã"},
            {id: "Coxilha", name: "RS - Coxilha"},
            {id: "Crissiumal", name: "RS - Crissiumal"},
            {id: "Cristal", name: "RS - Cristal"},
            {id: "Cristal do Sul", name: "RS - Cristal do Sul"},
            {id: "Cruz Alta", name: "RS - Cruz Alta"},
            {id: "Cruzaltense", name: "RS - Cruzaltense"},
            {id: "Cruzeiro do Sul", name: "RS - Cruzeiro do Sul"},
            {id: "David Canabarro", name: "RS - David Canabarro"},
            {id: "Derrubadas", name: "RS - Derrubadas"},
            {id: "Dezesseis de Novembro", name: "RS - Dezesseis de Novembro"},
            {id: "Dilermando de Aguiar", name: "RS - Dilermando de Aguiar"},
            {id: "Dois Irmãos", name: "RS - Dois Irmãos"},
            {id: "Dois Irmãos Das Missões", name: "RS - Dois Irmãos Das Missões"},
            {id: "Dois Lajeados", name: "RS - Dois Lajeados"},
            {id: "Dom Feliciano", name: "RS - Dom Feliciano"},
            {id: "Dom Pedro de Alcântara", name: "RS - Dom Pedro de Alcântara"},
            {id: "Dom Pedrito", name: "RS - Dom Pedrito"},
            {id: "Dona Francisca", name: "RS - Dona Francisca"},
            {id: "Doutor Maurício Cardoso", name: "RS - Doutor Maurício Cardoso"},
            {id: "Doutor Ricardo", name: "RS - Doutor Ricardo"},
            {id: "Eldorado do Sul", name: "RS - Eldorado do Sul"},
            {id: "Encantado", name: "RS - Encantado"},
            {id: "Encruzilhada do Sul", name: "RS - Encruzilhada do Sul"},
            {id: "Engenho Velho", name: "RS - Engenho Velho"},
            {id: "Entre-ijuís", name: "RS - Entre-ijuís"},
            {id: "Entre Rios do Sul", name: "RS - Entre Rios do Sul"},
            {id: "Erebango", name: "RS - Erebango"},
            {id: "Erechim", name: "RS - Erechim"},
            {id: "Ernestina", name: "RS - Ernestina"},
            {id: "Herval", name: "RS - Herval"},
            {id: "Erval Grande", name: "RS - Erval Grande"},
            {id: "Erval Seco", name: "RS - Erval Seco"},
            {id: "Esmeralda", name: "RS - Esmeralda"},
            {id: "Esperança do Sul", name: "RS - Esperança do Sul"},
            {id: "Espumoso", name: "RS - Espumoso"},
            {id: "Estação", name: "RS - Estação"},
            {id: "Estância Velha", name: "RS - Estância Velha"},
            {id: "Esteio", name: "RS - Esteio"},
            {id: "Estrela", name: "RS - Estrela"},
            {id: "Estrela Velha", name: "RS - Estrela Velha"},
            {id: "Eugênio de Castro", name: "RS - Eugênio de Castro"},
            {id: "Fagundes Varela", name: "RS - Fagundes Varela"},
            {id: "Farroupilha", name: "RS - Farroupilha"},
            {id: "Faxinal do Soturno", name: "RS - Faxinal do Soturno"},
            {id: "Faxinalzinho", name: "RS - Faxinalzinho"},
            {id: "Fazenda Vilanova", name: "RS - Fazenda Vilanova"},
            {id: "Feliz", name: "RS - Feliz"},
            {id: "Flores da Cunha", name: "RS - Flores da Cunha"},
            {id: "Floriano Peixoto", name: "RS - Floriano Peixoto"},
            {id: "Fontoura Xavier", name: "RS - Fontoura Xavier"},
            {id: "Formigueiro", name: "RS - Formigueiro"},
            {id: "Forquetinha", name: "RS - Forquetinha"},
            {id: "Fortaleza Dos Valos", name: "RS - Fortaleza Dos Valos"},
            {id: "Frederico Westphalen", name: "RS - Frederico Westphalen"},
            {id: "Garibaldi", name: "RS - Garibaldi"},
            {id: "Garruchos", name: "RS - Garruchos"},
            {id: "Gaurama", name: "RS - Gaurama"},
            {id: "General Câmara", name: "RS - General Câmara"},
            {id: "Gentil", name: "RS - Gentil"},
            {id: "Getúlio Vargas", name: "RS - Getúlio Vargas"},
            {id: "Giruá", name: "RS - Giruá"},
            {id: "Glorinha", name: "RS - Glorinha"},
            {id: "Gramado", name: "RS - Gramado"},
            {id: "Gramado Dos Loureiros", name: "RS - Gramado Dos Loureiros"},
            {id: "Gramado Xavier", name: "RS - Gramado Xavier"},
            {id: "Gravataí", name: "RS - Gravataí"},
            {id: "Guabiju", name: "RS - Guabiju"},
            {id: "Guaíba", name: "RS - Guaíba"},
            {id: "Guaporé", name: "RS - Guaporé"},
            {id: "Guarani Das Missões", name: "RS - Guarani Das Missões"},
            {id: "Harmonia", name: "RS - Harmonia"},
            {id: "Herveiras", name: "RS - Herveiras"},
            {id: "Horizontina", name: "RS - Horizontina"},
            {id: "Hulha Negra", name: "RS - Hulha Negra"},
            {id: "Humaitá", name: "RS - Humaitá"},
            {id: "Ibarama", name: "RS - Ibarama"},
            {id: "Ibiaçá", name: "RS - Ibiaçá"},
            {id: "Ibiraiaras", name: "RS - Ibiraiaras"},
            {id: "Ibirapuitã", name: "RS - Ibirapuitã"},
            {id: "Ibirubá", name: "RS - Ibirubá"},
            {id: "Igrejinha", name: "RS - Igrejinha"},
            {id: "Ijuí", name: "RS - Ijuí"},
            {id: "Ilópolis", name: "RS - Ilópolis"},
            {id: "Imbé", name: "RS - Imbé"},
            {id: "Imigrante", name: "RS - Imigrante"},
            {id: "Independência", name: "RS - Independência"},
            {id: "Inhacorá", name: "RS - Inhacorá"},
            {id: "Ipê", name: "RS - Ipê"},
            {id: "Ipiranga do Sul", name: "RS - Ipiranga do Sul"},
            {id: "Iraí", name: "RS - Iraí"},
            {id: "Itaara", name: "RS - Itaara"},
            {id: "Itacurubi", name: "RS - Itacurubi"},
            {id: "Itapuca", name: "RS - Itapuca"},
            {id: "Itaqui", name: "RS - Itaqui"},
            {id: "Itati", name: "RS - Itati"},
            {id: "Itatiba do Sul", name: "RS - Itatiba do Sul"},
            {id: "Ivorá", name: "RS - Ivorá"},
            {id: "Ivoti", name: "RS - Ivoti"},
            {id: "Jaboticaba", name: "RS - Jaboticaba"},
            {id: "Jacuizinho", name: "RS - Jacuizinho"},
            {id: "Jacutinga", name: "RS - Jacutinga"},
            {id: "Jaguarão", name: "RS - Jaguarão"},
            {id: "Jaguari", name: "RS - Jaguari"},
            {id: "Jaquirana", name: "RS - Jaquirana"},
            {id: "Jari", name: "RS - Jari"},
            {id: "Jóia", name: "RS - Jóia"},
            {id: "Júlio de Castilhos", name: "RS - Júlio de Castilhos"},
            {id: "Lagoa Bonita do Sul", name: "RS - Lagoa Bonita do Sul"},
            {id: "Lagoão", name: "RS - Lagoão"},
            {id: "Lagoa Dos Três Cantos", name: "RS - Lagoa Dos Três Cantos"},
            {id: "Lagoa Vermelha", name: "RS - Lagoa Vermelha"},
            {id: "Lajeado", name: "RS - Lajeado"},
            {id: "Lajeado do Bugre", name: "RS - Lajeado do Bugre"},
            {id: "Lavras do Sul", name: "RS - Lavras do Sul"},
            {id: "Liberato Salzano", name: "RS - Liberato Salzano"},
            {id: "Lindolfo Collor", name: "RS - Lindolfo Collor"},
            {id: "Linha Nova", name: "RS - Linha Nova"},
            {id: "Machadinho", name: "RS - Machadinho"},
            {id: "Maçambara", name: "RS - Maçambara"},
            {id: "Mampituba", name: "RS - Mampituba"},
            {id: "Manoel Viana", name: "RS - Manoel Viana"},
            {id: "Maquiné", name: "RS - Maquiné"},
            {id: "Maratá", name: "RS - Maratá"},
            {id: "Marau", name: "RS - Marau"},
            {id: "Marcelino Ramos", name: "RS - Marcelino Ramos"},
            {id: "Mariana Pimentel", name: "RS - Mariana Pimentel"},
            {id: "Mariano Moro", name: "RS - Mariano Moro"},
            {id: "Marques de Souza", name: "RS - Marques de Souza"},
            {id: "Mata", name: "RS - Mata"},
            {id: "Mato Castelhano", name: "RS - Mato Castelhano"},
            {id: "Mato Leitão", name: "RS - Mato Leitão"},
            {id: "Mato Queimado", name: "RS - Mato Queimado"},
            {id: "Maximiliano de Almeida", name: "RS - Maximiliano de Almeida"},
            {id: "Minas do Leão", name: "RS - Minas do Leão"},
            {id: "Miraguaí", name: "RS - Miraguaí"},
            {id: "Montauri", name: "RS - Montauri"},
            {id: "Monte Alegre Dos Campos", name: "RS - Monte Alegre Dos Campos"},
            {id: "Monte Belo do Sul", name: "RS - Monte Belo do Sul"},
            {id: "Montenegro", name: "RS - Montenegro"},
            {id: "Mormaço", name: "RS - Mormaço"},
            {id: "Morrinhos do Sul", name: "RS - Morrinhos do Sul"},
            {id: "Morro Redondo", name: "RS - Morro Redondo"},
            {id: "Morro Reuter", name: "RS - Morro Reuter"},
            {id: "Mostardas", name: "RS - Mostardas"},
            {id: "Muçum", name: "RS - Muçum"},
            {id: "Muitos Capões", name: "RS - Muitos Capões"},
            {id: "Muliterno", name: "RS - Muliterno"},
            {id: "Não-me-toque", name: "RS - Não-me-toque"},
            {id: "Nicolau Vergueiro", name: "RS - Nicolau Vergueiro"},
            {id: "Nonoai", name: "RS - Nonoai"},
            {id: "Nova Alvorada", name: "RS - Nova Alvorada"},
            {id: "Nova Araçá", name: "RS - Nova Araçá"},
            {id: "Nova Bassano", name: "RS - Nova Bassano"},
            {id: "Nova Boa Vista", name: "RS - Nova Boa Vista"},
            {id: "Nova Bréscia", name: "RS - Nova Bréscia"},
            {id: "Nova Candelária", name: "RS - Nova Candelária"},
            {id: "Nova Esperança do Sul", name: "RS - Nova Esperança do Sul"},
            {id: "Nova Hartz", name: "RS - Nova Hartz"},
            {id: "Nova Pádua", name: "RS - Nova Pádua"},
            {id: "Nova Palma", name: "RS - Nova Palma"},
            {id: "Nova Petrópolis", name: "RS - Nova Petrópolis"},
            {id: "Nova Prata", name: "RS - Nova Prata"},
            {id: "Nova Ramada", name: "RS - Nova Ramada"},
            {id: "Nova Roma do Sul", name: "RS - Nova Roma do Sul"},
            {id: "Nova Santa Rita", name: "RS - Nova Santa Rita"},
            {id: "Novo Cabrais", name: "RS - Novo Cabrais"},
            {id: "Novo Hamburgo", name: "RS - Novo Hamburgo"},
            {id: "Novo Machado", name: "RS - Novo Machado"},
            {id: "Novo Tiradentes", name: "RS - Novo Tiradentes"},
            {id: "Novo Xingu", name: "RS - Novo Xingu"},
            {id: "Novo Barreiro", name: "RS - Novo Barreiro"},
            {id: "Osório", name: "RS - Osório"},
            {id: "Paim Filho", name: "RS - Paim Filho"},
            {id: "Palmares do Sul", name: "RS - Palmares do Sul"},
            {id: "Palmeira Das Missões", name: "RS - Palmeira Das Missões"},
            {id: "Palmitinho", name: "RS - Palmitinho"},
            {id: "Panambi", name: "RS - Panambi"},
            {id: "Pantano Grande", name: "RS - Pantano Grande"},
            {id: "Paraí", name: "RS - Paraí"},
            {id: "Paraíso do Sul", name: "RS - Paraíso do Sul"},
            {id: "Pareci Novo", name: "RS - Pareci Novo"},
            {id: "Parobé", name: "RS - Parobé"},
            {id: "Passa Sete", name: "RS - Passa Sete"},
            {id: "Passo do Sobrado", name: "RS - Passo do Sobrado"},
            {id: "Passo Fundo", name: "RS - Passo Fundo"},
            {id: "Paulo Bento", name: "RS - Paulo Bento"},
            {id: "Paverama", name: "RS - Paverama"},
            {id: "Pedras Altas", name: "RS - Pedras Altas"},
            {id: "Pedro Osório", name: "RS - Pedro Osório"},
            {id: "Pejuçara", name: "RS - Pejuçara"},
            {id: "Pelotas", name: "RS - Pelotas"},
            {id: "Picada Café", name: "RS - Picada Café"},
            {id: "Pinhal", name: "RS - Pinhal"},
            {id: "Pinhal da Serra", name: "RS - Pinhal da Serra"},
            {id: "Pinhal Grande", name: "RS - Pinhal Grande"},
            {id: "Pinheirinho do Vale", name: "RS - Pinheirinho do Vale"},
            {id: "Pinheiro Machado", name: "RS - Pinheiro Machado"},
            {id: "Pinto Bandeira", name: "RS - Pinto Bandeira"},
            {id: "Pirapó", name: "RS - Pirapó"},
            {id: "Piratini", name: "RS - Piratini"},
            {id: "Planalto", name: "RS - Planalto"},
            {id: "Poço Das Antas", name: "RS - Poço Das Antas"},
            {id: "Pontão", name: "RS - Pontão"},
            {id: "Ponte Preta", name: "RS - Ponte Preta"},
            {id: "Portão", name: "RS - Portão"},
            {id: "Porto Alegre", name: "RS - Porto Alegre"},
            {id: "Porto Lucena", name: "RS - Porto Lucena"},
            {id: "Porto Mauá", name: "RS - Porto Mauá"},
            {id: "Porto Vera Cruz", name: "RS - Porto Vera Cruz"},
            {id: "Porto Xavier", name: "RS - Porto Xavier"},
            {id: "Pouso Novo", name: "RS - Pouso Novo"},
            {id: "Presidente Lucena", name: "RS - Presidente Lucena"},
            {id: "Progresso", name: "RS - Progresso"},
            {id: "Protásio Alves", name: "RS - Protásio Alves"},
            {id: "Putinga", name: "RS - Putinga"},
            {id: "Quaraí", name: "RS - Quaraí"},
            {id: "Quatro Irmãos", name: "RS - Quatro Irmãos"},
            {id: "Quevedos", name: "RS - Quevedos"},
            {id: "Quinze de Novembro", name: "RS - Quinze de Novembro"},
            {id: "Redentora", name: "RS - Redentora"},
            {id: "Relvado", name: "RS - Relvado"},
            {id: "Restinga Seca", name: "RS - Restinga Seca"},
            {id: "Rio Dos Índios", name: "RS - Rio Dos Índios"},
            {id: "Rio Grande", name: "RS - Rio Grande"},
            {id: "Rio Pardo", name: "RS - Rio Pardo"},
            {id: "Riozinho", name: "RS - Riozinho"},
            {id: "Roca Sales", name: "RS - Roca Sales"},
            {id: "Rodeio Bonito", name: "RS - Rodeio Bonito"},
            {id: "Rolador", name: "RS - Rolador"},
            {id: "Rolante", name: "RS - Rolante"},
            {id: "Ronda Alta", name: "RS - Ronda Alta"},
            {id: "Rondinha", name: "RS - Rondinha"},
            {id: "Roque Gonzales", name: "RS - Roque Gonzales"},
            {id: "Rosário do Sul", name: "RS - Rosário do Sul"},
            {id: "Sagrada Família", name: "RS - Sagrada Família"},
            {id: "Saldanha Marinho", name: "RS - Saldanha Marinho"},
            {id: "Salto do Jacuí", name: "RS - Salto do Jacuí"},
            {id: "Salvador Das Missões", name: "RS - Salvador Das Missões"},
            {id: "Salvador do Sul", name: "RS - Salvador do Sul"},
            {id: "Sananduva", name: "RS - Sananduva"},
            {id: "Santa Bárbara do Sul", name: "RS - Santa Bárbara do Sul"},
            {id: "Santa Cecília do Sul", name: "RS - Santa Cecília do Sul"},
            {id: "Santa Clara do Sul", name: "RS - Santa Clara do Sul"},
            {id: "Santa Cruz do Sul", name: "RS - Santa Cruz do Sul"},
            {id: "Santa Maria", name: "RS - Santa Maria"},
            {id: "Santa Maria do Herval", name: "RS - Santa Maria do Herval"},
            {id: "Santa Margarida do Sul", name: "RS - Santa Margarida do Sul"},
            {id: "Santana da Boa Vista", name: "RS - Santana da Boa Vista"},
            {id: "Santana do Livramento", name: "RS - Santana do Livramento"},
            {id: "Santa Rosa", name: "RS - Santa Rosa"},
            {id: "Santa Tereza", name: "RS - Santa Tereza"},
            {id: "Santa Vitória do Palmar", name: "RS - Santa Vitória do Palmar"},
            {id: "Santiago", name: "RS - Santiago"},
            {id: "Santo Ângelo", name: "RS - Santo Ângelo"},
            {id: "Santo Antônio do Palma", name: "RS - Santo Antônio do Palma"},
            {id: "Santo Antônio da Patrulha", name: "RS - Santo Antônio da Patrulha"},
            {id: "Santo Antônio Das Missões", name: "RS - Santo Antônio Das Missões"},
            {id: "Santo Antônio do Planalto", name: "RS - Santo Antônio do Planalto"},
            {id: "Santo Augusto", name: "RS - Santo Augusto"},
            {id: "Santo Cristo", name: "RS - Santo Cristo"},
            {id: "Santo Expedito do Sul", name: "RS - Santo Expedito do Sul"},
            {id: "São Borja", name: "RS - São Borja"},
            {id: "São Domingos do Sul", name: "RS - São Domingos do Sul"},
            {id: "São Francisco de Assis", name: "RS - São Francisco de Assis"},
            {id: "São Francisco de Paula", name: "RS - São Francisco de Paula"},
            {id: "São Gabriel", name: "RS - São Gabriel"},
            {id: "São Jerônimo", name: "RS - São Jerônimo"},
            {id: "São João da Urtiga", name: "RS - São João da Urtiga"},
            {id: "São João do Polêsine", name: "RS - São João do Polêsine"},
            {id: "São Jorge", name: "RS - São Jorge"},
            {id: "São José Das Missões", name: "RS - São José Das Missões"},
            {id: "São José do Herval", name: "RS - São José do Herval"},
            {id: "São José do Hortêncio", name: "RS - São José do Hortêncio"},
            {id: "São José do Inhacorá", name: "RS - São José do Inhacorá"},
            {id: "São José do Norte", name: "RS - São José do Norte"},
            {id: "São José do Ouro", name: "RS - São José do Ouro"},
            {id: "São José do Sul", name: "RS - São José do Sul"},
            {id: "São José Dos Ausentes", name: "RS - São José Dos Ausentes"},
            {id: "São Leopoldo", name: "RS - São Leopoldo"},
            {id: "São Lourenço do Sul", name: "RS - São Lourenço do Sul"},
            {id: "São Luiz Gonzaga", name: "RS - São Luiz Gonzaga"},
            {id: "São Marcos", name: "RS - São Marcos"},
            {id: "São Martinho", name: "RS - São Martinho"},
            {id: "São Martinho da Serra", name: "RS - São Martinho da Serra"},
            {id: "São Miguel Das Missões", name: "RS - São Miguel Das Missões"},
            {id: "São Nicolau", name: "RS - São Nicolau"},
            {id: "São Paulo Das Missões", name: "RS - São Paulo Das Missões"},
            {id: "São Pedro da Serra", name: "RS - São Pedro da Serra"},
            {id: "São Pedro Das Missões", name: "RS - São Pedro Das Missões"},
            {id: "São Pedro do Butiá", name: "RS - São Pedro do Butiá"},
            {id: "São Pedro do Sul", name: "RS - São Pedro do Sul"},
            {id: "São Sebastião do Caí", name: "RS - São Sebastião do Caí"},
            {id: "São Sepé", name: "RS - São Sepé"},
            {id: "São Valentim", name: "RS - São Valentim"},
            {id: "São Valentim do Sul", name: "RS - São Valentim do Sul"},
            {id: "São Valério do Sul", name: "RS - São Valério do Sul"},
            {id: "São Vendelino", name: "RS - São Vendelino"},
            {id: "São Vicente do Sul", name: "RS - São Vicente do Sul"},
            {id: "Sapiranga", name: "RS - Sapiranga"},
            {id: "Sapucaia do Sul", name: "RS - Sapucaia do Sul"},
            {id: "Sarandi", name: "RS - Sarandi"},
            {id: "Seberi", name: "RS - Seberi"},
            {id: "Sede Nova", name: "RS - Sede Nova"},
            {id: "Segredo", name: "RS - Segredo"},
            {id: "Selbach", name: "RS - Selbach"},
            {id: "Senador Salgado Filho", name: "RS - Senador Salgado Filho"},
            {id: "Sentinela do Sul", name: "RS - Sentinela do Sul"},
            {id: "Serafina Corrêa", name: "RS - Serafina Corrêa"},
            {id: "Sério", name: "RS - Sério"},
            {id: "Sertão", name: "RS - Sertão"},
            {id: "Sertão Santana", name: "RS - Sertão Santana"},
            {id: "Sete de Setembro", name: "RS - Sete de Setembro"},
            {id: "Severiano de Almeida", name: "RS - Severiano de Almeida"},
            {id: "Silveira Martins", name: "RS - Silveira Martins"},
            {id: "Sinimbu", name: "RS - Sinimbu"},
            {id: "Sobradinho", name: "RS - Sobradinho"},
            {id: "Soledade", name: "RS - Soledade"},
            {id: "Tabaí", name: "RS - Tabaí"},
            {id: "Tapejara", name: "RS - Tapejara"},
            {id: "Tapera", name: "RS - Tapera"},
            {id: "Tapes", name: "RS - Tapes"},
            {id: "Taquara", name: "RS - Taquara"},
            {id: "Taquari", name: "RS - Taquari"},
            {id: "Taquaruçu do Sul", name: "RS - Taquaruçu do Sul"},
            {id: "Tavares", name: "RS - Tavares"},
            {id: "Tenente Portela", name: "RS - Tenente Portela"},
            {id: "Terra de Areia", name: "RS - Terra de Areia"},
            {id: "Teutônia", name: "RS - Teutônia"},
            {id: "Tio Hugo", name: "RS - Tio Hugo"},
            {id: "Tiradentes do Sul", name: "RS - Tiradentes do Sul"},
            {id: "Toropi", name: "RS - Toropi"},
            {id: "Torres", name: "RS - Torres"},
            {id: "Tramandaí", name: "RS - Tramandaí"},
            {id: "Travesseiro", name: "RS - Travesseiro"},
            {id: "Três Arroios", name: "RS - Três Arroios"},
            {id: "Três Cachoeiras", name: "RS - Três Cachoeiras"},
            {id: "Três Coroas", name: "RS - Três Coroas"},
            {id: "Três de Maio", name: "RS - Três de Maio"},
            {id: "Três Forquilhas", name: "RS - Três Forquilhas"},
            {id: "Três Palmeiras", name: "RS - Três Palmeiras"},
            {id: "Três Passos", name: "RS - Três Passos"},
            {id: "Trindade do Sul", name: "RS - Trindade do Sul"},
            {id: "Triunfo", name: "RS - Triunfo"},
            {id: "Tucunduva", name: "RS - Tucunduva"},
            {id: "Tunas", name: "RS - Tunas"},
            {id: "Tupanci do Sul", name: "RS - Tupanci do Sul"},
            {id: "Tupanciretã", name: "RS - Tupanciretã"},
            {id: "Tupandi", name: "RS - Tupandi"},
            {id: "Tuparendi", name: "RS - Tuparendi"},
            {id: "Turuçu", name: "RS - Turuçu"},
            {id: "Ubiretama", name: "RS - Ubiretama"},
            {id: "União da Serra", name: "RS - União da Serra"},
            {id: "Unistalda", name: "RS - Unistalda"},
            {id: "Uruguaiana", name: "RS - Uruguaiana"},
            {id: "Vacaria", name: "RS - Vacaria"},
            {id: "Vale Verde", name: "RS - Vale Verde"},
            {id: "Vale do Sol", name: "RS - Vale do Sol"},
            {id: "Vale Real", name: "RS - Vale Real"},
            {id: "Vanini", name: "RS - Vanini"},
            {id: "Venâncio Aires", name: "RS - Venâncio Aires"},
            {id: "Vera Cruz", name: "RS - Vera Cruz"},
            {id: "Veranópolis", name: "RS - Veranópolis"},
            {id: "Vespasiano Correa", name: "RS - Vespasiano Correa"},
            {id: "Viadutos", name: "RS - Viadutos"},
            {id: "Viamão", name: "RS - Viamão"},
            {id: "Vicente Dutra", name: "RS - Vicente Dutra"},
            {id: "Victor Graeff", name: "RS - Victor Graeff"},
            {id: "Vila Flores", name: "RS - Vila Flores"},
            {id: "Vila Lângaro", name: "RS - Vila Lângaro"},
            {id: "Vila Maria", name: "RS - Vila Maria"},
            {id: "Vila Nova do Sul", name: "RS - Vila Nova do Sul"},
            {id: "Vista Alegre", name: "RS - Vista Alegre"},
            {id: "Vista Alegre do Prata", name: "RS - Vista Alegre do Prata"},
            {id: "Vista Gaúcha", name: "RS - Vista Gaúcha"},
            {id: "Vitória Das Missões", name: "RS - Vitória Das Missões"},
            {id: "Westfalia", name: "RS - Westfalia"},
            {id: "Xangri-lá", name: "RS - Xangri-lá"},
            {id: "Água Clara", name: "MS - Água Clara"},
            {id: "Alcinópolis", name: "MS - Alcinópolis"},
            {id: "Amambaí", name: "MS - Amambaí"},
            {id: "Anastácio", name: "MS - Anastácio"},
            {id: "Anaurilândia", name: "MS - Anaurilândia"},
            {id: "Angélica", name: "MS - Angélica"},
            {id: "Antônio João", name: "MS - Antônio João"},
            {id: "Aparecida do Taboado", name: "MS - Aparecida do Taboado"},
            {id: "Aquidauana", name: "MS - Aquidauana"},
            {id: "Aral Moreira", name: "MS - Aral Moreira"},
            {id: "Bandeirantes", name: "MS - Bandeirantes"},
            {id: "Bataguassu", name: "MS - Bataguassu"},
            {id: "Batayporã", name: "MS - Batayporã"},
            {id: "Bela Vista", name: "MS - Bela Vista"},
            {id: "Bodoquena", name: "MS - Bodoquena"},
            {id: "Bonito", name: "MS - Bonito"},
            {id: "Brasilândia", name: "MS - Brasilândia"},
            {id: "Caarapó", name: "MS - Caarapó"},
            {id: "Camapuã", name: "MS - Camapuã"},
            {id: "Campo Grande", name: "MS - Campo Grande"},
            {id: "Caracol", name: "MS - Caracol"},
            {id: "Cassilândia", name: "MS - Cassilândia"},
            {id: "Chapadão do Sul", name: "MS - Chapadão do Sul"},
            {id: "Corguinho", name: "MS - Corguinho"},
            {id: "Coronel Sapucaia", name: "MS - Coronel Sapucaia"},
            {id: "Corumbá", name: "MS - Corumbá"},
            {id: "Costa Rica", name: "MS - Costa Rica"},
            {id: "Coxim", name: "MS - Coxim"},
            {id: "Deodápolis", name: "MS - Deodápolis"},
            {id: "Dois Irmãos do Buriti", name: "MS - Dois Irmãos do Buriti"},
            {id: "Douradina", name: "MS - Douradina"},
            {id: "Dourados", name: "MS - Dourados"},
            {id: "Eldorado", name: "MS - Eldorado"},
            {id: "Fátima do Sul", name: "MS - Fátima do Sul"},
            {id: "Figueirão", name: "MS - Figueirão"},
            {id: "Glória de Dourados", name: "MS - Glória de Dourados"},
            {id: "Guia Lopes da Laguna", name: "MS - Guia Lopes da Laguna"},
            {id: "Iguatemi", name: "MS - Iguatemi"},
            {id: "Inocência", name: "MS - Inocência"},
            {id: "Itaporã", name: "MS - Itaporã"},
            {id: "Itaquiraí", name: "MS - Itaquiraí"},
            {id: "Ivinhema", name: "MS - Ivinhema"},
            {id: "Japorã", name: "MS - Japorã"},
            {id: "Jaraguari", name: "MS - Jaraguari"},
            {id: "Jardim", name: "MS - Jardim"},
            {id: "Jateí", name: "MS - Jateí"},
            {id: "Juti", name: "MS - Juti"},
            {id: "Ladário", name: "MS - Ladário"},
            {id: "Laguna Carapã", name: "MS - Laguna Carapã"},
            {id: "Maracaju", name: "MS - Maracaju"},
            {id: "Miranda", name: "MS - Miranda"},
            {id: "Mundo Novo", name: "MS - Mundo Novo"},
            {id: "Naviraí", name: "MS - Naviraí"},
            {id: "Nioaque", name: "MS - Nioaque"},
            {id: "Nova Alvorada do Sul", name: "MS - Nova Alvorada do Sul"},
            {id: "Nova Andradina", name: "MS - Nova Andradina"},
            {id: "Novo Horizonte do Sul", name: "MS - Novo Horizonte do Sul"},
            {id: "Paraíso das Águas", name: "MS - Paraíso das Águas"},
            {id: "Paranaíba", name: "MS - Paranaíba"},
            {id: "Paranhos", name: "MS - Paranhos"},
            {id: "Pedro Gomes", name: "MS - Pedro Gomes"},
            {id: "Ponta Porã", name: "MS - Ponta Porã"},
            {id: "Porto Murtinho", name: "MS - Porto Murtinho"},
            {id: "Ribas do Rio Pardo", name: "MS - Ribas do Rio Pardo"},
            {id: "Rio Brilhante", name: "MS - Rio Brilhante"},
            {id: "Rio Negro", name: "MS - Rio Negro"},
            {id: "Rio Verde de Mato Grosso", name: "MS - Rio Verde de Mato Grosso"},
            {id: "Rochedo", name: "MS - Rochedo"},
            {id: "Santa Rita do Pardo", name: "MS - Santa Rita do Pardo"},
            {id: "São Gabriel do Oeste", name: "MS - São Gabriel do Oeste"},
            {id: "Sete Quedas", name: "MS - Sete Quedas"},
            {id: "Selvíria", name: "MS - Selvíria"},
            {id: "Sidrolândia", name: "MS - Sidrolândia"},
            {id: "Sonora", name: "MS - Sonora"},
            {id: "Tacuru", name: "MS - Tacuru"},
            {id: "Taquarussu", name: "MS - Taquarussu"},
            {id: "Terenos", name: "MS - Terenos"},
            {id: "Três Lagoas", name: "MS - Três Lagoas"},
            {id: "Vicentina", name: "MS - Vicentina"},
            {id: "Acorizal", name: "MT - Acorizal"},
            {id: "Água Boa", name: "MT - Água Boa"},
            {id: "Alta Floresta", name: "MT - Alta Floresta"},
            {id: "Alto Araguaia", name: "MT - Alto Araguaia"},
            {id: "Alto Boa Vista", name: "MT - Alto Boa Vista"},
            {id: "Alto Garças", name: "MT - Alto Garças"},
            {id: "Alto Paraguai", name: "MT - Alto Paraguai"},
            {id: "Alto Taquari", name: "MT - Alto Taquari"},
            {id: "Apiacás", name: "MT - Apiacás"},
            {id: "Araguaiana", name: "MT - Araguaiana"},
            {id: "Araguainha", name: "MT - Araguainha"},
            {id: "Araputanga", name: "MT - Araputanga"},
            {id: "Arenápolis", name: "MT - Arenápolis"},
            {id: "Aripuanã", name: "MT - Aripuanã"},
            {id: "Barão de Melgaço", name: "MT - Barão de Melgaço"},
            {id: "Barra do Bugres", name: "MT - Barra do Bugres"},
            {id: "Barra do Garças", name: "MT - Barra do Garças"},
            {id: "Bom Jesus do Araguaia", name: "MT - Bom Jesus do Araguaia"},
            {id: "Brasnorte", name: "MT - Brasnorte"},
            {id: "Cáceres", name: "MT - Cáceres"},
            {id: "Campinápolis", name: "MT - Campinápolis"},
            {id: "Campo Novo do Parecis", name: "MT - Campo Novo do Parecis"},
            {id: "Campo Verde", name: "MT - Campo Verde"},
            {id: "Campos de Júlio", name: "MT - Campos de Júlio"},
            {id: "Canabrava do Norte", name: "MT - Canabrava do Norte"},
            {id: "Canarana", name: "MT - Canarana"},
            {id: "Carlinda", name: "MT - Carlinda"},
            {id: "Castanheira", name: "MT - Castanheira"},
            {id: "Chapada Dos Guimarães", name: "MT - Chapada Dos Guimarães"},
            {id: "Cláudia", name: "MT - Cláudia"},
            {id: "Cocalinho", name: "MT - Cocalinho"},
            {id: "Colíder", name: "MT - Colíder"},
            {id: "Colniza", name: "MT - Colniza"},
            {id: "Comodoro", name: "MT - Comodoro"},
            {id: "Confresa", name: "MT - Confresa"},
            {id: "Conquista D'oeste", name: "MT - Conquista D'oeste"},
            {id: "Cotriguaçu", name: "MT - Cotriguaçu"},
            {id: "Cuiabá", name: "MT - Cuiabá"},
            {id: "Curvelândia", name: "MT - Curvelândia"},
            {id: "Denise", name: "MT - Denise"},
            {id: "Diamantino", name: "MT - Diamantino"},
            {id: "Dom Aquino", name: "MT - Dom Aquino"},
            {id: "Feliz Natal", name: "MT - Feliz Natal"},
            {id: "Figueirópolis D'oeste", name: "MT - Figueirópolis D'oeste"},
            {id: "Gaúcha do Norte", name: "MT - Gaúcha do Norte"},
            {id: "General Carneiro", name: "MT - General Carneiro"},
            {id: "Glória D'oeste", name: "MT - Glória D'oeste"},
            {id: "Guarantã do Norte", name: "MT - Guarantã do Norte"},
            {id: "Guiratinga", name: "MT - Guiratinga"},
            {id: "Indiavaí", name: "MT - Indiavaí"},
            {id: "Ipiranga do Norte", name: "MT - Ipiranga do Norte"},
            {id: "Itanhangá", name: "MT - Itanhangá"},
            {id: "Itaúba", name: "MT - Itaúba"},
            {id: "Itiquira", name: "MT - Itiquira"},
            {id: "Jaciara", name: "MT - Jaciara"},
            {id: "Jangada", name: "MT - Jangada"},
            {id: "Jauru", name: "MT - Jauru"},
            {id: "Juara", name: "MT - Juara"},
            {id: "Juína", name: "MT - Juína"},
            {id: "Juruena", name: "MT - Juruena"},
            {id: "Juscimeira", name: "MT - Juscimeira"},
            {id: "Lambari D'oeste", name: "MT - Lambari D'oeste"},
            {id: "Lucas do Rio Verde", name: "MT - Lucas do Rio Verde"},
            {id: "Luciára", name: "MT - Luciára"},
            {id: "Vila Bela da Santíssima Trindade", name: "MT - Vila Bela da Santíssima Trindade"},
            {id: "Marcelândia", name: "MT - Marcelândia"},
            {id: "Matupá", name: "MT - Matupá"},
            {id: "Mirassol D'oeste", name: "MT - Mirassol D'oeste"},
            {id: "Nobres", name: "MT - Nobres"},
            {id: "Nortelândia", name: "MT - Nortelândia"},
            {id: "Nossa Senhora do Livramento", name: "MT - Nossa Senhora do Livramento"},
            {id: "Nova Bandeirantes", name: "MT - Nova Bandeirantes"},
            {id: "Nova Nazaré", name: "MT - Nova Nazaré"},
            {id: "Nova Lacerda", name: "MT - Nova Lacerda"},
            {id: "Nova Santa Helena", name: "MT - Nova Santa Helena"},
            {id: "Nova Brasilândia", name: "MT - Nova Brasilândia"},
            {id: "Nova Canaã do Norte", name: "MT - Nova Canaã do Norte"},
            {id: "Nova Mutum", name: "MT - Nova Mutum"},
            {id: "Nova Olímpia", name: "MT - Nova Olímpia"},
            {id: "Nova Ubiratã", name: "MT - Nova Ubiratã"},
            {id: "Nova Xavantina", name: "MT - Nova Xavantina"},
            {id: "Novo Mundo", name: "MT - Novo Mundo"},
            {id: "Novo Horizonte do Norte", name: "MT - Novo Horizonte do Norte"},
            {id: "Novo São Joaquim", name: "MT - Novo São Joaquim"},
            {id: "Paranaíta", name: "MT - Paranaíta"},
            {id: "Paranatinga", name: "MT - Paranatinga"},
            {id: "Novo Santo Antônio", name: "MT - Novo Santo Antônio"},
            {id: "Pedra Preta", name: "MT - Pedra Preta"},
            {id: "Peixoto de Azevedo", name: "MT - Peixoto de Azevedo"},
            {id: "Planalto da Serra", name: "MT - Planalto da Serra"},
            {id: "Poconé", name: "MT - Poconé"},
            {id: "Pontal do Araguaia", name: "MT - Pontal do Araguaia"},
            {id: "Ponte Branca", name: "MT - Ponte Branca"},
            {id: "Pontes e Lacerda", name: "MT - Pontes e Lacerda"},
            {id: "Porto Alegre do Norte", name: "MT - Porto Alegre do Norte"},
            {id: "Porto Dos Gaúchos", name: "MT - Porto Dos Gaúchos"},
            {id: "Porto Esperidião", name: "MT - Porto Esperidião"},
            {id: "Porto Estrela", name: "MT - Porto Estrela"},
            {id: "Poxoréo", name: "MT - Poxoréo"},
            {id: "Primavera do Leste", name: "MT - Primavera do Leste"},
            {id: "Querência", name: "MT - Querência"},
            {id: "São José Dos Quatro Marcos", name: "MT - São José Dos Quatro Marcos"},
            {id: "Reserva do Cabaçal", name: "MT - Reserva do Cabaçal"},
            {id: "Ribeirão Cascalheira", name: "MT - Ribeirão Cascalheira"},
            {id: "Ribeirãozinho", name: "MT - Ribeirãozinho"},
            {id: "Rio Branco", name: "MT - Rio Branco"},
            {id: "Santa Carmem", name: "MT - Santa Carmem"},
            {id: "Santo Afonso", name: "MT - Santo Afonso"},
            {id: "São José do Povo", name: "MT - São José do Povo"},
            {id: "São José do Rio Claro", name: "MT - São José do Rio Claro"},
            {id: "São José do Xingu", name: "MT - São José do Xingu"},
            {id: "São Pedro da Cipa", name: "MT - São Pedro da Cipa"},
            {id: "Rondolândia", name: "MT - Rondolândia"},
            {id: "Rondonópolis", name: "MT - Rondonópolis"},
            {id: "Rosário Oeste", name: "MT - Rosário Oeste"},
            {id: "Santa Cruz do Xingu", name: "MT - Santa Cruz do Xingu"},
            {id: "Salto do Céu", name: "MT - Salto do Céu"},
            {id: "Santa Rita do Trivelato", name: "MT - Santa Rita do Trivelato"},
            {id: "Santa Terezinha", name: "MT - Santa Terezinha"},
            {id: "Santo Antônio do Leste", name: "MT - Santo Antônio do Leste"},
            {id: "Santo Antônio do Leverger", name: "MT - Santo Antônio do Leverger"},
            {id: "São Félix do Araguaia", name: "MT - São Félix do Araguaia"},
            {id: "Sapezal", name: "MT - Sapezal"},
            {id: "Serra Nova Dourada", name: "MT - Serra Nova Dourada"},
            {id: "Sinop", name: "MT - Sinop"},
            {id: "Sorriso", name: "MT - Sorriso"},
            {id: "Tabaporã", name: "MT - Tabaporã"},
            {id: "Tangará da Serra", name: "MT - Tangará da Serra"},
            {id: "Tapurah", name: "MT - Tapurah"},
            {id: "Terra Nova do Norte", name: "MT - Terra Nova do Norte"},
            {id: "Tesouro", name: "MT - Tesouro"},
            {id: "Torixoréu", name: "MT - Torixoréu"},
            {id: "União do Sul", name: "MT - União do Sul"},
            {id: "Vale de São Domingos", name: "MT - Vale de São Domingos"},
            {id: "Várzea Grande", name: "MT - Várzea Grande"},
            {id: "Vera", name: "MT - Vera"},
            {id: "Vila Rica", name: "MT - Vila Rica"},
            {id: "Nova Guarita", name: "MT - Nova Guarita"},
            {id: "Nova Marilândia", name: "MT - Nova Marilândia"},
            {id: "Nova Maringá", name: "MT - Nova Maringá"},
            {id: "Nova Monte Verde", name: "MT - Nova Monte Verde"},
            {id: "Abadia de Goiás", name: "GO - Abadia de Goiás"},
            {id: "Abadiânia", name: "GO - Abadiânia"},
            {id: "Acreúna", name: "GO - Acreúna"},
            {id: "Adelândia", name: "GO - Adelândia"},
            {id: "Água Fria de Goiás", name: "GO - Água Fria de Goiás"},
            {id: "Água Limpa", name: "GO - Água Limpa"},
            {id: "Águas Lindas de Goiás", name: "GO - Águas Lindas de Goiás"},
            {id: "Alexânia", name: "GO - Alexânia"},
            {id: "Aloândia", name: "GO - Aloândia"},
            {id: "Alto Horizonte", name: "GO - Alto Horizonte"},
            {id: "Alto Paraíso de Goiás", name: "GO - Alto Paraíso de Goiás"},
            {id: "Alvorada do Norte", name: "GO - Alvorada do Norte"},
            {id: "Amaralina", name: "GO - Amaralina"},
            {id: "Americano do Brasil", name: "GO - Americano do Brasil"},
            {id: "Amorinópolis", name: "GO - Amorinópolis"},
            {id: "Anápolis", name: "GO - Anápolis"},
            {id: "Anhanguera", name: "GO - Anhanguera"},
            {id: "Anicuns", name: "GO - Anicuns"},
            {id: "Aparecida de Goiânia", name: "GO - Aparecida de Goiânia"},
            {id: "Aparecida do Rio Doce", name: "GO - Aparecida do Rio Doce"},
            {id: "Aporé", name: "GO - Aporé"},
            {id: "Araçu", name: "GO - Araçu"},
            {id: "Aragarças", name: "GO - Aragarças"},
            {id: "Aragoiânia", name: "GO - Aragoiânia"},
            {id: "Araguapaz", name: "GO - Araguapaz"},
            {id: "Arenópolis", name: "GO - Arenópolis"},
            {id: "Aruanã", name: "GO - Aruanã"},
            {id: "Aurilândia", name: "GO - Aurilândia"},
            {id: "Avelinópolis", name: "GO - Avelinópolis"},
            {id: "Baliza", name: "GO - Baliza"},
            {id: "Barro Alto", name: "GO - Barro Alto"},
            {id: "Bela Vista de Goiás", name: "GO - Bela Vista de Goiás"},
            {id: "Bom Jardim de Goiás", name: "GO - Bom Jardim de Goiás"},
            {id: "Bom Jesus de Goiás", name: "GO - Bom Jesus de Goiás"},
            {id: "Bonfinópolis", name: "GO - Bonfinópolis"},
            {id: "Bonópolis", name: "GO - Bonópolis"},
            {id: "Brazabrantes", name: "GO - Brazabrantes"},
            {id: "Britânia", name: "GO - Britânia"},
            {id: "Buriti Alegre", name: "GO - Buriti Alegre"},
            {id: "Buriti de Goiás", name: "GO - Buriti de Goiás"},
            {id: "Buritinópolis", name: "GO - Buritinópolis"},
            {id: "Cabeceiras", name: "GO - Cabeceiras"},
            {id: "Cachoeira Alta", name: "GO - Cachoeira Alta"},
            {id: "Cachoeira de Goiás", name: "GO - Cachoeira de Goiás"},
            {id: "Cachoeira Dourada", name: "GO - Cachoeira Dourada"},
            {id: "Caçu", name: "GO - Caçu"},
            {id: "Caiapônia", name: "GO - Caiapônia"},
            {id: "Caldas Novas", name: "GO - Caldas Novas"},
            {id: "Caldazinha", name: "GO - Caldazinha"},
            {id: "Campestre de Goiás", name: "GO - Campestre de Goiás"},
            {id: "Campinaçu", name: "GO - Campinaçu"},
            {id: "Campinorte", name: "GO - Campinorte"},
            {id: "Campo Alegre de Goiás", name: "GO - Campo Alegre de Goiás"},
            {id: "Campo Limpo de Goiás", name: "GO - Campo Limpo de Goiás"},
            {id: "Campos Belos", name: "GO - Campos Belos"},
            {id: "Campos Verdes", name: "GO - Campos Verdes"},
            {id: "Carmo do Rio Verde", name: "GO - Carmo do Rio Verde"},
            {id: "Castelândia", name: "GO - Castelândia"},
            {id: "Catalão", name: "GO - Catalão"},
            {id: "Caturaí", name: "GO - Caturaí"},
            {id: "Cavalcante", name: "GO - Cavalcante"},
            {id: "Ceres", name: "GO - Ceres"},
            {id: "Cezarina", name: "GO - Cezarina"},
            {id: "Chapadão do Céu", name: "GO - Chapadão do Céu"},
            {id: "Cidade Ocidental", name: "GO - Cidade Ocidental"},
            {id: "Cocalzinho de Goiás", name: "GO - Cocalzinho de Goiás"},
            {id: "Colinas do Sul", name: "GO - Colinas do Sul"},
            {id: "Córrego do Ouro", name: "GO - Córrego do Ouro"},
            {id: "Corumbá de Goiás", name: "GO - Corumbá de Goiás"},
            {id: "Corumbaíba", name: "GO - Corumbaíba"},
            {id: "Cristalina", name: "GO - Cristalina"},
            {id: "Cristianópolis", name: "GO - Cristianópolis"},
            {id: "Crixás", name: "GO - Crixás"},
            {id: "Cromínia", name: "GO - Cromínia"},
            {id: "Cumari", name: "GO - Cumari"},
            {id: "Damianópolis", name: "GO - Damianópolis"},
            {id: "Damolândia", name: "GO - Damolândia"},
            {id: "Davinópolis", name: "GO - Davinópolis"},
            {id: "Diorama", name: "GO - Diorama"},
            {id: "Doverlândia", name: "GO - Doverlândia"},
            {id: "Edealina", name: "GO - Edealina"},
            {id: "Edéia", name: "GO - Edéia"},
            {id: "Estrela do Norte", name: "GO - Estrela do Norte"},
            {id: "Faina", name: "GO - Faina"},
            {id: "Fazenda Nova", name: "GO - Fazenda Nova"},
            {id: "Firminópolis", name: "GO - Firminópolis"},
            {id: "Flores de Goiás", name: "GO - Flores de Goiás"},
            {id: "Formosa", name: "GO - Formosa"},
            {id: "Formoso", name: "GO - Formoso"},
            {id: "Gameleira de Goiás", name: "GO - Gameleira de Goiás"},
            {id: "Divinópolis de Goiás", name: "GO - Divinópolis de Goiás"},
            {id: "Goianápolis", name: "GO - Goianápolis"},
            {id: "Goiandira", name: "GO - Goiandira"},
            {id: "Goianésia", name: "GO - Goianésia"},
            {id: "Goiânia", name: "GO - Goiânia"},
            {id: "Goianira", name: "GO - Goianira"},
            {id: "Goiás", name: "GO - Goiás"},
            {id: "Goiatuba", name: "GO - Goiatuba"},
            {id: "Gouvelândia", name: "GO - Gouvelândia"},
            {id: "Guapó", name: "GO - Guapó"},
            {id: "Guaraíta", name: "GO - Guaraíta"},
            {id: "Guarani de Goiás", name: "GO - Guarani de Goiás"},
            {id: "Guarinos", name: "GO - Guarinos"},
            {id: "Heitoraí", name: "GO - Heitoraí"},
            {id: "Hidrolândia", name: "GO - Hidrolândia"},
            {id: "Hidrolina", name: "GO - Hidrolina"},
            {id: "Iaciara", name: "GO - Iaciara"},
            {id: "Inaciolândia", name: "GO - Inaciolândia"},
            {id: "Indiara", name: "GO - Indiara"},
            {id: "Inhumas", name: "GO - Inhumas"},
            {id: "Ipameri", name: "GO - Ipameri"},
            {id: "Ipiranga de Goiás", name: "GO - Ipiranga de Goiás"},
            {id: "Iporá", name: "GO - Iporá"},
            {id: "Israelândia", name: "GO - Israelândia"},
            {id: "Itaberaí", name: "GO - Itaberaí"},
            {id: "Itaguari", name: "GO - Itaguari"},
            {id: "Itaguaru", name: "GO - Itaguaru"},
            {id: "Itajá", name: "GO - Itajá"},
            {id: "Itapaci", name: "GO - Itapaci"},
            {id: "Itapirapuã", name: "GO - Itapirapuã"},
            {id: "Itapuranga", name: "GO - Itapuranga"},
            {id: "Itarumã", name: "GO - Itarumã"},
            {id: "Itauçu", name: "GO - Itauçu"},
            {id: "Itumbiara", name: "GO - Itumbiara"},
            {id: "Ivolândia", name: "GO - Ivolândia"},
            {id: "Jandaia", name: "GO - Jandaia"},
            {id: "Jaraguá", name: "GO - Jaraguá"},
            {id: "Jataí", name: "GO - Jataí"},
            {id: "Jaupaci", name: "GO - Jaupaci"},
            {id: "Jesúpolis", name: "GO - Jesúpolis"},
            {id: "Joviânia", name: "GO - Joviânia"},
            {id: "Jussara", name: "GO - Jussara"},
            {id: "Lagoa Santa", name: "GO - Lagoa Santa"},
            {id: "Leopoldo de Bulhões", name: "GO - Leopoldo de Bulhões"},
            {id: "Luziânia", name: "GO - Luziânia"},
            {id: "Mairipotaba", name: "GO - Mairipotaba"},
            {id: "Mambaí", name: "GO - Mambaí"},
            {id: "Mara Rosa", name: "GO - Mara Rosa"},
            {id: "Marzagão", name: "GO - Marzagão"},
            {id: "Matrinchã", name: "GO - Matrinchã"},
            {id: "Maurilândia", name: "GO - Maurilândia"},
            {id: "Mimoso de Goiás", name: "GO - Mimoso de Goiás"},
            {id: "Minaçu", name: "GO - Minaçu"},
            {id: "Mineiros", name: "GO - Mineiros"},
            {id: "Moiporá", name: "GO - Moiporá"},
            {id: "Monte Alegre de Goiás", name: "GO - Monte Alegre de Goiás"},
            {id: "Montes Claros de Goiás", name: "GO - Montes Claros de Goiás"},
            {id: "Montividiu", name: "GO - Montividiu"},
            {id: "Montividiu do Norte", name: "GO - Montividiu do Norte"},
            {id: "Morrinhos", name: "GO - Morrinhos"},
            {id: "Morro Agudo de Goiás", name: "GO - Morro Agudo de Goiás"},
            {id: "Mossâmedes", name: "GO - Mossâmedes"},
            {id: "Mozarlândia", name: "GO - Mozarlândia"},
            {id: "Mundo Novo", name: "GO - Mundo Novo"},
            {id: "Mutunópolis", name: "GO - Mutunópolis"},
            {id: "Nazário", name: "GO - Nazário"},
            {id: "Nerópolis", name: "GO - Nerópolis"},
            {id: "Niquelândia", name: "GO - Niquelândia"},
            {id: "Nova América", name: "GO - Nova América"},
            {id: "Nova Aurora", name: "GO - Nova Aurora"},
            {id: "Nova Crixás", name: "GO - Nova Crixás"},
            {id: "Nova Glória", name: "GO - Nova Glória"},
            {id: "Nova Iguaçu de Goiás", name: "GO - Nova Iguaçu de Goiás"},
            {id: "Nova Roma", name: "GO - Nova Roma"},
            {id: "Nova Veneza", name: "GO - Nova Veneza"},
            {id: "Novo Brasil", name: "GO - Novo Brasil"},
            {id: "Novo Gama", name: "GO - Novo Gama"},
            {id: "Novo Planalto", name: "GO - Novo Planalto"},
            {id: "Orizona", name: "GO - Orizona"},
            {id: "Ouro Verde de Goiás", name: "GO - Ouro Verde de Goiás"},
            {id: "Ouvidor", name: "GO - Ouvidor"},
            {id: "Padre Bernardo", name: "GO - Padre Bernardo"},
            {id: "Palestina de Goiás", name: "GO - Palestina de Goiás"},
            {id: "Palmeiras de Goiás", name: "GO - Palmeiras de Goiás"},
            {id: "Palmelo", name: "GO - Palmelo"},
            {id: "Palminópolis", name: "GO - Palminópolis"},
            {id: "Panamá", name: "GO - Panamá"},
            {id: "Paranaiguara", name: "GO - Paranaiguara"},
            {id: "Paraúna", name: "GO - Paraúna"},
            {id: "Perolândia", name: "GO - Perolândia"},
            {id: "Petrolina de Goiás", name: "GO - Petrolina de Goiás"},
            {id: "Pilar de Goiás", name: "GO - Pilar de Goiás"},
            {id: "Piracanjuba", name: "GO - Piracanjuba"},
            {id: "Piranhas", name: "GO - Piranhas"},
            {id: "Pirenópolis", name: "GO - Pirenópolis"},
            {id: "Pires do Rio", name: "GO - Pires do Rio"},
            {id: "Planaltina", name: "GO - Planaltina"},
            {id: "Pontalina", name: "GO - Pontalina"},
            {id: "Porangatu", name: "GO - Porangatu"},
            {id: "Porteirão", name: "GO - Porteirão"},
            {id: "Portelândia", name: "GO - Portelândia"},
            {id: "Posse", name: "GO - Posse"},
            {id: "Professor Jamil", name: "GO - Professor Jamil"},
            {id: "Quirinópolis", name: "GO - Quirinópolis"},
            {id: "Rialma", name: "GO - Rialma"},
            {id: "Rianápolis", name: "GO - Rianápolis"},
            {id: "Rio Quente", name: "GO - Rio Quente"},
            {id: "Rio Verde", name: "GO - Rio Verde"},
            {id: "Rubiataba", name: "GO - Rubiataba"},
            {id: "Sanclerlândia", name: "GO - Sanclerlândia"},
            {id: "Santa Bárbara de Goiás", name: "GO - Santa Bárbara de Goiás"},
            {id: "Santa Cruz de Goiás", name: "GO - Santa Cruz de Goiás"},
            {id: "Santa fé de Goiás", name: "GO - Santa fé de Goiás"},
            {id: "Santa Helena de Goiás", name: "GO - Santa Helena de Goiás"},
            {id: "Santa Isabel", name: "GO - Santa Isabel"},
            {id: "Santa Rita do Araguaia", name: "GO - Santa Rita do Araguaia"},
            {id: "Santa Rita do Novo Destino", name: "GO - Santa Rita do Novo Destino"},
            {id: "Santa Rosa de Goiás", name: "GO - Santa Rosa de Goiás"},
            {id: "Santa Tereza de Goiás", name: "GO - Santa Tereza de Goiás"},
            {id: "Santa Terezinha de Goiás", name: "GO - Santa Terezinha de Goiás"},
            {id: "Santo Antônio da Barra", name: "GO - Santo Antônio da Barra"},
            {id: "Santo Antônio de Goiás", name: "GO - Santo Antônio de Goiás"},
            {id: "Santo Antônio do Descoberto", name: "GO - Santo Antônio do Descoberto"},
            {id: "São Domingos", name: "GO - São Domingos"},
            {id: "São Francisco de Goiás", name: "GO - São Francisco de Goiás"},
            {id: "São João D'aliança", name: "GO - São João D'aliança"},
            {id: "São João da Paraúna", name: "GO - São João da Paraúna"},
            {id: "São Luís de Montes Belos", name: "GO - São Luís de Montes Belos"},
            {id: "São Luíz do Norte", name: "GO - São Luíz do Norte"},
            {id: "São Miguel do Araguaia", name: "GO - São Miguel do Araguaia"},
            {id: "São Miguel do Passa Quatro", name: "GO - São Miguel do Passa Quatro"},
            {id: "São Patrício", name: "GO - São Patrício"},
            {id: "São Simão", name: "GO - São Simão"},
            {id: "Senador Canedo", name: "GO - Senador Canedo"},
            {id: "Serranópolis", name: "GO - Serranópolis"},
            {id: "Silvânia", name: "GO - Silvânia"},
            {id: "Simolândia", name: "GO - Simolândia"},
            {id: "Sítio D'abadia", name: "GO - Sítio D'abadia"},
            {id: "Taquaral de Goiás", name: "GO - Taquaral de Goiás"},
            {id: "Teresina de Goiás", name: "GO - Teresina de Goiás"},
            {id: "Terezópolis de Goiás", name: "GO - Terezópolis de Goiás"},
            {id: "Três Ranchos", name: "GO - Três Ranchos"},
            {id: "Trindade", name: "GO - Trindade"},
            {id: "Trombas", name: "GO - Trombas"},
            {id: "Turvânia", name: "GO - Turvânia"},
            {id: "Turvelândia", name: "GO - Turvelândia"},
            {id: "Uirapuru", name: "GO - Uirapuru"},
            {id: "Uruaçu", name: "GO - Uruaçu"},
            {id: "Uruana", name: "GO - Uruana"},
            {id: "Urutaí", name: "GO - Urutaí"},
            {id: "Valparaíso de Goiás", name: "GO - Valparaíso de Goiás"},
            {id: "Varjão", name: "GO - Varjão"},
            {id: "Vianópolis", name: "GO - Vianópolis"},
            {id: "Vicentinópolis", name: "GO - Vicentinópolis"},
            {id: "Vila Boa", name: "GO - Vila Boa"},
            {id: "Vila Propício", name: "GO - Vila Propício"},
            {id: "Brasília", name: "DF - Brasília"}
          ]
        
        if(arrayEstados !== undefined){
            var i;
            var arrayEstadosAux = [];
            
            for (i = 0; i < arrayEstados.length; i++) {
        
                arrayEstadosAux[i] = arrayEstados[i].id;
            }
        
            function inEstado(value) {
                var muninicipioEstado = value.name.substring(0,2);
                return arrayEstadosAux.includes(muninicipioEstado);
            }
    
    
            return filtered.filter(inEstado);

        }else{
            return arrayEstadosAux
        }



  }