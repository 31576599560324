import { getRequest } from "./request";

class GraphService {
  getGeo = () =>
    new Promise((resolve, reject) => {
      getRequest(`graph/geoUF`, resolve, reject);
    });

  getGeoUF = (uf) =>
    new Promise((resolve, reject) => {
      getRequest(`graph/geomunicipio/${uf}`, resolve, reject);
    });

  formataGraphGeo = (geo) =>
    geo
      .filter((data) => data.uf && data.uf?.trim())
      .map((data) => ["br-" + data.uf.toLowerCase(), data.percentual]);

  getDadosEmpre = () =>
    new Promise((resolve, reject) => {
      getRequest(`graph/geoempresas`, resolve, reject);
    });

  getSecao = () =>
    new Promise((resolve, reject) => {
      getRequest(`graph/geosecaodivisao`, resolve, reject);
    });
}

export default new GraphService();
