import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "../pages/Login";

export default function Auth() {

  return (
    <>
      <Switch>
        <Route path="/auth" exact component={Login} />        
      </Switch>
    </>
  );
}
