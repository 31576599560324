import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  max-width: 100vw;
`;
export const Buttons = styled.div`
  

  .buttons {
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 5px;
    border-style: none;
    opacity: 1;
    background: #307da2 0% 0% no-repeat padding-box;
  }
.buttons svg{
  font-size:16px
}
  .buttons:hover {
    color: #ffffff;
    background: #13597b 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border-style: none;
    opacity: 1;
  }
`;
