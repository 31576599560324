import { getRequest } from './request';

class CnaeService {
  getCnaeAutoComplete = () =>
    new Promise((resolve, reject) => {
      getRequest(`cnaes/cnaeAutoComplete`, resolve, reject);
    });

  getCnae = (cnae) =>
    new Promise((resolve, reject) => {
      getRequest(`cnaes/cnae/${cnae}`, resolve, reject);
    });

  formataCnae = (cnae)  => {                  

      cnae.cnaeFiscal = cnae.cnae;
      cnae.cnaePrincipal = cnae['cnae'] + ' - ' + cnae['descricao_cnae'];
      cnae.secao = cnae['secao'] + ' - ' + cnae['descricao_secao'];
      cnae.divisao = cnae['divisao'] + ' - ' + cnae['descricao_divisao'];
      cnae.grupo = cnae['grupo'] + ' - ' + cnae['descricao_grupo'];
      cnae.classe = cnae['classe'] + ' - ' +cnae['descricao_classe']

      return cnae
  }

}

export default new CnaeService();
