import axios from "axios";

const source = axios.CancelToken.source();

const instance = axios.create({
  //baseURL: 'http://localhost:555/node/pm/myapp/',
  //baseURL: 'http://144.22.201.247:555/node/pm/myapp/',
  baseURL: "https://pmnode.cigam.com.br/node/pm/myapp/",

  cancelToken: source.token,
});

export default instance;
