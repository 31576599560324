import React, { Component } from "react";

import { Container } from "./assets/styles/styles";
import ImageMenu from "./assets/images/menu.svg";

import {
  ImagemContatos,
  ImagemPesquisa,
  ImagemDashboard,
} from "./assets/images";

import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  state = {
    openMenu: false,
    permissao: "N",
  };

  componentDidMount() {
    this.setState({ permissao: localStorage.getItem("LIBERADO_PORTAL") });
  }

  handleOpenMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };
  
  render() {
    const { openMenu } = this.state;
    const { permissao } = this.state;

    return (
      <Container>
        <div className="menu">
          {!openMenu && (
            <img src={ImageMenu} alt="Menu" onClick={this.handleOpenMenu} />
          )}
          {openMenu && (
            <img src={ImageMenu} alt="Menu" onClick={this.handleOpenMenu} />
          )}
        </div>

        <div className={`menu-options ${openMenu ? "is-open" : ""}`}>
          {permissao === "S" ? (
            <Link to={"/pm/dasboard"} className="image-link">
              <ImagemDashboard />
              <p>Dashboard</p>
            </Link>

          ) : (
            <></>
          )}

          <Link to={"/pm/empresa"} className="image-link">
            <ImagemContatos />
            <p>Empresa</p>
          </Link>

          {permissao === "S" ? (
            <Link to={"/pm/pesquisa"} className="image-link">
              <ImagemPesquisa />
              <p>Consulta empresa</p>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </Container>
    );
  }
}
