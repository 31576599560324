import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  max-width: 100vw;
`;
export const ResUndefined = styled.div`
  margin-top: 150px;
  max-width: 100vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 250px;
  }
  p {
    color: #6c7a8f;
    font-size: 14px;
    padding-top: 2rem;
  }
`;
export const Res = styled.div`
  margin-top: 10px;
  max-width: 100vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Voltar = styled.button`
  background-color: Transparent;
  font-size: 16px;
  font-weight: 500;
  border: none;
  color: #307da2;
  height: auto;
  margin-top: 20px;
  :hover {
    opacity: 1;
    color: #ff5811;
  }
`;
