import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import graphService from "../../services/graphService";
import drilldown from "highcharts/modules/drilldown";
import { Layout, Container } from "./styles";
import Card from "react-bootstrap/Card";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Highcharts from "highcharts";
import "./grafico.css";

const GraphSetores = () => {
  const [optionsSetores, setOptionsSetores] = useState([]);
  const [options, setOptions] = useState({});
  drilldown(Highcharts);

  Highcharts.setOptions({
    drilldown: {
      activeDataLabelStyle: {
        textDecoration: "none",
        color: "black",
      },
    },

    lang: {
      drillUpText: "◁ Voltar",
    },
  });

  const optionsDefault = {
    chart: {
      type: "column",
      height: 600,
    },
    title: {
      style: {
        fontSize: "1.5rem",
      },
      text: "<b>Demonstrativo de seções e divisões</b>",
    },
    subtitle: {
      text: "Clique nas colunas para visualizar as divisões de cada seção",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -60,
      },
    },
    colorAxis: {
      stops: [
        [0, "#8DCEB1"],
        [0.3, "#0ead69"],
        [0.6, "#3da5d9"],
        [1, "#FF5811"],
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.2f}%",
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    },
    series: [
      {
        colorByPoint: true,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    const getSecoesDivisoes = async () => {
      const responseSecoesDivisoes = await graphService.getSecao();
      setOptionsSetores(responseSecoesDivisoes);
    };

    getSecoesDivisoes();
  }, []);

  useEffect(() => {
    const dadosSetores = optionsSetores.map((a) => {
      return {
        name: a.secao,
        y: a.percentual,
        drilldown: a.sigla,
      };
    });
    const dadosDivisoes = optionsSetores.map((a) => {
      return {
        name: a.secao,
        id: a.sigla,
        data: a.divisoes,
      };
    });

    setOptions({
      ...optionsDefault,
      series: [
        {
          name: "Setores",
          colorByPoint: true,
          data: dadosSetores,
        },
      ],
      drilldown: {
        series: dadosDivisoes,
      },
    });
  }, [optionsSetores]);

  return (
    <>
      <Container>
        <Row>
          <Col lg={12} xs={12} sm={12}>
            <Card>
              <Layout>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Layout>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default GraphSetores;
