import React from "react";
import { Balao } from "./styles";
import icone from "../../assets/license.svg";
const AlertLicense = () => {
  return (
    <Balao>
      <img src={icone} alt="" />
      <p>
        Produto não licenciado. <br />
        Entre em contato com a equipe comercial CIGAM.
      </p>
    </Balao>
  );
};
export default AlertLicense;
