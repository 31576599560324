import React from "react";

import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import PrivateRoute from "../src/components/PrivateRoute";

import Auth from "./layout/Auth";
import LoginCRM from "../src/pages/LoginPortalCRM";
import PesquisMercado from "../src/layout/PesquisaMercado";
import PesquisaMercadoNoAuth from "../src/layout/PesquisaMercadoNoAuth";
import { SnackbarProvider } from 'notistack';
import Extrato from "./pages/Extrato";

function App() {
  return (
    <SnackbarProvider maxSnack={10} autoHideDuration={3000}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/pm" component={PesquisMercado} />
          <Route path="/auth" component={Auth} />
          <Route path="/signin" component={LoginCRM} />
          
          <Route exact path="/empresa/:cnpj" component={PesquisaMercadoNoAuth} />
          <Redirect from="*" to="/pm" />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
