import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Form } from "react-bootstrap";

import authService from "../../services/authService";

import desenvolvido from "./desenvolvido.png";
import logo from "./Logotipo.png";
import Styled from "./styledPage";

export default function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [alerta, setAlerta] = useState(false);
  async function handleSubmit(e){
    e.preventDefault(); 

    try {
      const data = await authService.autenticate(email, password);

      localStorage.setItem("LIBERADO_PORTAL", "S");

      if (!!data.cnpj) {
        history.push("/pm");
      } else {
        setAlerta(true);
        // alert("Login inválido.");
      }
    } catch (err) {
      setAlerta(true);
      // alert("Falha no login, tente novamente.");
    }
  }

  return (
    <Styled>
      <Card.Body className="container-layout">
        <Card className="container">
          <Form className="login-form" id="login-form" onSubmit={handleSubmit}>
            <Card className="logo">
              <img
                className="img-responsive"
                id="loginImage"
                alt="CIGAM Software Corporativo"
                src={logo}
              ></img>
            </Card>

            <Card className="logintext">Faça login</Card>
            <Card className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Usuário"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Card>

            <Card className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span>{alerta === true ? "login ou senha invalidos" : ""}</span>
            </Card>
            <label className="continuar-conectado">
              <input type="checkbox" className="checkbox" />
              Continuar conectado
            </label>
            <button type="submit" className="btn-login">
              ENTRAR
            </button>
            <Card className="logo">
              <img
                className="img-desenvolvido"
                alt="CIGAM Software Corporativo"
                src={desenvolvido}
              ></img>
            </Card>
          </Form>
        </Card>
      </Card.Body>
    </Styled>
  );
}
