import React from "react";
import { Card, CardBody, CardBlock } from "./styles";

export const CardDashboard = (props) => {
  return (
    <>
      <Card style={{ background: props.color }}>
        <CardBody style={{ background: props.color }}>
          <CardBlock>
            <img src={props.icone} alt="" />
            <span
              className="count-numbers" style={{ color: props.fonteNumero }}>
              {props.numero}
            </span>
            <span className="count-name" style={{ color: props.fonteTitle }}>
              {props.cardName}
            </span>
          </CardBlock>
        </CardBody>
      </Card>
    </>
  );
};
