import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  max-width: 100vw;
`;

export const CardsUsers = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
`;
export const TopBarUsers = styled.div`
  display: flex;
  position: fixed;
  background: #ffffff;
  height: 64px;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  left: 0;
  right: 0;
  border-style: none;
  top: 50px;
  z-index: 1;
  justify-content: space-between;
`;
export const TopBarUsersTitles = styled.div`
  margin-left: 5rem;
  h3 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #6c7a8f;
    opacity: 1;
  }
  p {
    margin: 0%;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #ff5811;
    opacity: 1;
  }
`;
export const TopBarUsersAdd = styled.div`
  float: right;
  margin-right: 2.2rem;
  display: flex;
 .iconAdd{
   font-size:20px
 }
  .iconAdd:hover{
  opacity: 1;
  color: #ff5811;
}
  }
  `;
export const BtnAddUsers = styled.button`
  background: #ffffff;
  width: 48px;
  height: 48px;
  font-size: 10px;
  font-weight: normal;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  opacity: 0.8;
  color: #307da2;
  .iconAdd:hover, &:hover {
    opacity: 1;
    color: #ff5811;
  }
`;
