import styled from "styled-components";

export const Styled = styled.div`
  display: flex;

  .card {
    margin: 5px;
  }

  .card-text {
    display: flex;
  }

  .totalDeFiliais {
    text-align: right;
    cursor: pointer;
  }

  .enderecoCompleto {
    align: line;
  }

  .iconCard {
    content: "\f003";
    font-size: 16px;
    border-radius: 4%;
    text-align: left;
  }

  .LinksExternos {
    font-size: 14px;
  }
`;
export const InfComplementar = styled.div`
  width: auto;
  margin-left: auto;
  font-size: larger;
  padding-left: 10px;
  cursor: pointer;
`;
export const Divisor = styled.div`
  width: auto;
  padding-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
`;
