import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as moment from 'moment'


import { textFilter } from "react-bootstrap-table2-filter";
import { Row, Col } from "react-bootstrap";
import {
  BtnAddUsers,
  Container,
  TopBarUsers,
  TopBarUsersAdd,
  TopBarUsersTitles,
} from "./styles";

//components
import { CardDashboard } from "../../components/CardDashboard";

//services
import empresaUsuarioService from "../../services/empresaUsuarioService";

//icons e Images
import business from "../../assets/business.svg";
import people from "../../assets/people.svg";
import TableResultsSingle from "../../components/TableResultsSingle";
import { RiUserAddLine } from "react-icons/ri";
import { ModalAddUsers } from "../../components/ModalAddUsers";

export default function Users() {
  let history = useHistory();

  const [findResult, setFindResult] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [permissao] = useState(localStorage.getItem("LIBERADO_PORTAL"));
  const [cnpjUser] = useState(localStorage.getItem("CNPJ"));
  const [showModal, setShowModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const openModal = useCallback((props) => {
    setShowModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (permissao !== "S" || cnpjUser !== '93578813000144') {
      history.push("/pm/empresa");
    }
  }, [permissao, history]);

  useEffect(() => {
    const getCompanysUser = async () => {
      const empresasUsuario = await empresaUsuarioService.getEmpresasUsuario();
      setEmpresas(empresasUsuario);
    };
    const getUsuarios = async () => {
      const usuariosEmpresa = await empresaUsuarioService.getUsuarios();

      console.log(usuariosEmpresa)
      const userFormat = usuariosEmpresa.map((emp) => {
        let dateFormat = "";
       
        if (!!emp.ultimo_acesso) {
          var date1 = new Date(emp.ultimo_acesso); 
          dateFormat = moment(date1).format('DD/MM/YYYY')
        }

        return {...emp, ultimo_acesso: dateFormat}
      })
      
      setFindResult(userFormat);
    };

    getCompanysUser();
    getUsuarios();

    if (!refreshList) {
      getCompanysUser();
      getUsuarios();
    }
    setRefreshList(false);
  }, [refreshList]);

  var columnsTable = [
    {
      dataField: "nome",
      text: "Nome",
      filter: textFilter(),
    },
    {
      dataField: "user",
      text: "Usuário",
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "E-Mail",
      filter: textFilter(),
    },
    {
      dataField: "razaoSocial",
      text: "Razão Social",
      filter: textFilter(),
    },
    {
      dataField: "ultimo_acesso",
      text: "Último acesso"
    },

    

    
  ];

  return (
    <>
      <ModalAddUsers
        showModal={showModal}
        setShowModal={setShowModal}
        setRefreshList={setRefreshList}
      />
      <TopBarUsers>
        <TopBarUsersTitles>
          <p>Gerenciamento de usuários</p>
          <h3>Usuários</h3>
        </TopBarUsersTitles>
        <TopBarUsersAdd>
          <BtnAddUsers
            id={"btn-addUsers"}
            className="btn-addUsers"
            onClick={() => openModal()}
            title={"Adicionar usuários"}
          >
            <RiUserAddLine alt="" className="iconAdd" />
            <br />
            Usuários
          </BtnAddUsers>
        </TopBarUsersAdd>
      </TopBarUsers>
      <Container>
        <Row>
          {/* <CardsUsers > */}
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de empresas"
              color="#66bb6a"
              icone={business}
              numero={!!empresas ? empresas.length : "0"}
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de usuários"
              color="#13597B"
              icone={people}
              numero={!!findResult ? findResult.length : "0"}
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
        </Row>
      </Container>
      <TableResultsSingle tableResults={findResult} columns={columnsTable} />
    </>
  );
}
