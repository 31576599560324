import React from 'react'
import CardTextExtrato from "../CardTextExtrato"

const CardTextCNAEsecundariaExtrato = ({ArrayCnaeSecundaria}) => 
{
    return ArrayCnaeSecundaria.map(cnae => (      
        <CardTextExtrato subtitle={''} key={cnae.cnaeFiscal} value={cnae.cnaePrincipal} />
      
    ))
}
export default CardTextCNAEsecundariaExtrato
