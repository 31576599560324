import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import {
  CardPrimaryResult,
  PrimaryResult,
  Container,
  TopBarTitle,  
  TopBarPesquisa,
  TopBarDateImport
} from "./styles";

//components
import { CardDashboard } from "../../components/CardDashboard";
import GraphGeo from "../../components/GraphGeo";
import GraphSetores from "../../components/GraphSetores";

//services

//icons e Imagens
import PesquisarSemResult from "../../assets/pesquisa_sem_resultado.svg";
import business from "../../assets/business.svg";
import business_disabled from "../../assets/business_disabled.svg";
import add_business from "../../assets/add_business.svg";
import company from "../../assets/company.svg";
import graphService from "../../services/graphService";

export default function Dashboard() {
  const [permissao] = useState(localStorage.getItem("LIBERADO_PORTAL"));
  const [checked] = React.useState(true);
  const [dadosEmpre, setDadosEmpre] = useState([]);
  const [resumo] = useState({});
  var internationalNumberFormat = new Intl.NumberFormat("pt-BR");
  let history = useHistory();

 

  useEffect(() => {
    if (permissao !== "S") {
      history.push("/pm/empresa");
    }
  }, [permissao, history]);

  useEffect(() => {
    async function dadosEmpreGraph() {
      const a = await graphService.getDadosEmpre();
      setDadosEmpre(a);
    }
    dadosEmpreGraph();
  }, []);

  useEffect(() => {
  
  }, []);

  return resumo.clientes === 0 ? (
    <Card className="card-primary-result">
      <CardPrimaryResult>
        <PrimaryResult>
          <p>Dados de clientes inexistentes</p>
          <img src={PesquisarSemResult} alt="Sem clientes" />
        </PrimaryResult>
      </CardPrimaryResult>
    </Card>
  ) : (
    <>
      <TopBarPesquisa>
        <TopBarTitle>
          <p>Visão Geral</p>
          <h3>Dashboard</h3>
        </TopBarTitle>
       <TopBarDateImport>
       <p>Última Importação</p>
          <h3>{dadosEmpre.dataImportacao}</h3>
        </TopBarDateImport>
      </TopBarPesquisa>
      {checked ? (
        <Container>
          <Row>
            <Col lg={3} sm={4}>
              <CardDashboard
                cardName="Empresas Ativas"
                color="#FF5811"
                icone={business}
                numero={internationalNumberFormat.format(
                  dadosEmpre.quantidadeEmpresasAtivas
                )}
                fonteTitle={"#ffffff"}
                fonteNumero={"#f5f5f5"}
              />
            </Col>
            <Col lg={3} sm={4}>
              <CardDashboard
                cardName="Empresas Abertas"
                color="#66bb6a"
                icone={add_business}
                numero={internationalNumberFormat.format(
                  dadosEmpre.quantidadeEmpresasAbertas
                )}
                fonteTitle={"#ffffff"}
                fonteNumero={"#f5f5f5"}
              />
            </Col>
            <Col lg={3} sm={4}>
              <CardDashboard
                cardName="Empresas Extintas"
                color="#FF0000"
                icone={business_disabled}
                numero={internationalNumberFormat.format(
                  dadosEmpre.quantidadeEmpresasExtintas
                )}
                fonteTitle={"#ffffff"}
                fonteNumero={"#f5f5f5"}
              />
            </Col>
            <Col lg={3} sm={4}>
              <CardDashboard
                cardName="Empresas Cadastradas"
                color="#13597B"
                icone={company}
                numero={internationalNumberFormat.format(
                  dadosEmpre.quantidadeEmpresasCadastradas
                )}
                fonteTitle={"#ffffff"}
                fonteNumero={"#f5f5f5"}
              />
            </Col>
          </Row>
          <Row>
            <Col className="graficos" lg={12} sm={12}>
              <GraphGeo />
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12}>
              <GraphSetores />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col lg={12} sm={12}>
              <GraphSetores />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
