import React, { useCallback, useRef, useEffect } from "react";
import {
  Background,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  Row,
  Column,
} from "./styles";

export const ModalAbout = ({ showModal, setShowModal }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <ModalWrapper showModal={showModal}>
            <ModalHeader>
              <button
                onClick={() => setShowModal((prev) => !prev)}
                className="close"
                aria-label="Fechar"
              >
                <span>x</span>
              </button>
              <span className="title">Sobre o CIGAM</span>
              <span className="subtitle">
                ERP | CRM | BI | RH | PDV | Mobile
              </span>
            </ModalHeader>
            <ModalBody>
              <hr className="modalseparator" />
              <Row>
                <Column>Site - </Column>
                <Column>
                  <a href="http://www.cigam.com.br">www.cigam.com.br</a>
                </Column>
              </Row>
              <Row>
                <Column>Contato - </Column>
                <Column>
                  <a href="mailto:suporte@cigam.com.br?Subject=Contato">
                    suporte@cigam.com.br
                  </a>
                </Column>
              </Row>
              {localStorage.getItem("LIBERADO_PORTAL") !== "S" ? (
                <>
                  <Row>
                    <Column className="semLicenca">
                      Este sistema não está licenciado.
                      <br />
                      Entre em contato com a equipe comercial CIGAM.
                    </Column>
                  </Row>
                </>
              ) : (
                <></>
              )}

              {localStorage.getItem("RAZAOSOCIAL") === "undefined" ||
              localStorage.getItem("LIBERADO_PORTAL") !== "S" ? (
                <></>
              ) : (
                <>
                  <Row>
                    <Column className="licenca">
                      Este sistema está licenciado para
                    </Column>
                  </Row>
                  <Row>
                    <Column>Fantasia | Nome - </Column>
                    <Column>{localStorage.getItem("RAZAOSOCIAL")}</Column>
                  </Row>
                  <Row>
                    <Column>CNPJ/CPF - </Column>
                    <Column>{localStorage.getItem("CNPJ")}</Column>
                  </Row>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <p>
                Este software é de autoria da CIGAM SOFTWARE CORPORATIVA S.A.,
                todos os direitos reservados e protegidos pela Lei Nº 9.610, de
                19/02/1998 e tratados internacionais. Proibida a reprodução
                parcial ou total, por qualquer meio.
                <br /> O ERP CIGAM está registrado junto ao INPI (Instituto
                Nacional da Propriedade Industrial) conforme processo nº BR 51
                2018 000152-1
              </p>
            </ModalFooter>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
