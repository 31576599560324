import styled from "styled-components";

export const Styled = styled.div`
  header {
    display: flex;
    height: 60px;
    color: #6c7a8f;
    align-items: center;
  }
  header .title {
    font-size: 16px;
    margin-left: 20px;
    font-weight: 400;
  }
  header .subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
  }

  .logo {
    display: inline-block;
    width: -webkit-fill-available;
  }
  .complement {
    text-align: end;
  
  }


  header .logo {
    text-align: left;
    display: inline-block;
  }

  .iconCard {
    content: "\f003";
    text-align: left;
    padding: 10px auto;
    background-color: transparent;
    color: #6c7a8f;
    margin-bottom: 15px;
  }

  .card-header {
    width: 100%;
    height: 64px;
    background: #ffffff;
    opacity: 1;
    padding: 0.3rem 1.5rem;
  }
`;

export default Styled;
