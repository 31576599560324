import React from "react";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import "./style.css";

const TableResultsExtract = ({ expandRow, columns, tableResults }) => {
  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
  };
  
  return (
    <>
      <div style={{ marginTop: 30 }}>
        <BootstrapTable
          striped
          hover
          keyField="cnpj"
          data={tableResults}
          selectRow={selectRow}
          columns={columns}
          filter={filterFactory()}
        />
        <div className="">
          <img src="" alt="" />
        </div>
      </div>
    </>
  );
};

export default TableResultsExtract;
