import React from "react";

import GlobalStyle from "../assets/styles/global";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import Extrato from "../pages/Extrato";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";

import authService from "../services/authService";
import ModalAguarde from "../components/CardAguarde";

export default function PesquisaMercado(props) {
  let { cnpj } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const params = new URLSearchParams(props.location.search);

  /*useState(() => {
    console.log(params.get("token"));
    const tokenAPI = params.get("tokenApi");
    const token = params.get("token");
    console.log(params.toString());
    if (token === undefined || token === null || token === "") {
      setError("Token não encontrado");
      setLoading(false);
    } else {
      authService
        .autenticateBI(token)
        .catch((e) => {
          if (e === undefined || e === null || e === "") {
            setError('Erro ao realizar autenticação para acesso via BI');
          } else {
            setError(e.response.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);*/

  if (loading) {
    return (
      <ModalAguarde
        popupLoadVisible={loading}
        message=" Aguarde, processando...!"
      />
    );
  } else {
    return (
      <>
        <Header />
        <Sidebar />
        <div className="container-layout">
          {error !== null ? <a>{error}</a> : <Extrato cnpjParam={cnpj} />}
        </div>

        <GlobalStyle />
      </>
    );
  }
}
