// import jwtDecode from 'jwt-decode';
import axiosAuth from "../utils/axiosPMAuth";
import axios from "../utils/axiosPM";

class AuthService {
  loginInWithToken = (token) =>
    new Promise((resolve, reject) => {
      axiosAuth.defaults.headers["x-access-token"] = token;

      axiosAuth
        .get("/auth/validaToken")
        .then((response) => {
          if (response.data) {
            this.setSession(response.data);
            resolve(response.data.data);
          } else {
            reject({ error: "Erro ao Logar" });
          }
        })
        .catch((error) => {
          //const messages = errorHandler(error);

          reject(error);
        });
    });

  autenticate = (email, password) =>
    new Promise((resolve, reject) => {
      axiosAuth
        .post("/auth/authenticate", { email, password })
        .then((response) => {
          if (response.data) {
            this.setSession(response.data);
            resolve(response.data.data);
          } else {
            reject({ error: "Erro ao Logar" });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  autenticateBI = (token) =>
    new Promise((resolve, reject) => {
      axiosAuth
        .post(
          "cigam/authenticate/bi",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data) {
            this.setSession(response.data);
            resolve(response.data.data);
          } else {
            reject({ error: "Erro ao Logar" });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  isAuthenticated = async (props) => {
    var validade = new Date(localStorage.getItem("VALIDADE"));
    var token = localStorage.getItem("TOKEN");

    var hoje = new Date();

    if (hoje <= validade) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return true;
    } else {
      const response = await axiosAuth.post("/auth/session", { token });

      localStorage.setItem("TOKEN", response.data.token);
      localStorage.setItem("VALIDADE", response.data.validade);
      return true;
    }
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  setSession = (data) => {
    const {
      cnpj,
      contrato,
      codigoUsuario,
      nomeUsuario,
      razaoSocial,
      urlERPIntegation,
      podeUsarPesquisaDeMercado,
    } = data.data;

    const { token, validade, tokenApiCigam } = data;
    if (token) {
      localStorage.setItem("TOKEN", token);
      localStorage.setItem("VALIDADE", validade);
      localStorage.setItem("CNPJ", cnpj);
      localStorage.setItem("CONTRATO", contrato);
      localStorage.setItem("CODIGO_USUARIO", codigoUsuario);
      localStorage.setItem("NOME_USUARIO", nomeUsuario);

      if (!!razaoSocial) localStorage.setItem("RAZAOSOCIAL", razaoSocial);

      console.log(data.data);
      console.log(urlERPIntegation);

      if (!!urlERPIntegation)
        localStorage.setItem("URLINTEGRATION", urlERPIntegation);

      if (!!tokenApiCigam) localStorage.setItem("TOKENAPI", tokenApiCigam);

      localStorage.setItem(
        "LIBERADO_PORTAL",
        podeUsarPesquisaDeMercado ? "S" : "N"
      );

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("VALIDADE");
      localStorage.removeItem("CNPJ");
      localStorage.removeItem("CONTRATO");
      localStorage.removeItem("CODIGO_USUARIO");
      localStorage.removeItem("NOME_USUARIO");
      localStorage.removeItem("RAZAOSOCIAL");
      localStorage.removeItem("URLINTEGRATION");
      localStorage.removeItem("LIBERADO_PORTAL");
      localStorage.removeItem("TOKENAPI");

      delete axios.defaults.headers.common.Authorization;
    }
  };
}

const authService = new AuthService();

export default authService;
