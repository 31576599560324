import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container, ResUndefined, Voltar, Res } from "./styles";
import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
//components
import TopBarHeader from "../../components/TopBarHeader";
import { CardDashboard } from "../../components/CardDashboard";
import TableResultsExtract from "../../components/TableResultsExtract";
import ModalAguarde from "../../components/CardAguarde";
//services
import extratoCreditosService from "../../services/extratoCreditosService";

//icons e Images
import fileDownload from "../../assets/FileDownload.svg";
import lupa from "../../assets/Lupa.svg";
import Money from "../../assets/Money.svg";
import pesquisa_sem_resultado from "../../assets/pesquisa_sem_resultado.svg";

export default function ExtratoCreditos() {
  const { cnpjExtrato } = useParams();
  let history = useHistory();
  const [findResult, setFindResult] = useState([]);
  const [permissao] = useState(localStorage.getItem("LIBERADO_PORTAL"));

  const cnpj = cnpjExtrato;
  const [totalCreditosUtilizados, setTotalCreditosUtilizados] = useState([]);
  const [creditosPesquisa, setCreditosPesquisa] = useState([]);
  const [creditosDownload, setCreditosDownload] = useState([]);
  const [popupAguarde, setPopupAguarde] = useState(false);
  function getQueryParams(qs) {
    qs = qs.split("+").join(" ");
    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;
    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  }
  const queryString = getQueryParams(window.location.search);
  const razaoSocial = queryString.razaoSocial;

  useEffect(() => {
    if (permissao !== "S") {
      history.push("/pm/empresa");
    }
  }, [permissao, history]);
 
  useEffect(() => {
    const getExtratoCreditosEmpresa = async () => {
      setPopupAguarde(true);
      const extratoEmpresa = await extratoCreditosService.getExtratoByCompany(
        cnpj
      );
      setTotalCreditosUtilizados(extratoEmpresa.totalCreditosUtilizados);
      setCreditosPesquisa(extratoEmpresa.totalPesquisa);
      setCreditosDownload(extratoEmpresa.totalDownload);
      const extratoFormat = extratoEmpresa.data.map((extrato) => {
        let dateFormat = "";
        let tipo1 = "";

        if (extrato.tipo === "csv") {
          tipo1 = "Download CSV";
        } else {
          tipo1 = "Pesquisa";
        }
 
        if (!!extrato.createdAt) {
          var date1 = new Date(extrato.createdAt);
          dateFormat = moment(date1).format("DD/MM/YYYY");
        }

        return {
          ...extrato,
          empresa: razaoSocial,
          createdAt: dateFormat,
          tipo: tipo1,
        };
      });
      setFindResult(extratoFormat);
      setPopupAguarde(false);
    };

    getExtratoCreditosEmpresa();
  }, [cnpj, razaoSocial]);
  
  var columnsTable = [
    {
      dataField: "empresa",
      text: "Empresa  ",
    },
    {
      dataField: "nomeUsuario",
      text: "Usuário  ",
    },
    {
      dataField: "tipo",
      text: "Tipo",
      sort: true,
    },
    {
      dataField: "razaoSocial",
      text: "Empresa Pesquisada",
    },
    {
      dataField: "createdAt",
      text: "Data",
    },
    {
      dataField: "creditosUtilizados",
      text: "Créditos",
      sort: true,
    },
  ];
  console.log(findResult)
  return (
    <>
      <ModalAguarde
        popupLoadVisible={popupAguarde}
        message="Aguarde enquanto buscamos os créditos"
      />
      <TopBarHeader title="Empresas" subtitle="Extrato de créditos" />
      {popupAguarde === false ? (
        findResult.length !== 0 ? (
          <>
            <Container>
              <Row>
                <Col lg={4} sm={4}>
                  <CardDashboard
                    cardName="Créditos por pesquisa"
                    color="#66bb6a"
                    icone={lupa}
                    numero={creditosPesquisa}
                    fonteTitle={"#ffffff"}
                    fonteNumero={"#f5f5f5"}
                  />
                </Col>
                <Col lg={4} sm={4}>
                  <CardDashboard
                    cardName="Total de créditos utilizado"
                    color="#FF5811"
                    icone={Money}
                    numero={totalCreditosUtilizados}
                    fonteTitle={"#ffffff"}
                    fonteNumero={"#f5f5f5"}
                  />
                </Col>
                <Col lg={4} sm={4}>
                  <CardDashboard
                    cardName="Créditos por download"
                    color="#13597B"
                    icone={fileDownload}
                    numero={creditosDownload}
                    fonteTitle={"#ffffff"}
                    fonteNumero={"#f5f5f5"}
                  />
                </Col>
              </Row>
            </Container>

            <TableResultsExtract
              tableResults={findResult}
              columns={columnsTable}
            />
            <Res>
              <Link to="/pm/companies">
                <Voltar>Voltar</Voltar>
              </Link>
            </Res>
          </>
        ) : (
          <>
            <ResUndefined>
              <img src={pesquisa_sem_resultado} alt=""></img>
              <p>Sem resultados encontrados</p>

              <Link to="/pm/companies">
                <Voltar>Voltar</Voltar>
              </Link>
            </ResUndefined>
          </>
        )
      ) : null}
    </>
  );
}
