export function formatNumberMongoDBToReal(p_valor){

    console.log(p_valor)
    var valor = p_valor.replace(/^0+/, '');
    valor = valor.replace(',', '');

    
    var valorAux = valor.trim();
    var valorAux2 = valorAux.substring(0, valorAux.length -2);

    var valorSemDecimal = Number(valorAux2);
    var valorNumber = valorSemDecimal.toLocaleString("pt-BR");
    
    return valorNumber + ",00";
  }