import React from 'react'
import Tags from "../tagsInput/ReactTags"


const TagFilter = ({label,p_tags,p_noSuggestionsText,p_suggestions,p_handleDelete,p_handleAddition}) => 
{
    return(<div>
            <label>{label}:</label>
            <React.Fragment>
                <Tags tags={p_tags} placeholder={'Adicionar '+ label} noSuggestionsText={p_noSuggestionsText} suggestions={p_suggestions} handleDelete={p_handleDelete} handleAddition={p_handleAddition}/>
            </React.Fragment> 
        </div>
    )
}

export default TagFilter
