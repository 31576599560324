import React from "react";
import Card from "react-bootstrap/Card";

import { Ativo, Inativo } from "./styles";

const CardTextExtrato = ({
  subtitle,
  id,
  value,
  ultimoElementoDoCard,
  textChip,
  iconeChip,
  handleChip,
  statusChip,
}) => {
  return (
    <div>
      <Card.Subtitle>
        {subtitle}
        {textChip &&
          (statusChip ? (
            <Ativo className="ativo" onClick={() => handleChip()}>
              <img src={iconeChip} alt="" /> {textChip}
            </Ativo>
          ) : (
            <Inativo className="inativo" onClick={() => handleChip()}>
              <img src={iconeChip} alt="" /> {textChip}
            </Inativo>
          ))}
      </Card.Subtitle>

      <Card.Text id={id}> {value} </Card.Text>
      {ultimoElementoDoCard === true ? " " : " "}

      <hr></hr>
    </div>
  );
};
export default CardTextExtrato;
