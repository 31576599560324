import React, { useEffect, useState } from "react";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import { AiOutlineDelete } from "react-icons/ai";
import { ModalConfirm } from "../ModalConfirm";
import { useSnackbar } from "notistack";
import "./style.css";
import integracaoEmpresaService from "../../services/integracaoEmpresaService";

const TableResultsSingle = ({ columns, tableResults, setRefreshList }) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selected, setSelected] = useState([]);
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const openModal = () => {
    setShowModalConfirm((prev) => !prev);
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    color: "#def3ff",

    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        const newArray = selected.concat(row);

        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });

        setSelected(newArray);
        setEmpresasSelecionadas(newArrayIntegration);
      } else {
        const newArray = selected.filter((p) => p.cnpj !== row.cnpj);
        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });
        setSelected(newArray);
        setEmpresasSelecionadas(newArrayIntegration);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const newArray = selected.concat(rows);
        const newArrayIntegration = newArray.map(function (item, indice) {
          return { cnpj: item.cnpj };
        });
        setSelected(newArray);
        setEmpresasSelecionadas(newArrayIntegration);
      } else {
        setSelected([]);
        setEmpresasSelecionadas([]);
      }
    },
  };
  const deletar = () => {
    openModal();
  };
  useEffect(() => {
    const deleteAll = async () => {
      if (confirm) {
        const valida = selected.some((item) => item.status === "I");
        if (!valida) {
         
          await integracaoEmpresaService
            .deleteAllIntegracaoEmpresa(empresasSelecionadas)
            .then((res) => {
              enqueueSnackbar("Removido com sucesso!", {
                variant: "success",
              });
              setEmpresasSelecionadas([]);
              setRefreshList(true);
            });
        } else {
          
          enqueueSnackbar(
            selected.map((a) =>
              a.status === "I"
                ? "A empresa " +
                  a.razaoSocial +
                  " não pode ser removida"
                : null
            ),
            {
              variant: "error",
            }
          );
        }
      }
    };

    deleteAll();
    setConfirm(false);
  }, [confirm]);

  return (
    <>
      <ModalConfirm
        showModalConfirm={showModalConfirm}
        setShowModalConfirm={setShowModalConfirm}
        setConfirm={setConfirm}
        message={
          selected.length === 1
            ? "Tem certeza que deseja remover a empresa selecionada?"
            : "Tem certeza que deseja remover as empresas selecionadas?"
        }
      />
      <div style={{ marginTop: 30 }}>
        <BootstrapTable
          striped
          hover
          keyField="cnpj"
          data={tableResults}
          columns={columns}
          filter={filterFactory()}
          selectRow={selectRow}
        />
        <div className="">
          <img src="" alt="" />
        </div>
        {selected.length !== 0 ? (
          <>
            <div className="fab_del">
              <button
                className="delete"
                id={"btn"}
                onClick={() => deletar()}
                title="Remover"
              >
                <AiOutlineDelete
                  alt=""
                  style={{ fontSize: 24 }}
                  className="iconCard"
                />
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TableResultsSingle;
