import styled from "styled-components";

export const TopBarPesquisa = styled.div`
  display: flex;
  position: fixed;
  background: #ffffff;
  height: 64px;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  left: 0;
  right: 0;
  border-style: none;
  top: 50px;
  z-index: 500;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0%;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #ff5811;
    opacity: 1;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #6c7a8f;
    opacity: 1;
  }
`;

export const TopBarTitle = styled.div`
  margin-left: 5rem;
`;
