export function montaLinkParaGoogleMaps(tipoLogradouro,logradouro,numeroLogradouro,municipio){

    return `https://www.google.com/maps/dir/?api=1&destination=`+ tipoLogradouro+ ` ` + logradouro+ ` ` + numeroLogradouro + ` ` +municipio

}


export function montaLinkParaGoogle(razaoSocial){

    return `https://www.google.com/search?q=` + razaoSocial

}

export function montaLinkParaLinkedin(razaoSocial){

    return `https://www.linkedin.com/search/results/all/?keywords=` + razaoSocial

}


export function montaLinkParaTwitter(razaoSocial){

    return `https://twitter.com/search?q=` + razaoSocial

}
