import React, { useEffect, useState } from "react";

import { textFilter } from "react-bootstrap-table2-filter";
import Button from "react-bootstrap/Button";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container, Buttons } from "./styles";
import { useSnackbar } from "notistack";
import * as moment from "moment";
//components
import TableResultsSingle from "../../components/TableResultsSingle";
import { CardDashboard } from "../../components/CardDashboard";
import TopBarHeader from "../../components/TopBarHeader";
import { ModalConfirm } from "../../components/ModalConfirm";
//services
import integracaoEmpresaService from "../../services/integracaoEmpresaService";

//icons e Images
import integration from "../../assets/integration.svg";
import business from "../../assets/business.svg";
import { AiOutlineDelete } from "react-icons/ai";

const MinhasIntegracoes = () => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [findResult, setFindResult] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [deleteCnpj, setDeleteCnpj] = useState("");
  const [refreshList, setRefreshList] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const openModal = (cnpj) => {
    setShowModalConfirm((prev) => !prev);
    setDeleteCnpj(cnpj);
  };

  const getIntegracaoEmpresa = async () => {
    const empresasIntegracao =
      await integracaoEmpresaService.getIntegracaoEmpresaAll();
    setFindResult(empresasIntegracao);
  };

  useEffect(() => {
    const deleteEmp = async () => {
      if (confirm) {
        await integracaoEmpresaService
          .deleteIntegracaoEmpresa(deleteCnpj)
          .then((res) => {
            enqueueSnackbar("Empresa removida com sucesso!", {
              variant: "success",
            });
            setDeleteCnpj("");
          })
          .catch((error) => {
            enqueueSnackbar("Ocorreu um erro ao remover", {
              variant: "error",
            });
          })
          .finally(() => {});
      }
    };
    deleteEmp();
    setRefreshList(true);
    setConfirm(false);
  }, [confirm]);

  useEffect(() => {
    if (!refreshList) {
      getIntegracaoEmpresa();
    }

    setRefreshList(false);
  }, [refreshList]);

  var columnsTable = [
    {
      dataField: "razaoSocial",
      text: "Razão Social  ",
      filter: textFilter(),
    },
    {
      dataField: "cnpj",
      text: "CNPJ",
      filter: textFilter(),
    },
    {
      dataField: "status",
      text: "Status",
      filter: textFilter(),
      formatter: (cell, row) => {
        return row.status === "N" ? "Não Integrado" : "Integrado";
      },
    },

    {
      dataField: "id_erp",
      text: "Id ERP",
      filter: textFilter(),
    },
    {
      dataField: "data_criacao",
      text: "Data de criação",
      filter: textFilter(),
      formatter: (cell, row) => {
        return moment(row.data_criacao).format("DD/MM/YYYY");
      },
    },
    {
      dataField: "",
      text: "",
      align: (cell, row, rowIndex, colIndex) => {
        return "center";
      },
      formatter: (cell, row) => {
        return row.status === "N" ? (
          <Buttons>
            <Button
              title="Remover"
              className="buttons"
              onClick={() => openModal(row.cnpj)}
            >
              <AiOutlineDelete />
            </Button>
          </Buttons>
        ) : null;
      },
    },
  ];

  return (
    <>
      <ModalConfirm
        showModalConfirm={showModalConfirm}
        setShowModalConfirm={setShowModalConfirm}
        setConfirm={setConfirm}
        message={"Tem certeza que deseja excluir a empresa?"}
      />
      <TopBarHeader title="Integrações" subtitle="Minhas Integrações" />
      <Container>
        <Row>
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de empresas"
              color="#66bb6a"
              icone={business}
              numero={!!findResult ? findResult.length : "0"}
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de empresas integradas"
              color="#13597B"
              icone={integration}
              numero={
                !!findResult
                  ? findResult
                      .map((f) => f.status === "I")
                      .filter((a) => a === true).length
                  : "0"
              }
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
        </Row>
      </Container>
      <TableResultsSingle setRefreshList={setRefreshList} tableResults={findResult} columns={columnsTable} />
    </>
  );
};
export default MinhasIntegracoes;
