import styled from "styled-components";

export const Container = styled.div`
  .text-left {
    margin-top: 0;
  }
`;
export const Layout = styled.div`
  width: 80%;
  height: auto;
  align-self: center;
`;

