import React from "react";
import { Switch, Redirect } from "react-router-dom";

import GlobalStyle from "../assets/styles/global";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import Extrato from "../pages/Extrato";
import Dashboard from "../pages/Dashboard";
import Pesquisa from "../pages/Pesquisa";
import PrivateRoute from "../components/PrivateRoute";
import Companies from "../pages/Companies";
import ExtratoCreditos from "../pages/ExtratoCreditos";
import MinhasIntegracoes from "../pages/MinhasIntegracoes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Users from "../pages/Users";

export default function PesquisaMercado(props) {
  const params = new URLSearchParams(props.location.search);
  const cnpjSearch = params.get("cnpj");
  return (
    <>
      <Header />
      <Sidebar />
      <div className="container-layout">
        <Switch>
          <PrivateRoute exact path="/pm/pesquisa" component={Pesquisa} />
          <PrivateRoute exact path="/pm/empresa" component={()=>Extrato(undefined)} />
          <PrivateRoute exact path="/pm/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/pm/companies" component={Companies} />
          <PrivateRoute
            exact
            path="/pm/extrato-empresa/:cnpjExtrato"
            component={ExtratoCreditos}
          />
          <PrivateRoute
            exact
            path="/pm/integracoes"
            component={MinhasIntegracoes}
          />
          <PrivateRoute exact path="/pm/users" component={Users} />
          <PrivateRoute path="/pm/empresa/:cnpj" exact component={Extrato} />
          {/* <PrivateRoute path="/pm/empresabi" exact //component={Extrato({cnpjSearch})} 
          render={(props)=><Extrato{...props} cnpjParam={cnpjSearch} />}
          /> */}

          <Redirect from="/" to="/pm/dashboard" />
        </Switch>
      </div>

      <GlobalStyle />
    </>
  );
}
