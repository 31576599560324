import axios from '../utils/axiosPM';



export const getRequest = (url, resolve, reject) =>
  
axios
    .get(url, {headers: {  'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`  }})
    .then(response => {
      const { data } = response;

      resolve(data);
    })
    .catch(error => {
      reject(error);
    });

export const getWithParamsRequest = (url, params, resolve, reject) =>
axios
    .get(url, {...params, headers: {  'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`  }})
    .then(response => {
      const { data } = response;

      resolve(data);
    })
    .catch(error => {
      reject(error);
    });


export const putRequest = (url, data, resolve, reject) => {
  return axios
    .put(url, data, {headers: {  'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`  }})
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data);
    });
};

export const deleteRequest = (url,  resolve, reject) => {
  return axios
    .delete(url)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data);
    });
};

export const postRequest = (url, data, resolve, reject) => {
  return axios
    .post(url, data, {headers: {  'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`  }})
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data);
    });
  };

export const postWithParamsRequest = (url, params, resolve, reject) => {
  return axios
    .post(url, {...params, headers: {  'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`  }})
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data);
    });
};