import React, { useState, useCallback } from "react";
import { Styled, InfComplementar, Divisor } from "./styledComponent";
import { ModalSocios } from "../../components/ModalSocios";
import { AiFillLinkedin } from "react-icons/ai";
import { BsJournalPlus } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import CardTextComplement from "../CardTextComplement";

const CardTextSocioExtrato = ({
  Arraysocios,
  cnpj,
  setRefreshList,
  complements,
  attribute,
}) => {
  const [showModalSocios, setShowModalSocios] = useState(false);
  const [idSocioSelected, setIdSocioSelected] = useState(false);
  const [nomeSocioSelected, setNomeSocioSelected] = useState(false);
  const openModalSocios = useCallback((props) => {
    setShowModalSocios((prev) => !prev);
    setIdSocioSelected(props.id_socio);
    setNomeSocioSelected(props.nome_socio);
  }, []);

  return Arraysocios.map((socio, index) => (
    <div key={index+"iSoc"}>
      <ModalSocios
        showModal={showModalSocios}
        setShowModal={setShowModalSocios}
        cnpjSelected={cnpj}
        setRefreshList={setRefreshList}
        id_socio={idSocioSelected}
        nome_socio={nomeSocioSelected}
        attributes={attribute}
      />
      <Styled key={index+"StyleSocios"}>
        <Card.Text>
          <a
            href={
              `https://www.linkedin.com/search/results/all/?keywords=` +
              socio.nomeSocio.replace(/ /g, "%20")
            }
            className="LinksExternos"
          >
            <AiFillLinkedin className="iconCard" style={{ color: "#3b96c2" }} />
          </a>
          <b>{socio.nomeSocio}</b>
        </Card.Text>
        <InfComplementar
          id={"complement"}
          onClick={() =>
            openModalSocios({
              id_socio: socio.cpfRepresentanteLegal,
              nome_socio: socio.nomeSocio,
            })
          }
          title={"Adicionar complementos"}
        >
          <BsJournalPlus alt="" className="iconPlus" />
        </InfComplementar>
        <hr></hr>
      </Styled>
      {complements.map((complement, index) =>
        complement.id_socio === socio.cpfRepresentanteLegal ? (
          <CardTextComplement
            key={index}
            id={complement.id_atribute}
            value={complement.values}
            ultimoElementoDoCard={true}
            idComplement={complement._id}
            qtdLikes={complement.qtdeLikes}
            qtdDislikes={complement.qtdeDisLikes}
            like={complement.usuarioCurtiu}
            dislike={complement.usuarioDislike}
            setRefreshList={setRefreshList}
            attribute={attribute}
          />
        ) : null
      )}
      <Divisor />
    </div>
  ));
};

export default CardTextSocioExtrato;
