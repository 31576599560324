import cigamWebApi from "../services/webCigamApiService";
import axios from "axios";
import clienteEmpresaService from "../services/clienteEmpresaService";

export async function importaEmpresaWebApi(
  nome_completo,
  fone,
  fax_fone,
  endereco,
  bairro,
  municipio,
  uf,
  cep,
  cnpj,
  fantasia,
  faturamento,
  numero,
  complemento,
  cnae,
  optanteSimples
) {
  var usuarioCriacao = localStorage.getItem("CODIGO_USUARIO");

  var obj = {
    NomeCompleto: nome_completo,
    Fantasia: fantasia,
    Endereco: endereco,
    Numero: numero,
    Bairro: bairro,
    Municipio: municipio,
    Uf: uf,
    Telefone: fone,
    CnpjCpf: cnpj,
    FaxFone: fax_fone,
    UsuarioCriacao: usuarioCriacao,
    Cep: cep,
    Cnae: cnae,
    Faturamento: faturamento.replace(".", "").replace(",", "."),
    Complemento: complemento,
    OptanteSimples: optanteSimples,
  };

  try {
    const res = await clienteEmpresaService.postImportarEmpresa({
      urlIntegration: localStorage.getItem("URLINTEGRATION"),
      tokenApi: localStorage.getItem("TOKENAPI"),
      data: [obj]
    });

    if (!!res) {
      console.log(res)
      if (!!res.data) {
        if (res.data.data.Cadastrados.length === 0) {
          alert("Empresa já cadastrada");
        } else {
          alert("Empresa criada com sucesso! Código " + res.data.data.Cadastrados[0].codigo);
          return res.data;
        }
      }
    }
    
  } catch (error) {
    alert("Erro ao cadastrar Empresa");
  }
}
