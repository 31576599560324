import { postRequest } from "./request";

class CadastrarUsuarioService {
  postCadastrarUsuario = data => {
    return new Promise((resolve, reject) => {
      postRequest('/auth/registrar', data, resolve, reject);
    });
  }
 
}

export default new CadastrarUsuarioService();
