import React from "react";
import Card from "react-bootstrap/Card";
import CardTextExtrato from "../CardTextExtrato";

const CardTextRegimeTributario = ({ listaRegimeTributario }) => {
  return listaRegimeTributario.map((regime, index) => (
   
    <div key={index+"rTrib"}>
      <Card.Subtitle>Regime Tributário</Card.Subtitle>
      <p className="card-text">Forma: {regime.formadetributacao} </p>
      <p className="card-text">Ano: {regime.ano}</p>
      <hr />
    </div>
  ));
};

export default CardTextRegimeTributario;
