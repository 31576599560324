import styled from "styled-components";

export const Styled = styled.div`
  .container-layout {
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #6c7a8f;
  }
  .container {
    width: 365px;
    background: #ffffff;
    border-radius: 7px;
    padding: 20px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
  }
  .usuario {
    padding: 0.5rem;
  }
  .logo {
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    border: 0;
  }
  .img-responsive {
    max-width: 100%;
    height: auto;
  }
  .logintext {
    font: 20px Roboto;
    letter-spacing: 0;
    color: #999;
    opacity: 1;
    margin-bottom: 10px;
    border: 0;
  }

  .form-group {
    margin-bottom: 20px;
    span{
      color:red;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .form-group {
    border: none;
  }
  .form-control {
    font: 14px Roboto;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #307da2;
    border-radius: 7px;
    opacity: 1;
  }


 
  .continuar-conectado {
    color: #6c7a8f !important;
    font-size: 14px;
    opacity: 0.8;
  }
  .continuar-conectado input {
    font-size: 14px;
    color: blue;
    font-style: bold;
    margin-right: 5px;
  }
  .btn-login {
    height: 42px;

    text-align: center;
    background: #307da2 0% 0% no-repeat padding-box;
    color: #fff;
    border-radius: 7px 7px 7px 7px;
    border: none;
    font-size: 16px;
    font-family: "Roboto";

    
    opacity: 1;
  }
`;
export default Styled;
