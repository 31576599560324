import React, { useCallback, useRef, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { RiUserAddLine } from "react-icons/ri";
import {
  Background,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  Row,
  Column,
} from "./styles";
import Button from "react-bootstrap/Button";
import empresaUsuarioService from "../../services/empresaUsuarioService";

export const ModalEmpresa = ({
  showModal,
  setShowModal,
  setRefreshList,
  empresa,
}) => {
  const { urlERPIntegation, cnpj } = empresa;
  const modalRef = useRef();
  const [newUrl, setUrl] = useState(urlERPIntegation);
  const { enqueueSnackbar } = useSnackbar();

  console.log(urlERPIntegation);
  console.log(newUrl);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const handleChange = (event, value = 0) => {
    console.log(event?.target?.value);
    setUrl(event?.target?.value);
  };

  const handleSubmit = () => {
    if (newUrl === "") {
      enqueueSnackbar("URL não informada", {
        variant: "error",
      });
      return;
    } else {
      empresaUsuarioService
        .postEmpresaUrl({ cnpj, urlERPIntegation: newUrl })
        .then((res) => {
          enqueueSnackbar("URL atualizada com sucesso", {
            variant: "success",
          });
          setShowModal(false);
          setRefreshList();
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Erro ao atualizar.", {
            variant: "error",
          });
        });

      console.log(newUrl);
    }
  };

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal}>
          <ModalWrapper showModal={showModal}>
            <ModalHeader>
              <button
                onClick={() => setShowModal((prev) => !prev)}
                className="close"
                aria-label="Fechar"
              >
                <span>x</span>
              </button>

              <span className="title">
                <RiUserAddLine className="iconeHeader" />
                Cadastrar URL Integração Cigam
              </span>
            </ModalHeader>
            <ModalBody>
              <hr className="modalseparator" />

              <Row>
                <Column>
                  <form key={cnpj} id="urlId">
                    <input
                      id="urlIntegration"
                      key={cnpj + "inputid"}
                      type="text"
                      className="form-full"
                      placeholder="URL"
                      onChange={handleChange}
                      name="urlIntegratione"
                      defaultValue={urlERPIntegation}
                    ></input>
                  </form>
                </Column>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="none"
                className="buttons"
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
