export function MontaDescircaoFiltros(razaoSocial,capitalSocial,tagsMunicipio,tagsEstado,tagsCnaes,
    porteEmpresa,nomeSocio,raizCNPJ,listarEmpresasDaBase,listarFiliais,listarEmpresaNaoAtivas)
  {
    var i;
    var filter = [];
  
    if(razaoSocial !== undefined && razaoSocial !== "")
    {
      filter = ' / Razão Social: ' + razaoSocial;
    }
    
    if(capitalSocial  !== undefined)
    {
      if(capitalSocial !== '0'){
        filter += ' / Capital Social maior que: ' + capitalSocial;
      }
    }
  
    if(tagsMunicipio !== undefined && tagsMunicipio !== "")
    {
      filter += ' / Município: '
      i = 0;
      for(i in tagsMunicipio)
      {
        if(i > 0)
        {
          filter += ', ' + tagsMunicipio[i].id.toUpperCase();
        }else
        { 
          filter += ' ' + tagsMunicipio[i].id.toUpperCase();
        }
      }
    }
  
    if(tagsEstado !== undefined && tagsEstado !== "")
    {
      
      filter += ' / UFs: '
      i=0;
      for(i in tagsEstado){
        if(i > 0)
        {
          filter += ', ' + tagsEstado[i].id;
        }else
        {
          filter += ' ' + tagsEstado[i].id;
        }
      }
    }
  
    if(tagsCnaes !== undefined && tagsCnaes !== "")
    {
      
      filter += ' / CNAEs: '
      i = 0;
      for(i in tagsCnaes)
      {
        if(i > 0)
        {
          filter += ', ' +  tagsCnaes[i].id;
        }else
        {
          filter += ' ' +  tagsCnaes[i].id;
        }
  
      }
    }
  
    if(porteEmpresa !== undefined && porteEmpresa !== "")
    {
      filter += ' / Porte Empresa: '+ porteEmpresa;
    }
  
    if(nomeSocio !== undefined && nomeSocio !== "")
    {
      filter += ' / Nome sócio: '+ nomeSocio;
    }
  
  
    if(listarEmpresasDaBase !== undefined && listarEmpresasDaBase === true)
    {
      filter += ' '
    }else
    {
      filter += ' / Apenas empresas não cadastradas na base'
    }
  
    if(listarFiliais !== undefined && listarFiliais !== false)
    {
      filter += ' '
    }else
    {
      filter += ' / Apenas empresas Matriz'
    }
  
    if(listarEmpresaNaoAtivas !== undefined && listarEmpresaNaoAtivas === true)
    {
      filter += ' '
    }else
    {
      filter += ' / Apenas empresas Ativas'
    }
  
  
    return filter.substring(2,filter.length);
  
  }