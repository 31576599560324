import React from "react";

import Pesquisa from "./pesquisa1.svg";
import Arquivos from "./documento.svg";
import Contatos from "./contatos.svg";
import Agenda from "./agenda.svg";
import Home from "./home.svg";
import Dashboard from "./dashboard.svg";

export const ImagemHome = () => (
  <img src={Home} alt="Home" height="20" width="20" />
);
export const ImagemArquivos = () => (
  <img src={Arquivos} alt="Home" height="22" width="22" />
);
export const ImagemContatos = () => (
  <img src={Contatos} alt="Home" height="19" width="19" />
);
export const ImagemPesquisa = () => (
  <img src={Pesquisa} alt="Home" height="20" width="22" />
);
export const ImagemAgenda = () => (
  <img src={Agenda} alt="Home" height="20" width="22" />
);
export const ImagemDashboard = () => (
  <img src={Dashboard} alt="Dashboard" height="20" width="22" />
);
