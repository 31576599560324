export function removeMunicipiosTags(tagsEstado,tagsMunicipio){

    if(tagsEstado === []){
        return [];
    }else{

        if(tagsEstado !== undefined){
            var i;
            var arrayEstadosAux = [];
            
            for (i = 0; i < tagsEstado.length; i++) {
        
                arrayEstadosAux[i] = tagsEstado[i].id;
            }
        
            function inEstado(value) {
        
                var muninicipioEstado = value.name.substring(0,2);
                return arrayEstadosAux.includes(muninicipioEstado);
        
            }
    
            return tagsMunicipio.filter(inEstado);

        }else{
            return [];
        }
    }
}