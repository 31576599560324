import cigamAPISQLServer from "../services/apiCadastroDeEmpresas";

export async function importaEmpresa(
  nome_completo,
  fone,
  fax_fone,
  endereco,
  bairro,
  municipio,
  uf,
  cep,
  cnpj,
  fantasia,
  faturamento,
  numero,
  complemento,
  cnae
) {
  var cd_representante = "2000";
  var usuarioCriacao = localStorage.getItem("CODIGO_USUARIO");
  var cd_pais = "1";

  var obj = {
    nome_completo: nome_completo,
    fone: fone,
    fax_fone: fax_fone,
    endereco: endereco,
    bairro: bairro,
    municipio: municipio,
    uf: uf,
    cep: cep,
    cnpj: cnpj,
    fantasia: fantasia,
    faturamento: 0,
    numero: numero,
    complemento: " ",
    cd_pais: cd_pais,
    cd_representante: cd_representante,
    usuario_criacao: usuarioCriacao,
    cnae,
  };

  var res;

  try {
    res = await cigamAPISQLServer.post("/empresa", obj);
  } catch (error) {}

  if (!!res) {
    if (!!res.data) {
      if (!!res.data.erro) {
        alert(res.data.erro);
      } else {
        alert("Empresa criada com sucesso! Código " + res.data);
        return res.data;
      }
    }
  }
}

export async function reservaEmpresa(
  nome_completo,
  fone,
  fax_fone,
  endereco,
  bairro,
  municipio,
  uf,
  cep,
  cnpj,
  fantasia,
  faturamento,
  numero,
  complemento,
  cnae,
  reserva
) {
  var cd_representante = "2000";
  var usuarioCriacao = localStorage.getItem("CODIGO_USUARIO");
  var cd_pais = "1";

  var obj = {
    nome_completo: nome_completo,
    fone: fone,
    fax_fone: fax_fone,
    endereco: endereco,
    bairro: bairro,
    municipio: municipio,
    uf: uf,
    cep: cep,
    cnpj: cnpj,
    fantasia: fantasia,
    faturamento: 0,
    numero: numero,
    complemento: " ",
    cd_pais: cd_pais,
    cd_representante: cd_representante,
    usuario_criacao: usuarioCriacao,
    cnae,
    reserva,
  };

  var res;

  try {
    res = await cigamAPISQLServer.post("/empresa/reservar", obj);
  } catch (error) {}

  if (!!res) {
    if (!!res.data) {
      if (!!res.data.erro) {
        alert(res.data.erro);
      } else {
        alert("Empresa reservada com sucesso.");
        return res.data;
      }
    }
  }
}
