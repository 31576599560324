import styled from "styled-components";

export const Card = styled.div`
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;

  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
  margin-bottom: 20px;
`;

export const CardBody = styled.div``;
export const CardBlock = styled.div`

  img{
    width:60px;
    height:60px;
    padding-top: 05px;
    opacity: 0.3; 
  }
  .count-numbers{
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
  }

  .count-name{
    position: absolute;
    right: 35px;
    top: 65px;
    font-style: italic;
    opacity: 0.7;
    display: block;
    font-size: 18px;
  }

`;
