import styled from "styled-components";

export const Main = styled.div`
  width: 60px;
  height: auto;
  display: flex;
`;

export const DivLike = styled.div`
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #28a745;
  }

 
`;
export const Likes = styled.div`
  font-size: 10px;
`;

export const DivDislike = styled.div`
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #dc3545;
  }
 
`;
export const Dislikes = styled.div`
  font-size: 10px;
`;
