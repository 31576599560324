import React, { useCallback, useRef, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { RiUserAddLine } from "react-icons/ri";
import {
  Background,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  Row,
  Column,
} from "./styles";
import Button from "react-bootstrap/Button";
import CadastrarUsuarioService from "../../services/cadastrarUsuarioService";

export const ModalAddUsers = ({ showModal, setShowModal, setRefreshList }) => {
  const modalRef = useRef();
  const [user, setUser] = useState({});
  const [senha, setSenha] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };
  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  const handleChange = (event, value = 0) => {
    const newValue = user;
    newValue[event?.target?.name] = event?.target?.value;
    setUser({ ...newValue });
  };
  const handleChangeSenha = (event, value = 0) => {
    const newValue = senha;
    newValue[event?.target?.name] = event?.target?.value;
    setSenha({ ...newValue });
  };

  const handleSubmit = (publish) => {
    if (senha.senhaAut?.length === 0) {
      enqueueSnackbar("Campo senha de autorização obrigatório", {
        variant: "error",
      });
      return;
    }

    if (senha.senhaAut !== "@zyba.@") {
      enqueueSnackbar("Senha de autorização incorreta", {
        variant: "error",
      });
      return;
    }

    if (user.cnpj?.length === 0) {
      enqueueSnackbar("Campo CNPJ obrigatório", {
        variant: "error",
      });
      return;
    }
    if (user.nome?.length === 0) {
      enqueueSnackbar("Campo nome obrigatório", {
        variant: "error",
      });
      return;
    }
    if (user.password?.length === 0) {
      enqueueSnackbar("Campo senha obrigatório", {
        variant: "error",
      });
      return;
    }
   
    return CadastrarUsuarioService.postCadastrarUsuario(user)
      .then((res) => {
        enqueueSnackbar("Usuário criado com sucesso", {
          variant: "success",
        });
        setShowModal(false);
        setUser({});
      })
      .catch((error) => {
        enqueueSnackbar("E-mail ja cadastrado", {
          variant: "error",
        });
      })
      .finally(() => {
        setRefreshList();
      });
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <ModalWrapper showModal={showModal}>
            <ModalHeader>
              <button
                onClick={() => setShowModal((prev) => !prev)}
                className="close"
                aria-label="Fechar"
              >
                <span>x</span>
              </button>

              <span className="title">
                <RiUserAddLine className="iconeHeader" />
                Adicionar Usuário
              </span>
            </ModalHeader>
            <ModalBody>
              <hr className="modalseparator" />

              <Row>
                <Column>
                  <form>
                    <InputMask
                      id="cnpj"
                      mask="99999999999999"
                      maskPlaceholder={null}
                      type="text"
                      className="form-full"
                      placeholder="CNPJ"
                      onChange={handleChange}
                      name="cnpj"
                    ></InputMask>
                    <input
                      id="nome"
                      type="text"
                      className="form-full"
                      placeholder="Nome"
                      onChange={handleChange}
                      name="nome"
                    ></input>
                    <input
                      id="value"
                      type="email"
                      className="form-full"
                      placeholder="E-mail"
                      onChange={handleChange}
                      name="email"
                    ></input>
                    <input
                      id="password"
                      type="text"
                      className="form-full"
                      placeholder="Senha"
                      onChange={handleChange}
                      name="password"
                    ></input>
                    <p>Senha de autorização</p>
                    <input
                      id="senhaAut"
                      type="password"
                      className="form-full"
                      placeholder="Autorização para criar usuário"
                      onChange={handleChangeSenha}
                      name="senhaAut"
                    ></input>
                  </form>
                </Column>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="none"
                className="buttons"
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
