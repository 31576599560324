import styled from "styled-components";

export const Ativo = styled.button`
  border-radius: 20px;
  border: none;
  color: #ECBF11;
  background-color: #f2ec6f;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin-left: 20px;
  :active{
    border: none;
    text-decoration: none;
  }
`;
export const Inativo = styled.button`
  border-radius: 20px;
  border: none;
  color: #ebebeb;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ebebeb;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  opacity: 0.8;
  margin-left: 20px;
  :hover {
    color: #ffffff;
    background-color: #ecbf11;
  }
`;
