import styled from "styled-components";

export const CardTextDashboard = styled.div``;

export const Container = styled.div`
  .text-left {
    margin-top: 0;
  }
`;
export const Layout = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1315px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const ContainerMapInfo = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
`;
export const InfoMap = styled.div`
width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ContainerPercent = styled.div`

  align-items: center;
  padding: 10px;
  font-size: 3rem;
  font-weight: 500;
  color: #ff5811;
  @media screen and (max-width: 870px) {
  font-size: 2rem;
  }
`;

export const ContainerDescricao = styled.div`
  align-items: center;
  font-size: 1.5rem;
  @media screen and (max-width: 870px) {
  font-size: 1rem;
  }
`;

export const ContainerEstado = styled.div`
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  @media screen and (max-width: 870px) {
  font-size: 1rem;
  }
`;
//Mapa
export const Mapa = styled.div`
  max-width: 90vw;
  #graphmap .highcharts-root {
    height: auto;
    width: auto;
  }
`;

//Grafico
export const ContainerGrafico = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
`;

export const Grafico = styled.div`
  min-width: 40vw;
  max-width: 90vw;
`;
export const InfoMapGrafico = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;

export const ContainerDescricaoGrafico = styled.div`
  align-items: left;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 30px;
  padding-bottom: 10px;
  @media screen and (max-width: 870px) {
  font-size: 1rem;
  }
`;
