import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: stretch;
  .text {
    margin-left: 10px;
    font-size: 14px;
    margin-top: -3px;
  }
`;
export const Subtitle = styled.div`
  display: flex;
  .subtitle a {
    color: #307da2;
    /* color: #FF5811 */
  }
`;
export const Ativo = styled.button`
  border-radius: 20px;
  border: none;
  color: #ecbf11;
  background-color: #f2ec6f;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin-left: 20px;
  :active {
    border: none;
    text-decoration: none;
  }
`;
export const Inativo = styled.button`
  border-radius: 20px;
  border: none;
  color: #ebebeb;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ebebeb;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  opacity: 0.8;
  margin-left: 20px;
  :hover {
    color: #ffffff;
    background-color: #ecbf11;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Right = styled.div``;

export const Left = styled.div``;
