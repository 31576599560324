import { getRequest, postRequest } from "./request";

class ComplementService {
  getAttributes = () =>
    new Promise((resolve, reject) => {
      getRequest(`/informacaoComplementar/atributes`, resolve, reject);
    });

  getComplement = () =>
    new Promise((resolve, reject) => {
      getRequest(`/informacaoComplementar`, resolve, reject);
    });

  getComplementID = (id) => {
    return new Promise((resolve, reject) => {
      getRequest(`/informacaoComplementar/id/${id}`, resolve, reject);
    });
  };

  getComplementCNPJ = (cnpj) => {
    return new Promise((resolve, reject) => {
      getRequest(`/informacaoComplementar/cnpj/${cnpj}`, resolve, reject);
    });
  };

  postComplement = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("/informacaoComplementar", data, resolve, reject);
    });
  };

  putComplement = (id, data) => {
    return new Promise((resolve, reject) => {
      postRequest(`/informacaoComplementar/${id}`, data, resolve, reject);
    });
  };

  postLike = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("/informacaoComplementar/like", data, resolve, reject);
    });
  };
}

export default new ComplementService();
