import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { textFilter } from "react-bootstrap-table2-filter";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container } from "./styles";
import * as moment from "moment";
import Button from "react-bootstrap/Button";
import { useCookies } from "react-cookie";
//components
import TopBarHeader from "../../components/TopBarHeader";
import { CardDashboard } from "../../components/CardDashboard";
import { ModalEmpresa } from "../../components/ModalEmpresa";
//services
import empresaUsuarioService from "../../services/empresaUsuarioService";

//icons e Images
import business from "../../assets/business.svg";
import people from "../../assets/people.svg";
import TableResultsExtract from "../../components/TableResultsExtract";

export default function Companies() {
  let history = useHistory();
  const [findResult, setFindResult] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [permissao] = useState(localStorage.getItem("LIBERADO_PORTAL"));
  const [cnpjUser] = useState(localStorage.getItem("CNPJ"));
  const [showModalUrl, setShowModalUrl] = useState(false);
  const [selectedEmpresa, setSelectEmpresa] = useState('');
  const [refreshList, setRefreshList] = useState(false);

  useEffect(() => {
    if (permissao !== "S" || cnpjUser !== '93578813000144') {
      history.push("/pm/empresa");
    }
  }, [permissao, history]);

  useEffect(() => {
    const getCompaniesUser = async () => {
      const empresasUsuario = await empresaUsuarioService.getEmpresasUsuario();

      const empresFormat = empresasUsuario.map((emp) => {
        let dateFormat = "";

        if (!!emp.ultimo_acesso) {
          var date1 = new Date(emp.ultimo_acesso);
          dateFormat = moment(date1).format("DD/MM/YYYY");
        }

        return { ...emp, ultimoAcesso: dateFormat };
      });

      setFindResult(empresFormat);
    };
    const getUsuarios = async () => {
      const usuariosEmpresa = await empresaUsuarioService.getUsuarios();
      setUsuarios(usuariosEmpresa);
    };
    getCompaniesUser();
    getUsuarios();

    setRefreshList(false);
  }, [refreshList]);

  var columnsTable = [
    {
      dataField: "razaoSocial",
      text: "Razão Social  ",
      filter: textFilter(),
    },
    {
      dataField: "cnpj",
      text: "CNPJ",
      filter: textFilter(),
    },
    {
      dataField: "contrato",
      text: "Contrato",
      filter: textFilter(),
    },
    {
      dataField: "ultimoAcesso",
      text: "Último Acesso",
    },
    {
      dataField: "",
      text: "",
      align: (cell, row, rowIndex, colIndex) => {
        return "center";
      },
      formatter: (cell, row) => {
        return (
          <Button
            title="Extrato Créditos"
            className="buttons"
            onClick={() => PushRedirect(row)}
          >
            Créditos
          </Button>
        );
      },
    },
    {
      dataField: "",
      text: "",
      align: (cell, row, rowIndex, colIndex) => {
        return "center";
      },
      formatter: (cell, row) => {
        return (
          <Button
          className="buttons"
          onClick={() => openModalURL(row)}
          title={"URL Integração"}
        >
         
    
          URL
        </Button>
        );
      },
    },
  ];

  function PushRedirect(p_row) {
    history.push(
      "extrato-empresa" +
        "/" +
        p_row.cnpj +
        "?razaoSocial=" +
        p_row.razaoSocial,
      "_self"
    );
  }

  function openModalURL(p_row){
    console.log(p_row.cnpj);
    console.log(p_row);
    setSelectEmpresa(p_row);
    setShowModalUrl(true);
  }

  return (
    <>
      <TopBarHeader title="Empresas" subtitle="Empresas do usuário" />
      <Container>
        <Row>
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de empresas"
              color="#66bb6a"
              icone={business}
              numero={!!findResult ? findResult.length : "0"}
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
          <Col lg={6} sm={6}>
            <CardDashboard
              cardName="Quantidade de usuários"
              color="#13597B"
              icone={people}
              numero={!!usuarios ? usuarios.length : "0"}
              fonteTitle={"#ffffff"}
              fonteNumero={"#f5f5f5"}
            />
          </Col>
        </Row>
      </Container>
      <TableResultsExtract tableResults={findResult} columns={columnsTable} />
      <ModalEmpresa
        showModal={showModalUrl}
        setShowModal={setShowModalUrl}
        setRefreshList={setRefreshList}
        empresa = {selectedEmpresa}
       // urlAtual = {}
      />
    </>
  );
}
