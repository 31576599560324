import { getRequest, postRequest } from "./request";

class ExtratoCreditosService {
  getExtratoCreditosEmpresa = () =>
    new Promise((resolve, reject) => {
      getRequest(`extratoCreditos/companies`, resolve, reject);
    });

  getExtratoByCompany = (cnpj) =>
    new Promise((resolve, reject) => {
      getRequest(`extratoCreditos/byCompany?cnpj=${cnpj}`, resolve, reject);
    });

  getExtratoCreditosUsuario = () =>
    new Promise((resolve, reject) => {
      getRequest(`extratoCreditos/users`, resolve, reject);
    });

  postExtratoCreditos = (data) =>
    new Promise((resolve, reject) => {
      postRequest("/extratoCreditos", data, resolve, reject);
    });

  // postRemoveCliente = (cnpj) =>
  // new Promise((resolve, reject) => {
  //   postRequest("clienteempresa/remove",  { cnpj } , resolve, reject);
  // });
}

export default new ExtratoCreditosService();
