import axios from "axios"

const cigamAPISQLServer = axios.create({
    baseURL: 'https://pmnode.cigam.com.br/node/CGNodeIntegration/myapp/',
});




export default cigamAPISQLServer;
