import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  max-width: 100vw;
`;

export const TopBarPesquisa = styled.div`
  display: flex;
  position: fixed;
  background: #ffffff;
  height: 64px;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  left: 0;
  right: 0;
  border-style: none;
  top: 50px;
  z-index: 500;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0%;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #ff5811;
    opacity: 1;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    color: #6c7a8f;
    opacity: 1;
  }
`;

export const TopBarTitle = styled.div`
  margin-left: 5rem;
`;
export const TopBarDateImport = styled.div`
  float: right;
  margin-right: 2.2rem;
  text-align-last: center;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
`;
export const TopSelect = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  p {
    padding: 10px;
    font-size: 14px;
    color: #6c7a8f;
  }
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const CardPrimaryResult = styled.div`
  margin-top: 0px;
  border-radius: 16px;
`;

export const PrimaryResult = styled.div`
  padding: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  img {
    width: 142px;
    height: 186px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01px;
    color: #6c7a8f;
    opacity: 1;
    color: #6c7a8f;
    margin: 0;
  }
`;
