import { getRequest, putRequest, postRequest } from "./request";

class EmpresaUsuarioService {
  getEmpresasUsuario = () =>
    new Promise((resolve, reject) => {
      getRequest(`empresaUsuario`, resolve, reject);
    });
  getUsuarios = () =>
    new Promise((resolve, reject) => {
      getRequest(`empresaUsuario/usuarios`, resolve, reject);
    });

  postEmpresaUrl = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("/empresaUsuario/urlintegration", data, resolve, reject);
    });
  };
}

export default new EmpresaUsuarioService();
