import React from "react";
import { TopBarTitle, TopBarPesquisa } from "./styles";

const TopBarHeader = ({ title, subtitle }) => {
  return (
    <>
    

      <TopBarPesquisa>
        <TopBarTitle>
          <p>{subtitle}</p>
          <h3>{title}</h3>
        </TopBarTitle>
      </TopBarPesquisa>
    </>
  );
};
export default TopBarHeader;
