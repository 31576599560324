import React, { useState } from "react";
import { useCookies } from "react-cookie";
import InputMask from "react-input-mask";

import "../../../public/Pesquisar.svg";
import "../../../public/voltar.svg";
import "../../../public/clear.svg";
import "./buttonStyledPage.css";

export const ButtonSearchMobile = ({ handleKeyPressEnter, handleFind }) => {
  const [pesquisar, setPesquisar] = useState(true);
  const mascaraCNPJ = "99.999.999/9999-99";
  const [cookies] = useCookies([""]);
  const [cnpj, setCnpj] = useState(cookies.cnpjExtrato);

  const alterarBotao = () => {
    setPesquisar(!pesquisar);
  };

  const keypress = (e) => {
    const value = e.target.value.replaceAll("_", "");
    handleKeyPressEnter(e);
    if (value.length === 18) {
      handleFind();
    }
  };

  return pesquisar ? (
    <div className="pesquisar">
      <button onClick={alterarBotao} className="pesquisar-botao">
        <img src="./Pesquisar.svg" alt="Pesquisar" />
      </button>
    </div>
  ) : (
    <div className="barraPesquisaMobile">
      <button onClick={alterarBotao}>
        <img src="voltar.svg" alt="voltar" />
      </button>
      <InputMask
        mask={mascaraCNPJ}
        className="inputTextSearchMobile"
        type="search"
        placeholder="Pesquise por CNPJ"
        value={cnpj}
        onChange={(e) => setCnpj(e.target.value)}
        onKeyPress={(e) => keypress(e)}
      />
    </div>
  );
};
